import { Grid } from '@mui/material';
import React, { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import ApiService from '../../../../services/ApiService';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const SelectDataInvoice = (props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            props.setIsLoadingBackDrop(true);

            let params = [
                { 'attr': 'contactId', 'value': '' + props.SelectedData.customerId },
                { 'attr': 'contactTypeId', 'value': '' + props.SelectedData.customerTypeId }
            ];

            props.setInvoiceId(props.SelectedData.id);
            props.setInvoiceNo(props.SelectedData.invoiceNo2);
            props.setShipmentId(props.SelectedData.shipmentId);
            props.setShipmentNo(props.SelectedData.shipmentNo);
            props.setJobOwnerId(props.SelectedData.jobOwnerId);

            props.setCustomerId(props.SelectedData.customerId);
            props.setCustomerName(props.SelectedData.customerName);
            props.setCustomerAddress(props.SelectedData.customerAddress);

            ApiService.DynamicPostByPage("regContact/regContact", 'SEARCH', 1, 1, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        props.setNPWPData(response.data.data.contact[0].npwp);

                        if (response.data.data.contact[0].nppkp !== '') {
                            props.setNPPPKPData(response.data.data.contact[0].nppkp);
                        }

                        ApiService.OperationPostById("invoice/invoice", props.SelectedData.id)
                            .then((res) => {
                                if (res.data.code === 200) {
                                    props.setInvoiceDetailList(res.data.data.invoice.invoiceDetails);
                                }

                                props.setIsLoadingBackDrop(false);
                            })
                            .catch(function (error) {
                                props.setIsLoadingBackDrop(false);
                                console.error('error saat fetch', error);
                                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                            })
                    }
                })
                .catch(function (error) {
                    props.setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }

        emptyState();

        props.setIsModal(false);
    }

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getDataHeader(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#FFF'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'documents'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataInvoice;