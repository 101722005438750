import { Card, CardContent, Collapse, Grid, IconButton, Stack, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import { Icon, showToast, TextInfo } from "../../../../components/ui";
import { memo, useEffect, useState } from "react";
import ModalConote from "../Modals/ModalConote";
import ModalContainerTrucking from "../Modals/ModalContainerTrucking";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#FFFFFF',
        fontstyle: 'normal',
        padding: '8px 16px !important',
        overflow: 'hidden',
        color: 'var(--Mjr-black-2, #747475)',
        textOverflow: 'ellipsis',
        fontFamily: 'Switzer',
        fontSize: '8px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '150%',
        letterSpacing: '0.32px',
    },
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
        padding: '0px 16px !important',
        height: '35px !important',
        color: 'var(--Mjr-black-1, #171717)',
        fontFamily: 'Switzer',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '160%',
        letterSpacing: '0.1px',
    },
}));

const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

const ConoteDetail = memo(({ index, data, ConoteData, setConoteData, ShipmentId, EditConote, DeleteConote, dataContainerPackaging, dataContainerType }) => {
    const [selectedDetail, setSelectedDetail] = useState({});
    const [isOpen, setIsOpen] = useState(true);

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [detailSequence, setDetailSequence] = useState(0);
    const [detailEdit, setDetailEdit] = useState(false);
    const [containerSize, setContainerSize] = useState('');
    const [containerNumber, setContainerNumber] = useState('');
    const [containerCollie, setContainerCollie] = useState('');
    const [containerKgs, setContainerKgs] = useState('');
    const [containerCbm, setContainerCbm] = useState('');
    const [containerType, setContainerType] = useState('');
    const [containerSealNumber, setContainerSealNumber] = useState('');
    const [containerCommodity, setContainerCommodity] = useState('');
    const [containerPieces, setContainerPieces] = useState('');
    const [containerPackaging, setContainerPackaging] = useState('');
    const [containerPartOf, setContainerPartOf] = useState('');

    const handleClick = () => {
        if (isModal) {
            setIsModal(false);
        }
        else {
            setIsModal(true);
        }
    }

    const emptyState = () => {
        setDetailEdit(false);
        setSelectedDetail({});
        setDetailSequence(0);
        setContainerSize('');
        setContainerNumber('');
        setContainerCollie('');
        setContainerKgs('');
        setContainerCbm('');
        setContainerType('');
        setContainerSealNumber('');
        setContainerCommodity('');
        setContainerPieces('');
        setContainerPackaging('');
        setContainerPartOf('');
    }

    const AddContainer = () => {
        setTitleModal('Add New Container');
        setDetailEdit(false);
        if (ConoteData.length > 0) {
            let data = ConoteData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleClick();
    }

    const EditContainer = (data) => {
        if (!data.sequence) {
            showToast({ type: 'error', message: 'Please Select a Container To Edit!' });
        }
        else {
            setTitleModal('Edit Container');
            setDetailEdit(true);

            setDetailSequence(data.sequence);
            setContainerSize(data.containerSize);
            setContainerNumber(data.containerNo);
            setContainerCollie(data.collie);
            setContainerKgs(data.netWeight);
            setContainerCbm(data.cbm);
            setContainerType(data.containerTypeCode);
            setContainerSealNumber(data.sealNo);
            setContainerCommodity(data.commodity);
            setContainerPieces(data.noOfPieces);
            setContainerPackaging(data.packagingCode);
            setContainerPartOf(data.partOf);

            handleClick();
        }
    }

    const DeleteContainer = (dataContainer) => {
        let tempSequence = dataContainer.sequence;

        const deleteFunction = (data) => {
            return data.sequence !== tempSequence;
        }

        const result = ConoteData.map((el) => {
            if (el.sequence === data.sequence) {
                let tempSequence = 0;

                el.containerData = el.containerData.filter(deleteFunction);

                if (el.containerData.length > 0) {
                    el.containerData.forEach((el) => {
                        tempSequence += 1;
                        el.sequence = tempSequence;
                    })
                }
            }

            return el;
        })

        setSelectedDetail({});
        setConoteData(result);
    }

    const handleDoubleClick = () => {
        EditContainer(selectedDetail);
    }

    const handleOpenCollapse = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
        else {
            setIsOpen(false);
        }
    }

    return (
        <>
            <Card
                id={index}
                component="div"
                style={{
                    marginTop: '24px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '24px',
                    alignSelf: 'stretch',
                    borderRadius: '12px',
                    border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                    background: 'var(--Mjr-white-4, #FFF)',
                }}
            >
                <CardContent style={{ width: '100%', padding: '24px 40px 24px 32px', }}>
                    <Grid container sx={{ width: '100%' }}>
                        <Grid item xs={5}>
                            <Typography
                                sx={{
                                    color: 'var(--Mjr-black-1, #171717)',
                                    fontFamily: 'Switzer',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}
                            >
                                {`${data.commodity} - ${data.conNote}`}
                            </Typography>

                            <div style={{ marginTop: '8px' }}>
                                <Stack direction={'row'} spacing={'8px'}>
                                    {ButtonComponent.AddButton(AddContainer, 'CONTAINER')}
                                    {ButtonComponent.EditButton(() => EditConote(data), '')}
                                    {ButtonComponent.DeleteButton(() => DeleteConote(data), '')}
                                </Stack>
                            </div>
                        </Grid>

                        <Grid item xs={2}>
                            <TextInfo title={'Qty Collie'} value={data.collie} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextInfo title={'Qty Kgs'} value={data.weight} />
                        </Grid>

                        <Grid item xs={2}>
                            <TextInfo title={'Qty Cbm'} value={data.cbm} />
                        </Grid>

                        <Grid item xs={1} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                disableFocusRipple={true}
                                disableRipple={true}
                                onClick={() => handleOpenCollapse()}
                                sx={{ display: "flex", alignItems: "flex-start", justifyContent: "flex-start", height: "auto" }}
                            >
                                <Icon
                                    iconName={isOpen ? 'arrow-up' : 'arrow-down'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#00286D',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px', marginTop: '24px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell>Container No</StyledTableCell>
                                        <StyledTableCell>Seal No</StyledTableCell>
                                        <StyledTableCell>Size</StyledTableCell>
                                        <StyledTableCell>Type</StyledTableCell>
                                        <StyledTableCell>Part Of</StyledTableCell>
                                        <StyledTableCell>Commodity</StyledTableCell>
                                        <StyledTableCell>No. Of Pieces</StyledTableCell>
                                        <StyledTableCell>Packing</StyledTableCell>
                                        <StyledTableCell>Collie</StyledTableCell>
                                        <StyledTableCell>Kgs</StyledTableCell>
                                        <StyledTableCell>Cbm</StyledTableCell>
                                        <StyledTableCell>Action</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        data.containerData.length > 0 ?
                                            data.containerData.map((el) => {
                                                return (
                                                    <TableRow
                                                        key={el.sequence}
                                                        onClick={() => setSelectedDetail(el)}
                                                        onDoubleClick={() => handleDoubleClick()}
                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                        hover={true}
                                                    >
                                                        <StyledTableBodyCell>{el.containerNo}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.sealNo}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.containerSize}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.containerTypeCode}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.partOf ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.commodity}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.noOfPieces}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.packagingCode}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.collie}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.netWeight}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.cbm}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>
                                                            <Stack direction={'row'} spacing={'8px'}>
                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    disableFocusRipple={true}
                                                                    disableRipple={true}
                                                                    onClick={() => EditContainer(el)}
                                                                >
                                                                    <Icon
                                                                        iconName={'edit'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#00286D',
                                                                            sx: {
                                                                                height: '10px',
                                                                                width: '10px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>

                                                                <IconButton
                                                                    aria-label="expand row"
                                                                    size="small"
                                                                    disableFocusRipple={true}
                                                                    disableRipple={true}
                                                                    onClick={() => DeleteContainer(el)}
                                                                >
                                                                    <Icon
                                                                        iconName={'delete'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#BE2957',
                                                                            sx: {
                                                                                height: '10px',
                                                                                width: '10px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Stack>
                                                        </StyledTableBodyCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Mjr-black-1, #171717)',
                                                            fontFamily: 'Switzer',
                                                            fontSize: '10px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '160%',
                                                            letterSpacing: '0.1px',
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Collapse>
                </CardContent>
            </Card>

            {
                isModal && (
                    <ModalContainerTrucking
                        isModal={isModal}
                        titleModal={titleModal}
                        handleClick={handleClick}
                        emptyState={emptyState}

                        containerSize={containerSize}
                        setContainerSize={setContainerSize}
                        containerNumber={containerNumber}
                        setContainerNumber={setContainerNumber}
                        containerCollie={containerCollie}
                        setContainerCollie={setContainerCollie}
                        containerKgs={containerKgs}
                        setContainerKgs={setContainerKgs}
                        containerCbm={containerCbm}
                        setContainerCbm={setContainerCbm}
                        containerType={containerType}
                        setContainerType={setContainerType}
                        dataContainerType={dataContainerType}
                        containerSealNumber={containerSealNumber}
                        setContainerSealNumber={setContainerSealNumber}
                        containerCommodity={containerCommodity}
                        setContainerCommodity={setContainerCommodity}
                        containerPieces={containerPieces}
                        setContainerPieces={setContainerPieces}
                        containerPackaging={containerPackaging}
                        setContainerPackaging={setContainerPackaging}
                        dataContainerPackaging={dataContainerPackaging}
                        containerPartOf={containerPartOf}
                        setContainerPartOf={setContainerPartOf}
                        detailEdit={detailEdit}
                        detailSequence={detailSequence}
                        selectedDetail={selectedDetail}
                        ShipmentId={ShipmentId}

                        SelectedConote={data}
                        ConoteData={ConoteData}
                        setConoteData={setConoteData}
                    />
                )
            }
        </>
    )
})

const Conote = memo(({
    ConoteData,
    setConoteData,
    ShipmentId,
    dataContainerPackaging,
    dataContainerType,
}) => {
    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [detailSequence, setDetailSequence] = useState(0);
    const [conoteNumber, setConoteNumber] = useState('');
    const [conoteName, setConoteName] = useState('');
    const [conoteCollie, setConoteCollie] = useState(0);
    const [conoteKgs, setConoteKgs] = useState(0);
    const [conoteCbm, setConoteCbm] = useState(0);
    const [detailEdit, setDetailEdit] = useState(false);
    const [totalContainer, setTotalContainer] = useState(0);
    const [total20, setTotal20] = useState(0);
    const [total40, setTotal40] = useState(0);
    const [total45, setTotal45] = useState(0);

    useEffect(() => {
        handleCalculateTotalContainer();
        // eslint-disable-next-line
    }, [ConoteData]);

    const handleCalculateTotalContainer = () => {
        let totalContainer = 0;
        let total20 = 0;
        let total40 = 0;
        let total45 = 0;

        ConoteData.forEach((el) => {
            el.containerData.forEach((data) => {
                totalContainer += 1;

                if (data.containerSize === `20'`) {
                    total20 += 1;
                }
                else if (data.containerSize === `40'`) {
                    total40 += 1;
                }
                else if (data.containerSize === `45'`) {
                    total45 += 1;
                }
            })
        })

        setTotalContainer(totalContainer);
        setTotal20(total20);
        setTotal40(total40);
        setTotal45(total45);
    }

    const handleClick = () => {
        if (isModal) {
            setIsModal(false);
        }
        else {
            setIsModal(true);
        }
    }

    const emptyState = () => {
        setDetailEdit(false);
        setConoteNumber('');
        setConoteName('');
        setConoteCollie(0);
        setConoteKgs(0);
        setConoteCbm(0);
    }

    const AddConote = () => {
        setTitleModal('Add New Conote');
        setDetailEdit(false);
        if (ConoteData.length > 0) {
            let data = ConoteData.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        handleClick();
    }

    const EditConote = (data) => {
        setTitleModal('Edit Conote');
        setDetailEdit(true);

        setDetailSequence(data.sequence);
        setConoteNumber(data.number);
        setConoteName(data.name);
        setConoteCollie(data.collie);
        setConoteKgs(data.kgs);
        setConoteCbm(data.cbm);
        handleClick();
    }

    const DeleteConote = (data) => {
        let tempSequence = data.sequence;

        const deleteFunction = (data) => {
            return data.sequence !== tempSequence;
        }

        const result = ConoteData.filter(deleteFunction);

        if (result.length > 0) {
            tempSequence = 0;

            result.forEach((el) => {
                tempSequence += 1;
                el.sequence = tempSequence;
            })
        }

        setConoteData(result);
    }

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <form>
                    {ButtonComponent.AddButton(AddConote, 'NEW', false, false, true)}

                    {
                        ConoteData.length > 0 && (
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '24px 40px 24px 32px',
                                    alignItems: 'flex-start',
                                    alignSelf: 'stretch',
                                    borderTop: '1px solid var(--Mjr-white-1, #E9E9EA)',
                                    background: 'var(--Mjr-white-3, #FAFAFA)',
                                    marginTop: '16px'
                                }}
                            >
                                <Grid container sx={{ width: '100%' }}>
                                    <Grid item xs={5}>
                                        <TextInfo title={'Container Total'} value={totalContainer} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextInfo title={`20'`} value={total20} />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <TextInfo title={`40'`} value={total40} />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextInfo title={`45'`} value={total45} />
                                    </Grid>
                                </Grid>
                            </div>
                        )
                    }

                    {
                        ConoteData.length > 0 && (
                            ConoteData.map((data, index) => {
                                return (
                                    <ConoteDetail
                                        index={index}
                                        data={data}
                                        ConoteData={ConoteData}
                                        setConoteData={setConoteData}
                                        ShipmentId={ShipmentId}
                                        EditConote={EditConote}
                                        DeleteConote={DeleteConote}
                                        dataContainerPackaging={dataContainerPackaging}
                                        dataContainerType={dataContainerType}
                                    />
                                )
                            })
                        )
                    }
                </form>
            </div>

            {
                isModal && (
                    <ModalConote
                        isModal={isModal}
                        titleModal={titleModal}
                        handleClick={handleClick}
                        conoteNumber={conoteNumber}
                        setConoteNumber={setConoteNumber}
                        conoteName={conoteName}
                        setConoteName={setConoteName}
                        conoteCollie={conoteCollie}
                        setConoteCollie={setConoteCollie}
                        conoteKgs={conoteKgs}
                        setConoteKgs={setConoteKgs}
                        conoteCbm={conoteCbm}
                        setConoteCbm={setConoteCbm}
                        ShipmentId={ShipmentId}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        emptyState={emptyState}
                        detailSequence={detailSequence}
                        setConoteData={setConoteData}
                        conoteData={ConoteData}
                    />
                )
            }
        </>
    )
});

export default memo(Conote);