import { Box, Typography, Grid, Button } from '@mui/material';
import React, { memo } from 'react';
import { PopUp } from '../../../../components/ui';

const ModalConfirmRequest = memo(({ isModal, handleClick, handleExportData, isImport = false }) => {
    return (
        <>
            <PopUp
                id='modal-id'
                title={isImport ? 'Update Confirmation' : 'Export Confirmation'}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xs'}
                fullWidth={true}
                topButton={false}
            >
                <Box display="flex" sx={{ marginTop: '16px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Typography
                        sx={{
                            color: 'var(--Mjr-black-1, #171717)',
                            fontFamily: 'Switzer',
                            fontSize: '10px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            letterSpacing: '0.1px',
                        }}
                    >
                        {isImport ? `All tax invoice number(s) in invoice will be updated to our system. Are you sure you want to update invoice?` : `Selected invoice(s) will be exported to XML file that need to submit to Coretax. Are you sure you want to export?`}
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" sx={{ marginTop: '24px' }}>
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="text"
                                className='d-flex align-items-center'
                                sx={{
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '14px',
                                    background: 'var(--Mjr-red-2, #6692DE)',
                                    '&:hover': {
                                        backgroundColor: `#6692DE !important`,
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={handleExportData}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-white-4, #FFF)',
                                        textAlign: 'center',
                                        fontFamily: 'Switzer',
                                        fontSize: '8px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '150%',
                                        letterSpacing: '0.16px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {isImport ? 'UPDATE INVOICE' : 'EXPORT'}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </PopUp >
        </>
    )
})

export default memo(ModalConfirmRequest);