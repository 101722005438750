import { Grid } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const ModalAccount = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    function Close() {
        setNumPage(1);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({})
        setFilter([])
        setFilterBy([])
        props.handleClick();
    }

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        } else {
            props.setTempAccountId(props.SelectedData.id);
            props.setTempAccountCode(props.SelectedData.masterCode);
            props.setTempAccountName(props.SelectedData.name)
            props.setDescription(props.SelectedData.name)
            props.setCOACode(props.SelectedData.coaCode)
            props.setCOAName(props.SelectedData.coaName)
            props.setIsContainer(props.SelectedData.quantity);
            if (props.SelectedData.name === 'OTHER') {
                props.setIsOthers(true);
            }
            else {
                props.setIsOthers(false);
            }
            if (props.SelectedData.quantity === false) {
                props.setContainerSize('All');
                props.setContainerTypeId(0);
                props.setContainerTypeCode('');
                props.setContainerTypeName('');
                props.setContainerFormat('All');
                // props.handleQuantityUnit(0);
            }
            else {
                if (props.detailEdit === false) {
                    if (props.containerData.length > 0) {
                        props.setContainerSize(props.containerData[0].containerSize);
                        props.setContainerTypeId(props.containerData[0].containerTypeId);
                        props.setContainerTypeCode(props.containerData[0].containerTypeCode);
                        props.setContainerTypeName(props.containerData[0].containerTypeName);
                        props.setContainerFormat(props.containerData[0].formatContainer);
                        props.handleQuantityUnit(props.containerData[0].amount);
                    }
                    else {
                        if (props.isVolumeCBM) {
                            props.setContainerSize('M3');
                            props.setQuantity(props.shipmentVolumeCBM);
                        }
                    }
                }
            }
            props.handleClick()
        }
        setNumPage(1);
        setRowsCount(50);
        setStateX({})
        setFilter([])
        setFilterBy([])
        setGoTo(1);
    }

    const handleReset = () => {
        setGoTo(1);
        setNumPage(1);
        setRowsCount(50);
        setStateX({})
        setFilter([])
        setFilterBy([])
        props.getData(1, 50)
        setStateX({})
    }

    function CellDoubleClick(row) {
        Select()
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#FFF'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            dataTable={props.dataTable}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Close}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default ModalAccount