import { Box, Typography, Grid, Button } from '@mui/material';
import React, { memo } from 'react';
import { PopUp } from '../../../../components/ui';

const ModalChangeProfile = memo(({ isModal, handleClick, ChangeUserProfile }) => {
    return (
        <>
            <PopUp
                id='modal-id'
                title={'Switch User Role'}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xs'}
                fullWidth={true}
                topButton={false}
            >
                <Box display="flex" sx={{ marginTop: '16px', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Typography
                        sx={{
                            color: 'var(--Mjr-black-1, #171717)',
                            fontFamily: 'Switzer',
                            fontSize: '10px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            letterSpacing: '0.1px',
                        }}
                    >
                        Switching roles will display all data from the selected branch. Do you want to continue?
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center" sx={{ marginTop: '24px' }}>
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '14px',
                                    background: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                    '&:hover': {
                                        backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={ChangeUserProfile}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-white-4, #FFF)',
                                        textAlign: 'center',
                                        fontFamily: 'Switzer',
                                        fontSize: '8px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '150%',
                                        letterSpacing: '0.16px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Switch Role
                                </Typography>
                            </Button>

                            <Button
                                size='small'
                                variant="text"
                                className='d-flex align-items-center'
                                sx={{
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '14px',
                                    marginInlineStart: '12px',
                                }}
                                onClick={handleClick}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--existing-Background-Blue-1, #0087C2)',
                                        textAlign: 'center',
                                        fontFamily: 'Switzer',
                                        fontSize: '8px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '150%',
                                        letterSpacing: '0.16px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </PopUp>
        </>
    )
})

export default memo(ModalChangeProfile);