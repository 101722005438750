import React, { forwardRef, useEffect, useState } from 'react'
import { Backdrop, Box, Card, CardContent, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';

import ModalSearchShipmentOrder from './components/ModalSearchShipmentOrder';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast, TickBox } from '../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';

const ActualEstimateProfitMarginReport = forwardRef(() => {
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const localJobTypeId = localStorage.getItem("jobTypeId")
  const date = new Date()

  const [jobTypeList, setJobTypeList] = useState([])
  const [principleList, setPrincipleList] = useState([])

  const [activeModal, setActiveModal] = useState(false)
  const [selectedShipmentOrder, setSelectedShipmentOrder] = useState({})

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Actual & Estimate Profit Margin Report', link: '/Majura/ActualEstimateProfitMarginReport', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Actual & Estimate Profit Margin Report - ' + JSON.parse(localStorage.getItem('branchName'));
    getAdditionalData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorToast = (location) => {
    showToast({ type: 'error', message: `Can't get ${location} data! please contact Administrator` })
  }

  const getAdditionalData = async () => {
    try {
      const [resJT, resJO] = await Promise.all([
        ApiService.PostByPage('regJobType/regJobType', 1, 999, []),
        ApiService.PostByPage('jobOwner/jobOwner', 1, 999, []),
      ])

      if (resJT?.data?.code === 200) {
        const tempData = resJT.data.data.jobType
        const mapData = tempData.map((jt) => {
          return { value: jt.id, label: jt.name }
        })

        setJobTypeList(mapData)
      } else {
        errorToast('job type')
      }

      if (resJO?.data?.code === 200) {
        const tempData = resJO.data.data.jobOwner
        const mapData = tempData.map((jo) => {
          return { value: jo.id, label: jo.name }
        })

        if (mapData.length === 1) {
          formik.setFieldValue('principle', mapData[0].value)
        }
        setPrincipleList(mapData)
      } else {
        errorToast('principal')
      }

    } catch (error) {
      errorToast('additional')
    }

    setIsLoadingBackDrop(false)
  }

  const checkInput = () => {
    let check = 0;

    if (formik.values.shipmentOrder === 'custom' && selectedShipmentOrder.shipmentNo === undefined) {
      check = 1;
    }

    if (check === 1) {
      showToast({ type: 'error', message: 'Please Select Data Shipment Order!' })
    }

    return check;
  }

  const handlePrint = (fileType) => {
    if (checkInput() === 0) {
      setIsLoadingBackDrop(true);

      let params = [];
      let isAllSubJob = 0;
      let shipmentOrderId = 0;
      let payload = formik.values;

      if (selectedShipmentOrder.shipmentNo !== undefined) {
        shipmentOrderId = selectedShipmentOrder.id;
      }

      if (payload.printSubJob !== false) {
        isAllSubJob = 1;
      }

      params = [
        ...params,
        {
          "attr": "StartDate",
          "value": payload.eta
        },
        {
          "attr": "EndDate",
          "value": payload.etd
        },
        {
          "attr": "JobTypeId",
          "value": '' + payload.jobType
        },
        {
          "attr": "JobOwnerId",
          "value": '' + payload.principle
        },
        {
          "attr": "ShipmentId",
          "value": '' + shipmentOrderId
        },
        {
          "attr": "CTCType",
          "value": '' + payload.costToCost
        },
        {
          "attr": "IsAllSubJob",
          "value": '' + isAllSubJob
        }
      ]

      let reportLink = 'Reports/Finance/Print/ControlReport/ActEstProfitMarginReport/ActEstProfitMarginReport/PrintActualEstimateProfitMargin?typeReport=' + fileType;
      if (fileType === 'pdf') {
        PrintPDF(setIsLoadingBackDrop, 'Actual Estimate Profit Margin', reportLink, params);
      }
      else {
        PrintExcel(setIsLoadingBackDrop, 'Actual Estimate Profit Margin', reportLink, params);
      }
    }
  }

  const searchModal = () => {
    setActiveModal(!activeModal)
  }

  const formik = useFormik({
    initialValues: {
      principle: '0',
      costToCost: '2',
      jobType: localJobTypeId || 1,
      shipmentOrder: 'all',
      printSubJob: false,
      eta: date.toLocaleDateString("sv-se").split('T')[0],
      etd: date.toLocaleDateString("sv-se").split('T')[0],
    },
    validationSchema: undefined,
  })

  const ButtonComponents = () => {
    return (
      <div className="row" spacing={'12px'}>
        <div className='col-12' spacing={'12px'}>
          <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
            {ButtonComponent.PrintButton(() => handlePrint('pdf'), 'PDF')}
            {ButtonComponent.ExcelButton(() => handlePrint('xls'), 'Print Excel')}
          </Grid>
        </div>
      </div>
    )
  }

  function handleChange(name, value) {
    formik.setFieldValue(name, !value);
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: 3 }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo
          title={'Actual & Estimate Profit Margin Report'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div style={{ marginTop: '24px' }}>
            <Grid
              container
              spacing={'32px'}
            >
              <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                  <CardContent>
                    <Grid container spacing={'16px'}>
                      <Grid item xs={6}>
                        <Selection
                          id='0'
                          fullWidth={true}
                          label={'Principle By'}
                          value={formik.values.principle}
                          placeholder={'Select Principle By'}
                          onChange={(e) => {
                            formik.setFieldValue('principle', e.target.value)
                            setSelectedShipmentOrder({})
                          }}
                        >
                          {
                            principleList.length !== 1 &&
                            <MenuItem sx={{ fontSize: '12px' }} value={'0'}>All</MenuItem>
                          }
                          {
                            principleList.map((principle) => (
                              <MenuItem sx={{ fontSize: '12px' }} key={principle.value} value={principle.value}>{principle.label}</MenuItem>
                            ))
                          }
                        </Selection>
                      </Grid>

                      <Grid item xs={6}>
                        <Selection
                          id='0'
                          fullWidth={true}
                          value={formik.values.costToCost}
                          label={'Cost To Cost (Reimbursement)'}
                          placeholder={'Select Cost To Cost (Reimbursement)'}
                          onChange={(e) => formik.setFieldValue('costToCost', e.target.value)}
                        >
                          <MenuItem sx={{ fontSize: '12px' }} value={'2'}>All</MenuItem>
                          <MenuItem sx={{ fontSize: '12px' }} value={'1'}>Yes</MenuItem>
                          <MenuItem sx={{ fontSize: '12px' }} value={'0'}>No</MenuItem>
                        </Selection>
                      </Grid>

                      <Grid item xs={12}>
                        <Selection
                          id='0'
                          fullWidth={true}
                          label={'Shipment Status'}
                          value={formik.values.jobType}
                          placeholder={'Select Shipment Status'}
                          onChange={(e) => {
                            formik.setFieldValue('jobType', e.target.value)
                            setSelectedShipmentOrder({})
                          }}
                        >
                          {
                            jobTypeList.map((jobType) => (
                              <MenuItem sx={{ fontSize: '12px' }} key={jobType.value} value={jobType.value}>{jobType.label}</MenuItem>
                            ))
                          }
                        </Selection>

                        <Box mt={'16px'} p={'16px'} borderRadius={'5px'} border={'1px solid #C4C4C4'}>
                          <RadioGroup
                            row
                            value={formik.values.shipmentOrder}
                            onChange={(e) => {
                              formik.setFieldValue('shipmentOrder', e.target.value)
                              setSelectedShipmentOrder({})
                            }}
                          >
                            <FormControlLabel
                              sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '12px !important',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                              }}
                              control={<Radio size='medium' />}
                              key={0}
                              value={'all'}
                              label={
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  All Shipment Order
                                </Typography>
                              }
                            />

                            <FormControlLabel
                              sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '12px !important',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                              }}
                              control={<Radio size='medium' />}
                              key={1}
                              value={'custom'}
                              label={
                                <Typography
                                  sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '12px !important',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20px',
                                  }}
                                >
                                  Custom Shipment Order
                                </Typography>
                              }
                            />
                          </RadioGroup>

                          <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            placeholder='Search Shipment Order'
                            value={selectedShipmentOrder?.shipmentNo || ''}
                            type='text'
                            disabled
                            formControlProps={{
                              sx: {
                                width: '100%',
                                marginTop: '16px !important'
                              }
                            }}
                            inputBaseProps={{
                              readOnly: true,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={searchModal}
                                    hidden={formik.values.shipmentOrder === 'all' || formik.values.jobType === 0}
                                  >
                                    <Icon
                                      iconName={'search'}
                                      svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                          height: '12px',
                                          width: '12px'
                                        }
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                          />

                          <TickBox
                            size={'small'}
                            name="printSubJob"
                            label={
                              <Typography
                                sx={{
                                  color: 'var(--Blue-Primary-1, #083A50)',
                                  fontFamily: 'Nunito',
                                  fontSize: '12px !important',
                                  fontStyle: 'normal',
                                  fontWeight: '500',
                                  lineHeight: '20px',
                                  marginTop: '1px'
                                }}
                              >
                                Print All Sub Job
                              </Typography>
                            }
                            checked={formik.values.printSubJob}
                            baseLabelProps={{ sx: { marginTop: '16px' } }}
                            onChange={() => handleChange('printSubJob', formik.values.printSubJob)}
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '20px',
                            marginBottom: '3px'
                          }}
                        >
                          ETA/ETD
                        </Typography>

                        <Stack spacing={'16px'} direction={'row'} alignItems={'center'}>
                          <CustomDatePicker
                            value={formik.values.eta}
                            onChange={(e) => formik.setFieldValue('eta', e)}
                            type={'date'}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          />

                          <Typography
                            sx={{
                              color: 'var(--Blue-Primary-1, #083A50)',
                              fontFamily: 'Nunito',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '20px',
                              marginBottom: '12px'
                            }}
                          >
                            To
                          </Typography>

                          <CustomDatePicker
                            value={formik.values.etd}
                            onChange={(e) => formik.setFieldValue('etd', e)}
                            type={'date'}
                            formControlProps={{
                              sx: {
                                width: '100%',
                              }
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }

      {activeModal && (
        <ModalSearchShipmentOrder
          isModal={activeModal}
          activeModal={activeModal}
          toggleModal={searchModal}
          dataSelected={selectedShipmentOrder}
          onSelect={setSelectedShipmentOrder}
          errorToast={errorToast}
          formValue={formik.values}
        />
      )}
    </Box>
  )
})

export default ActualEstimateProfitMarginReport