import { forwardRef, memo, useImperativeHandle, useRef, useState } from "react";
import { FullPopUp, Icon, Paginate, showToast, TickBox } from "../../../../components/ui";
import { Typography, Stack, Box, Grid, IconButton } from "@mui/material";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import TableCheck from "../../../../components/Tables/TableCheck";
import LoadingSpinner from "../../../../components/Components/LoadingSpinner";
import ModalConfirmRequest from "../Modals/ModalConfirmRequest";
import { encryptFunction, PrintXML } from "../../../../utils/reusableFunction";
import { isListAsc } from "../../../../services/ApiService";

const TitleDetailComp = forwardRef(({ subTitle = '', handleChange }, ref) => {
    return (
        <div ref={ref}>
            <Stack direction={'row'} alignItems={'center'} spacing={'8px'}>
                <Typography
                    sx={{
                        color: 'var(--Mjr-black-1, #171717)',
                        fontFamily: 'Switzer',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                    }}
                >
                    {subTitle}
                </Typography>

                {ButtonComponent.CustomButton(handleChange, 'convert', 'CHANGE')}
            </Stack>
        </div>
    )
})

const SelectDataInvoice = memo(forwardRef((
    {
        isModal,
        handleClick,
        subTitle,
        handleChangeData,
        setTotalRows,
        getData,
        columnData,
        isLoading,
        dataMap,
        SelectedData,
        setSelectedData,
        dataTable,
        totalRows,
        setIsLoadingBackDrop,
        filterByData,
        Data1,
        Data2,
        emptyStateParent,
        getDataParent,
        fetchDataTab,
        dataCheck,
        setDataCheck
    }
    , ref) => {
    const titleDetailRef = useRef(null);
    const modalTableRef = useRef(null);

    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [dataNoCheck, setDataNoCheck] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [isSelectAll, setIsSelectAll] = useState(true);

    const [isModalConfirm, setIsModalConfirm] = useState(false);

    useImperativeHandle(ref, () => ({
        emptyState,
    }));

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setDataCheck([]);
        setDataNoCheck([]);
        setIsSelectAll(true);
    };

    const handleChange = () => {
        handleChangeData();
    }

    function Cancel() {
        emptyState();
        handleClick();
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50);
    };

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter, sortArr);
    }

    const getContainerHeight = () => {
        const hTitle = 32
        const hPadding = 64
        const hBtnPage = 56
        const hTabPage = 64
        const actionPage = 110

        const totalHeight = hPadding + hTitle + hBtnPage + hTabPage + actionPage

        return `${totalHeight}px`
    }

    const handleChangeSelectAll = (event) => {
        setIsSelectAll(event.target.checked);

        if (event.target.checked) {
            setDataCheck(dataMap);
            setDataNoCheck([]);
        }
        else {
            setDataCheck([]);
            setDataNoCheck(dataMap);
        }
    }

    const handleClickConfirm = () => {
        if (isModalConfirm === false) {
            setIsModalConfirm(true);
        }
        else {
            setIsModalConfirm(false);
        }
    }

    const handleExport = () => {
        if (dataCheck.length > 0) {
            handleClickConfirm();
        }
        else {
            showToast({ type: 'error', message: 'Please Select At Least One Invoice!' });
        }
    }

    const handleExportData = () => {
        setIsLoadingBackDrop(true);

        let params = [];
        let unProcessedData = '';

        dataNoCheck.forEach((element, index) => {
            let dataType = element.invoiceType === 'Job' ? 'INV' : 'GI';
            if (index === dataNoCheck.length - 1) {
                unProcessedData = unProcessedData + dataType + ':' + element.invoiceId;
            }
            else {
                unProcessedData = unProcessedData + dataType + ':' + element.invoiceId + ',';
            }
        });

        params = [
            ...params,
            {
                "attr": "FilterBy",
                "value": "" + filterByData
            },
            {
                "attr": "StartDate",
                "value": filterByData === '1' ? Data1 : ''
            },
            {
                "attr": "EndDate",
                "value": filterByData === '1' ? Data2 : ''
            },
            {
                "attr": "StartInvoice",
                "value": filterByData !== '1' ? Data1 : '0'
            },
            {
                "attr": "EndInvoice",
                "value": filterByData !== '1' ? Data2 : '0'
            },
            {
                "attr": "UnProcessedData",
                "value": unProcessedData
            }
        ];

        handleClickConfirm();

        let reportLink = 'Reports/Finance/Print/EFaktur/ExportEFakturReport/ExportEFakturReport/PrintExportEFakturXML?payloads=' + encryptFunction(params);
        PrintXML(setIsLoadingBackDrop, 'Export E-Faktur', reportLink, [], handleFinishExport);
    }

    const handleFinishExport = () => {
        handleClick();

        setTimeout(() => {
            emptyStateParent();
            getDataParent(isListAsc === true ? 0 : 1, 50, []);
            fetchDataTab();
        }, 2500);
    }

    const scrollToBottom = () => {
        if (modalTableRef.current) {
            modalTableRef.current.scrollBy({ top: 10000, behavior: "smooth" });
        }
    };

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    style={{
                        display: 'flex',
                        padding: '0px 16px 16px',
                        marginLeft: '5px'
                    }}
                >
                    <TickBox
                        size={'small'}
                        label={
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    color: 'var(--Mjr-Random-Dark, #6692DE)',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Switzer',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '140%',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                {`Select All (${totalRows} Data)`}
                            </Typography>
                        }
                        checked={isSelectAll}
                        onChange={handleChangeSelectAll}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        padding: '8px 16px',
                        flexDirection: 'column',
                        alignSelf: 'stretch',
                        borderRadius: '12px',
                        border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                        background: 'var(--Mjr-white-4, #FFF)',
                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        marginTop: filterBy.length > 0 ? '16px' : '0px',
                    }}
                >
                    <div
                        ref={modalTableRef}
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                        }}
                    >
                        {
                            isLoading ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <TableCheck
                                            uniqueKey={'invoiceId'}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            dataCheck={dataCheck}
                                            setDataCheck={setDataCheck}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                            goTo={goTo}
                                            dataTable={dataTable}
                                            isCheckedAll={true}
                                            dataNoCheck={dataNoCheck}
                                            setDataNoCheck={setDataNoCheck}
                                            setIsSelectAll={setIsSelectAll}
                                            isSelectAll={isSelectAll}
                                        />
                                    </>
                                )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <FullPopUp
                id='modal-id'
                title={'Batch Request - Tax Invoice Number'}
                isOpen={isModal}
                handleClose={Cancel}
                titleDetail={
                    <TitleDetailComp
                        subTitle={subTitle}
                        handleChange={handleChange}
                        ref={titleDetailRef}
                    />
                }
            >
                <Box sx={{ marginTop: '8px', width: '100%' }}>
                    {mainContainer}

                    <div style={{ marginTop: '32px' }}>
                        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={8}>
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-black-1, #171717)',
                                        fontFamily: 'Switzer',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '140%',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    You will be get the XML file contains above invoices that need to upload to CORETAX system to request the tax invoice (faktur) number
                                </Typography>

                                <div style={{ marginTop: '16px' }}>
                                    {ButtonComponent.UploadButton(handleExport, 'EXPORT', true)}
                                </div>
                            </Grid>

                            <Grid item xs={4} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        display: 'flex',
                                        padding: '12px 20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '16px',
                                        border: '1px solid var(--Mjr-Random-Light, #96B9FF)',
                                        maxHeight: '42px',
                                        maxWidth: '58px'
                                    }}
                                    onClick={scrollToBottom}
                                >
                                    <Icon
                                        iconName="arrow-down"
                                        svgIconProps={{
                                            fontSize: "small",
                                            htmlColor: "#00286D",
                                            sx: { height: "18px", width: "18px" },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </FullPopUp>

            {
                isModalConfirm && (
                    <ModalConfirmRequest
                        isModal={isModalConfirm}
                        handleClick={handleClickConfirm}
                        handleExportData={handleExportData}
                    />
                )
            }
        </>
    );
}))

export default memo(SelectDataInvoice);