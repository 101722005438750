import { forwardRef, memo } from "react";
import { CustomRadioGroup, Icon, Input, PopUp, Selection, showToast } from "../../../../components/ui";
import CustomizeButtonComponent from "../../../../components/Buttons/CustomizeButtonComponent";
import { Grid, MenuItem, Stack } from "@mui/material";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { branchId, companyId, countryId } from "../../../../services/ApiService";

const ContainerMask = forwardRef(({ maskOptions, ...inputProps }, ref) => {
    return (
        <MaskedInput
            {...inputProps}
            mask={[
                /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/, /[a-zA-Z]/,
                " ",
                /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,
                "-",
                /\d/
            ]}
            keepCharPositions
        />
    );
});

ContainerMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const ModalContainerTrucking = memo(({
    isModal,
    titleModal,
    handleClick,
    emptyState,
    containerSize,
    setContainerSize,
    containerNumber,
    setContainerNumber,
    containerCollie,
    setContainerCollie,
    containerKgs,
    setContainerKgs,
    containerCbm,
    setContainerCbm,
    containerType,
    setContainerType,
    dataContainerType,
    containerSealNumber,
    setContainerSealNumber,
    containerCommodity,
    setContainerCommodity,
    containerPieces,
    setContainerPieces,
    containerPackaging,
    setContainerPackaging,
    dataContainerPackaging,
    containerPartOf,
    setContainerPartOf,
    detailEdit,
    detailSequence,
    selectedDetail,
    ShipmentId,
    ConoteData,
    setConoteData,
    SelectedConote
}) => {
    const dataSize = [
        { id: 1, code: `20'`, name: `20'` },
        { id: 2, code: `40'`, name: `40'` },
        { id: 3, code: `45'`, name: `45'` },
    ];

    const dataPartOf = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ];

    const handleSelectContainerSize = (value) => {
        setContainerSize(value);
    }

    const handleContainerPartOf = (value) => {
        setContainerPartOf(value);
    }

    const handleClose = () => {
        emptyState();
        handleClick();
    }

    const handleSave = () => {
        let Sequence = 1;

        if (detailEdit) {
            if (selectedDetail.sequence) {
                Sequence = selectedDetail.sequence;
            }
            else {
                Sequence = detailSequence;
            }
        }
        else {
            if (detailSequence > 0) Sequence = detailSequence + 1;
        }

        const selectedType = dataContainerType.find(data => data.code === containerType);
        const containerTypeId = selectedType.id;
        const containerTypeName = selectedType.name;

        const selectedPackaging = dataContainerPackaging.find(data => data.code === containerPackaging);
        const containerPackagingName = selectedPackaging.name;

        let data = {
            "rowStatus": "ACT",
            "countryId": countryId,
            "companyId": companyId,
            "branchId": branchId,
            "sequence": Sequence,
            "shipmentId": ShipmentId,

            "sequenceConNote": SelectedConote.sequence,
            "containerNo": containerNumber,
            "sealNo": containerSealNumber,
            "containerSize": containerSize,
            "containerTypeId": containerTypeId,
            "containerTypeCode": containerType,
            "containerTypeName": containerTypeName,
            "cbm": parseFloat(containerCbm),
            "netWeight": parseFloat(containerKgs),
            "collie": parseInt(containerCollie),
            "partOf": JSON.parse(containerPartOf),
            "commodity": containerCommodity,
            "noOfPieces": parseInt(containerPieces),
            "packagingCode": containerPackaging,
            "packagingName": containerPackagingName,
        }

        let errorMessage = undefined;

        if (data.conNote === '') {
            errorMessage = 'Conote Number is required';
        }
        else if (data.commodity === '') {
            errorMessage = 'Item Name is required';
        }
        else if (data.collie === '') {
            errorMessage = 'Qty Collie is required';
        }
        else if (data.weight === '') {
            errorMessage = 'Qty Kgs is required';
        }
        else if (data.cbm === '') {
            errorMessage = 'Qty Cbm is required';
        }

        if (errorMessage === undefined) {
            let containerData = SelectedConote.containerData;

            if (!detailEdit) {
                containerData.push(data);
            }
            else {
                containerData.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.containerNo = data.containerNo;
                        el.sealNo = data.sealNo;
                        el.containerSize = data.containerSize;
                        el.containerTypeId = data.containerTypeId;
                        el.containerTypeCode = data.containerTypeCode;
                        el.containerTypeName = data.containerTypeName;
                        el.cbm = data.cbm;
                        el.netWeight = data.netWeight;
                        el.collie = data.collie;
                        el.partOf = data.partOf;
                        el.commodity = data.commodity;
                        el.noOfPieces = data.noOfPieces;
                        el.packagingCode = data.packagingCode;
                        el.packagingName = data.packagingName;
                    }
                });
            }

            const UpdatedConoteData = ConoteData.slice();
            UpdatedConoteData.forEach(el => {
                if (el.sequence === SelectedConote.sequence) {
                    el.containerData = containerData;
                }
            });

            setConoteData(UpdatedConoteData);

            handleClose();
        }
        else {
            showToast({ type: 'error', message: errorMessage });
        }
    }

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={titleModal}
            isOpen={isModal}
            onClose={handleClose}
            draggable={true}
            shrinkable={true}
            disableScrollLock={true}
            maxWidth={'md'}
            fullWidth={true}
            topButton={false}
        >
            <div style={{ padding: '16px 6px 0px' }}>
                <Grid container spacing={'32px'}>
                    <Grid item xs={6}>
                        <CustomRadioGroup
                            title={'Container Size'}
                            isChecked={containerSize}
                            setIsChecked={handleSelectContainerSize}
                            dataGroup={dataSize}
                            dataLength={dataSize.length}
                        />

                        <Input
                            label="Container Number"
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important',
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: ContainerMask,
                                value: containerNumber,
                                onChange: (e) => {
                                    let text = e.target.value
                                    if (text) { text = text.toUpperCase() }
                                    setContainerNumber(text)
                                }
                            }}
                        />

                        <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                            <Input
                                label="Qty Collie"
                                value={containerCollie}
                                onChange={(e) => setContainerCollie(e.target.value)}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label="Qty Kgs"
                                value={containerKgs}
                                onChange={(e) => setContainerKgs(e.target.value)}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label="Qty Cbm"
                                value={containerCbm}
                                onChange={(e) => setContainerCbm(e.target.value)}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        </Stack>
                    </Grid>

                    <Grid item xs={6}>
                        <Selection
                            id='0'
                            label={'Type'}
                            fullWidth={true}
                            placeholder={'Select Type'}
                            value={containerType}
                            onChange={(e) => setContainerType(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                        >
                            {
                                dataContainerType.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.code} - {data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Input
                            label="Seal Number"
                            value={containerSealNumber}
                            onChange={(e) => setContainerSealNumber(e.target.value)}
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important',
                                }
                            }}
                        />

                        <Input
                            label={'Commodity'}
                            value={containerCommodity}
                            onChange={(e) => setContainerCommodity(e.target.value)}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important',
                                }
                            }}
                            inputBaseProps={{
                                rows: 4,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: '16px' }}>
                    <Grid container spacing={'32px'}>
                        <Grid item xs={6}>
                            <Stack direction={'row'} spacing={'16px'}>
                                <Input
                                    label="No of Pieces"
                                    value={containerPieces}
                                    onChange={(e) => setContainerPieces(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Selection
                                    id='0'
                                    label={'Packaging'}
                                    fullWidth={true}
                                    placeholder={'Select Packaging'}
                                    value={containerPackaging}
                                    onChange={(e) => setContainerPackaging(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%'
                                        }
                                    }}
                                >
                                    {
                                        dataContainerPackaging.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.code} - {data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>
                            </Stack>
                        </Grid>

                        <Grid item xs={6}>
                            <CustomRadioGroup
                                title={'Part Of'}
                                isChecked={containerPartOf}
                                setIsChecked={handleContainerPartOf}
                                dataGroup={dataPartOf}
                            />
                        </Grid>
                    </Grid>
                </div>

                <div style={{ marginTop: '32px' }}>
                    {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                </div>
            </div>
        </PopUp>
    )
})

export default memo(ModalContainerTrucking);