import { Typography, IconButton, Table, TableContainer, TableBody, TableRow, TableCell, tableCellClasses } from '@mui/material';
import React, {useState} from 'react';
import { styled } from '@mui/material/styles';

import '../../../../Styles/scrollbar.css';
import { Icon } from "../../../../components/ui";

const IncomeStatement = (props) => {
    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
      [`&.${tableCellClasses.body}`]: {
        // backgroundColor: '#FFFFFF',
        color: 'var(--Blue-Primary-1, #083A50)',
        fontFamily: 'Nunito',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
        padding: '12px 16px !important',
      },
    }));

    function handleOpen(openChild, setOpenChild, rData)
    {
        setOpenChild(!openChild);
        
        let data = JSON.parse(localStorage.getItem(props.localVar));
        handleChild(openChild, rData, data);
        localStorage.setItem(props.localVar, JSON.stringify(data));
        props.setData(data);

        return !openChild;
    }

    function handleChild(openChild, rData, data)
    {
        data.forEach(element => {
            if(element.coaParentId === rData.coaParentId)
            {
                if(element.coaId === rData.coaId)
                {
                    element.isCollapse = !openChild;
                }
                else
                {
                    handleChild(openChild, rData, element.incomeStatements);
                }
            }
            else
            {
                if(element.dataMartGroups !== undefined)
                {
                    if(element.dataMartGroups.length > 0)
                    {
                        handleChild(openChild, rData, element.dataMartGroups);
                    }
                    else
                    {
                        handleChild(openChild, rData, element.incomeStatements);
                    }
                }
                else
                {
                    handleChild(openChild, rData, element.incomeStatements);
                }
            }
        });
    }

    const IncomeStatementComponent = (props) => {
        const [openChild, setOpenChild] = useState(props.data.isCollapse);

        if(props.data.accountTitle !== undefined)
        {
            const rData = props.data;
            let openRow = props.open;

            let weight = 'medium';
            let fontSize = 13;
            
            if(props.child === true)
            {
                weight = 'normal';
                fontSize = 12;
            }

            return (
                <>
                    <tr
                        hidden = {!openRow}
                        style = {{
                            'cursor': 'pointer', 
                            'fontSize':'12px',
                        }} 
                        className="align-middle"
                        onClick={() => handleOpen(openChild, setOpenChild, rData)}
                    >
                        {
                            rData.incomeStatements.length > 0 && rData.incomeStatements !== undefined?
                                <td colSpan={1}>
                                    <IconButton
                                        aria-label="expand row"
                                        size='medium'
                                        onClick={() => handleOpen(openChild, setOpenChild, rData)}
                                    >
                                        {
                                            openChild ?
                                                <Icon 
                                                    iconName={'arrow-up'}
                                                    svgIconProps={{
                                                        fontSize: 'medium',
                                                        htmlColor: '#0087C2',
                                                        sx: { 
                                                            height: '12px', 
                                                            width: '12px' 
                                                        }
                                                    }}
                                                />
                                            :
                                                <Icon 
                                                    iconName={'arrow-down'}
                                                    svgIconProps={{
                                                        fontSize: 'medium',
                                                        htmlColor: '#0087C2',
                                                        sx: { 
                                                            height: '12px', 
                                                            width: '12px' 
                                                        }
                                                    }}
                                                />
                                        }
                                    </IconButton>
                                </td>
                            :
                                <td></td>
                        }

                        <td>
                            <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="left">
                                {rData.accountTitle}
                            </Typography>
                        </td>

                        <td>
                            {
                                props.child === true?
                                    rData.actualIDR < 0?
                                        <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="right">
                                            ({new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.actualIDR))}) &nbsp; IDR
                                        </Typography>
                                    :
                                        <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="right">
                                            {new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.actualIDR))} &nbsp; IDR
                                        </Typography>
                                :
                                    rData.sumIDRDisplay < 0?
                                        <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="right">
                                            ({new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.sumIDRDisplay))}) &nbsp; IDR
                                        </Typography>
                                    :
                                        <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="right">
                                            {new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.sumIDRDisplay))} &nbsp; IDR
                                        </Typography>
                            }
                        </td>
                    </tr>

                    {
                        rData.incomeStatements.length > 0?
                            rData.incomeStatements.map((rows, index) => (
                                <IncomeStatementComponent key={index} data = {rows} open = {rData.isCollapse} child = {true} />
                            ))
                        :
                            <></>
                    }
                </>
            )
        }
    }

    const DataMartComponent = (props) => {
        if(props.data.name !== undefined)
        {
            let rData = props.data;

            let weight = 'bold';
            let fontSize = 15;

            if(props.child === true)
            {
                weight = 'bold';
                fontSize = 14;
            }

            return (
                <>
                    {
                        rData.dataMartGroups.length > 0 && rData.dataMartGroups !== undefined?
                            rData.dataMartGroups.map((rows, index) => (
                                <DataMartComponent key={index} data = {rows} child = {true} />
                            ))
                        :
                            <></>
                    }
                    
                    {
                        props.child === false && rData.incomeStatements.length > 0 && rData.incomeStatements !== undefined?
                            rData.incomeStatements.map((rows, index) => (
                                <IncomeStatementComponent key={index} data = {rows} open = {true} child = {false} />
                            ))
                        :
                            <></>
                    }

                    <tr
                        style = {{
                            'cursor': 'pointer', 
                            'fontSize':'12px',
                            'borderBottom': props.child === false? '1.5px solid' : '',
                        }} 
                        className="align-middle"
                    >
                        <td colSpan={2}>
                            <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="left">
                                {rData.name}
                            </Typography>
                        </td>
                        
                        <td style={{width: '12vw'}}>
                            {
                                rData.sumIDRDisplay < 0?
                                    <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="right">
                                        ({new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.sumIDRDisplay))}) &nbsp; IDR
                                    </Typography>
                                :
                                    <Typography sx={{ fontSize: fontSize, fontWeight: weight }} align="right">
                                        {new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.sumIDRDisplay))} &nbsp; IDR
                                    </Typography>
                            }
                        </td>
                    </tr>
                    
                    {
                        props.child === true && rData.incomeStatements.length > 0?
                            rData.incomeStatements.map((rows, index) => (
                                <IncomeStatementComponent key={index} data = {rows} open = {true} child = {false} />
                            ))
                        :
                            <></>
                    }
                </>
            )
        }
    }

    const DataMartAdditionalComponent = (props)  => {
        if(props.data.name !== undefined)
        {
            let rData = props.data;

            return (
                <>
                    <tr
                        style = {{
                            'cursor': 'pointer', 
                            'fontSize':'12px',
                            'borderTop': '1.5px solid',
                        }} 
                        className="align-middle"
                    >
                        <td colSpan={2}>
                            <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} align="left">
                                {rData.name}
                            </Typography>
                        </td>
                        
                        <td style={{width: '12vw'}}>
                            {
                                rData.amount < 0?
                                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} align="right">
                                        ({new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.amount))}) &nbsp; IDR
                                    </Typography>
                                :
                                    <Typography sx={{ fontSize: 15, fontWeight: 'bold' }} align="right">
                                        {new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(Math.abs(rData.amount))} &nbsp; IDR
                                    </Typography>
                            }
                        </td>
                    </tr>
                </>
            )
        }
    }

    return (
        <div className='table-responsive' style={{ padding: '24px 12px' }}>
            <Typography 
                sx={{
                    color: 'var(--Primary-One, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '18px',
                    fontstyle: 'normal',
                    fontWeight: '700',
                    lineHeight: 'normal'
                }} 
                gutterBottom
            >
                {props.title}
            </Typography>

            <TableContainer sx={{ background: '#FFF', marginTop: '16px' }}>
                <Table>
                    <TableBody>
                        {
                            props.dataI.length > 0?
                                <>
                                    {
                                        props.dataI.map((row, index) => (
                                            <DataMartComponent key = {index} data = {row} child = {false} />
                                        ))
                                    }
                                    {
                                        props.dataJ.length > 0 && props.dataJ !== undefined?
                                            props.dataJ.map((row, index) => (
                                                <DataMartAdditionalComponent key = {index} data = {row} />
                                            ))
                                        :
                                            <></>
                                    }
                                </>
                            :
                                <TableRow sx={{ border: '0px !important' }}>
                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '15px',
                                                fontstyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            Data Empty
                                        </Typography>
                                    </StyledTableBodyCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default IncomeStatement;