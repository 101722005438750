import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Avatar, Backdrop, Box, Button, Grid, IconButton, Menu, MenuItem, Stack, Toolbar, Typography, styled, useTheme } from '@mui/material';

import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import MyRoute from '../../Components/MyRoute';
import Login, { getDataMenu } from '../../../pages/Account/Login';
import AppHeader from '../Header/AppHeader';
import ShrinkMenu from './ShrinkMenu';
import ApiService, { branchId, branchName, countryName, userCode, userId } from '../../../services/ApiService';
import { getActiveNodes } from '../../../utils/useSidebar';
import MajuraIconBlue from '../../../Assets/Icon/majuraIconOnlyBlue.svg';

import { openedMixin, closedMixin, drawerWidth } from './config';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { Icon, showToast } from '../../ui';
import ModalChangeProfile from '../../../pages/Account/Profile/Modals/ModalChangeProfile';
import jwtDecode from 'jwt-decode';
import LoadingSpinner from '../../Components/LoadingSpinner';

const missingIcon = '';
const dataProfile = JSON.parse(localStorage.getItem("dataProfile"))
const MIN_WIDTH = 240;
const MAX_WIDTH = 360;
const dataMenu = JSON.parse(localStorage.getItem("dataMenu")) || [];
const dataMenuCode = JSON.parse(localStorage.getItem("dataMenuCode")) || [];

export const LightTooltip = styled(({ className, ...props }) => (<Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: theme.palette.common.white,
        backgroundColor: '#fffcbd',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 0px 35px 0px rgba(154, 161, 171, 0.15)',
        fontSize: 11,
    },
}));

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
    color: theme.palette.text.secondary,
    '&:not(.MuiTreeItem-child-root)': {
        margin: '0 -16px',
    },
    '.MuiTreeItem-group': {
        '.MuiTreeItem-child-root': {
            '.MuiTreeItem-content': {
                paddingLeft: '16px',
                alignItems: 'center'
            },
        },
    },
    '.MuiTreeItem-iconContainer': {
        svg: {
            transition: 'all .01s ease-in-out',
        },
    },
    '&.MuiTreeItem-parent-root': {
        '&.MuiParent-shrink-selected .MuiTreeItem-content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        }
    },
    [`& .${treeItemClasses.content}`]: {
        flexDirection: 'row-reverse',
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightRegular,
        transition: 'all .01s ease-out',
        borderRadius: '4px',
        '&.Mui-selected': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '&.Mui-expanded': {
            fontWeight: theme.typography.fontWeightBold,
        },
        '&:not(&.Mui-expanded)': {
            '.MuiTreeItem-iconContainer': {
                svg: {
                    transform: 'rotate(-90deg)'
                }
            },
        },
        '&.Mui-collapsed': {
            marginLeft: '17px',
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
            color: 'var(--tree-view-color)',
        },
        [`& .${treeItemClasses.label}`]: {
            fontWeight: 'inherit',
            color: 'inherit',
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 0,
        [`& .${treeItemClasses.content}`]: {
            paddingLeft: theme.spacing(2),
        },
    },
}));

const StyledTreeItem = React.memo((props) => {
    const { bgColor, color, labelIcon, fontWeight, labelInfo, labelText, isParent, parentId, isChild, textRef, ...other } = props;

    return (
        <StyledTreeItemRoot
            label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: '8px 4px' }}>
                    <Icon
                        iconName={labelIcon}
                        svgIconProps={{
                            htmlColor: props.open ? '#00286D' : '#D1D1D2',
                            sx: {
                                height: isParent ? '14px' : '12px',
                                width: isParent ? '14px' : '12px',
                                marginTop: '-2px'
                            }
                        }}
                    />

                    <Typography
                        ref={textRef}
                        sx={{
                            color: props.open ? '#00286D' : 'var(--Mjr-black-2, #747475)',
                            fontFamily: 'Switzer',
                            fontSize: '10px',
                            fontStyle: 'normal',
                            fontWeight: fontWeight,
                            lineHeight: '160%',
                            letterSpacing: '0.1px',
                            flexGrow: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginInlineStart: isChild ? '24px' : '8px',
                        }}
                    >
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </Box>
            }
            title={labelText}
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
})

StyledTreeItem.propTypes = {
    bgColor: PropTypes.string,
    color: PropTypes.string,
    labelIcon: PropTypes.string.isRequired,
    labelInfo: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    isParent: PropTypes.bool,
    parentId: PropTypes.string,
};

// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     height: '80px',
//     justifyContent: 'flex-end',
//     padding: theme.spacing(0, 1),
//     // necessary for content to be below app bar
//     ...theme.mixins.toolbar,
// }));

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open', })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open, drawerWidth = '240' }) => ({
        width: `${drawerWidth}px`,  // Set width dynamically
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        boxShadow: 1,
        border: 'none',
        borderRight: '1px solid var(--Mjr-blue-4, #E5E9F0)',
        background: 'var(--Mjr-white-4, #FFF)',
        ...(open && {
            ...openedMixin(theme, drawerWidth),  // Pass drawerWidth dynamically
            '& .MuiDrawer-paper': { ...openedMixin(theme, drawerWidth) },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': { ...closedMixin(theme) },
        }),
    })
);

export default function SideNavbar() {
    const { pathname } = useLocation()
    const [nodeExpanded, setNodeExpanded] = useState([]);
    const [nodeSelected, setNodeSelected] = useState();
    const [parentNodeSelected, setParentNodeSelected] = useState();
    const [childNodeSelected, setChildNodeSelected] = useState();

    const theme = useTheme();

    const childRef = useRef();
    const [pin, setPin] = useState(JSON.parse(localStorage.getItem("pinStatus")) === 'true' ? true : false);
    const [open, setOpen] = React.useState(JSON.parse(localStorage.getItem("sidebarStatus")) === 'true' ? true : false);
    const history = useNavigate();

    useEffect(() => {
        checkLogin();
        const activeNodes = getActiveNodes(dataMenu, pathname)
        setNodeExpanded(activeNodes?.expandedNode)
        setNodeSelected(activeNodes?.selectedNode)
        setParentNodeSelected(activeNodes?.selectedParentNode)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function checkLogin() {
        let userNames = JSON.parse(localStorage.getItem("username"));
        if (!userNames) {
            history('/', { replace: true });
            window.location.reload();
        }
    }

    const handleToggle = (e, nodeIds) => {
        setNodeExpanded(nodeIds)
    };

    const handleSelect = (e, nodeId) => {
        if (dataMenuCode.findIndex((menu) => menu.id === nodeId) !== -1) {
            setNodeSelected(nodeId)
        }
    }

    const handleDrawerOpen = () => {
        setOpen(true);
        localStorage.setItem("sidebarStatus", JSON.stringify('true'));
    };

    const handleDrawerClose = () => {
        if (!pin) {
            setOpen(false);
            localStorage.setItem("sidebarStatus", JSON.stringify('false'));
        }
    };

    function nodeClick(url, parentId) {
        history(url)
        setParentNodeSelected(parentId)
        setChildNodeSelected(url)
    };

    const renderTree = (nodes) => (
        <StyledTreeItem
            key={nodes.id}
            nodeId={nodes.id}
            parentId={nodes.id}
            labelText={nodes.name}
            labelIcon={nodes?.icon || missingIcon}
            isParent={true}
            textRef={(el) => (textRefs.current[nodes.id] = el)}
            open={nodes.id === parentNodeSelected ? true : false}
            hidden={nodes.hidden === 'true' ? true : false}
            fontWeight={nodes.id === parentNodeSelected ? '700' : '400'}
            color={!open && nodes.id === parentNodeSelected ? theme.palette.primary?.[500] : null}
            bgColor={!open && nodes.id === parentNodeSelected ? "transparent" : null}
            className={`MuiTreeItem-parent-root ${!open && nodes.id === parentNodeSelected ? 'MuiParent-shrink-selected' : ''}`}
        >
            {
                Array.isArray(nodes.children) ?
                    nodes.children.map((node) => renderTreeChild(node, nodes.id))
                    :
                    null
            }
        </StyledTreeItem>
    );

    const renderTreeChild = (nodes, parentId, isChild = false) => (
        <StyledTreeItem
            key={nodes.id}
            nodeId={nodes.id}
            parentId={parentId}
            labelText={nodes.name}
            textRef={(el) => (textRefs.current[nodes.id] = el)}
            open={childNodeSelected === nodes.url ? true : false}
            labelIcon={nodes.url !== '' ? nodes?.icon || missingIcon : ''}
            color={nodes.url !== '' ? theme.palette.primary?.[500] : null}
            bgColor={nodes.url !== '' ? "#fafafa" : null}
            fontWeight={childNodeSelected === nodes.url ? '700' : '400'}
            onClick={() => (nodes.url !== '' ? nodeClick(nodes.url, parentId) : null)}
            onContextMenu={(e) => {
                if (nodes.url !== '') {
                    e.preventDefault();
                    handleWindowsMenuOpen(e)
                    setUrl(nodes.url)
                }
            }}
            isChild={isChild}
            hidden={nodes.hidden === 'true' ? true : false}
            className='MuiTreeItem-child-root'
        >
            {
                Array.isArray(nodes.children) ?
                    nodes.children.map((node) => {
                        return (
                            <div>
                                {renderTreeChild(node, parentId, true)}
                            </div>
                        )
                    })
                    :
                    null
            }
        </StyledTreeItem>
    );

    const handlePin = () => {
        if (pin) {
            setPin(false)
            localStorage.setItem("pinStatus", JSON.stringify('false'));
            handleDrawerClose();
        }
        else {
            setPin(true)
            localStorage.setItem("pinStatus", JSON.stringify('true'));
        }
    }

    const roleRef = useRef(null);
    const treeRef = useRef(null);
    const textRefs = useRef([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorRoleEl, setAnchorRoleEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMenuRoleOpen = Boolean(anchorRoleEl);
    const [url, setUrl] = useState('');
    const menuId = 'primary-search-account-menu';

    const [shrinkNodeEl, setShrinkNodeEl] = useState(null);
    const [shrinkActiveMenu, setShrinkActiveMenu] = useState([]);
    const isShrinkMenuOpen = Boolean(shrinkNodeEl);

    const [draggableDrawerWidth, setDraggableDrawerWidth] = useState(JSON.parse(localStorage.getItem("drawerWidth")) ? JSON.parse(localStorage.getItem("drawerWidth")) : MIN_WIDTH); // Initial width
    const isResizing = useRef(false);
    const drawerRef = useRef(null);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [isModalProfile, setIsModalProfile] = useState(false);
    const [SelectedProfile, setSelectedProfile] = useState();

    const handleClickProfile = () => {
        if (isModalProfile) {
            setIsModalProfile(false);
        }
        else {
            setIsModalProfile(true);
        }
    }

    const handleChangeProfile = (item) => {
        setSelectedProfile(item);
        handleClickProfile();
    }

    const ChangeUserProfile = (item) => {
        setIsLoadingBackDrop(true);

        const companyId = item.companyId;
        const countryId = item.countryId;
        const branchId = item.branchId;
        const companyName = item.companyName;
        const countryName = item.countryName;
        const branchName = item.branchName;
        const roleId = item.roleId;
        const roleLevel = item.roleLevel;
        const roleName = item.roleName;
        const groupId = item.groupId;
        const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
        //set JWT token to local
        localStorage.setItem("companyId", JSON.stringify(companyId));
        localStorage.setItem("countryId", JSON.stringify(countryId));
        localStorage.setItem("branchId", JSON.stringify(branchId));
        localStorage.setItem("companyName", JSON.stringify(companyName));
        localStorage.setItem("countryName", JSON.stringify(countryName));
        localStorage.setItem("branchName", JSON.stringify(branchName));
        localStorage.setItem("roleId", JSON.stringify(roleId));
        localStorage.setItem("roleLevel", JSON.stringify(roleLevel));
        localStorage.setItem("roleName", JSON.stringify(roleName));
        localStorage.setItem("groupId", JSON.stringify(groupId));

        //get new token
        const data = {
            "groupId": groupId,
            "countryId": countryId,
            "companyId": companyId,
            "branchId": branchId,
            "userId": userId,
            "roleId": roleId,
            "refreshToken": refreshToken,
        }
        ApiService.ChangeProfile(data).then((res) => {
            if (res.data.code === 200) {
                const data = res.data.data;
                let dataLogin = data.authentication;
                let jobTypeId = 0;
                let currency = 'USD';
                let parentCompanyId = 0;

                if (dataLogin.companyIdPusat !== undefined) {
                    parentCompanyId = dataLogin.companyIdPusat;
                }

                if (dataLogin.jobTypeId === 0) {
                    jobTypeId = 1;
                }
                else {
                    jobTypeId = dataLogin.jobTypeId;
                }

                let decode = jwtDecode(dataLogin.token);
                localStorage.setItem("page", JSON.stringify(1));
                localStorage.setItem("rows", JSON.stringify(50));
                localStorage.setItem("dataUser", JSON.stringify(data));
                localStorage.setItem('dashboardMenu', JSON.stringify(0));
                localStorage.setItem("pinStatus", JSON.stringify('false'));
                localStorage.setItem("jobTypeId", JSON.stringify(jobTypeId));
                localStorage.setItem("bsPeriod", JSON.stringify(new Date()));
                localStorage.setItem("token", JSON.stringify(dataLogin.token));
                localStorage.setItem("sidebarStatus", JSON.stringify('false'));
                localStorage.setItem("expiredToken", JSON.stringify(decode.exp));
                localStorage.setItem("userId", JSON.stringify(dataLogin.userId));
                localStorage.setItem("roleId", JSON.stringify(dataLogin.roleId));
                localStorage.setItem("username", JSON.stringify(dataLogin.code));
                localStorage.setItem("userCode", JSON.stringify(dataLogin.code));
                localStorage.setItem("groupId", JSON.stringify(dataLogin.groupId));
                localStorage.setItem('roleInt', JSON.stringify(dataLogin.roleCode));
                localStorage.setItem("roleName", JSON.stringify(dataLogin.roleName));
                localStorage.setItem("branchId", JSON.stringify(dataLogin.branchId));
                localStorage.setItem("roleLevel", JSON.stringify(dataLogin.roleLevel));
                localStorage.setItem("companyId", JSON.stringify(dataLogin.companyId));
                localStorage.setItem("idleTimer", JSON.stringify(dataLogin.idleTimer));
                localStorage.setItem("countryId", JSON.stringify(dataLogin.countryId));
                localStorage.setItem("roleCode", JSON.stringify(dataLogin.department));
                localStorage.setItem("employeeId", JSON.stringify(dataLogin.employeeId));
                localStorage.setItem('parentCompanyId', JSON.stringify(parentCompanyId));
                localStorage.setItem("branchName", JSON.stringify(dataLogin.branchName));
                localStorage.setItem(dataLogin.code + "-isLogOut", JSON.stringify(false));
                localStorage.setItem("companyName", JSON.stringify(dataLogin.companyName));
                localStorage.setItem("countryName", JSON.stringify(dataLogin.countryName));
                localStorage.setItem("refreshToken", JSON.stringify(dataLogin.refreshToken));

                if (currency === 'USD') {
                    localStorage.setItem('currency', JSON.stringify('US-en'));
                    localStorage.setItem('formatCurrency', JSON.stringify('USD'));
                }
                else if (currency === 'IDR') {
                    localStorage.setItem('currency', JSON.stringify('ID-id'));
                    localStorage.setItem('formatCurrency', JSON.stringify('IDR'));
                }

                //generate menu
                var dataMenu = data.permissions;
                getDataMenu(dataMenu)
                showToast({ type: 'success', message: 'Success Change Profile!' });

                setTimeout(
                    function () {
                        ApiService.refreshToken();
                        window.location.reload();
                        handleClickProfile();
                        setIsLoadingBackDrop(false);
                    },
                    1250);
            }
            else if (res.data.code === 500) {
                showToast({ type: 'error', message: 'Failed to Change Profile, Please Contact Administrator, ' + res.data.message });

                setTimeout(
                    function () {
                        setIsLoadingBackDrop(false);
                    },
                    1250);
            }
            else {
                showToast({ type: 'error', message: res.data.message });

                setTimeout(
                    function () {
                        setIsLoadingBackDrop(false);
                    },
                    1250);
            }
        }).catch(function (error) {
            console.error('error', error)
            // showToast({ type: 'error', message: 'Failed to Change Profile, Please Contact Administrator, ' + error });
        })
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuRoleClose = () => {
        setAnchorRoleEl(null);
    };

    const toggleShrinkMenu = (e, nodeIds) => {
        e?.preventDefault();
        if (!e || nodeIds === 'backdropClick') {
            setShrinkNodeEl(undefined)
            setShrinkActiveMenu([])
            return
        }
        setShrinkNodeEl(e.currentTarget)
        let indexMenu = dataMenu?.findIndex((menu) => menu.id === nodeIds?.[0])
        if (indexMenu !== -1) {
            setShrinkActiveMenu(dataMenu[indexMenu])
        }
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                flexDirection: 'column',
                borderRadius: '4px',
                marginTop: '6px',
                padding: '0px !important',
                boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: '16px',
                        padding: '0px !important',
                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        border: '1px solid var(--Mjr-blue-4, #E5E9F0)',
                        background: '#FFF',
                    }
                }
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem
                onClick={newTab}
                sx={{
                    padding: '8px 16px',
                    cursor: 'pointer',
                    borderBottom: '1px solid #E9E9EA',
                    transition: 'color 0.2s ease-in-out',
                }}
            >
                <Typography
                    sx={{
                        color: 'var(--Mjr-black-2, #747475)',
                        fontFamily: 'Switzer',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '160%',
                        letterSpacing: '0.1px',
                        '&:hover': {
                            color: '#00286D',
                        },
                    }}
                >
                    New Tab
                </Typography>
            </MenuItem>
            <MenuItem
                onClick={newWindow}
                sx={{
                    padding: '8px 16px',
                    cursor: 'pointer',
                    transition: 'color 0.2s ease-in-out',
                }}
            >
                <Typography
                    sx={{
                        color: 'var(--Mjr-black-2, #747475)',
                        fontFamily: 'Switzer',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '160%',
                        letterSpacing: '0.1px',
                        '&:hover': {
                            color: '#00286D',
                        },
                    }}
                >
                    New Window
                </Typography>
            </MenuItem>
        </Menu>
    );

    function newTab() {
        window.open(url, '_blank')
        handleMenuClose()
    };

    function newWindow() {
        window.open(url, '_blank', 'location=yes,height=screen.availHeight,width=screen.availWidth,scrollbars=yes,status=yes')
        handleMenuClose()
    };

    const handleWindowsMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Mouse move event to resize the drawer
    const handleMouseMove = useCallback((e) => {
        if (!isResizing.current || !drawerRef.current) return;

        const drawerLeft = drawerRef.current.getBoundingClientRect().left;
        const newWidth = e.clientX - drawerLeft; // Calculate relative to drawer

        if (newWidth >= MIN_WIDTH && newWidth <= MAX_WIDTH) {
            setDraggableDrawerWidth(newWidth);
            localStorage.setItem("drawerWidth", JSON.stringify(`${newWidth}`));
        }
    }, [setDraggableDrawerWidth, drawerRef, isResizing]);

    // Mouse up event to stop resizing
    const handleMouseUp = useCallback(() => {
        isResizing.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove]);

    // Mouse down event on the resizer
    const handleMouseDown = useCallback((e) => {
        e.preventDefault(); // Prevents text selection & TreeView interference
        isResizing.current = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [handleMouseMove, handleMouseUp]);

    return (
        <>
            {
                userCode ?
                    <Box sx={{ display: 'flex', width: "100%", height: "100vh", maxHeight: '100vh' }}>
                        <Grid container style={{ 'width': `100%`, height: '100%' }} >
                            <Drawer
                                variant="permanent"
                                open={open}
                                drawerWidth={draggableDrawerWidth}
                                onMouseEnter={handleDrawerOpen}
                                onMouseLeave={handleDrawerClose}
                                sx={{
                                    height: '100vh',
                                    borderRight: '1px solid var(--Mjr-blue-4, #E5E9F0) !important',
                                    background: 'var(--Mjr-white-4, #FFF) !important',
                                    transition: "width 0.3s ease-in-out",
                                    "& .MuiDrawer-paper": {
                                        transition: "width 0.3s ease-in-out",
                                    },
                                }}
                                ref={drawerRef}
                            >
                                <div
                                    style={{
                                        width: '1.5px',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        height: '100%',
                                        cursor: "ew-resize",
                                        position: "absolute",
                                        backgroundColor: "transparent",
                                        borderRadius: "5px",
                                        zIndex: 100,
                                        display: open ? "block" : "none",
                                        transition: "width 0.25s ease-in-out, background-color 0.25s ease-in-out",
                                    }}
                                    onMouseDown={handleMouseDown}
                                />

                                <div
                                    style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            padding: open ? '14px 24px' : '14px 16px',
                                            height: '64px !important',
                                            minHeight: '64px !important',
                                            maxHeight: '64px !important',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            width: '100%',
                                        }}
                                    >
                                        {
                                            open && (
                                                <Grid container alignItems='center' sx={{ width: '100%', height: '100%' }}>
                                                    <Grid item xs={3} hidden={!open}>
                                                        <Avatar sx={{ width: '20px', height: '20px' }} src={MajuraIconBlue} />
                                                    </Grid>

                                                    <Grid item xs={9} className='text-end' display={'flex'} justifyContent={'flex-end'} hidden={!open}>
                                                        <Button
                                                            sx={{
                                                                display: 'flex',
                                                                maxHeight: '28px !important',
                                                                height: '28px !important',
                                                                minHeight: '28px !important',
                                                                padding: '4px 12px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: '6px',
                                                                borderRadius: '16px',
                                                                border: '1px solid var(--Mjr-black-4, #D1D1D2)',
                                                                background: pin ? '#F2FAFF' : '#FFFFFF',
                                                            }}
                                                            hidden={!open}
                                                            onClick={handlePin}
                                                        >
                                                            <Stack direction={'row'} spacing={'6px'} alignItems={'center'} justifyContent={'center'}>
                                                                <Icon
                                                                    iconName={pin ? 'lock' : 'unlock'}
                                                                    svgIconProps={{
                                                                        htmlColor: '#00286D',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px',
                                                                        }
                                                                    }}
                                                                />

                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--Mjr-blue-2, #00286D)',
                                                                        textAlign: 'center',
                                                                        fontFamily: 'Switzer',
                                                                        fontSize: '8px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '600',
                                                                        lineHeight: '150%',
                                                                        letterSpacing: '0.16px',
                                                                        textTransform: 'uppercase',
                                                                        marginTop: '2px'
                                                                    }}
                                                                >
                                                                    {!pin ? 'Lock Menu' : 'Unlock Menu'}
                                                                </Typography>
                                                            </Stack>
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                        {
                                            !open && (
                                                <Grid container alignItems='center' sx={{ width: '100%', height: '100%', marginLeft: '3px' }}>
                                                    <Grid item xs={12} hidden={open} alignItems={'center'}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                maxHeight: '28px !important',
                                                                height: '28px !important',
                                                                minHeight: '28px !important',
                                                                padding: '4px 8px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                width: 'fit-content',
                                                                gap: '6px',
                                                                borderRadius: '16px',
                                                                border: '1px solid var(--Mjr-black-4, #D1D1D2)',
                                                            }}
                                                        >
                                                            <Icon
                                                                iconName={pin ? 'lock' : 'unlock'}
                                                                svgIconProps={{
                                                                    htmlColor: '#00286D',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px',
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Box>

                                    <div ref={roleRef}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                padding: '0px 24px',
                                                alignItems: 'center',
                                                gap: '8px',
                                                alignSelf: 'stretch',
                                                cursor: dataProfile.length === 0 ? 'inherit' : 'pointer',
                                            }}
                                            onClick={() => dataProfile.length === 0 ? null : setAnchorRoleEl(roleRef.current)}
                                        >
                                            <Grid container alignItems={'center'}>
                                                <Grid item xs={dataProfile.length !== 0 ? 9 : 12}>
                                                    <Stack direction={'column'} spacing={'2px'}>
                                                        <Typography
                                                            sx={{
                                                                overflow: 'hidden',
                                                                color: open ? 'var(--Mjr-black-2, #747475)' : 'transparent',
                                                                textOverflow: 'ellipsis',
                                                                fontFamily: 'Switzer',
                                                                fontSize: '10px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '160%',
                                                                letterSpacing: '0.1px',
                                                                userSelect: 'none',
                                                            }}
                                                        >
                                                            {branchName}
                                                        </Typography>

                                                        <Typography
                                                            sx={{
                                                                overflow: 'hidden',
                                                                color: open ? 'var(--Mjr-black-2, #747475)' : 'transparent',
                                                                textOverflow: 'ellipsis',
                                                                fontFamily: 'Switzer',
                                                                fontSize: '10px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '160%',
                                                                letterSpacing: '0.1px',
                                                                userSelect: 'none',
                                                            }}
                                                        >
                                                            {countryName}
                                                        </Typography>
                                                    </Stack>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={dataProfile.length === 0 ? 0 : 3}
                                                    className='text-end'
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                    hidden={dataProfile.length === 0 ? true : !open}
                                                >
                                                    <IconButton
                                                        size="small"
                                                        disableFocusRipple={true}
                                                        disableRipple={true}
                                                        aria-label="show new notifications"
                                                        color="inherit"
                                                        sx={{ padding: '0px !important' }}
                                                    >
                                                        <Icon
                                                            iconName={'arrow-down'}
                                                            svgIconProps={{
                                                                htmlColor: '#6692DE',
                                                                sx: {
                                                                    width: '14px',
                                                                    height: '14px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {
                                            dataProfile.length > 0 && (
                                                <Menu
                                                    id={'primary-search-account-menu'}
                                                    anchorEl={anchorRoleEl}
                                                    open={isMenuRoleOpen}
                                                    keepMounted
                                                    onClose={handleMenuRoleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    slotProps={{
                                                        paper: {
                                                            sx: {
                                                                borderRadius: '16px',
                                                                padding: '0px !important',
                                                                boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                                                border: '1px solid var(--Mjr-blue-4, #E5E9F0)',
                                                                background: '#FFF',
                                                            }
                                                        }
                                                    }}
                                                    MenuProps={{
                                                        sx: {
                                                            flexDirection: 'column',
                                                            borderRadius: '16px !important',
                                                            boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                                        }
                                                    }}
                                                    sx={{
                                                        marginTop: '8px !important',
                                                        borderRadius: '16px'
                                                    }}
                                                >
                                                    {
                                                        dataProfile.map((el, index) => {
                                                            return (
                                                                <MenuItem
                                                                    key={index}
                                                                    onClick={() => handleChangeProfile(el)}
                                                                    sx={{
                                                                        cursor: 'pointer',
                                                                        padding: '8px 16px',
                                                                        minWidth: roleRef.current?.clientWidth,
                                                                        width: '100%',
                                                                        borderBottom: index !== dataProfile.length - 1 ? '1px solid #E9E9EA' : '',
                                                                        transition: 'color 0.2s ease-in-out',
                                                                    }}
                                                                    hidden={el.branchId === branchId}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            color: 'var(--Mjr-black-2, #747475)',
                                                                            fontFamily: 'Switzer',
                                                                            fontSize: '10px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '160%',
                                                                            letterSpacing: '0.1px',
                                                                            '&:hover': {
                                                                                color: '#00286D',
                                                                            },
                                                                        }}
                                                                    >
                                                                        {el.branchName}
                                                                    </Typography>
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Menu>
                                            )
                                        }
                                    </div>

                                    {
                                        open && (
                                            <div style={{ padding: '16px 24px 0px' }}>
                                                <Box sx={{ borderTop: 1, borderColor: '#E9E9EA' }} />
                                            </div>
                                        )
                                    }

                                    <Box ref={treeRef}>
                                        <TreeView
                                            expanded={open ? nodeExpanded || [] : []}
                                            selected={nodeSelected || ''}
                                            onNodeToggle={open ? handleToggle : toggleShrinkMenu}
                                            onNodeSelect={open ? handleSelect : undefined}
                                            defaultCollapseIcon={open && <ExpandMore />}
                                            defaultExpandIcon={open && <ExpandMore />}
                                            defaultEndIcon={<div style={{ width: 24 }} />}
                                            sx={{
                                                padding: open ? '8px 24px 16px' : '25px 24px 16px',
                                                flexGrow: 1,
                                                maxWidth: `${MAX_WIDTH}px`,
                                                animation: 'all .01s ease-in-out',
                                            }}
                                        >

                                            {/* <StyledTreeItem
                                            key={'0'}
                                            nodeId={'0'}
                                            labelText={'DASHBOARD'}
                                            labelIcon={'dashboard'}
                                            isParent={true}
                                            open={parentNodeSelected === '0' ? true : false}
                                            parentId={parentNodeSelected}
                                            color={theme.palette.primary?.[300]}
                                            bgColor="#e8f0fe"
                                            sx={{ marginTop: open ? '0px' : '52px !important' }}
                                            onClick={() => { setParentNodeSelected('0'); nodeClick('/Majura/Dashboard', '0'); }}
                                        /> */}

                                            {
                                                dataMenu?.map((el, index) => {
                                                    return (
                                                        <Box key={index}>
                                                            {renderTree(el)}
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </TreeView>
                                    </Box>

                                    <ShrinkMenu
                                        shrinkNodeEl={shrinkNodeEl}
                                        isShrinkMenuOpen={isShrinkMenuOpen}
                                        toggleShrinkMenu={toggleShrinkMenu}
                                        shrinkActiveMenu={shrinkActiveMenu}
                                        handleClick={nodeClick}
                                        setNodeSelected={handleSelect}
                                    />
                                    {renderMenu}
                                </div>
                            </Drawer>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexGrow: 1,
                                    width: `calc(100% - ${draggableDrawerWidth}px)`,
                                    height: '100vh',
                                    background: '#FAFAFA'
                                }}
                            >
                                <AppBar
                                    position='static'
                                    sx={{
                                        boxShadow: 'none !important',
                                        '&.MuiPaper-root, &.MuiAppBar-root': {
                                            boxShadow: 'none !important',
                                        },
                                        '&MuiPaper-root-MuiAppBar-root': {
                                            boxShadow: 'none !important',
                                        },
                                    }}
                                >
                                    <Toolbar sx={{ px: { sm: 0 } }}>
                                        <Box width={'100%'}>
                                            <AppHeader childRef={childRef} />
                                        </Box>
                                    </Toolbar>
                                </AppBar>

                                <Grid component='div'>
                                    {/* <DrawerHeader /> */}
                                    <MyRoute childRef={childRef} />
                                </Grid>
                            </Box>
                        </Grid>
                    </Box >
                    :
                    <Login />
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalProfile && (
                    <ModalChangeProfile
                        isModal={isModalProfile}
                        handleClick={handleClickProfile}
                        setIsModal={setIsModalProfile}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}

                        ChangeUserProfile={() => ChangeUserProfile(SelectedProfile)}
                    />
                )
            }
        </>
    );
}