import { Box, Typography, Grid, Button, Stack, IconButton } from '@mui/material';
import React, { memo, useRef, useState, useCallback } from 'react';
import { Icon, PopUp, showToast } from '../../../../components/ui';

const ModalImportTaxNumber = memo(({ isModal, handleClick, handleImportData }) => {
    const fileInputRef = useRef(null);
    const [documentData, setDocumentData] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const allowedTypes = [
                "application/vnd.ms-excel", // .xls
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ];

            if (!allowedTypes.includes(file.type)) {
                showToast({ type: 'error', message: 'Invalid File Type. Please Upload Only Excel Files!' });
                return;
            }

            processFile(file);
        }

        event.target.value = '';
    };

    const processFile = (file) => {
        setDocumentData({
            url: URL.createObjectURL(file),
            name: file.name,
        });
    };

    const handleRemoveFile = () => {
        setDocumentData(null);
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];

        if (file) {
            const allowedTypes = [
                "application/vnd.ms-excel", // .xls
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ];

            if (!allowedTypes.includes(file.type)) {
                showToast({ type: 'error', message: 'Invalid File Type. Please Upload Only Excel Files!' });
                return;
            }

            processFile(file);
        }
    }, []);


    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <PopUp
            id='modal-id'
            title={'Import Tax Number'}
            isOpen={isModal}
            onClose={handleClick}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'xs'}
            fullWidth={true}
            topButton={false}
        >
            <Box
                id='designated-area'
                sx={{
                    marginTop: '16px',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography
                    sx={{
                        color: 'var(--Mjr-black-1, #171717)',
                        fontFamily: 'Switzer',
                        fontSize: '10px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        letterSpacing: '0.1px',
                        textAlign: 'justify'
                    }}
                >
                    Upload Excel file from Coretax to import tax numbers for multiple invoices in the system
                </Typography>

                <Box
                    display={'flex'}
                    sx={{
                        width: '100%',
                        borderRadius: '8px',
                        background: 'var(--Mjr-white-3,#FAFAFA)',
                        marginTop: '12px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '24px',
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    <Stack spacing={'12px'} direction="column" alignItems="center" sx={{ width: '100%' }}>
                        {documentData ? (
                            <div
                                style={{
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    alignSelf: 'stretch',
                                    borderRadius: '8px',
                                    border: '1px solid var(--Mjr-black-4, #D1D1D2)',
                                }}
                            >
                                <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item xs={8}>
                                        <Stack direction={'row'} spacing={'8px'} alignItems={'center'} justifyContent={'center'}>
                                            <Icon
                                                svgIconProps={{
                                                    htmlColor: '#00286D',
                                                    sx: { height: '12px', width: '12px' },
                                                }}
                                                iconName={'document-outline'}
                                            />

                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    color: 'var(--Mjr-black-1, #171717)',
                                                    textOverflow: 'ellipsis',
                                                    fontFamily: 'Switzer',
                                                    fontSize: '10px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '140%',
                                                    letterSpacing: '0.1px',
                                                }}
                                            >
                                                {documentData?.name}
                                            </Typography>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={4} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                        <IconButton
                                            sx={{ height: "20px", width: "20px" }}
                                            onClick={handleRemoveFile}
                                        >
                                            <Icon
                                                iconName="close"
                                                svgIconProps={{
                                                    fontSize: "small",
                                                    htmlColor: "#BE2957",
                                                    sx: { height: "12px", width: "12px" },
                                                }}
                                            />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <Typography
                                sx={{
                                    overflow: 'hidden',
                                    color: 'var(--Mjr-black-2, #747475)',
                                    textOverflow: 'ellipsis',
                                    fontFamily: 'Switzer',
                                    fontSize: '10px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '140%',
                                    letterSpacing: '0.1px',
                                }}
                            >
                                Upload Excel File Here
                            </Typography>
                        )}

                        <Box textAlign="center" mt={2}>
                            <Button
                                size='small'
                                variant="text"
                                sx={{
                                    padding: '8px 16px',
                                    borderRadius: '12px',
                                    border: '1px solid var(--Mjr-Random-Light, #96B9FF)',
                                }}
                                onClick={handleButtonClick}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-blue-2, #00286D)',
                                        fontFamily: 'Switzer',
                                        fontSize: '8px',
                                        fontWeight: '600',
                                    }}
                                >
                                    {documentData ? 'CHANGE FILE' : 'UPLOAD'}
                                </Typography>
                            </Button>

                            <input
                                type="file"
                                accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                            />
                        </Box>
                    </Stack>
                </Box>
            </Box>

            <Box display="flex" alignItems="center" sx={{ marginTop: '24px' }}>
                <Grid container item direction="row">
                    <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                        <Button
                            size='small'
                            variant="text"
                            sx={{
                                padding: '8px 16px',
                                borderRadius: '14px',
                                background: documentData === null ? '#EAEAEA' : 'var(--Mjr-red-2, #6692DE)',
                                '&:hover': { backgroundColor: `#6692DE !important` },
                            }}
                            onClick={() => handleImportData(documentData)}
                            disabled={documentData === null}
                        >
                            <Typography
                                sx={{
                                    color: documentData === null ? '#A3A3A3' : 'var(--Mjr-white-4, #FFF)',
                                    fontFamily: 'Switzer',
                                    fontSize: '8px',
                                    fontWeight: '600',
                                    textTransform: 'uppercase',
                                }}
                            >
                                IMPORT
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </PopUp>
    );
});

export default memo(ModalImportTaxNumber);