import {
    FormControl, Grid, MenuItem,
    IconButton, Stack, TextareaAutosize,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react';

import ApiService from '../../../../services/ApiService';
import TabsContact from '../Tabs/TabsContact';

import SelectDataContact from '../Selects/SelectDataContact';
import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { Table } from 'react-bootstrap';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const ShippingInstruction = (props) => {
    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [dataTableN, setDataTableN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    let countErrN = 1;

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');
    const [tipe, setTipe] = useState(0);
    const tipe2 = 1;
    const [contactTypeId2, setContactTypeId2] = useState(1);

    const paramC = 'regContact/regContact';
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    let countErrC = 1;

    const [tabValue, setTabValue] = useState('Shipper');

    const [description, setDescription] = useState(props.SIGoodDesc);

    // Sync initial value from parent to local state
    useEffect(() => {
        setDescription(props.SIGoodDesc);
    }, [props]);

    // Debounce function to limit update frequency
    const sendValueToParent = (newValue) => {
        if (props.setSIGoodDesc) {
            props.setSIGoodDesc(newValue);
        }
    };

    const handleChange = (event) => {
        const newValue = event.target.value.toUpperCase();
        setDescription(newValue);

        clearTimeout(ShippingInstruction.debounceTimeout);
        ShippingInstruction.debounceTimeout = setTimeout(() => {
            sendValueToParent(newValue);
        }, 300);
    };

    const handleBlur = () => {
        sendValueToParent(description);
    };

    const handleClick = (title) => {
        if (isModal === false) {
            setIsModal(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal(false);
            localStorage.removeItem("title");
        }
    };

    const handleClick2 = (type1) => {
        if (isModal2 === false) {
            setIsModal2(true);
            localStorage.setItem("type1", JSON.stringify(type1));
            getDataContact(1, 50);
        }
        else {
            setIsModal2(false);
            localStorage.removeItem("type1");
        }
    };

    const AddCollectAt = (data) => {
        if (data === 'search') {
            setTitleModal2('Agent');
            setContactTypeId2(21);
            setTipe(1);
            handleClick2(1);
        }
        else if (data === 'delete') {
            props.setSICollectAgentId(0);
            props.setSICollectAgentName('');
            props.setSICollectAgentAddress('');
        }
    };

    const dataOriginalBL = [
        { id: 1, code: 'N', name: 'None' },
        { id: 2, code: 'T', name: '3 (Three)' },
        { id: 3, code: 'S', name: 'Seawaybill' },
    ];

    const dataFreightOBL = [
        { id: 1, code: 'P', name: 'Prepaid' },
        { id: 2, code: 'C', name: 'Collect' },
        { id: 3, code: 'A', name: 'Collect At' },
    ];

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        let title = JSON.parse(localStorage.getItem("title"));

        if (title === 'Company') {
            param = 'company/company';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (title) {
                            case 'Company':
                                setDataN(response.data.data.company);
                                temp = response.data.data.company;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableN, 'id');
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    countErrN++
                    if (countErrN < 3) {
                        getDataNonContact(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                        setIsModal(false);
                    }
                })
        }
    };

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        if (JSON.parse(localStorage.getItem("type1"))) {
            let type = parseInt(JSON.parse(localStorage.getItem("type1")));

            if (JSON.parse(localStorage.getItem("type2"))) {
                var type2 = parseInt(JSON.parse(localStorage.getItem("type2")));
                ApiService.BookingPContactPostByPage(paramC, type, type2, pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        countErrC++
                        if (countErrC < 3) {
                            getDataContact(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                            setIsModal2(false);
                        }
                    })
            }
            else {
                ApiService.ContactPostByPage(paramC, type, pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        countErrC++
                        if (countErrC < 3) {
                            getDataContact(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                            setIsModal2(false);
                        }
                    })
            }
        }
        else {
            showToast({ type: 'error', message: 'Error' });
            setIsModal2(false);
        }
    };

    return (
        <div>
            <form>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Reference"
                            value={props.Reference}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '24px !important'
                                }
                            }}
                        />

                        <Input
                            label="Attention"
                            value={props.Attention}
                            onChange={(e) => props.setAttention(e.target.value)}
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={6}
                    >
                        <Input
                            label="Updated"
                            value={props.SIUpdatedCount}
                            textAlign='right'
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '24px !important'
                                }
                            }}
                        />

                        <Input
                            label="Print"
                            value={props.PrintingUpdatedCount}
                            textAlign='right'
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={6}
                    >
                        <CustomDatePicker
                            label="Last Updated"
                            value={props.lastUpdated}
                            disabled
                            type={'date'}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '24px !important'
                                }
                            }}
                        />

                        <CustomDatePicker
                            label="Date Print"
                            value={props.datePrint}
                            disabled
                            type={'date'}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <br></br>
                <TabsContact
                    tabValue={tabValue} setTabValue={setTabValue}
                    contactTypeId={props.contactTypeId} setContactTypeId={props.setContactTypeId}
                    contactType={'ShippingInstruction'}

                    handleClick={handleClick} setTitleModal={setTitleModal}

                    SICompanyId={props.SICompanyId} SICompanyName={props.SICompanyName} SICompanyAddress={props.SICompanyAddress} setSICompanyAddress={props.setSICompanyAddress}

                    ConsigneeId={props.ConsigneeId} setConsigneeId={props.setConsigneeId}
                    ConsigneeName={props.ConsigneeName} setConsigneeName={props.setConsigneeName}
                    ConsigneeAddress={props.ConsigneeAddress} setConsigneeAddress={props.setConsigneeAddress}
                    RefConsigneeId={props.SIAgentId} RefConsigneeName={props.SIAgentName} RefConsigneeAddress={props.SIAgentAddress}

                    NPartyId={props.NPartyId} setNPartyId={props.setNPartyId}
                    NPartyName={props.NPartyName} setNPartyName={props.setNPartyName}
                    NPartyAddress={props.NPartyAddress} setNPartyAddress={props.setNPartyAddress}
                    RefNPartyId={props.SIAgentId} RefNPartyName={props.SIAgentName} RefNPartyAddress={props.SIAgentAddress}
                />

                <br></br>
                <Box component="div">
                    {
                        props.jobType.portType !== 'AIR' ?
                            <>
                                <FormControl fullWidth variant="standard" className='mt-2' style={{ alignItems: 'center' }}>
                                    <Table style={{ width: '858px', alignContent: 'center', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                                        <tbody>
                                            <tr style={{ width: '762px' }}>
                                                <td style={{ width: '230px', borderBottom: 'none' }}>
                                                    <label style={{ fontSize: '12.5px ' }}>
                                                        Shipping Marks
                                                    </label>
                                                </td>

                                                <td style={{ width: '450px', borderBottom: 'none' }}>
                                                    <label style={{ fontSize: '12.5px ' }}>
                                                        Nos. Pkgs & Description of Goods
                                                    </label>
                                                </td>

                                                <td style={{ width: '10px', borderBottom: 'none' }}>
                                                    <label style={{ fontSize: '12.5px ' }}>
                                                        Weight/Meas.
                                                    </label>
                                                </td>
                                            </tr>

                                            <tr style={{ width: '858px' }}>
                                                <td colSpan={3} style={{ borderBottom: 'none' }}>
                                                    <label></label>
                                                    <TextareaAutosize
                                                        aria-label="minimum height"
                                                        style={{
                                                            minWidth: '858px',
                                                            minHeight: '172px',
                                                            fontFamily: 'Tahoma',
                                                            fontSize: '12.5px',
                                                            textAlign: 'left',
                                                            backgroundColor: '#DCFFCA'
                                                        }}
                                                        value={description}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </FormControl>
                            </>
                            :
                            <div>
                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        sm={4}
                                        xs={8}
                                    >
                                        <Input
                                            label="No Of Pieces RCP"
                                            value={props.SIPiecesRCP}
                                            onChange={(e) => props.setSIPiecesRCP(e.target.value)}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <Input
                                            label="Gross Weight"
                                            textAlign='right'
                                            value={props.SIGrossWeight}
                                            onChange={(e) => props.setSIGrossWeight(e.target.value)}
                                            type={'number'}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        sm={4}
                                        xs={8}
                                    >
                                        <Input
                                            label="KG / Lb"
                                            value={props.SIKGLB}
                                            onChange={(e) => props.setSIKGLB(e.target.value)}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <Input
                                            label="Chargeable Weight"
                                            textAlign='right'
                                            value={props.SIChargeWeight}
                                            onChange={(e) => props.setSIChargeWeight(e.target.value)}
                                            type={'number'}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={4}
                                        lg={4}
                                        sm={4}
                                        xs={8}
                                    >
                                        <Input
                                            label="Rate / Charge"
                                            textAlign='right'
                                            value={props.SIChargeRate}
                                            onChange={(e) => props.setSIChargeRate(e.target.value)}
                                            type={'number'}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <Input
                                            label="Total"
                                            value={props.SITotal}
                                            onChange={(e) => props.setSITotal(e.target.value)}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label="Commodity"
                                            value={props.SICommodity}
                                            onChange={(e) => props.setSICommodity(e.target.value)}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <div style={{ marginTop: '16px' }}>
                                            {props.selectPackagingType(props.SIPackageCode, props.setSIPackageCode, 'Packaging Code')}
                                        </div>
                                    </Grid>
                                </Grid>

                                <Input
                                    label="Nature and Quantity of Goods (Incl. Dimensions or Volume)"
                                    value={props.SIGoodNatureQ}
                                    onChange={(e) => props.setSIGoodNatureQ(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 4,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />

                                <Input
                                    label="Shipping Mark - Side Mark"
                                    value={props.SIShipmark}
                                    onChange={(e) => props.setSIShipmark(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 4,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </div>
                    }
                </Box>

                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Original B/L'}
                            fullWidth={true}
                            placeholder={'Select Original B/L'}
                            value={props.SIOriginalBL}
                            onChange={(e) => props.setSIOriginalBL(e.target.value)}
                            formControlProps={{
                                sx: {
                                    marginTop: '16px !important'
                                }
                            }}
                        >
                            {
                                dataOriginalBL.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Selection
                            id='0'
                            label={'Freight OBL'}
                            fullWidth={true}
                            placeholder={'Select Freight OBL'}
                            value={props.freightOBL}
                            onChange={(e) => props.setFreightOBL(e.target.value)}
                            formControlProps={{
                                sx: {
                                    marginTop: '16px !important'
                                }
                            }}
                        >
                            {
                                dataFreightOBL.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>

                        <Stack spacing={'12px'} direction="row" sx={{ marginTop: '16px' }} hidden={props.freightOBL === 'A' ? false : true}>
                            <Input
                                label='Collect At'
                                value={props.freightOBL === 'A' ? props.SICollectAgentId : '0'}
                                disabled={props.freightOBL === 'A' ? false : true}
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px',
                                        width: '35%'
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                }}
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.freightOBL === 'A' ? props.SICollectAgentName : ''}
                                type='text'
                                label={''}
                                formControlProps={{
                                    sx: {
                                        width: '65%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                }}
                                disabled={props.freightOBL === 'A' ? false : true}
                            />

                            <Input
                                value={props.freightOBL === 'A' ? props.SICollectAgentAddress : ''}
                                multiline
                                fullWidth
                                disabled={props.freightOBL === 'A' ? false : true}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    rows: 3,
                                    sx: {
                                        marginTop: '23px !important',
                                        padding: '8px',
                                    },
                                }}
                            />

                            <Stack direction={'column'} spacing={'12px'} sx={{ marginTop: '24px !important' }} hidden={props.freightOBL === 'A' ? false : true}>
                                <IconButton
                                    onClick={() => AddCollectAt('search')}
                                    hidden={props.freightOBL === 'A' ? false : true}
                                >
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '18px',
                                                width: '18px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton
                                    onClick={() => AddCollectAt('delete')}
                                    hidden={props.freightOBL === 'A' && props.SICollectAgentAddress ? false : true}
                                >
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '18px',
                                                width: '18px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Special Instruction"
                            value={props.SISpecialIns}
                            onChange={(e) => props.setSISpecialIns(e.target.value)}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                            inputBaseProps={{
                                rows: 10,
                                sx: {
                                    marginTop: '3px !important',
                                    display: 'flex',
                                    padding: '8px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    borderRadius: '8px',
                                    border: '1.5px solid var(--Grey-Outline-2, #989898)',
                                    background: '#FFF',
                                },
                            }}
                            formLabelProps={{
                                sx: {
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontWeight: '700',
                                    color: '#083A50 !important',
                                    textAlign: 'left'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </form>

            {
                isModal && (
                    <SelectDataNonContact
                        jobType={props.jobType}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        setTitleModal={setTitleModal}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        dataTable={dataTableN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setSICompanyId={props.setSICompanyId}
                        setSICompanyName={props.setSICompanyName}
                        setSICompanyAddress={props.setSICompanyAddress}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataContact
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        tipe={tipe}
                        tipe2={tipe2}
                        setIsModal={setIsModal2}
                        contactTypeId={contactTypeId2}
                        setTipe={setTipe}

                        getData={getDataContact}
                        isLoading={isLoadingC}
                        setIsLoading={setIsLoadingC}
                        data={dataC}
                        setData={setDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setDataMap={setDataMapC}
                        columnData={columnDataC}
                        setColumnData={setColumnDataC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}

                        setSICollectAgentId={props.setSICollectAgentId}
                        setSICollectAgentName={props.setSICollectAgentName}
                        setSICollectAgentAddress={props.setSICollectAgentAddress}
                    />
                )
            }
        </div>
    )
}

export default ShippingInstruction;