import React, { useCallback, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';

import BodyComponent from './BodyComponent';
import { Icon, Selection, Input, CustomDatePicker } from '../ui';
import {
    // IconButton, InputAdornment, 
    Stack, Typography
} from '@mui/material';
import { isInfossNoList } from '../../services/ApiService';
import noDataAvailable from '../../Assets/Background/noDataAvailable.png';

const TableZ = (props) => {
    const [filterJson, setFilterJson] = useState({})
    const [filterArr, setFilterArr] = useState([])

    const filterTable = (key, val, type = 'Standard') => {
        if (type !== 'Stop') {
            let filter = filterJson
            let temp = {
                field: key,
                data: val?.trim()
            }
            let arr = []
            if (!filter[key]) {
                filter[key] = temp
                setFilterJson(filter)
            } else {
                filter[key].data = val
                setFilterJson(filter)
            }

            if (filter[key].data.length === 0) {
                delete filter[key]
                delete props.stateX[key]
                setFilterJson(filter)
            } else {
                props.setStateX({ ...props.stateX, [key]: val })
            }

            for (const key in filter) {
                arr.push(filter[key])
            }

            setFilterArr(arr)

            if (type === 'Immediately' || type === 'Standard') {
                props.setNumPage(1)
                props.setRowsCount(50)
                props.setGoTo(1)
                let arr = []
                let arrFilter = []
                let filter = { ...props.stateX, [key]: val }
                for (const [key, value] of Object.entries(filter)) {
                    let realCol = key;
                    let realValue = value;

                    if (realValue === 'true') {
                        realValue = 'Yes';
                    }
                    else if (realValue === 'false') {
                        realValue = 'No';
                    }
                    else if (realValue === 'ACT') {
                        realValue = 'Active';
                    }
                    else if (realValue === 'DEL') {
                        realValue = 'Deleted';
                    }

                    realCol = props.columnData.find(el => el.column === key);

                    arr.push(realCol.text + ': ' + realValue)

                    arrFilter = [
                        ...arrFilter,
                        {
                            field: key,
                            data: value?.trim()
                        }
                    ]
                }
                props.setFilterBy(arr)
                props.setFilter(arrFilter)
                if (props.id !== undefined) {
                    props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
                }
                else {
                    props.getData(1, props.rowsCount, arrFilter, props.sortArr)
                }
            }
        }
    }

    function handleKeyDownInput(e, el) {
        if (e.key === "Enter") {
            filterTable(el.column, e.target.value, 'Standard');
        }
    }

    const handleDoubleClick = (el) => {
        props.CellDoubleClick(el)
    }

    const handleClick = (el) => {
        if (props?.static) return

        if (el) {
            props.setSelectedData(el);
        }
        if (props.handleGetData !== undefined) {
            props.handleGetData(el);
        }
    }

    function handleKeyDown(e) {
        if (props?.preventKeyCapture) return
        if (props?.static && e.keyCode !== 13) return

        const keycode = e.keyCode;
        const valid = [38, 40, 13]

        if (!props.isModal && valid.includes(keycode)) {
            let currIndex = 0
            if (e.keyCode === 38 && props.SelectedData.index > 1) {
                //UP ARROW
                currIndex = props.SelectedData.index
                currIndex -= 1
            } else if (e.keyCode === 40 && props.SelectedData.index < props.dataMap.length - 1) {
                //DOWN ARROW
                currIndex = props.SelectedData.index
                currIndex += 1
            } else if (e.keyCode === 13 && filterArr.length >= 0) {
                //PRESS ENTER
                //THEN DO FILTER
                if (filterArr.length > 0) {
                    FilterData();
                }
            }

            if (![13].includes(e.keyCode)) {
                const copyArr = [...props.dataMap]
                const changeSelectedData = (data) => {
                    return data.index === currIndex
                }
                const result = copyArr.filter(changeSelectedData)
                props.setSelectedData(...result);
                handleClick(...result)
            }
        }
    }

    const FilterData = () => {
        props.setNumPage(1)
        props.setRowsCount(50)
        props.setGoTo(1)
        let arr = []
        let arrFilter = []
        let filter = props.stateX
        for (const [key, value] of Object.entries(filter)) {
            let realCol = key;
            let realValue = value;

            if (realValue === 'true') {
                realValue = 'Yes';
            }
            else if (realValue === 'false') {
                realValue = 'No';
            }
            else if (realValue === 'ACT') {
                realValue = 'Active';
            }
            else if (realValue === 'DEL') {
                realValue = 'Deleted';
            }

            realCol = props.columnData.find(el => el.column === key);

            arr.push(realCol.text + ': ' + realValue)

            arrFilter = [
                ...arrFilter,
                {
                    field: key,
                    data: value?.trim()
                }
            ]
        }
        props.setFilterBy(arr)
        props.setFilter(arrFilter)
        if (props.id !== undefined) {
            props.getData(1, props.rowsCount, arrFilter, props.id, props.sortArr)
        }
        else {
            props.getData(1, props.rowsCount, arrFilter, props.sortArr)
        }
    }

    const SortData = (key) => {
        if (props.sortArr) {
            let temp = {
                attr: key,
                method: 'asc'
            }
            var arr = []
            var cekArr = props.sortArr
            cekArr = cekArr.filter(el => el.attr === key)

            if (cekArr.length > 0) {
                arr = props.sortArr
                arr.forEach(el => {
                    if (el.attr === key) {
                        if (el.method === 'asc') {
                            el.method = 'desc'
                        } else {
                            arr = arr.filter(el => el.attr !== key)
                        }
                    }
                });
            } else {
                arr = [...props.sortArr, temp]
            }
            props.setSortArr(arr)

            let arrFilter = []
            let filter = props.stateX
            for (const [key, value] of Object.entries(filter)) {
                arrFilter = [...arrFilter, {
                    field: key,
                    data: value?.trim()
                }]
            }
            props.getData(1, props.rowsCount, arrFilter, arr)
            props.setNumPage(1)
            props.setGoTo(1)
            // CekSortStatus(key)
        }
    }

    const CekSortStatus = (key, text) => {
        if (!props.sortArr) return null;

        const sortItem = props.sortArr.find(el => el.attr === key);
        let colors = "#D1D1D2";
        let textColor = "#747475";
        let iconName = 'sort-arrow';

        if (sortItem) {
            if (sortItem.method === "asc") {
                iconName = 'sort-up';
            } else {
                iconName = 'sort-down';
            }
            colors = '#00286D';
            textColor = '#00286D';
        }

        return (
            <Stack
                direction='row'
                spacing='6px'
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: 'fit-content'
                }}
            >
                <Typography
                    sx={{
                        overflow: 'hidden',
                        color: textColor,
                        textOverflow: 'ellipsis',
                        fontFamily: 'Switzer',
                        fontSize: '8px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '150%',
                        letterSpacing: '0.32px',
                    }}
                >
                    {text.toUpperCase()}
                </Typography>

                <Icon
                    iconName={iconName}
                    svgIconProps={{
                        fontSize: 'small',
                        htmlColor: colors,
                        sx: {
                            height: '12px',
                            width: '12px'
                        }
                    }}
                />
            </Stack>
        );
    };

    const TableHeaderRow = React.memo(({ columnData, SortData, CekSortStatus }) => {
        const handleSortClick = useCallback((column) => {
            SortData(column);
        }, [SortData]);

        return (
            <tr style={{ borderBottom: 'transparent' }}>
                <th></th>
                {
                    columnData.map((el, index) => (
                        <th
                            key={index}
                            hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                            style={{
                                textAlign: el.textAlign,
                                whiteSpace: 'nowrap',
                                fontWeight: 'normal',
                                maxWidth: 'fit-content',
                                cursor: 'pointer',
                                padding: '2px 8px',
                                background: '#FFF',
                                fontSize: '12px !important'
                            }}
                        >
                            <label
                                onClick={() => handleSortClick(el.column)}
                                style={{
                                    fontWeight: 'bold',
                                    cursor: 'pointer',
                                    alignItems: 'center',
                                    fontSize: '12px !important',
                                    maxWidth: 'fit-content'
                                }}
                            >
                                {CekSortStatus(el.column, el.text)}
                            </label>
                        </th>
                    ))
                }
            </tr>
        );
    });

    const TableFilterRow = React.memo(({ columnData, filterTable }) => {
        const cellStyle = useMemo(() => ({
            maxWidth: '150px',
            padding: '2px 6px 4px',
            background: '#FFF',
        }), []);

        const selectionStyle = useMemo(() => ({
            height: '27px',
            borderRadius: '10px',
            fontSize: '10px !important',
            border: '1px solid #EDEDEE',
            backgroundColor: 'transparent',
        }), []);

        const menuItemStyle = useMemo(() => ({
            color: '#00286D',
            fontWeight: '600',
            fontFamily: 'Switzer',
            fontSize: '8px !important',
        }), []);

        const inputStyle = useMemo(() => ({
            borderRadius: '10px',
            width: '100% !important',
            fontSize: '10px !important',
            maxWidth: '100% !important',
            minHeight: '27px !important',
            border: '1px solid #EDEDEE',
            padding: '5px 10px !important',
        }), []);

        const datePickerStyle = useMemo(() => ({
            width: '100% !important',
            minWidth: '65px !important',
            maxWidth: '100% !important',
        }), []);

        const handleSelectionChange = useCallback((column, value) => {
            filterTable(column, value, 'Immediately');
        }, [filterTable]);

        const handleInputChange = useCallback((column, value) => {
            filterTable(column, value, 'Stop');
        }, [filterTable]);

        const renderCellContent = useCallback((el) => {
            switch (true) {
                case el.text === 'Data Status':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[{ value: 'ACT', label: 'Active' }, { value: 'DEL', label: 'Deleted' }]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.dataType === 'boolean' || el.dataType === 'bit':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[{ value: 'true', label: 'Yes' }, { value: 'false', label: 'No' }]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.column === 'glStatusName' || el.column === 'glStatus':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[
                                { value: 'Success', label: 'Success' },
                                { value: 'Pending', label: 'Pending' },
                                { value: 'Failed', label: 'Failed' },
                            ]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.column === 'category':
                    return (
                        <Selection
                            onChange={(e) => handleSelectionChange(el.column, e.target.value)}
                            options={[
                                { value: 'OR', label: 'OR' },
                                { value: 'PV', label: 'PV' },
                                { value: 'RV', label: 'RV' },
                                { value: 'TP', label: 'TP' },
                                { value: 'TR', label: 'TR' },
                                { value: 'ME', label: 'ME' },
                            ]}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            selectBaseProps={{ sx: selectionStyle }}
                            menuItemProps={{ sx: menuItemStyle }}
                            selectInnerStyle={{ backgroundColor: 'transparent' }}
                        />
                    );

                case el.dataType === 'datetime':
                    return (
                        <CustomDatePicker
                            onChange={(e) => handleSelectionChange(el.column, e)}
                            formControlProps={{ sx: datePickerStyle }}
                            inputBaseProps={{ sx: inputStyle }}
                            customDateProps={{ toolbarPlaceholder: '' }}
                        />
                    );

                default:
                    return (
                        <Input
                            onKeyDown={(e) => handleKeyDownInput(e, el)}
                            onChange={(e) => handleInputChange(el.column, e.target.value)}
                            formControlProps={{ sx: { width: '100% !important' } }}
                            inputBaseProps={{ sx: inputStyle }}
                        />
                    );
            }
        }, [selectionStyle, menuItemStyle, datePickerStyle, inputStyle, handleSelectionChange, handleInputChange]);

        return (
            <tr style={{ borderBottom: '1px solid #E9E9EA' }}>
                <td />
                {columnData.map((el, index) => (
                    <td
                        key={index}
                        hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                        align={el.textAlign}
                        style={cellStyle}
                    >
                        {renderCellContent(el)}
                    </td>
                ))}
            </tr>
        );
    });

    const memoizedColumnData = useMemo(() => props.columnData, [props.columnData]);

    return (
        <Table
            hover={!props?.static}
            className='table table-sm'
            style={{ '--bs-table-bg': '#FFF' }}
            onKeyDown={handleKeyDown}
        >
            <thead style={{ position: 'sticky', top: '-4px', 'color': '#FFF', background: '#FFF', zIndex: 1 }}>
                {
                    props.columnData.length > 0 && (
                        <>
                            <TableHeaderRow columnData={props.columnData} SortData={SortData} CekSortStatus={CekSortStatus} />
                            <TableFilterRow columnData={props.columnData} filterTable={filterTable} />
                        </>
                    )
                }
            </thead>

            <tbody>
                {
                    props.dataTable.length > 0 ?
                        props.dataTable.map((el, index) => {
                            let tempStyle = 'text-dark';
                            if (props.SelectedData?.id === el?.id) {
                                tempStyle = "bg-infoss text-white"
                            }
                            else if (el.rowStatus === 'DEL') {
                                tempStyle = "text-danger"
                            }
                            else if (el.printing > 0) {
                                tempStyle = "text-secondary"
                            }

                            let tempTextStyle = 'normal-text';
                            if (props.SelectedData && el && props.SelectedData[props.uniqueKey] === el[props.uniqueKey]) {
                                tempTextStyle = "selected-text";
                            } else {
                                tempTextStyle = "normal-text";
                            }

                            return (
                                <BodyComponent
                                    key={index}
                                    tempStyle={tempStyle}
                                    tempTextStyle={tempTextStyle}
                                    setDataMap={props.setDataMap}
                                    index={index}
                                    el={el}
                                    localVar={props.localVar}
                                    setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                                    handleClick={handleClick}
                                    source={props.source}
                                    handleDoubleClick={handleDoubleClick}
                                    handleFailedGL={props.handleFailedGL}

                                    dataMap={props.dataMap}
                                    uniqueKey={props.uniqueKey}
                                    columnData={memoizedColumnData}
                                    SelectedData={props.SelectedData}
                                    uniqueCol={props.uniqueCol}
                                    customAction={props.customAction}
                                    tableAccess={props.tableAccess}
                                />
                            )
                        })
                        :
                        <tr style={{ borderBottom: 'transparent', borderTop: 'transparent', userSelect: 'none', pointerEvents: 'none' }}>
                            <td colSpan={20} className="text-center py-3 text-muted">
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '32px 0px',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '16px',
                                        borderRadius: '12px',
                                        background: '#FAFAFA',
                                    }}
                                >
                                    <img
                                        src={noDataAvailable}
                                        alt="Preview"
                                        style={{ width: "80px", height: "auto", justifyContent: "center", alignItems: "center" }}
                                    />

                                    <Typography
                                        sx={{
                                            overflow: 'hidden',
                                            color: 'var(--Mjr-black-1, #747475)',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '140%',
                                            letterSpacing: '0.1px',
                                        }}
                                    >
                                        No Data Available
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}

export default TableZ