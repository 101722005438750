import React, { useEffect, useState } from 'react';
import { Alert, IconButton, Typography } from '@mui/material';
import Icon from '../Icon/Icon';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CustomToast.css';

const colorMapping = {
    success: '#168444',
    warning: '#FEA51E',
    error: '#D20C0C',
    info: '#3EB4E8',
};

const iconMapping = {
    success: 'active',
    warning: 'warning',
    error: 'inactive',
    info: 'notification',
};

const CustomToast = ({ id, type = 'info', message = 'Default Message', variant = 'outlined', autoClose = 1500 }) => {
    const [progress, setProgress] = useState(100);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prev) => Math.max(prev - 100 / ((autoClose + 500) / 100), 0));
        }, 100);

        return () => clearInterval(timer);
    }, [autoClose]);

    return (
        <Alert
            action={
                <IconButton
                    size='small'
                    sx={{ paddingTop: '0px' }}
                    onClick={() => toast.dismiss(id)}
                >
                    <Icon
                        iconName={'close'}
                        svgIconProps={{
                            htmlColor: variant === 'outlined' ? colorMapping[type] : '#f2f2f2',
                            sx: {
                                width: '18px',
                                height: '18px',
                                padding: '0px',
                            }
                        }}
                    />
                </IconButton>
            }
            severity={type}
            variant={variant}
            icon={
                <Icon 
                    iconName={iconMapping[type]} 
                    svgIconProps={{
                        htmlColor: variant === 'outlined' ? colorMapping[type] : '#f2f2f2',
                        sx: {
                            width: '24px',
                            height: '24px',
                            padding: '0px',
                        }
                    }} 
                />
            }
            sx={{
                paddingTop: '12px !important',
                paddingBottom: '12px !important',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: variant === 'outlined' ? '#F2F2F2' : colorMapping[type],
                width: 'fit-content',
                minWidth: '280px',
                borderRadius: '8px',
                border: variant === 'outlined' ? '2px solid ' + colorMapping[type] : '2px solid #f2f2f2',
                color: variant === 'outlined' ? colorMapping[type] : '#f2f2f2',
                position: 'relative',
                zIndex: 0,
            }}
        >
            <Typography
                sx={{
                    color: variant === 'outlined' ? 'var(--Blue-Primary-1, #083A50)' : '#f2f2f2',
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    letterSpacing: '0.36px',
                    marginTop: '1px !important',
                    textAlign: 'left',
                }}
            >
                {message}
            </Typography>

            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    height: '2px',
                    backgroundColor: variant === 'outlined' ? colorMapping[type] : '#f2f2f2',
                    width: `${progress}%`,
                    transition: 'width 0.1s linear',
                    borderRadius: '0 0 8px 8px',
                    clipPath: 'inset(0 0 -4px 0 round 0 0 8px 8px)',
                }}
            />
        </Alert>
    );
};

export const showToast = ({ type, message, variant = 'outlined', autoClose }) => {
    toast(<CustomToast type={type} message={message} variant={variant} autoClose={autoClose} />, {
        autoClose,
        hideProgressBar: true,
        closeButton: false,
        containerId: 'custom-toast-container',
    });
};

const CustomToastContainer = () => (
    <ToastContainer
        position="top-right"
        hideProgressBar
        autoClose = {1500}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        containerId="custom-toast-container"
        toastStyle={{ background: 'transparent', boxShadow: 'none', padding: '0px 0px 12px 0px', margin: 0 }}
    />
);

export default CustomToastContainer;