import { memo } from "react";
import { Icon, Input, PopUp, showToast } from "../../../../components/ui";
import CustomizeButtonComponent from "../../../../components/Buttons/CustomizeButtonComponent";
import { Stack } from "@mui/material";
import { branchId, companyId, countryId } from "../../../../services/ApiService";

const ModalConote = memo(({
    isModal,
    titleModal,
    handleClick,
    conoteNumber,
    setConoteNumber,
    conoteName,
    setConoteName,
    conoteCollie,
    setConoteCollie,
    conoteKgs,
    setConoteKgs,
    conoteCbm,
    setConoteCbm,
    ShipmentId,
    detailEdit,
    emptyState,
    detailSequence,
    setConoteData,
    conoteData
}) => {
    const handleClose = () => {
        emptyState();
        handleClick();
    }

    const handleSave = () => {
        let Sequence = 1;

        if (detailEdit) {
            Sequence = detailSequence;
        }
        else {
            if (detailSequence > 0) Sequence = detailSequence + 1;
        }

        let data = {
            "rowStatus": "ACT",
            "countryId": countryId,
            "companyId": companyId,
            "branchId": branchId,
            "sequence": Sequence,
            "shipmentId": ShipmentId,
            "conNote": conoteNumber,
            "commodity": conoteName,
            "collie": parseFloat(conoteCollie),
            "weight": parseFloat(conoteKgs),
            "cbm": parseFloat(conoteCbm),
            "containerData": [],
        }

        let errorMessage = undefined;

        if (data.conNote === '') {
            errorMessage = 'Conote Number is required';
        }
        else if (data.commodity === '') {
            errorMessage = 'Item Name is required';
        }
        else if (data.collie === 0) {
            errorMessage = 'Qty Collie is required';
        }
        else if (data.weight === 0) {
            errorMessage = 'Qty Kgs is required';
        }
        else if (data.cbm === 0) {
            errorMessage = 'Qty Cbm is required';
        }

        if (errorMessage === undefined) {
            if (!detailEdit) {
                setConoteData([...conoteData, data]);
            }
            else {
                const newArr = conoteData.slice();
                newArr.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.shipmentId = data.shipmentId;
                        el.conNote = data.conNote;
                        el.commodity = data.commodity;
                        el.collie = data.collie;
                        el.weight = data.weight;
                        el.cbm = data.cbm;
                    }
                });

                setConoteData(newArr);
            }

            handleClose();
        }
        else {
            showToast({ type: 'error', message: errorMessage });
        }
    }

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={titleModal}
            isOpen={isModal}
            onClose={handleClose}
            draggable={true}
            shrinkable={true}
            disableScrollLock={true}
            maxWidth={'sm'}
            fullWidth={true}
            topButton={false}
        >
            <div style={{ padding: '16px 6px 0px' }}>
                <Input
                    label="Conote Number"
                    value={conoteNumber}
                    onChange={(e) => setConoteNumber(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                />

                <Input
                    label="Item Name"
                    value={conoteName}
                    onChange={(e) => setConoteName(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: '16px' }}>
                    <Input
                        label="Qty Collie"
                        value={conoteCollie}
                        onChange={(e) => setConoteCollie(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="Qty Kgs"
                        value={conoteKgs}
                        onChange={(e) => setConoteKgs(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />

                    <Input
                        label="Qty Cbm"
                        value={conoteCbm}
                        onChange={(e) => setConoteCbm(e.target.value)}
                        fullWidth
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Stack>

                <div style={{ marginTop: '32px' }}>
                    {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                </div>
            </div>
        </PopUp>
    )
});

export default memo(ModalConote);