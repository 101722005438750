import { Grid, Box, MenuItem, FormControl, InputAdornment, IconButton, Card, CardContent } from '@mui/material';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import ApiService from '../../../../services/ApiService';
import SelectDataShipment from './Selects/SelectDataShipment';
import ButtonCargoManifestView from './Buttons/ButtonCargoManifestView';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, Input, Selection, showToast } from '../../../../components/ui';
import { handleDataTable, PrintPDF } from '../../../../utils/reusableFunction';

const CargoManifestView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const [jobType, setJobType] = useState(1);

    const [dataJobType, setDataJobType] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const paramS = "shipmentOrder/shipmentOrder";
    const [isLoadingS, setIsLoadingS] = useState(false);
    const [dataS, setDataS] = useState([]);
    const [dataMapS, setDataMapS] = useState([]);
    const [dataTableS, setDataTableS] = useState([]);
    const [columnDataS, setColumnDataS] = useState([]);
    const [SelectedDataS, setSelectedDataS] = useState({});
    const [totalRowsS, setTotalRowsS] = useState(50);

    const [shipmentId, setShipmentId] = useState(0);
    const [shipmentNo, setShipmentNo] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Cargo Manifest', link: '/Majura/CargoManifestReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Cargo Manifest - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    setDataJobType(response.data.data.jobType);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });
            })
    }

    const selectJobType = (jobType, setJobType) => {
        return (
            <Selection
                id='0'
                label={'Job Type'}
                fullWidth={true}
                placeholder={'Select Job Type'}
                value={jobType}
                onChange={(e) => handleSelectJobType(setJobType, e.target.value)}
            >
                {
                    dataJobType.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.code} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>
        )
    }

    function handleSelectJobType(setJobType, value) {
        setJobType(value);
        setShipmentId(0);
        setShipmentNo('');
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'RCM')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        GetDataJobType();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getDataShipment = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingS(true);
        let params = [];
        params = [
            ...params,
            { 'attr': 'jobTypeId', 'value': '' + jobType },
        ];

        ApiService.OperationDynamicPostByPage(paramS, 'SEACM', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataS({})
                    setDataS(response.data.data.shipmentOrder);

                    let temp = response.data.data.shipmentOrder
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapS(indexed)
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableS, 'id');
                    setColumnDataS(response.data.data.columns.headerColumns)
                    setTotalRowsS(response.data.totalRowCount)

                    setIsLoadingS(false)
                }
                setIsLoadingS(false)
            })
            .catch(function (error) {
                setIsLoadingS(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error.toString() });
            })
    };

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddShipmentOrder = (method) => {
        if (method === 'search') {
            setTitleModal('Shipment Order');
            getDataShipment(1, 50);
            handleClick();
        }
        else {
            setShipmentId(0);
            setShipmentNo('');
        }
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCM() {
        if (titleConfirmation === 'Print') {
            setIsLoadingBackDrop(true);
            let reportLink = 'Reports/Operation/Print/CargoManifestReport/CargoManifestReport/PrintCargoManifest?id=' + shipmentId;
            PrintPDF(setIsLoadingBackDrop, 'Cargo Manifest', reportLink);
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (shipmentId === 0) {
            check = 1;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Shipment Order!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Cargo Manifest');
            handleClickConfirmation();
        }
    };

    const ButtonComponents = () => {
        return (
            <ButtonCargoManifestView
                PrintData={PrintData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Cargo Manifest'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <FormControl variant="standard" fullWidth>
                                            {selectJobType(jobType, setJobType)}
                                        </FormControl>

                                        <Input
                                            label="Shipment No."
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            disabled
                                            value={shipmentNo}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() => ShowAddShipmentOrder('delete')}
                                                            size='small'
                                                            hidden={shipmentId !== 0 ? false : true}
                                                        >
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <IconButton
                                                            onClick={() => ShowAddShipmentOrder('search')}
                                                            size='small'
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModal && (
                    <SelectDataShipment
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        titleModal={titleModal}

                        getData={getDataShipment}
                        isLoading={isLoadingS}
                        totalRows={totalRowsS}
                        setTotalRows={setTotalRowsS}
                        SelectedData={SelectedDataS}
                        setSelectedData={setSelectedDataS}
                        data={dataS}
                        columnData={columnDataS}
                        dataMap={dataMapS}
                        dataTable={dataTableS}

                        jobType={jobType}
                        setShipmentId={setShipmentId}
                        setShipmentNo={setShipmentNo}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCM}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default CargoManifestView