import Grid from '@mui/material/Grid';
import React from 'react'

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

const ButtonToDo = (props) => {
    return (
        <div className="row" spacing={'12px'}>
            <div className='col-12' spacing={'12px'}>
                <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {
                        props.title2 !== 'Activity Tracker' && (
                            <>
                                {ButtonComponent.ReloadButton(props.ReloadData)}
                            </>
                        )
                    }
                    {ButtonComponent.CustomButton(props.switchTitle, props.title2 !== 'Activity Tracker' ? 'documents' : 'posted', props.title2)}
                </Grid>
            </div>
        </div>
    )
}

export default ButtonToDo