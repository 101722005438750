import { Box, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import ButtonInfo from "./ButtonInfo";

const SectionInfo = memo(({
    sectionList = [],
    selectedChild = 0,
    setSelectedChild,
    children = null,
    ButtonComponents = null,
}) => {
    const [isClick, setIsClick] = useState(false);

    useEffect(() => {
        if (!isClick) {
            const observer = new IntersectionObserver(
                (entries) => {
                    const visibleSection = entries.find(entry => entry.isIntersecting);
                    if (visibleSection) {
                        const sectionId = sectionList.find(element => element.sectionId === visibleSection.target.id);
                        setSelectedChild(sectionId.id);
                    }
                },
                {
                    root: null,
                    rootMargin: "-24px 0px 0px 0px",
                    threshold: 0.5,
                }
            );

            sectionList.forEach((element) => {
                const section = document.getElementById(element.sectionId);
                if (section) observer.observe(section);
            });

            return () => observer.disconnect();
        }
    }, [sectionList, setSelectedChild, isClick]);

    const handleChangeSection = (data, setSelectedSection) => {
        setIsClick(true);
        setSelectedSection(data.id);

        const element = document.getElementById(data.sectionId);

        if (element) {
            const yOffset = -24;
            let y = element.getBoundingClientRect().top + window.scrollY + yOffset;
            console.log("Before scrolling:", window.scrollY);
            window.scrollTo({ top: y, behavior: "smooth" });
            console.log("After scrolling:", window.scrollY);

            setTimeout(() => {
                setIsClick(false);
            }, 5000);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <Stack direction={'row'} spacing={sectionList.length > 0 ? '24px' : '0px'} sx={{ width: '100%' }}>
                <Box
                    sx={{
                        display: 'flex',
                        maxWidth: '200px',
                        width: '100%',
                        height: 'fit-content',
                        padding: '8px 0px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        borderRadius: '12px',
                        border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                        background: 'var(--Mjr-white-4, #FFF)',
                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.04)',
                        marginTop: '24px !important',
                        position: 'sticky',
                        top: '24px',
                        zIndex: 1000,
                    }}
                    hidden={sectionList.length === 0}
                >
                    {
                        sectionList.map((element, index) => (
                            <Box
                                key={index}
                                hidden={element.hidden}
                                sx={{
                                    display: 'flex',
                                    width: '100%',
                                    padding: '12px 16px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    justifyContent: 'center',
                                    borderLeft: selectedChild === element.id ? '4px solid var(--Mjr-Random-Dark, #6692DE)' : 'none',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        background: 'var(--Mjr-white-3, #F2FAFF)'
                                    },
                                }}
                                onClick={() => handleChangeSection(element, setSelectedChild)}
                            >
                                <Typography
                                    sx={{
                                        color: selectedChild === element.id ? 'var(--Mjr-Random-Dark, #6692DE)' : '#7F888C',
                                        fontFamily: 'Switzer',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: selectedChild === element.id ? '700' : '400',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    {element.title}
                                </Typography>
                            </Box>
                        ))
                    }
                </Box>

                {
                    children && (
                        <div style={{ width: '100%' }}>
                            {children}
                        </div>
                    )
                }
            </Stack>

            {
                ButtonComponents && (
                    <ButtonInfo ButtonComponents={ButtonComponents} isForm={false} />
                )
            }
        </div>
    )
});

SectionInfo.propTypes = {
    children: PropTypes.node,
}

export default memo(SectionInfo);