import { Grid, TextField, Stack, FormControl, Select, MenuItem } from '@mui/material';
import { Pagination } from 'react-bootstrap';
import React from 'react';

const Paging = ({ setRowsCount, setNumPage, getData, rowsCount, numPage, data, totalRows, filter, id, sortArr, goTo, setGoTo, dataCheck, setDataCheck }) => {
    const inputPaging = (e) => {
        if (e.key === 'Enter') {
            let MaxPage = 1
            if (data?.length > 0) {
                MaxPage = Math.ceil(totalRows / rowsCount)
            }

            let page = Number(e.target.value);
            if (page > MaxPage) {
                page = MaxPage
            }
            else if (page < 1) {
                page = 1;
            }

            if (id) {
                getData(page, rowsCount, filter, id);
            }
            else {
                getData(page, rowsCount, filter, sortArr);
            }

            setNumPage(page)
            setGoTo(page)
        }
    }

    const renderPagination = () => {
        let MaxPage = 1
        if (data?.length > 0) {
            MaxPage = Math.ceil(totalRows / rowsCount)
        }

        if (numPage === 1 && numPage !== MaxPage) {
            return ( 
                <div style={{ display: 'inline-block' }}>
                    <Pagination>
                        <Pagination.Item >
                            {numPage}
                        </Pagination.Item>
                        <Pagination.Next onClick={() => { id ? getData(2, rowsCount, filter, id) : getData(2, rowsCount, filter, sortArr); setNumPage(numPage + 1); setGoTo(numPage + 1); }} />
                        <Pagination.Last onClick={() => { id ? getData(MaxPage, rowsCount, filter, id) : getData(MaxPage, rowsCount, filter, sortArr); setNumPage(MaxPage); setGoTo(MaxPage); }} />
                    </Pagination>
                </div>
            )
        }
        else if (numPage === 1 && numPage === MaxPage) {
            return (
                <div style={{ display: 'inline-block' }}>
                    <Pagination>
                        <Pagination.Item>
                            {numPage}
                        </Pagination.Item>
                    </Pagination>
                </div>
            )
        }
        else if (numPage === MaxPage) {
            return (
                <div style={{ display: 'inline-block' }}>
                    <Pagination>
                        <Pagination.First onClick={() => { id ? getData(1, rowsCount, filter, id) : getData(1, rowsCount, filter, sortArr); setNumPage(1); setGoTo(1); }} />
                        <Pagination.Prev onClick={() => { id ? getData(numPage - 1, rowsCount, filter, id) : getData(numPage - 1, rowsCount, filter, sortArr); setNumPage(numPage - 1); setGoTo(numPage - 1); }} />
                        <Pagination.Item >
                            {numPage}
                        </Pagination.Item>
                    </Pagination>
                </div>
            )
        }
        else {
            return (
                <div style={{ display: 'inline-block' }}>
                    <Pagination>
                        <Pagination.First onClick={() => { id ? getData(1, rowsCount, filter, id) : getData(1, rowsCount, filter, sortArr); setNumPage(1); setGoTo(1); }} />
                        <Pagination.Prev onClick={() => { id ? getData(numPage - 1, rowsCount, filter, id) : getData(numPage - 1, rowsCount, filter, sortArr); setNumPage(numPage - 1); setGoTo(numPage - 1); }} />
                        <Pagination.Item >
                            {numPage}
                        </Pagination.Item>
                        <Pagination.Next onClick={() => { id ? getData(numPage + 1, rowsCount, filter, id) : getData(numPage + 1, rowsCount, filter, sortArr); setNumPage(numPage + 1); setGoTo(numPage + 1); }} />
                        <Pagination.Last onClick={() => { id ? getData(MaxPage, rowsCount, filter, id) : getData(MaxPage, rowsCount, filter, sortArr); setNumPage(MaxPage); setGoTo(MaxPage); }} />
                    </Pagination>
                </div>
            )
        }
    }

    function handleChange(value) {
        setRowsCount(value);
        setNumPage(1);
        setGoTo(1);

        if (dataCheck) {
            setDataCheck([]);
        }

        if (id) {
            getData(1, value, filter, id)
        }
        else {
            getData(1, value, filter, sortArr)
        }
    }

    return (
        <div className='row mt-2'>
            <div>
                <Grid container item direction="row">
                    <Grid item xs={6}>
                        <Stack spacing={1} direction="row" justifyContent="flex-start">
                            <div className='mx-3' style={{ display: 'inline-block' }}>
                                {renderPagination()}
                            </div>

                            <FormControl className='mx-1' style={{ marginTop: '4px' }}>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={rowsCount}
                                    variant="standard"
                                    onChange={(e) => (handleChange(e.target.value))}
                                >
                                    <MenuItem value={50}>50 Rows</MenuItem>
                                    <MenuItem value={75}>75 Rows</MenuItem>
                                    <MenuItem value={100}>100 Rows</MenuItem>
                                </Select>
                            </FormControl>

                            <div className='mt-2'>
                                Rows Total : <b>{new Intl.NumberFormat('id-ID').format(totalRows)}</b>
                            </div>
                        </Stack>
                    </Grid>

                    <Grid item xs={6} alignItems='right' className='text-end'>
                        <Stack spacing={1} direction="row" justifyContent="flex-end" className='mt-1'>
                            <div>
                                Go to Page
                            </div>
                            <TextField
                                type="number"
                                className="input"
                                value={goTo}
                                variant="standard"
                                onKeyDown={(e) => inputPaging(e)}
                                onChange={(e) => { setGoTo(e.target.value); inputPaging(e) }}
                                style={{ marginTop: '-3px', width: '45px' }}
                                inputProps={{ style: { textAlign: 'center' } }}
                            />
                            <div>
                                of <b>{new Intl.NumberFormat('id-ID').format(Math.ceil(totalRows / rowsCount))}</b>
                            </div>
                        </Stack>
                    </Grid>
                </Grid>
            </div>
        </div>

    )
}

export default Paging