import { memo } from "react";
import { Typography } from "@mui/material";
import LoadingSpinner from "./LoadingSpinner";
import noDataAvailable from '../../Assets/Background/noDataAvailable.png';

const TableEmptyInfo = memo(({
    data,
    getContainerHeight,
    isLoading,
}) => {
    return (
        <>
            {
                data.length === 0 && (
                    <div
                        style={{
                            display: 'flex',
                            padding: '32px 0px',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '16px',
                            marginTop: '16px',
                            borderRadius: '12px',
                            background: '#FAFAFA',
                            height: `calc(100vh - ${getContainerHeight()})`,
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <img
                                        src={noDataAvailable}
                                        alt="Preview"
                                        style={{ width: "80px", height: "auto", justifyContent: "center", alignItems: "center", userSelect: 'none' }}
                                    />

                                    <Typography
                                        sx={{
                                            overflow: 'hidden',
                                            color: 'var(--Mjr-black-1, #747475)',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '140%',
                                            letterSpacing: '0.1px',
                                            userSelect: 'none',
                                        }}
                                    >
                                        No Data Available
                                    </Typography>
                                </>
                        }
                    </div>
                )
            }
        </>
    )
})

export default memo(TableEmptyInfo);