import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from "@mui/material";
import { forwardRef, memo } from "react";
import Icon from "../Icon/Icon";
import PropTypes from "prop-types";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const HalfPopUp = memo(({
    id,
    isOpen,
    handleClose,
    title = '',
    children,
    actions,
    titleDetail
}) => {
    return (
        <Dialog
            aria-describedby={id}
            open={isOpen}
            onClose={handleClose}
            fullScreen
            keepMounted
            TransitionComponent={Transition}
            PaperProps={{
                sx: {
                    maxHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    overflow: 'hidden',
                    boxShadow: 'none',
                    margin: '0px',
                    width: '50vw',
                    borderRadius: '16px 0px 0px 16px',
                    top: 0,
                    right: 0,
                }
            }}
        >
            <DialogTitle sx={{ padding: '16px 32px !important' }}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={8}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                            <Typography
                                sx={{
                                    color: "var(--Mjr-black-1, #171717)",
                                    fontFamily: "Switzer",
                                    fontSize: "16px",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    lineHeight: "normal",
                                }}
                            >
                                {title}
                            </Typography>

                            {titleDetail && (
                                <div style={{ marginTop: "8px" }}>
                                    {titleDetail}
                                </div>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={4} textAlign="end" className="content-end">
                        <IconButton
                            sx={{
                                height: "20px",
                                width: "20px",
                            }}
                            onClick={handleClose}
                        >
                            <Icon
                                iconName="close"
                                svgIconProps={{
                                    fontSize: "small",
                                    htmlColor: "#001A4F",
                                    sx: { height: "12px", width: "12px" },
                                }}
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            {children && (
                <DialogContent sx={{ padding: '0px 32px 16px !important' }}>
                    {children}
                </DialogContent>
            )}

            {actions && (
                <DialogActions>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    )
});

HalfPopUp.propTypes = {
    // *** Base Props
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,

    // *** Custom Props
    children: PropTypes.node,
    actions: PropTypes.node,

    // *** MUI Dialog Base Props
    baseProps: PropTypes.shape({
        'aria-describedby': PropTypes.string,
        BackdropComponent: PropTypes.elementType,
        BackdropProps: PropTypes.object,
        classes: PropTypes.object,
        className: PropTypes.string,
        disableEscapeKeyDown: PropTypes.bool,
        fullScreen: PropTypes.bool,
        onBackdropClick: PropTypes.func,
        PaperProps: PropTypes.object,
        sx: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
            PropTypes.func,
            PropTypes.object
        ]),
        TransitionComponent: PropTypes.elementType,
        transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number
        })]),
        TransitionProps: PropTypes.object
    })
}

export default memo(HalfPopUp);