import { Avatar, Backdrop, Box, Button, Grid, IconButton, InputAdornment, Stack, Step, StepConnector, stepConnectorClasses, StepLabel, Stepper, styled, Typography } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import majuraIconBlue from '../../../Assets/Icon/majuraIconBlue.svg';
import PropTypes from 'prop-types';
import CustomToastContainer, { Icon, Input, Paginate, showToast } from '../../../components/ui';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import TableX from '../../../components/Tables/TableX';
import TableCheck from '../../../components/Tables/TableCheck';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ApiService from '../../../services/ApiService';
import { handleDataTable } from '../../../utils/reusableFunction';

const ColorLibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2);',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2);',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 10,
        border: 0,
        backgroundColor: '#CACED8',
        borderRadius: 1,
    },
}));

const ColorLibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#CACED8',
    zIndex: 1,
    color: '#F2FAFF',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px', // default font size for step numbers
    ...(ownerState.active && {
        border: '5px solid #0087C2',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        backgroundColor: '#F2FAFF',
    }),
    ...(ownerState.completed && {
        border: '5px solid #0087C2',
        backgroundColor: '#F2FAFF',
    }),
    '& .step-number': {
        overflow: 'hidden',
        color: 'var(--Disable-Grey-Text-2, #7F888C)',
        leadingTrim: 'both',
        textEdge: 'cap',
        textOverflow: 'ellipsis',
        fontFamily: 'Nunito',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '34px',
        letterSpacing: '0.36px',
        ...(ownerState.active && {
            color: '#0087C2',
            overflow: 'hidden',
            leadingTrim: 'both',
            textEdge: 'cap',
            textOverflow: 'ellipsis',
            fontFamily: 'Nunito',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '34px',
            letterSpacing: '0.36px',
        }),
        ...(ownerState.completed && {
            color: '#0087C2',
            overflow: 'hidden',
            leadingTrim: 'both',
            textEdge: 'cap',
            textOverflow: 'ellipsis',
            fontFamily: 'Nunito',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '34px',
            letterSpacing: '0.36px',
        }),
    },
}));

function ColorLibStepIcon(props) {
    const { active, completed, className, icon } = props;

    return (
        <ColorLibStepIconRoot ownerState={{ completed, active }} className={className}>
            {
                completed ?
                    <Icon
                        iconName={'outline-select'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                width: '24px',
                                height: '24px',
                                alignItems: 'center',
                            }
                        }}
                    />
                    :
                    <div
                        className="step-number"
                        style={{
                            cursor: 'default',
                            userSelect: 'none',
                        }}
                    >
                        {icon}
                    </div>
            }
        </ColorLibStepIconRoot>
    );
}

ColorLibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const UserMigrationPage = memo(() => {
    const history = useNavigate();
    const btnTableRef = useRef(null);
    const titleRef = useRef(null);
    const stepperRef = useRef(null);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [dataCheck, setDataCheck] = useState([]);
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);
    const [stateX, setStateX] = useState({});

    const [activeStep, setActiveStep] = useState(0);
    const [Username, setUsername] = useState('');
    const [Password, setPassword] = useState('');
    const [OldPassword, setOldPassword] = useState('');
    const [ConfPassword, setConfPassword] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [isOldPassword, setIsOldPassword] = useState(false);
    const [isConfPassword, setIsConfPassword] = useState(false);

    const dataMigration = [
        { id: 1, label: 'Existing User' },
        { id: 2, label: 'Employee Data' },
        { id: 3, label: 'Reset Password' },
        { id: 4, label: 'New Login Credentials' },
    ]

    useEffect(() => {
        document.title = 'Majura - User Migration';
        handleActiveStep();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleActiveStep = () => {
        const isOldUsername = JSON.parse(localStorage.getItem('isOldUsername'))
        const isDefaultPass = JSON.parse(localStorage.getItem('isDefaultPass'))

        if (!isOldUsername && !isDefaultPass) {
            removeAllExcept([]);
            history('/', { replace: true });
        }
        else {
            if (isOldUsername) {
                setActiveStep(0);
                getDataEmployee(1, 50, [], sortArr);
                localStorage.setItem('activeStep', JSON.stringify(0));
            }
            else {
                if (isDefaultPass) {
                    setActiveStep(2);
                    setIsLoadingBackDrop(false);
                    localStorage.setItem('activeStep', JSON.stringify(2));
                }
                else {
                    let token = JSON.parse(localStorage.getItem('token'));

                    if (token !== undefined) {
                        history('/Majura', { replace: true });
                    }
                    else {
                        removeAllExcept([]);
                        history('/', { replace: true });
                    }
                    window.location.reload()
                }
            }
        }
    }

    const handleBack = (activeStep) => {
        let currentStep = activeStep - 1;

        emptyState(activeStep);
        setActiveStep(currentStep);
        localStorage.setItem('activeStep', JSON.stringify(currentStep));
        getDataEmployee(1, 50, [], sortArr);
    }

    const handleNext = (activeStep) => {
        if (activeStep === 0) {
            if (dataCheck?.length === 0) {
                showToast({ type: 'error', message: 'Please Select User Account!' });
            }
            else if (dataCheck?.length > 3) {
                showToast({ type: 'error', message: 'Please Select No More Than 3 User!' });
            }
            else {
                emptyState(1);
                setActiveStep(1);
                localStorage.setItem('activeStep', JSON.stringify(1));
                getDataEmployee(1, 50, [], sortArr);
            }
        }
        else if (activeStep === 1) {
            if (SelectedData?.id === undefined) {
                showToast({ type: 'error', message: 'Please Select Employee Account!' });
            }
            else {
                localStorage.setItem('activeStep', JSON.stringify(2));
                setActiveStep(2);
            }
        }
        else if (activeStep === 2) {
            let check = 0;
            if (!JSON.parse(localStorage.getItem('isOldUsername'))) {
                if (OldPassword === '') {
                    showToast({ type: 'error', message: 'Old Password Cannot Be Empty!' });
                    check = 1;
                }
                else if (OldPassword !== JSON.parse(localStorage.getItem('password'))) {
                    showToast({ type: 'error', message: 'Old Password Do Not Match!' });
                    check = 1;
                }
                else if (OldPassword === Password) {
                    showToast({ type: 'error', message: 'Old Password and New Password Cannot Be The Same!' });
                    check = 1;
                }
            }

            if (check === 0) {
                if (Password === '' || ConfPassword === '') {
                    showToast({ type: 'error', message: 'Password and Confirmation Password Cannot Be Empty!' });
                }
                else if (Password !== ConfPassword) {
                    showToast({ type: 'error', message: 'Password and Confirmation Password Do Not Match!' });
                }
                else {
                    setIsLoadingBackDrop(true);
                    if (JSON.parse(localStorage.getItem('isOldUsername'))) {
                        let data = {
                            "initCredential": {
                                "employeeId": SelectedData.id,
                                "oldEmployeeId1": dataCheck[0]?.id || 0,
                                "oldEmployeeId2": dataCheck[1]?.id || 0,
                                "oldEmployeeId3": dataCheck[2]?.id || 0,
                                "password": Password,
                                "confirmPassword": ConfPassword
                            }
                        }
                        ApiService.IdentityCustomPut('authentication/authentication/InitCredential', data)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    setIsLoadingBackDrop(false);
                                    setIsPassword(false);
                                    setUsername(response.data.data.Username);
                                    localStorage.setItem('activeStep', JSON.stringify(3));
                                    setActiveStep(3);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Error When Sending The Data, ' + response.data.message });
                                }
                            })
                            .catch(function (error) {
                                console.error('error saat fetch', error)
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'error', message: 'Error When Sending The Data, ' + error })
                            })
                    }
                    else {
                        let data = {
                            "authentication": {
                                "password": Password,
                                "oldPassword": OldPassword
                            }
                        }
                        ApiService.IdentityCustomPut('authentication/authentication/ChangePassword', data)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    setIsLoadingBackDrop(false);
                                    setIsPassword(false);
                                    setUsername(JSON.parse(localStorage.getItem('username')));
                                    localStorage.setItem('activeStep', JSON.stringify(3));
                                    setActiveStep(3);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Error When Sending The Data, ' + response.data.message });
                                }
                            })
                            .catch(function (error) {
                                console.error('error saat fetch', error)
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'error', message: 'Error When Sending The Data, ' + error })
                            })
                    }
                }
            }
        }
        else {
            removeAllExcept([]);
            history('/', { replace: true });
        }
    }

    function shouldKeep(key, prefixes) {
        return prefixes.some((prefix) => key.startsWith(prefix));
    }

    function removeAllExcept(prefixesToKeep) {
        const keys = Object.keys(localStorage);

        keys.forEach((key) => {
            if (!shouldKeep(key, prefixesToKeep)) {
                localStorage.removeItem(key);
            }
        });
    }

    const getDataEmployee = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        const activeStep = JSON.parse(localStorage.getItem('activeStep'));
        ApiService.IdentityCustomPostByPage(activeStep === 0 ? 'authentication/authentication/ReadOldEmployee?pageCode=SEAINIT' : 'authentication/authentication/ReadNewEmployee?pageCode=SEAINIT', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const emptyState = (activeStep) => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setStateX({})
        setGoTo(1);
        setSortArr([])

        if (activeStep === 0) {
            setDataCheck([]);
        }
        else if (activeStep === 1) {
            setSelectedData({});
        }
    };

    const handleReset = () => {
        emptyState(activeStep);
        getDataEmployee(1, 50, [], sortArr);
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getDataEmployee(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getDataEmployee(1, value, filter, sortArr);
    }

    const getContainerHeight = () => {
        const hHeader = titleRef?.current?.clientHeight ? (titleRef?.current?.clientHeight + 48) : 64
        const hBtnPage = stepperRef?.current?.clientHeight ? (stepperRef?.current?.clientHeight + 72) : 44
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    return (
        <div style={{ background: '#E4E8EC', minHeight: '100vh', height: 'auto', width: '100%', paddingTop: '64px', paddingBottom: '64px' }}>
            <CustomToastContainer />

            <Box
                className="container"
                sx={{
                    display: 'flex',
                    width: { xs: '100%', sm: '100%', md: '960px' }, // Adjust width based on screen size
                    padding: { xs: '16px', sm: '24px', md: '40px' }, // Smaller padding for mobile
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: { xs: '16px', sm: '20px', md: '24px' }, // Reduce gap for mobile
                    flexShrink: 0,
                    alignSelf: 'stretch',
                    borderRadius: '12px',
                    background: 'rgba(255, 255, 255, 0.80)',
                }}
            >
                <section id="content" style={{ maxWidth: '100%', width: '100%' }}>
                    <section className="section page-heading animate-onscroll">
                        <div ref={titleRef}>
                            <Grid container direction="row" alignItems={'center'} sx={{ marginTop: '-6px' }}>
                                <Grid item xs={12} sm={6}>
                                    <Typography
                                        sx={{
                                            color: '#000',
                                            fontFamily: 'Nunito',
                                            fontSize: '24px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        User Migration
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    className='text-end'
                                    sx={{
                                        display: { xs: 'none', sm: 'flex' },
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Avatar
                                        src={majuraIconBlue}
                                        sx={{
                                            marginTop: '6px',
                                            width: '120px',
                                            height: 'fit-content',
                                        }}
                                        variant='square'
                                        alt='.'
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </section>

                    <hr style={{ margin: '24px 0px' }} />

                    <div style={{ maxWidth: '100%' }}>
                        <Typography
                            style={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                textAlign: 'justify',
                            }}
                            className="animate-onscroll"
                        >
                            You're logged in with an Infoss account. Complete the following steps to finish the registration:
                        </Typography>

                        <div style={{ marginTop: '24px', maxWidth: '100%' }} ref={stepperRef}>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorLibConnector />}>
                                {
                                    dataMigration.map((data, index) => (
                                        <Step
                                            key={index}
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <StepLabel
                                                StepIconComponent={ColorLibStepIcon}
                                                sx={{
                                                    alignItems: 'center !important',
                                                    justifyContent: 'center !important',
                                                    width: '100%',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color:
                                                            index === activeStep ?
                                                                '#0087C2'
                                                                :
                                                                index > activeStep ?
                                                                    'var(--Blue-Primary-2, #4F6B77)'
                                                                    :
                                                                    'var(--Blue-Primary-1, #083A50)'
                                                        ,
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        textAlign: 'center',
                                                        fontWeight: '700',
                                                        cursor: 'default',
                                                        userSelect: 'none',
                                                    }}
                                                >
                                                    {data.label}
                                                </Typography>
                                            </StepLabel>
                                        </Step>
                                    ))
                                }
                            </Stepper>
                        </div>

                        {
                            activeStep === 0 && (
                                <div style={{ maxWidth: '100%', marginTop: '20px' }}>
                                    <Typography
                                        style={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            textAlign: 'justify',
                                        }}
                                        className="animate-onscroll"
                                    >
                                        Choose all your existing Infoss employee data to link with this Majura’s system
                                    </Typography>

                                    <Grid container direction="column" sx={{ width: '100%', maxWidth: '100%' }}>
                                        <Grid item xs={12} sx={{ width: '100%', borderRadius: '5px' }}>
                                            <div ref={btnTableRef}>
                                                {ButtonComponent.RefreshButton(
                                                    sortArr,
                                                    rowsCount,
                                                    columnData,
                                                    setGoTo,
                                                    handleReset,
                                                    setFilterBy,
                                                    filterBy,
                                                    setFilter,
                                                    setNumPage,
                                                    setRowsCount,
                                                    getDataEmployee,
                                                    stateX,
                                                    setStateX
                                                )}
                                            </div>

                                            <div
                                                className="rounded-10 table-responsive"
                                                style={{
                                                    maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                                    width: '100%', // Change this from maxWidth to width
                                                    marginTop: '16px',
                                                    padding: '2px 16px',
                                                    borderRadius: '12px',
                                                    background: '#FFF',
                                                }}
                                            >
                                                {isLoading ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    <div className="mt-1">
                                                        <TableCheck
                                                            uniqueKey={'id'}
                                                            goTo={goTo}
                                                            rowsCount={rowsCount}
                                                            setNumPage={setNumPage}
                                                            setRowsCount={setRowsCount}
                                                            getData={getDataEmployee}
                                                            columnData={columnData}
                                                            dataMap={dataMap}
                                                            dataTable={dataTable}
                                                            dataCheck={dataCheck}
                                                            setDataCheck={setDataCheck}
                                                            SelectedData={SelectedData}
                                                            setSelectedData={setSelectedData}
                                                            setFilter={setFilter}
                                                            setFilterBy={setFilterBy}
                                                            CellDoubleClick={() => handleNext(activeStep)}
                                                            stateX={stateX}
                                                            setStateX={setStateX}
                                                            sortArr={sortArr}
                                                            setSortArr={setSortArr}
                                                            setGoTo={setGoTo}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div style={{ marginTop: '16px' }}>
                                                <Paginate
                                                    page={numPage}
                                                    limit={rowsCount}
                                                    totalData={totalRows}
                                                    onChange={onChangePaging}
                                                    onChangeLimit={onChangeLimit}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }

                        {
                            activeStep === 1 && (
                                <div style={{ maxWidth: '100%', marginTop: '20px' }}>
                                    <Typography
                                        style={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            textAlign: 'justify',
                                        }}
                                        className="animate-onscroll"
                                    >
                                        Choose your employee data from this company’s registered list
                                    </Typography>

                                    <Grid container direction="column" sx={{ width: '100%', maxWidth: '100%' }}>
                                        <Grid item xs={12} sx={{ width: '100%', borderRadius: '5px' }}>
                                            <div ref={btnTableRef}>
                                                {ButtonComponent.RefreshButton(
                                                    sortArr,
                                                    rowsCount,
                                                    columnData,
                                                    setGoTo,
                                                    handleReset,
                                                    setFilterBy,
                                                    filterBy,
                                                    setFilter,
                                                    setNumPage,
                                                    setRowsCount,
                                                    getDataEmployee,
                                                    stateX,
                                                    setStateX
                                                )}
                                            </div>

                                            <div
                                                className="rounded-10 table-responsive"
                                                style={{
                                                    maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                                    width: '100%', // Change this from maxWidth to width
                                                    marginTop: '16px',
                                                    padding: '2px 16px',
                                                    borderRadius: '12px',
                                                    background: '#FFF',
                                                }}
                                            >
                                                {isLoading ? (
                                                    <LoadingSpinner />
                                                ) : (
                                                    <div className="mt-1">
                                                        <TableX
                                                            uniqueKey={'id'}
                                                            goTo={goTo}
                                                            rowsCount={rowsCount}
                                                            setNumPage={setNumPage}
                                                            setRowsCount={setRowsCount}
                                                            getData={getDataEmployee}
                                                            columnData={columnData}
                                                            dataMap={dataMap}
                                                            dataTable={dataTable}
                                                            SelectedData={SelectedData}
                                                            setSelectedData={setSelectedData}
                                                            setFilter={setFilter}
                                                            setFilterBy={setFilterBy}
                                                            CellDoubleClick={() => handleNext(activeStep)}
                                                            stateX={stateX}
                                                            setStateX={setStateX}
                                                            sortArr={sortArr}
                                                            setSortArr={setSortArr}
                                                            setGoTo={setGoTo}
                                                        />
                                                    </div>
                                                )}
                                            </div>

                                            <div style={{ marginTop: '16px' }}>
                                                <Paginate
                                                    page={numPage}
                                                    limit={rowsCount}
                                                    totalData={totalRows}
                                                    onChange={onChangePaging}
                                                    onChangeLimit={onChangeLimit}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }

                        {
                            activeStep === 2 && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography
                                        style={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            textAlign: 'justify',
                                        }}
                                        className="animate-onscroll"
                                    >
                                        Create a New Password to login to the Majura system (you still use the default password, please change your password)
                                    </Typography>

                                    <Grid container sx={{ marginTop: '10px' }}>
                                        <Grid item xs={6}>
                                            {
                                                !JSON.parse(localStorage.getItem('isOldUsername')) && (
                                                    <Input
                                                        name="password"
                                                        label={'Old Password'}
                                                        placeholder='Enter your old password'
                                                        type={isOldPassword ? 'text' : 'password'}
                                                        value={OldPassword}
                                                        onChange={(e) => setOldPassword(e.target.value)}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                        formLabelProps={{
                                                            sx: {
                                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                                fontFamily: 'Nunito',
                                                                fontStyle: 'normal',
                                                                fontWeight: '700',
                                                                lineHeight: 'normal',
                                                                userSelect: 'none',
                                                            }
                                                        }}
                                                        inputBaseProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setIsOldPassword(!isOldPassword)}
                                                                        size='small'
                                                                        sx={{ padding: '0px' }}
                                                                    >
                                                                        {
                                                                            isOldPassword ?
                                                                                <Icon
                                                                                    iconName={'eye-on'}
                                                                                    svgIconProps={{
                                                                                        sx: {
                                                                                            width: '20px',
                                                                                            height: '20px'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                :
                                                                                <Icon
                                                                                    iconName={'eye-off'}
                                                                                    svgIconProps={{
                                                                                        sx: {
                                                                                            width: '20px',
                                                                                            height: '20px'
                                                                                        }
                                                                                    }}
                                                                                />
                                                                        }
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                )
                                            }

                                            <Input
                                                name="password"
                                                label={'New Password'}
                                                placeholder='Enter your new password'
                                                type={isPassword ? 'text' : 'password'}
                                                value={Password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: !JSON.parse(localStorage.getItem('isOldUsername')) ? '16px' : '0px'
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                        userSelect: 'none',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setIsPassword(!isPassword)}
                                                                size='small'
                                                                sx={{ padding: '0px' }}
                                                            >
                                                                {
                                                                    isPassword ?
                                                                        <Icon
                                                                            iconName={'eye-on'}
                                                                            svgIconProps={{
                                                                                sx: {
                                                                                    width: '20px',
                                                                                    height: '20px'
                                                                                }
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Icon
                                                                            iconName={'eye-off'}
                                                                            svgIconProps={{
                                                                                sx: {
                                                                                    width: '20px',
                                                                                    height: '20px'
                                                                                }
                                                                            }}
                                                                        />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />

                                            <Input
                                                name="password"
                                                label={'New Password Confirmation'}
                                                placeholder='Enter your new password confirmation'
                                                type={isConfPassword ? 'text' : 'password'}
                                                value={ConfPassword}
                                                onChange={(e) => setConfPassword(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                        userSelect: 'none',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setIsConfPassword(!isConfPassword)}
                                                                size='small'
                                                                sx={{ padding: '0px' }}
                                                            >
                                                                {
                                                                    isConfPassword ?
                                                                        <Icon
                                                                            iconName={'eye-on'}
                                                                            svgIconProps={{
                                                                                sx: {
                                                                                    width: '20px',
                                                                                    height: '20px'
                                                                                }
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Icon
                                                                            iconName={'eye-off'}
                                                                            svgIconProps={{
                                                                                sx: {
                                                                                    width: '20px',
                                                                                    height: '20px'
                                                                                }
                                                                            }}
                                                                        />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }

                        {
                            activeStep === 3 && (
                                <div style={{ marginTop: '20px' }}>
                                    <Typography
                                        style={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            textAlign: 'justify',
                                        }}
                                        className="animate-onscroll"
                                    >
                                        Here are your username and password to login to the Majura system
                                    </Typography>

                                    <Grid container sx={{ marginTop: '10px' }}>
                                        <Grid item xs={6}>
                                            <Input
                                                label={'New Username'}
                                                value={Username}
                                                placeholder='Enter your username'
                                                onChange={(e) => setUsername(e.target.value)}
                                                disabled
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                            />

                                            <Input
                                                name="password"
                                                label={'New Password'}
                                                placeholder='Enter your new password'
                                                type={isPassword ? 'text' : 'password'}
                                                value={Password}
                                                disabled
                                                onChange={(e) => setPassword(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px'
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                        userSelect: 'none',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => setIsPassword(!isPassword)}
                                                                size='small'
                                                                sx={{ padding: '0px' }}
                                                            >
                                                                {
                                                                    isPassword ?
                                                                        <Icon
                                                                            iconName={'eye-on'}
                                                                            svgIconProps={{
                                                                                sx: {
                                                                                    width: '20px',
                                                                                    height: '20px'
                                                                                }
                                                                            }}
                                                                        />
                                                                        :
                                                                        <Icon
                                                                            iconName={'eye-off'}
                                                                            svgIconProps={{
                                                                                sx: {
                                                                                    width: '20px',
                                                                                    height: '20px'
                                                                                }
                                                                            }}
                                                                        />
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Box
                                        sx={{
                                            marginTop: '24px',
                                            display: 'flex',
                                            padding: '16px 12px',
                                            alignItems: 'center',
                                            gap: '16px',
                                            alignSelf: 'stretch',
                                            borderRadius: '8px',
                                            background: 'var(--Mjr-orange-4, #FEF3E5)',
                                        }}
                                    >
                                        <Stack direction="row" spacing={'16px'} alignItems="center">
                                            <Icon
                                                iconName={'warning'}
                                                svgIconProps={{
                                                    sx: {
                                                        width: '24px',
                                                        height: '24px'
                                                    }
                                                }}
                                            />

                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '500',
                                                    marginTop: '2px !important'
                                                }}
                                            >
                                                Make sure to remember and save this username and password to login to the Majura system
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </div>
                            )
                        }
                    </div>
                </section>
            </Box>

            <Box
                className="container"
                sx={{
                    display: 'flex',
                    width: { xs: '100%', sm: '100%', md: '960px' }, // Adjust width based on screen size
                    padding: { xs: '16px 0px', sm: '24px 0px', md: '40px 0px' }, // Smaller padding for mobile
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flexShrink: 0,
                    alignSelf: 'stretch',
                    borderRadius: '12px',
                    background: 'transparent',
                }}
            >
                <Stack direction={'row'} spacing={'12px'} justifyContent={'center'}>
                    {
                        (activeStep === 1 || (JSON.parse(localStorage.getItem('isOldUsername')) && activeStep === 2)) && (
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '8px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    minHeight: '28px !important',
                                    borderRadius: '8px',
                                    background: 'var(--Blue-Button-Primary-1, #B3E3FF)',
                                    '&:hover': {
                                        backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={() => handleBack(activeStep)}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px',
                                        }
                                    }}
                                    iconName={'return'}
                                />

                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '2px !important',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        color: '#0087C2',
                                    }}
                                >
                                    Back
                                </Typography>
                            </Button>
                        )
                    }

                    {
                        (activeStep === 0 || (!JSON.parse(localStorage.getItem('isOldUsername')) && activeStep === 2)) && (
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '8px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    minHeight: '28px !important',
                                    borderRadius: '8px',
                                    background: 'var(--Blue-Button-Primary-1, #B3E3FF)',
                                    '&:hover': {
                                        backgroundColor: 'var(--Blue-Button-Primary-1, #B3E3FF) !important',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={() => { removeAllExcept([]); history('/', { replace: true }); }}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '16px',
                                            width: '16px',
                                        }
                                    }}
                                    iconName={'log-out'}
                                />

                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '2px !important',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        color: '#0087C2',
                                    }}
                                >
                                    Log Out
                                </Typography>
                            </Button>
                        )
                    }

                    <Button
                        size='small'
                        variant="outlined"
                        className='d-flex align-items-center'
                        sx={{
                            padding: '4px 12px !important',
                            gap: '8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '32px !important',
                            minHeight: '28px !important',
                            borderRadius: '8px',
                            background: 'var(--Blue-Button-Primary-1, #0087C2)',
                            '&:hover': {
                                backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        }}
                        onClick={() => handleNext(activeStep)}
                    >
                        <Icon
                            svgIconProps={{
                                htmlColor: '#E4E8EC',
                                sx: {
                                    height: '16px',
                                    width: '16px',
                                }
                            }}
                            iconName={activeStep !== 3 ? 'save' : 'outline-select'}
                        />

                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                marginTop: '2px !important',
                                fontSize: '12px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'center',
                                color: '#E4E8EC',
                            }}
                        >
                            {activeStep !== 3 ? 'Next' : 'Finish & Re-Login'}
                        </Typography>
                    </Button>
                </Stack>
            </Box>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </div>
    )
})

export default memo(UserMigrationPage);