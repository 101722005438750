import { Grid, MenuItem, } from '@mui/material';
import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ButtonFindDataShipment from '../Buttons/ButtonFindDataShipment';
import { CustomDatePicker, Icon, Input, Paginate, PopUp, Selection, showToast } from '../../../../components/ui';
import { getDefaultDateFormat } from '../../../../utils/date';
import { handleDataTable } from '../../../../utils/reusableFunction';

const TableContainer = memo((props) => {
    return (
        <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
            <div>
                {
                    ButtonComponent.RefreshButton(
                        props.sortArr,
                        props.rowsCount,
                        props.columnData,
                        props.setGoTo,
                        props.handleReset,
                        props.setFilterBy,
                        props.filterBy,
                        props.setFilter,
                        props.setNumPage,
                        props.setRowsCount,
                        props.getData,
                        props.stateX,
                        props.setStateX
                    )
                }
            </div>

            <div
                className="rounded-10 table-responsive"
                style={{
                    maxHeight: '500px',
                    marginTop: '24px !important',
                    padding: '2px 16px',
                    borderRadius: '12px',
                    background: '#FFF'
                }}
            >
                {
                    props.isLoading ?
                        <LoadingSpinner />
                        :
                        <div className='mt-1'>
                            <TableX
                                uniqueKey={'id'}
                                goTo={props.goTo}
                                rowsCount={props.rowsCount}
                                setNumPage={props.setNumPage}
                                setRowsCount={props.setRowsCount}
                                getData={props.getData}
                                columnData={props.columnData}
                                dataMap={props.dataMap}
                                dataTable={props.dataTable}
                                SelectedData={props.SelectedData}
                                setSelectedData={props.setSelectedData}
                                setFilter={props.setFilter}
                                setFilterBy={props.setFilterBy}
                                CellDoubleClick={props.CellDoubleClick}
                                stateX={props.stateX}
                                setStateX={props.setStateX}
                                sortArr={props.sortArr}
                                setSortArr={props.setSortArr}
                                setGoTo={props.setGoTo}
                            />
                        </div>
                }
            </div>

            <div style={{ marginTop: '16px' }}>
                <Paginate
                    page={props.numPage}
                    limit={props.rowsCount}
                    totalData={props.totalRows}
                    onChange={props.onChangePaging}
                    onChangeLimit={props.onChangeLimit}
                />
            </div>
        </Grid>
    )
});

const FormContainer = memo((props) => {
    return (
        <Grid
            container
            spacing={'32px'}
        >
            <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
            >
                <Input
                    label={'Container No'}
                    value={props.ContainerNo}
                    onChange={(e) => props.setContainerNo(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                />

                <Input
                    label={'Feeder Vessel'}
                    value={props.FeederVessel}
                    onChange={(e) => props.setFeederVessel(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Input
                    label={'Ref. SI Shipper / PEB / PIB'}
                    value={props.RefShipper}
                    onChange={(e) => props.setRefShipper(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <CustomDatePicker
                    label={'Date From'}
                    value={props.ETFrom}
                    onChange={props.handleETD}
                    type={'date'}
                    hidden={!props.isETARange}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />
            </Grid>

            <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
            >
                <Input
                    label={'MBL / MAWB'}
                    value={props.MBLMAWB}
                    onChange={(e) => props.setMBLMAWB(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                />

                <Input
                    label={'HBL / HAWB'}
                    value={props.HBLHAWB}
                    onChange={(e) => props.setHBLHAWB(e.target.value)}
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />

                <Selection
                    id='0'
                    label={'ETA/ETD Range'}
                    fullWidth={true}
                    placeholder={'Select ETA/ETD Range'}
                    value={props.isETARange}
                    onChange={(e) => props.handleIsETARange(e.target.value)}
                    formControlProps={{
                        sx: {
                            marginTop: '16px !important'
                        }
                    }}
                >
                    {
                        props.dataETARange.map((data) => (
                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.value}>
                                {data.name}
                            </MenuItem>
                        ))
                    }
                </Selection>

                <CustomDatePicker
                    label={'Date To'}
                    value={props.ETTo}
                    onChange={props.handleETA}
                    type={'date'}
                    hidden={!props.isETARange}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
});

const ModalFindData = memo((props) => {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const [MBLMAWB, setMBLMAWB] = useState('');
    const [HBLHAWB, setHBLHAWB] = useState('');
    const history = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const param = 'shipmentOrder/shipmentOrder';
    const [data, setData] = useState([]);
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const dataETARange = [
        { id: 1, name: 'All', value: false },
        { id: 2, name: 'Custom', value: true }
    ];

    const [ContainerNo, setContainerNo] = useState('');
    const [FeederVessel, setFeederVessel] = useState('');
    const [RefShipper, setRefShipper] = useState('');
    const [ETFrom, setETFrom] = useState('');
    const [ETTo, setETTo] = useState('');
    const [isETARange, setIsETARange] = useState(false);

    const handleClose = () => {
        emptyState();
        emptyStateData();
        props.handleClick();
    };

    const emptyState = () => {
        setContainerNo('');
        setFeederVessel('');
        setRefShipper('');
        setETFrom('');
        setETTo('');
        setIsETARange(false);

        setData([]);
        setColumnData([]);
        setGoTo(1);
        setDataMap([]);
        setTotalRows(50);
        setStateX({});
        setSortArr([]);
    };

    const FindData = () => {
        getData(1, rowsCount);
    }

    const Report = () => {
        if (data.length !== 0) {
            ExportPDF(data, 'Print Shipment Order Report');
        }
        else {
            showToast({ type: 'error', message: 'Please Show Data Shipment Order First!' });
        }
    }

    const ReportExcel = () => {
        if (data.length !== 0) {
            ExportExcel(data, 'Print Shipment Order Report Excel');
        }
        else {
            showToast({ type: 'error', message: 'Please Show Data Shipment Order First!' });
        }
    }

    const EditData = () => {
        let check = 0;
        props.DataRegUserAccess.forEach(element => {
            if (element.accessCode === 'REI') {
                check = 1;
            }
        });

        if (check !== 0) {
            if (SelectedData.id === undefined) {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
            else {
                if (props.jobType !== '10') {
                    history('/Majura/ShipmentOrder/' + props.jobType + '/' + SelectedData.id);
                }
                else {
                    showToast({ type: 'error', message: 'Coming Soon!' });
                }
            }
        }
        else {
            showToast({ type: 'error', message: 'You Don\'t Have Access!' });
        }
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        let params = [];
        let filter = [];
        params = [...params,
        { 'attr': 'jobTypeId', 'value': '' + props.jobType },
        { "attr": "isWithSeaContainer", "value": "true" },
        { "attr": "isWithRouting", "value": "true" }
        ]

        if (ContainerNo !== '') {
            filter = [...filter,
            { 'field': 'containerNo', 'data': '' + ContainerNo },
            ]
        }

        if (FeederVessel !== '') {
            filter = [...filter,
            { 'field': 'firstFeeder', 'data': '' + FeederVessel },
            ]
        }

        if (RefShipper !== '') {
            filter = [...filter,
            { 'field': 'siReference', 'data': '' + RefShipper }
            ]
        }

        if (MBLMAWB !== '') {
            filter = [...filter,
            { 'field': 'masterBL', 'data': '' + MBLMAWB },
            ]
        }

        if (HBLHAWB !== '') {
            filter = [...filter,
            { 'field': 'houseBL', 'data': '' + HBLHAWB }
            ]
        }

        if (isETARange === true) {
            if (props.jobTypeData.portType !== 'Import') {
                params = [...params,
                {
                    "attr": "etd, etd",
                    "mathSign": ">=, <=",
                    'value': '' + ETFrom + ',' + ETTo
                }
                ]
            }
            else {
                params = [...params,
                {
                    "attr": "eta, eta",
                    "mathSign": ">=, <=",
                    'value': '' + ETFrom + ',' + ETTo
                }
                ]
            }
        }

        if (filters?.length > 0) {
            filter = [...filter, ...filters];
        }

        setIsLoading(true);
        ApiService.OperationDynamicPostByPage(param, 'SEAFND', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});
                    setData(response.data.data.shipmentOrder);

                    let temp = response.data.data.shipmentOrder;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp;
                    })

                    setDataMap(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setColumnData(response.data.data.columns.headerColumns);
                    setTotalRows(response.data.totalRowCount);

                    setIsLoading(false);
                }
                setIsLoading(false);
            })
            .catch(function (error) {
                setIsLoading(false);
                // console.error('error saat fetch', error);
            })
    }

    const emptyStateData = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setGoTo(1);
        setFilter([]);
        setFilterBy([]);
    };

    const handleReset = () => {
        emptyStateData();
        getData(1, 50);
    };

    function CellDoubleClick(row) {
        EditData();
    }

    function handleIsETARange(value) {
        setIsETARange(value);

        if (value === true) {
            var date = getDefaultDateFormat(new Date());
            setETFrom(date);
            setETTo(date);
        }
        else {
            setETFrom('');
            setETTo('');
        }
    }

    function handleETD(value) {
        if (ETTo < value) {
            showToast({ type: 'error', message: 'Date To Can\'t be Greater Than Date From!' });
        }
        else {
            setETFrom(value);
        }
    }

    function handleETA(value) {
        if (ETFrom > value) {
            showToast({ type: 'error', message: 'Date From Can\'t be Greater Than Date To!' });
        }
        else {
            setETTo(value);
        }
    }

    const ExportPDF = (data, title) => {
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        console.log('data', data)
        doc.setFontSize(15);
        var headers = [Object.keys(data[0])]
        var data2 = [];
        for (let i = 0; i < data.length; i++) {
            data2 = [...data2, Object.values(data[i])]
        }

        let content = {
            startY: 50,
            head: headers,
            body: data2
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save(title + ".pdf")
        showToast({ type: 'success', message: 'Export Data to PDF Success!' });
        handleClose();
    }

    const ExportExcel = (data, title) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data1 = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data1, title + fileExtension);
        showToast({ type: 'success', message: 'Export Data to Excel Success!' });
        handleClose();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter, sortArr);
    }

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'search'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px',
                                transform: 'rotateY(180deg)'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    <FormContainer
                        ContainerNo={ContainerNo}
                        setContainerNo={setContainerNo}
                        FeederVessel={FeederVessel}
                        setFeederVessel={setFeederVessel}
                        RefShipper={RefShipper}
                        setRefShipper={setRefShipper}
                        MBLMAWB={MBLMAWB}
                        setMBLMAWB={setMBLMAWB}
                        HBLHAWB={HBLHAWB}
                        setHBLHAWB={setHBLHAWB}
                        ETFrom={ETFrom}
                        ETTo={ETTo}
                        isETARange={isETARange}
                        handleETD={handleETD}
                        handleETA={handleETA}
                        dataETARange={dataETARange}
                        handleIsETARange={handleIsETARange}
                    />

                    <div style={{ marginTop: '16px' }}>
                        <TableContainer
                            sortArr={sortArr}
                            rowsCount={rowsCount}
                            columnData={columnData}
                            setGoTo={setGoTo}
                            handleReset={handleReset}
                            setFilterBy={setFilterBy}
                            filterBy={filterBy}
                            setFilter={setFilter}
                            setNumPage={setNumPage}
                            getData={getData}
                            stateX={stateX}
                            setStateX={setStateX}
                            dataTable={dataTable}
                            dataMap={dataMap}
                            SelectedData={SelectedData}
                            setSelectedData={setSelectedData}
                            numPage={numPage}
                            totalRows={totalRows}
                            goTo={goTo}
                            isLoading={isLoading}
                            setRowsCount={setRowsCount}
                            CellDoubleClick={CellDoubleClick}
                            setSortArr={setSortArr}
                            onChangePaging={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>

                    <div style={{ marginTop: '48px' }}>
                        <ButtonFindDataShipment
                            FindData={FindData}
                            Report={Report}
                            ReportExcel={ReportExcel}
                            EditData={EditData}
                            Close={handleClose}
                            DataRegUserAccess={props.DataRegUserAccess}
                        />
                    </div>
                </div>
            </PopUp>
        </>
    )
})

export default memo(ModalFindData);