import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService, { isListAsc } from '../../../../services/ApiService';
import Backdrop from '@mui/material/Backdrop';
import BankAdvButton from './ComponentBankAdv/BankAdvButton';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import TableCheck from '../../../../components/Tables/TableCheck';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks.jsx';
import { handleDataTable, PrintPDF } from '../../../../utils/reusableFunction.jsx';

const ListBankAdv = forwardRef((props, ref) => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const history = useNavigate()
  const param = 'bankAdvance/bankAdvance'
  const paramRegUserAccess = 'regUserAccess/regUserAccess';
  const [rowsCount, setRowsCount] = useState(50)
  const [numPage, setNumPage] = useState(isListAsc === true? 0 : 1)

  const [dataMap, setDataMap] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [columnData, setColumnData] = useState([])
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50)
  const [filter, setFilter] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [titleModalConfirmation, setTitleModalConfirmation] = useState("");
  const [titleConfirmation, setTitleConfirmation] = useState("");
  const [dataSelected, setDataSelected] = useState({});
  const [isRemarks, setIsRemarks] = useState(false);
  const [approvedCreditRemarks, setApprovedCreditRemarks] = useState('');

  const [isModalDelete, setIsModalDelete] = useState(false);
  const [titleModalDelete, setTitleModalDelete] = useState("");

  const [DropDownTitle, setDropDownTitle] = useState('All');

  const [isApprove, setIsApprove] = useState(false);
  const [dataCheck, setDataCheck] = useState([]);

  const dropDownList = [
    { label: 'All', icon: 'search-doc' },
    { label: 'Active', icon: 'documents' },
    { label: 'Approved', icon: 'approve-doc' },
    { label: 'Settled', icon: 'journal' },
    { label: 'Deleted', icon: 'status-deleted' },
  ]

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Bank Advance', link: '/Majura/BankAdvance', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useImperativeHandle(ref, () => ({
    localSave() {

    }
  }));

  useEffect(() => {
    document.title = 'Bank Advance - ' + JSON.parse(localStorage.getItem('branchName'));
    getDataAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = (pageNumber, pageSize, filters, orderBy, data) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    }
    else {
      setIsLoadingBackDrop(true);
    }

    let filter = [];
    let params = [];
    if (data) {
      if (data === 'All') {
        filter = [...filters]
      }
      else if (data === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
      }
      else if (data === 'Active') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }, { 'field': 'approved', 'data': 'false' }]
      }
      else if (data === 'Approved') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }, { 'field': 'approved', 'data': 'true' }, { 'field': 'paid', 'data': 'false' }]
      }
      else if (data === 'Settled') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }, { 'field': 'approved', 'data': 'true' }, { 'field': 'paid', 'data': 'true' }]
      }
    }
    else {
      if (DropDownTitle === 'All') {
        filter = [...filters]
      }
      else if (DropDownTitle === 'Deleted') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
      }
      else if (DropDownTitle === 'Active') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }, { 'field': 'approved', 'data': 'false' }]
      }
      else if (DropDownTitle === 'Approved') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }, { 'field': 'approved', 'data': 'true' }, { 'field': 'paid', 'data': 'false' }]
      }
      else if (DropDownTitle === 'Settled') {
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }, { 'field': 'approved', 'data': 'true' }, { 'field': 'paid', 'data': 'true' }]
      }
    }

    if (JSON.parse(localStorage.getItem('isApprove')) === true) {
      params = [...params, { 'attr': 'approved', 'value': 'false' }, { 'attr': 'rowStatus', 'value': 'ACT' }]
    }

    ApiService.ListFinAccPostByPage(param, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})

          let temp = response.data.data.bankAdvance
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          if (pageNumber === 0) {
            setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
          }

          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
          if (isLoadingBackDrop === false) {
            setIsLoading(false);
          }
          else {
            setIsLoadingBackDrop(false);
          }
        }

        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }
      })
      .catch(function (error) {
        if (isLoadingBackDrop === false) {
          setIsLoading(false);
        }
        else {
          setIsLoadingBackDrop(false);
        }

        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
      })
  }

  const getDataAccess = () => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }

    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'FBAD')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { 'attr': 'permissionId', 'value': '' + pId },
        { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
        { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
      ]
      ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);
            getData(isListAsc === true? 0 : 1, 50, []);
          }
          else if (response.data.code === 500) {
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
            if (isLoadingBackDrop === false) {
              setIsLoading(false);
            }
            else {
              setIsLoadingBackDrop(false);
            }
          }
        })
        .catch(function (error) {
          console.error('error saat fetch', error)
          showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
        })
    }
    else {
      history('/Majura');
    }
  }

  const emptyState = () => {
    setNumPage(isListAsc === true? 0 : 1);
    setRowsCount(50);
    setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setGoTo(1);
    setSortArr([]);
    setStateX({});
  };

  const handleClickConfirmation = () => {
    if (isModalConfirmation === false) {
      setApprovedCreditRemarks('');
      setIsModalConfirmation(true);
      setDataSelected(SelectedData);
    } else {
      setIsModalConfirmation(false);
    }
  };

  const handleClickDelete = () => {
    if (isModalDelete === false) {
      setIsModalDelete(true);
      setDataSelected(SelectedData);
    } else {
      setIsModalDelete(false);
    }
  };

  function ConfirmationBA(approveRemarks = '') {
    if (titleConfirmation === "Add") {
      Add();
    }
    else if (titleConfirmation === "Edit") {
      Edit();
    }
    else if (titleConfirmation === 'Approve') {
      Approve();
    }
    else if (titleConfirmation === 'Approval Credit') {
      ApproveCR(approveRemarks);
    }
    else if (titleConfirmation === 'Print') {
      Print();
    }
    setIsRemarks(false);
    setIsModalConfirmation(false);
  };

  const AddData = () => {
    setTitleConfirmation("Add");
    setTitleModalConfirmation("Bank Advance");
    handleClickConfirmation();
  };

  const Add = () => {
    window.open('/Majura/BankAdvance/Create', newWindowConfig);
  }

  const EditData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      setTitleConfirmation("Edit");
      setTitleModalConfirmation("Bank Advance");
      handleClickConfirmation();
    }
  };

  const Edit = () => {
    if (!dataSelected?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      window.open('/Majura/BankAdvance/' + dataSelected.id, newWindowConfig);
    }
  }

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' });
    setFilter([])
    emptyState();
    getData(isListAsc === true? 0 : 1, 50, []);
  }

  const DeleteData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      if (SelectedData.rowStatus === 'ACT') {
        if (SelectedData.settlementIDR === 0 && SelectedData.settlementUSD === 0) {
          setTitleModalDelete("Bank Advance");
          handleClickDelete();
        }
        else {
          showToast({ type: 'error', message: 'Data Can\'t Be Deleted, Settlement Already Exist!' });
        }
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted' });
      }
    }
  }

  const DeleteBA = () => {
    ApiService.FinAccDelete(param, dataSelected.id).then((res) => {
      if (res.data.code === 200) {
        getData(numPage, rowsCount, filter);
        showToast({ type: 'success', message: 'Delete Data Success' });
        setIsModalDelete(false);
      } else {
        showToast({ type: 'error', message: 'Delete Data Failed' });
        setIsModalDelete(false);
      }
    });
  };

  const PrintData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      if (SelectedData.rowStatus === 'ACT') {
        if (SelectedData.approved === true) {
          setTitleConfirmation('Print')
          setTitleModalConfirmation('Bank Advance');
          handleClickConfirmation();
        }
        else {
          showToast({ type: 'error', message: 'Data Need To Be Approved' });
        }
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted' });
      }
    }
  }

  const Print = () => {
    setIsLoadingBackDrop(true);
    if (dataSelected.rePrintApproved === false) {
      showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
      setIsModalConfirmation(false);
    }
    else {
      ApiService.FinAccUpdateStatusPrint(param, dataSelected.id).then((res) => {
        let message = '';
        message = res.data.message;
        if (res.data.code === 200) {
          getData(numPage, rowsCount, filter);
          let reportLink = 'Transactions/Finance/BankAdvanceReport/BankAdvanceReport/PrintBankAdvance?Id=' + SelectedData.id;
          PrintPDF(setIsLoadingBackDrop, 'Bank Advance', reportLink);
        }
        else {
          showToast({ type: 'error', message: message });
        }
      });
    }
  };

  const ApprovalCRData = () => {
    if (!SelectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      if (SelectedData.rowStatus === 'ACT') {
        setTitleConfirmation("Approval Credit");
        setTitleModalConfirmation("Cash Advance");
        setIsRemarks(true);
        handleClickConfirmation();
      }
      else {
        showToast({ type: 'error', message: 'Data Already Deleted' });
      }
    }
  }

  const ApproveCR = (approvedCreditRemarks) => {
    setIsLoadingBackDrop(true);
    ApiService.FinAccApproveCredit('bankAdvance', dataSelected.id, approvedCreditRemarks)
      .then((res) => {
        if (res.data.code === 200) {
          getData(numPage, rowsCount, filter);
          showToast({ type: 'success', message: 'Approval Credit Success!' });
          setIsLoadingBackDrop(false);
          handleClickConfirmation();
        }
        else {
          setIsLoadingBackDrop(false);
          showToast({ type: 'error', message: 'Can\'t Approve Credit!, ' + res.data.error });
        }
      })
      .catch(error => {
        setIsLoadingBackDrop(false);
        console.log(error);
        showToast({ type: 'error', message: 'Can\'t Approve Credit!, ' + error });
      });
  }

  const handleApprove = () => {
    if (isApprove === false) {
      document.title = 'Approve Bank Advance - ' + JSON.parse(localStorage.getItem('branchName'));
      localStorage.setItem('isApprove', JSON.stringify(true));
      setIsApprove(true);
    }
    else {
      document.title = 'Bank Advance - ' + JSON.parse(localStorage.getItem('branchName'));
      localStorage.setItem('isApprove', JSON.stringify(false));
      setIsApprove(false);
    }

    handleReset();
  }

  const ApprovalData = () => {
    if (dataCheck.length <= 0) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    }
    else {
      setTitleConfirmation("Approve");
      setTitleModalConfirmation("Bank Advance");
      handleClickConfirmation();
    }
  }

  const Approve = () => {
    let check = 0;
    setIsLoadingBackDrop(true);
    dataCheck.forEach(element => {
      ApiService.FinAccApprove(param, element.id)
        .then((res) => {
          if (res.data.code === 200) {
            if (dataCheck[dataCheck.length - 1].id === element.id && check === 0) {
              setIsLoadingBackDrop(false);
              setDataCheck([]);
              showToast({ type: 'success', message: 'Approve Bank Advance Success!' });
              getData(numPage, rowsCount, filter);
            }
          }
          else {
            check = 1;
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Can\'t Approve Bank Advance, ' + res.data.message });
          }
        })
        .catch(error => {
          setIsLoadingBackDrop(false);
          console.log(error);
          showToast({ type: 'error', message: 'Can\'t Approve Bank Advance, ' + error });
        });
    });
  }

  const handleReset = () => {
    setFilter([])
    setFilterBy([])
    setDataCheck([]);
    emptyState();
    getData(isListAsc === true? 0 : 1, 50, [])
    setStateX({})
  }

  function CellDoubleClick(row) {
    EditData()
  }

  function HandleStatus(data) {
    setDropDownTitle(data);
    getData(numPage, rowsCount, filter, sortArr, data);
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(isListAsc === true? 0 : 1);
    setRowsCount(value);
    getData(isListAsc === true? 0 : 1, value, filter, sortArr);
  }

  const ButtonComponents = () => {
    return (
      <BankAdvButton
        Add={AddData}
        Edit={EditData}
        ReloadData={ReloadData}
        DeleteData={DeleteData}
        PrintData={PrintData}
        Approve={ApprovalData}
        ApprovalCRData={ApprovalCRData}
        DataRegUserAccess={DataRegUserAccess}
        DropDownTitle={DropDownTitle}
        HandleStatus={HandleStatus}
        DropDownList={dropDownList}
        isApprove={isApprove}
        handleApprove={handleApprove}
      />
    )
  }

  return (
    <Box className='w-100' sx={{ width: '100%', p: '16px 32px 24px', ...freezeContainer }}>
      <CustomToastContainer />
      <Grid container spacing={0} direction="column">
        <HeaderInfo btnPageRef={btnPageRef}
          title={!isApprove ? 'Bank Advance' : 'Approve Bank Advance'}
          isJobType={false}
          BreadcrumbsItems={BreadcrumbsItems}
          ButtonComponents={ButtonComponents}
        />

        <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <div ref={btnTableRef}>
            {
              ButtonComponent.RefreshButton(
                sortArr,
                rowsCount,
                columnData,
                setGoTo,
                handleReset,
                setFilterBy,
                filterBy,
                setFilter,
                setNumPage,
                setRowsCount,
                getData,
                stateX,
                setStateX
              )
            }
          </div>

          <div
            className="rounded-10 table-responsive"
            style={{
              maxHeight: `calc(100vh - ${getContainerHeight()})`,
              marginTop: '16px',
              padding: '2px 16px',
              borderRadius: '12px',
              background: '#FFF'
            }}
          >
            {
              isLoading ?
                <LoadingSpinner />
                :
                <>
                  <div className='mt-1'>
                    {
                      isApprove === false ?
                        <TableX
                          uniqueKey={'id'}
                          goTo={goTo}
                          rowsCount={rowsCount}
                          setNumPage={setNumPage}
                          setRowsCount={setRowsCount}
                          getData={getData}
                          columnData={columnData}
                          dataMap={dataMap}
                          dataTable={dataTable}
                          SelectedData={SelectedData}
                          setSelectedData={setSelectedData}
                          setFilter={setFilter}
                          setFilterBy={setFilterBy}
                          CellDoubleClick={CellDoubleClick}
                          stateX={stateX}
                          setStateX={setStateX}
                          sortArr={sortArr}
                          setSortArr={setSortArr}
                          setGoTo={setGoTo}
                        />
                        :
                        <TableCheck
                          uniqueKey={'id'}
                          rowsCount={rowsCount}
                          setNumPage={setNumPage}
                          setRowsCount={setRowsCount}
                          getData={getData}
                          columnData={columnData}
                          dataMap={dataMap}
                          SelectedData={SelectedData}
                          setSelectedData={setSelectedData}
                          setFilter={setFilter}
                          setFilterBy={setFilterBy}
                          dataCheck={dataCheck}
                          setDataCheck={setDataCheck}
                          stateX={stateX}
                          setStateX={setStateX}
                          sortArr={sortArr}
                          setSortArr={setSortArr}
                          setGoTo={setGoTo}
                          dataTable={dataTable}
                        />
                    }
                  </div>
                </>
            }
          </div>

          <div style={{ marginTop: '16px' }}>
            <Paginate
              page={numPage}
              limit={rowsCount}
              totalData={totalRows}
              onChange={onChangePaging}
              onChangeLimit={onChangeLimit}
            />
          </div>
        </Grid>
      </Grid>

      {
        isModalConfirmation && (
          <ModalConfirmation
            isModal={isModalConfirmation}
            titleModal={titleModalConfirmation}
            title={titleConfirmation}
            handleClick={handleClickConfirmation}
            ConfirmationData={ConfirmationBA}

            isRemarks={isRemarks}
            Remarks={approvedCreditRemarks}
            setRemarks={setApprovedCreditRemarks}
          />
        )
      }

      {
        isModalDelete && (
          <ModalDelete
            isModal={isModalDelete}
            titleModal={titleModalDelete}
            handleClick={handleClickDelete}
            DeleteData={DeleteBA}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </Box>
  )
})

export default ListBankAdv