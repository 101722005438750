import { Table } from 'react-bootstrap';
import { IconButton, Popover, Stack, Typography } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react';
import { defaultDate } from '../../utils/date';
import { isInfossNoList } from '../../services/ApiService';
import { Icon } from '../ui';
import noDataAvailable from '../../Assets/Background/noDataAvailable.png';

const TableNoFilter = (props) => {
    function handleKeyDown(e) {
        let currIndex = 0
        if (e.keyCode === 38 && props.SelectedData.index > 0) {
            //UP ARROW
            currIndex = props.SelectedData.index
            currIndex -= 1
        } else if (e.keyCode === 40 && props.SelectedData.index < props.dataMap.length - 1) {
            //DOWN ARROW
            currIndex = props.SelectedData.index
            currIndex += 1
        } else if (e.keyCode === 13 && props.SelectedData.index > 0) {
            //PRESS ENTER
            //THEN DO FILTER
            props.setNumPage(1)
            props.setRowsCount(50)
            props.getData(1, props.rowsCount)
        }

        const copyArr = [...props.dataMap]
        const changeSelectedData = (data) => {
            return data.index === currIndex
        }
        const result = copyArr.filter(changeSelectedData)
        props.setSelectedData(...result);
        if (e.keyCode === 13 && props.SelectedData.index !== undefined) {
            handleDoubleClick(...result)
        }
    }

    const handleDoubleClick = (el) => {
        if (props.CellDoubleClick === undefined) {
            props.GoTo(el)
        }
        else {
            props.CellDoubleClick();
        }
    }

    const formatValue = (value, dataType) => {
        if (dataType === 'datetime' || dataType === 'date') {
            if (value !== "0001-01-01T00:00:00" && new Date(value).getFullYear() !== new Date(defaultDate).getFullYear()) {
                const tempDate = new Date(value);
                return tempDate.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });
            } else {
                return '-';
            }
        } else if (dataType === 'currency') {
            return new Intl.NumberFormat(JSON.parse(localStorage.getItem("currency")), { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(`${value}`);
        } else if (value === true) {
            return 'Yes';
        } else if (value === false) {
            return 'No';
        } else {
            return value;
        }
    };

    const TableBodyRow = React.memo(({
        el,
        index,
        handleClick,
        handleDoubleClick,
        SelectedData,
        uniqueKey,
        columnData,
        handleFailedGL,
        dataMap,
        uniqueCol,
        customAction,
        tableAccess
    }) => {
        const [isHovered, setIsHovered] = useState(false);
        const [anchorEl, setAnchorEl] = useState(null);
        const divRef = useRef(null);

        const tempStyle = useMemo(() => {
            if (SelectedData && el && SelectedData[uniqueKey] === el[uniqueKey]) {
                return "bg-infoss text-white";
            } else {
                return "text-dark";
            }
        }, [SelectedData, el, uniqueKey]);

        const tempTextStyle = useMemo(() => {
            if (SelectedData && el && SelectedData[uniqueKey] === el[uniqueKey]) {
                return "selected-text";
            } else {
                return "normal-text";
            }
        }, [SelectedData, el, uniqueKey])

        return (
            <tr
                key={index}
                className={tempStyle}
                onClick={() => handleClick(dataMap[index])}
                onDoubleClick={() => handleDoubleClick(dataMap[index])}
                style={{ fontSize: '14px', background: '#FFF', padding: '24px 8px !important', alignItems: 'center', height: '27.09px', borderBottom: '1px solid #FAFAFA', cursor: 'pointer', }}
            >
                {
                    Object.keys(el).map((headersEl, index) => {
                        const temp = formatValue(el[headersEl], columnData.find(el => el.column === headersEl)?.dataType);
                        const dataType = columnData.find(el => el.column === headersEl)?.dataType;

                        if (headersEl === uniqueKey && columnData.find(el => el.column === headersEl)?.hidden) return null;

                        if (dataType?.includes('custom') === false) {
                            if (headersEl === 'rowStatus') {
                                return (
                                    <td
                                        key={index}
                                        align={columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                padding: '4px',
                                                display: 'flex',
                                                width: 'fit-content',
                                                alignItems: 'center',
                                                borderRadius: '32px',
                                                justifyContent: 'center',
                                                background: temp?.trim() === 'ACT' ? 'var(--Green-Button, #168444)' : 'var(--Red-Alert, #D20C0C)',
                                            }}
                                        >
                                            <Icon
                                                iconName={temp?.trim() === 'ACT' ? 'active' : 'inactive'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#EAEAEA',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </div>
                                    </td>
                                )
                            }
                            else if (headersEl === 'documentStatus' && temp !== '' && temp !== undefined) {
                                return (
                                    <td
                                        key={index}
                                        align={columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '75px',
                                                alignItems: 'center',
                                                borderRadius: '32px',
                                                padding: '4px 12px 4px',
                                                justifyContent: 'center',
                                                border: temp === 'Submitted' ? '1.5px solid var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '1.5px solid #585858' : '1.5px solid #007330',
                                                background: temp === 'Submitted' ? 'rgba(254, 165, 30, 0.15)' : temp === 'Draft' ? 'rgba(88, 88, 88, 0.15)' : 'rgba(0, 115, 48, 0.15)',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '10px',
                                                    fontWeight: '600',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Nunito',
                                                    lineHeight: '12px !important',
                                                    color: temp === 'Submitted' ? 'var(--Orange-Outline-1, #FEA51E)' : temp === 'Draft' ? '#585858' : '#007330',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>
                                    </td>
                                )
                            }
                            else if (headersEl === 'glStatusName' || headersEl === 'glStatus') {
                                return (
                                    <td
                                        key={index}
                                        align={'center'}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <div
                                            style={{
                                                gap: '10px',
                                                height: '18px',
                                                padding: '10px',
                                                display: 'flex',
                                                borderRadius: '4px',
                                                alignItems: 'center',
                                                width: 'fit-content',
                                                justifyContent: 'center',
                                                background: temp === 'Failed' ? 'var(--Mjr-blue-4, #BE2957)' : 'transparent',
                                                boxShadow: isHovered && temp === 'Failed' ? '0px 4px 4px 0px rgba(0, 0, 0, 0.20)' : null,
                                            }}
                                            onMouseEnter={() => setIsHovered(true)}
                                            onMouseLeave={() => setIsHovered(false)}
                                            disabled={temp === 'Failed' ? false : true}
                                            onClick={() => temp === 'Failed' ? handleFailedGL(el) : null}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: '11px',
                                                    userSelect: 'none',
                                                    fontStyle: 'normal',
                                                    fontFamily: 'Nunito',
                                                    lineHeight: 'normal',
                                                    fontWeight: temp === 'Failed' ? '700' : '400',
                                                    cursor: temp === 'Failed' ? 'pointer' : 'default',
                                                    textDecorationLine: temp === 'Failed' ? 'underline' : 'none',
                                                    color: temp === 'Success' ? '#168444' : temp === 'Failed' ? '#FFFFFF' : temp === 'On Progress' ? '#F68C00' : '#F68C00',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>
                                    </td>
                                )
                            }
                            else {
                                return (
                                    <td
                                        key={index}
                                        align={columnData.find(el => el.column === headersEl)?.textAlign}
                                        style={{
                                            padding: '0px 10px',
                                            whiteSpace: "nowrap",
                                            verticalAlign: 'middle',
                                        }}
                                    >
                                        <Typography
                                            className={tempTextStyle}
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontFamily: 'Switzer',
                                                fontSize: '10px',
                                                fontStyle: 'normal',
                                                lineHeight: '140%',
                                                letterSpacing: '0.1px',
                                                cursor: 'text'
                                            }}
                                        >
                                            {temp}
                                        </Typography>
                                    </td>
                                )
                            }
                        }
                        else {
                            const uniqueMark = uniqueCol?.includes('-') ? uniqueCol?.split('-')[0] : uniqueCol
                            if (!dataType?.includes(uniqueMark)) return null;

                            const customData = {
                                statusName: {
                                    'Ready to Request': {
                                        textColor: '#FFF',
                                        backgroundColor: '#6692DE',
                                        isDropDown: false,
                                    },
                                    'Requested': {
                                        textColor: '#F68C00',
                                        backgroundColor: '#FEF3E5',
                                        isDropDown: !uniqueCol.includes('Request') && !uniqueCol.includes('Import') && tableAccess?.[uniqueCol]?.Requested.access ? true : false,
                                        dropDownValue: tableAccess?.[uniqueCol]?.Requested.dropDown
                                    },
                                    'Generated': {
                                        textColor: '#168444',
                                        backgroundColor: '#EBFDFF',
                                        isDropDown: false,
                                    },
                                },
                            };

                            const content = customData[headersEl]?.[temp];

                            if (content === undefined) return null;

                            const open = Boolean(anchorEl);
                            const id = open ? 'simple-popover' : undefined;

                            const handleClickPopOver = () => {
                                setAnchorEl(divRef.current);
                            };

                            const handleClose = () => {
                                setAnchorEl(null);
                            };

                            return (
                                <td
                                    key={index}
                                    align={columnData.find(el => el.column === headersEl)?.textAlign}
                                    style={{
                                        padding: '0px 10px',
                                        whiteSpace: "nowrap",
                                        verticalAlign: 'middle',
                                    }}
                                >
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <div
                                            ref={divRef}
                                            style={{
                                                display: 'flex',
                                                padding: '2px 6px',
                                                alignItems: 'center',
                                                gap: '8px',
                                                borderRadius: '4px',
                                                background: content.backgroundColor,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    overflow: 'hidden',
                                                    color: content.textColor,
                                                    textOverflow: 'ellipsis',
                                                    fontFamily: 'Switzer',
                                                    fontSize: '8px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '600',
                                                    lineHeight: '150%',
                                                    letterSpacing: '0.32px',
                                                }}
                                            >
                                                {temp}
                                            </Typography>
                                        </div>

                                        <IconButton
                                            sx={{ marginLeft: '4px' }}
                                            onClick={
                                                (event) => {
                                                    event.stopPropagation();
                                                    handleClickPopOver();
                                                }
                                            }
                                            hidden={!content.isDropDown}
                                            size='small'
                                        >
                                            <Icon
                                                iconName='arrow-down'
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#00286D',
                                                    sx: {
                                                        height: '8px',
                                                        width: '8px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <Popover
                                            id={id}
                                            open={open}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            sx={{
                                                flexDirection: 'column',
                                                borderRadius: '4px',
                                                marginTop: '4px',
                                                boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                            }}
                                        >
                                            {
                                                content.dropDownValue?.map((el, index) => (
                                                    <Typography
                                                        key={index}
                                                        sx={{
                                                            color: el.color,
                                                            fontFamily: 'Switzer',
                                                            fontSize: '8px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '150%',
                                                            letterSpacing: '0.32px',
                                                            padding: '8px 16px',
                                                            cursor: 'pointer',
                                                            borderBottom: index + 1 !== content.length ? '1px solid #E9E9EA' : 'none',
                                                            transition: 'color 0.2s ease-in-out',
                                                            '&:hover': {
                                                                color: el.hoverColor,
                                                            },
                                                        }}
                                                        hidden={el.hidden}
                                                        onClick={() => customAction(el.value, uniqueCol)}
                                                    >
                                                        {el.label}
                                                    </Typography>
                                                ))
                                            }
                                        </Popover>
                                    </Stack>
                                </td>
                            );
                        }
                    })
                }
            </tr >
        );
    });

    const memoizedColumnData = useMemo(() => props.columnData, [props.columnData]);

    return (
        <Table hover={!props?.static} className='table table-sm' style={{ '--bs-table-bg': '#FFF' }} onKeyDown={handleKeyDown}>
            <thead style={{ position: 'sticky', top: '-4px', 'color': '#FFF', background: '#FFF', zIndex: 1 }}>
                <tr>
                    {
                        props.columnData.map((el, index) => {
                            return (
                                <th
                                    key={index}
                                    hidden={el.column === 'noInfoss' && !isInfossNoList ? true : el.hidden}
                                    style={{
                                        textAlign: el.textAlign,
                                        "whiteSpace": "nowrap",
                                        fontWeight: 'normal',
                                        maxWidth: 'fit-content',
                                        cursor: 'pointer',
                                        padding: '2px 8px',
                                        background: '#FFF',
                                        fontSize: '12px !important'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            overflow: 'hidden',
                                            color: '#747475',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Switzer',
                                            fontSize: '8px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '150%',
                                            letterSpacing: '0.32px',
                                        }}
                                    >
                                        {el.text.toUpperCase()}
                                    </Typography>
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>

            <tbody className="text-muted">
                {
                    props.dataTable.length > 0 ?
                        props.dataTable.map((el, index) => (
                            <TableBodyRow
                                el={el}
                                key={index}
                                index={index}
                                dataMap={props.dataMap}
                                handleClick={props.setSelectedData}
                                uniqueKey={props.uniqueKey}
                                columnData={memoizedColumnData}
                                SelectedData={props.SelectedData}
                                handleDoubleClick={handleDoubleClick}
                                handleFailedGL={props.handleFailedGL}
                                uniqueCol={props.uniqueCol}
                                tableAccess={props.tableAccess}
                                customAction={props.customAction}
                            />
                        ))
                        :
                        <tr style={{ borderBottom: 'transparent', borderTop: 'transparent', userSelect: 'none', pointerEvents: 'none' }}>
                            <td colSpan={20} className="text-center py-3 text-muted">
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '32px 0px',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '16px',
                                        borderRadius: '12px',
                                        background: '#FAFAFA',
                                    }}
                                >
                                    <img
                                        src={noDataAvailable}
                                        alt="Preview"
                                        style={{ width: "80px", height: "auto", justifyContent: "center", alignItems: "center" }}
                                    />

                                    <Typography
                                        sx={{
                                            overflow: 'hidden',
                                            color: 'var(--Mjr-black-1, #747475)',
                                            textOverflow: 'ellipsis',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '140%',
                                            letterSpacing: '0.1px',
                                        }}
                                    >
                                        No Data Available
                                    </Typography>
                                </div>
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}
export default TableNoFilter