import { Box, Typography, Grid, Button, RadioGroup, FormControl, FormControlLabel, Radio, Stack } from '@mui/material';
import React, { memo } from 'react';
import { CustomDatePicker, Input, PopUp } from '../../../../components/ui';

const RadioChild = ({ value, RadioValue, setRadioValue, label, type, Data1, setData1, Data2, setData2 }) => {
    return (
        <div>
            <FormControl
                sx={{
                    width: '100%',
                    display: 'flex',
                    height: '32px',
                    marginTop: '6px',
                    borderRadius: '12px',
                    alignItems: 'left',
                }}
            >
                <FormControlLabel
                    control={
                        <Radio size='small'
                            onClick={(e) => {
                                setRadioValue(value);
                            }}
                        />
                    }
                    value={value}
                    label={
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                color: 'var(--Mjr-black-1, #171717)',
                                textOverflow: 'ellipsis',
                                fontFamily: 'Switzer',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '140%',
                                letterSpacing: '0.1px',
                            }}
                        >
                            {label}
                        </Typography>
                    }
                />
            </FormControl>

            <div style={{ marginLeft: '24px' }}>
                <Stack direction={'row'} columnGap={'6px'} alignItems={'center'}>
                    {
                        type === 'date' && (
                            <CustomDatePicker
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={RadioValue === value ? Data1 : ''}
                                onChange={(e) => setData1(e)}
                                type='date'
                                disabled={RadioValue === value ? false : true}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        )
                    }

                    {
                        type === 'input' && (
                            <Input
                                fullWidth
                                value={RadioValue === value ? Data1 : ''}
                                onChange={(e) => setData1(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                disabled={RadioValue === value ? false : true}
                            />
                        )
                    }

                    <Typography
                        sx={{
                            overflow: 'hidden',
                            color: 'var(--Mjr-black-2, #747475)',
                            textOverflow: 'ellipsis',
                            fontFamily: 'Switzer',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '800',
                            lineHeight: '150%',
                            letterSpacing: '0.32px',
                        }}
                    >
                        -
                    </Typography>

                    {
                        type === 'date' && (
                            <CustomDatePicker
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={RadioValue === value ? Data2 : ''}
                                disabled={RadioValue === value ? false : true}
                                onChange={(e) => setData2(e)}
                                type='date'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />
                        )
                    }

                    {
                        type === 'input' && (
                            <Input
                                fullWidth
                                value={RadioValue === value ? Data2 : ''}
                                onChange={(e) => setData2(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                disabled={RadioValue === value ? false : true}
                            />
                        )
                    }
                </Stack>
            </div>
        </div>
    )
}

const ModalRequest = memo(({ isModal, handleClick, handleRequest, isRequest = true, handleFilterData, RadioValue, setRadioValue, Data1, setData1, Data2, setData2 }) => {
    const changeRadioValue = (value) => {
        setRadioValue(value);
        setData1('');
        setData2('');
    }
    
    return (
        <>
            <PopUp
                id='modal-id'
                title={isRequest ? 'Request' : 'Filter'}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'xs'}
                fullWidth={true}
                topButton={false}
            >
                <Box display="flex" sx={{ alignItems: 'center', width: '100%', marginTop: '8px' }}>
                    <RadioGroup
                        column
                        value={RadioValue}
                        sx={{ columnGap: '8px', marginTop: '-1px !important' }}
                        onChange={(e) => changeRadioValue(e.target.value)}
                    >
                        <RadioChild
                            value={'1'}
                            RadioValue={RadioValue}
                            setRadioValue={setRadioValue}
                            label={'Print Date Invoice'}
                            type={'date'}
                            Data1={Data1}
                            setData1={setData1}
                            Data2={Data2}
                            setData2={setData2}
                        />

                        <RadioChild
                            value={'2'}
                            RadioValue={RadioValue}
                            setRadioValue={setRadioValue}
                            label={'Invoice Number'}
                            type={'input'}
                            Data1={Data1}
                            setData1={setData1}
                            Data2={Data2}
                            setData2={setData2}
                        />

                        <RadioChild
                            value={'3'}
                            RadioValue={RadioValue}
                            setRadioValue={setRadioValue}
                            label={'General Invoice Number'}
                            type={'input'}
                            Data1={Data1}
                            setData1={setData1}
                            Data2={Data2}
                            setData2={setData2}
                        />
                    </RadioGroup>
                </Box>

                <Box display="flex" alignItems="center" sx={{ marginTop: '24px' }}>
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="text"
                                className='d-flex align-items-center'
                                sx={{
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '16px',
                                    background: Data1 !== '' && Data2 !== '' ? 'var(--Mjr-red-2, #6692DE)' : '#EAEAEA',
                                    '&:hover': {
                                        backgroundColor: `#6692DE !important`,
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                disabled={Data1 !== '' && Data2 !== '' ? false : true}
                                onClick={isRequest ? handleRequest : () => handleFilterData(RadioValue, Data1, Data2)}
                            >
                                <Typography
                                    sx={{
                                        color: Data1 !== '' && Data2 !== '' ? 'var(--Mjr-white-4, #FFF)' : '#A3A3A3',
                                        textAlign: 'center',
                                        fontFamily: 'Switzer',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '150%',
                                        letterSpacing: '0.16px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    {isRequest ? 'SEE LIST' : 'APPLY'}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </PopUp >
        </>
    )
})

export default memo(ModalRequest);