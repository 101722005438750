import { Box, MenuItem, Grid, Card, CardContent } from '@mui/material';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username, formatCurrency, isInfossNo } from '../../../../services/ApiService';

import ButtonCreateEditMemorial from './Buttons/ButtonCreateEditMemorial';
import MemorialHeader from './Components/MemorialHeader';
import MemorialDetail from './Components/MemorialDetail';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal, newWindowConfig } from '../../../../utils/masks.jsx';
import { autoFocusField, PrintExcel, PrintPDF } from '../../../../utils/reusableFunction.jsx';
import { convertMask } from '../../../../components/Masks/DollarInput.jsx';
import { convertMaskIDR } from '../../../../components/Masks/RupiahInput.jsx';

const CreateEditMemorial = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataMemo"));

    const { memorialId } = useParams();

    const detailRef = useRef(null);
    const balanceRef = useRef(null);
    const infossNoRef = useRef(null);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    // const paramGL = 'generalLedger/generalLedger';
    const paramMemorial = 'memorial/memorial';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const paramMemorialType = 'memorialType/memorialType';
    const [memorialTypeList, setMemorialTypeList] = useState([]);

    const paramContactType = 'contactType/contactType';
    const [CustomerToData, setCustomerToData] = useState([]);

    const history = useNavigate();
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    //Memorial Variable
    const [MemorialNo, setMemorialNo] = useState('');
    const [MemorialNo2, setMemorialNo2] = useState('');
    const [Reference, setReference] = useState('');
    const [Type, setType] = useState(1);
    const [PrintDate, setPrintDate] = useState('');
    const [VerifyDate, setVerifyDate] = useState('');
    const [PrincipleBy, setPrincipleBy] = useState(0);
    const [ExRateId, setExRateId] = useState(0);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateDate, setExRateDate] = useState('');
    const [MemorialDate, setMemorialDate] = useState(null);
    const [SettlementTo, setSettlementTo] = useState('P01');
    const [CustomerTo, setCustomerTo] = useState(0);
    const [CustomerToInt, setCustomerToInt] = useState('N');
    const [Customer, setCustomer] = useState('');
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerCode, setCustomerCode] = useState('');
    const [CustomerName, setCustomerName] = useState('');
    const [DeleteDate, setDeleteDate] = useState('');

    const [verifyAcc, setVerifyAcc] = useState(false);
    const [printing, setPrinting] = useState(0);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setYearPeriod] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const [costMemorialId, setCostMemorialId] = useState(0);
    const [costMemorialNo, setCostMemorialNo] = useState('');
    const [costMemorialRef, setCostMemorialRef] = useState('');

    const [MemorialDetailList, setMemorialDetailList] = useState([]);

    const [TotalDebitUSD, setTotalDebitUSD] = useState(0);
    const [TotalDebitIDR, setTotalDebitIDR] = useState(0);
    const [TotalDebitInIDR, setTotalDebitInIDR] = useState(0);
    const [TotalCreditUSD, setTotalCreditUSD] = useState(0);
    const [TotalCreditIDR, setTotalCreditIDR] = useState(0);
    const [TotalCreditInIDR, setTotalCreditInIDR] = useState(0);
    const [TotalDifference, setTotalDifference] = useState(0);
    const [LabelDifference, setLabelDifference] = useState('Balanced');

    const [TotalDebitUSDReal, setTotalDebitUSDReal] = useState(0);
    const [TotalDebitIDRReal, setTotalDebitIDRReal] = useState(0);
    const [TotalDebitInIDRReal, setTotalDebitInIDRReal] = useState(0);
    const [TotalCreditUSDReal, setTotalCreditUSDReal] = useState(0);
    const [TotalCreditIDRReal, setTotalCreditIDRReal] = useState(0);
    const [TotalCreditInIDRReal, setTotalCreditInIDRReal] = useState(0);
    const [TotalDifferenceReal, setTotalDifferenceReal] = useState(0);

    const [isEdit, setIsEdit] = useState(false);
    const [isJobOwner, setIsJobOwner] = useState(true);
    const [isSettlement, setIsSettlement] = useState(false);
    const [isCustomer, setIsCustomer] = useState(false);
    const [isNone, setIsNone] = useState(true);
    const [isCostSettle, setIsCostSettle] = useState(true);

    const [isCost, setIsCost] = useState(false);
    const [customerTypeList, setCustomerTypeList] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDraft, setIsDraft] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Memorial', link: '/Majura/Memorial', style: '' },
        { label: 'Create Memorial', link: '/Majura/Memorial/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Memorial', link: '/Majura/Memorial', style: '' },
        { label: 'Detail Memorial', link: '/Majura/Memorial/' + memorialId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Memorial - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveDataLocal();
        }
    }));

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const saveDataLocal = () => {
        let id = memorialId ? parseFloat(memorialId) : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(VerifyDate, true);
        let dateDelete = updateDateFormat(DeleteDate, true);
        let dateEntry = updateDateFormat(MemorialDate, true);

        var data = {
            "memorial": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "id": id,
                'noInfoss': infossNo,
                "exRateId": ExRateId,
                "memorialNo": MemorialNo,
                "reference": Reference,
                "memorialTypeId": Type,
                "settlement": SettlementTo,
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "verifyAcc": verifyAcc,
                "verifyAccOn": dateVerify,
                "printing": printing,
                "printedOn": datePrint,
                "totalDebitUSD": TotalDebitUSDReal,
                "totalDebitIDR": TotalDebitIDRReal,
                "totalCreditUSD": TotalCreditUSDReal,
                "totalCreditIDR": TotalCreditIDRReal,
                "difference": TotalDifferenceReal,
                "period": period,
                "yearPeriod": yearPeriod,
                "entryDate": dateEntry,
                "customerTo": CustomerToInt,
                "customerId": CustomerId,
                "customerCode": CustomerCode,
                "customerName": CustomerName,
                "deleted": deleted,
                "deletedOn": dateDelete,
                "memorialNo2": MemorialNo2,
                "jobOwnerId": PrincipleBy,
                "isCost": isCost,
                "costMemorialId": costMemorialId,
                "costMemorialNo": costMemorialNo,
                "costMemorialRef": costMemorialRef,

                "idLama": 0,
            },
            "memorialDetails": MemorialDetailList,
        };

        localStorage.setItem(username + "-DataMemo", JSON.stringify(data));
    }

    const getLocalData = () => {
        let memorialData = dataLocal.memorial;
        let memorialDetailData = dataLocal.memorialDetails;

        if (memorialId) {
            setIsEdit(true);
        }
        setInfossNo(memorialData.noInfoss);
        setRateReal(memorialData.rate);
        setExRateId(memorialData.exRateId);
        setReference(memorialData.reference);
        setMemorialNo(memorialData.memorialNo);
        setPrincipleBy(memorialData.jobOwnerId);
        setMemorialNo2(memorialData.memorialNo2);
        setRate(convertToNominal(memorialData.rate));
        setIsCostSettle(memorialData.memorialTypeId === 3 ? false : true);
        handleDocumentStatus(memorialData.rowStatus, memorialData.verifyAcc);

        setType(memorialData.memorialTypeId);
        setCustomerId(memorialData.customerId);
        setCustomerToInt(memorialData.customerTo);
        setCustomerCode(memorialData.customerCode);
        setCustomerName(memorialData.customerName);

        setIsCost(memorialData.isCost);
        setPeriod(memorialData.period);
        setDeleted(memorialData.deleted);
        setPrinting(memorialData.printing);
        setVerifyAcc(memorialData.verifyAcc);
        setYearPeriod(memorialData.yearPeriod);
        setCostMemorialId(memorialData.costMemorialId);
        setCostMemorialNo(memorialData.costMemorialNo);
        setCostMemorialRef(memorialData.costMemorialRef);

        setDateFormat(memorialData.printedOn, setPrintDate);
        setDateFormat(memorialData.deletedOn, setDeleteDate);
        setDateFormat(memorialData.exRateDate, setExRateDate);
        setDateFormat(memorialData.verifyAccOn, setVerifyDate);
        setDateFormat(memorialData.entryDate, setMemorialDate);

        CalculateTotal(memorialDetailData);
        setMemorialDetailList(memorialDetailData);

        if (memorialId) {
            setIsEdit(true);
            setIsCustomer(true);
            setIsSettlement(true);
        }

        localStorage.removeItem(username + '-DataMemo');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    function getDateNow() {
        setDateFormat(new Date(), setMemorialDate);
        GetFormatCurrency();
    };

    const getData = (id) => {
        let arrayData = [];
        ApiService.FinAccPostById(paramMemorial, id)
            .then((response) => {
                if (response.data.code === 200) {
                    let memorialData = response.data.data.memorial;
                    setIsEdit(true);
                    setInfossNo(memorialData.noInfoss);
                    setRateReal(memorialData.rate);
                    setExRateId(memorialData.exRateId);
                    setReference(memorialData.reference);
                    setMemorialNo(memorialData.memorialNo);
                    setPrincipleBy(memorialData.jobOwnerId);
                    setSettlementTo(memorialData.settlement);
                    setMemorialNo2(memorialData.memorialNo2);
                    setRate(convertToNominal(memorialData.rate));
                    setIsCostSettle(memorialData.memorialTypeId === 3 ? false : true);
                    handleDocumentStatus(memorialData.rowStatus, memorialData.verifyAcc);

                    setType(memorialData.memorialTypeId);
                    setCustomerId(memorialData.customerId);
                    setCustomerToInt(memorialData.customerTo);
                    setCustomerCode(memorialData.customerCode);
                    setCustomerName(memorialData.customerName);

                    setIsCost(memorialData.isCost);
                    setPeriod(memorialData.period);
                    setDeleted(memorialData.deleted);
                    setPrinting(memorialData.printing);
                    setVerifyAcc(memorialData.verifyAcc);
                    setYearPeriod(memorialData.yearPeriod);
                    setCostMemorialId(memorialData.costMemorialId);
                    setCostMemorialNo(memorialData.costMemorialNo);
                    setCostMemorialRef(memorialData.costMemorialRef);

                    setDateFormat(memorialData.printedOn, setPrintDate);
                    setDateFormat(memorialData.deletedOn, setDeleteDate);
                    setDateFormat(memorialData.exRateDate, setExRateDate);
                    setDateFormat(memorialData.verifyAccOn, setVerifyDate);
                    setDateFormat(memorialData.entryDate, setMemorialDate);

                    setMemorialDetailList(memorialData.memorialDetails);
                    arrayData = memorialData.memorialDetails;
                }
                else {
                    // Back();
                }
            })
            .then(() => {
                CalculateTotal(arrayData);
                setIsSettlement(true);
                setIsCustomer(true);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FMEM')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        GetJobOwnerList(1, 20);
                        handleDocumentStatus();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    Back()
                })
        }
        else {
            history('/Majura');
        }
    }

    //Function for Principle By
    const GetJobOwnerList = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setPrincipleBy(response.data.data.jobOwner[0].id);
                    setJobOwnerList(response.data.data.jobOwner);
                    setIsJobOwner(false);

                    GetMemorialTypeList(1, 20);
                }
                else {
                    // Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={PrincipleBy}
                onChange={(e) => handleCheckJobOwner(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: props.isEdit ? '16px !important' : ''
                    }
                }}
                disabled={verifyAcc}
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetMemorialTypeList = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramMemorialType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setMemorialTypeList(response.data.data.memorialType);

                    getContactTypeData(1, 100);
                }
                else {
                    // Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getContactTypeData = (pageNumber, pageSize) => {
        let tempArray = [];
        ApiService.PostByPage(paramContactType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    let array = response.data.data.contactType;

                    array.forEach(element => {
                        if (element.id < 13) {
                            if (element.id !== 8 && element.id !== 9) {
                                tempArray = [
                                    ...tempArray,
                                    {
                                        id: element.id,
                                        value: element.code,
                                        code: element.id,
                                        name: element.name
                                    }
                                ];
                            }
                        }
                    });

                    tempArray = [...tempArray, { id: 13, value: 'N', code: 0, name: 'None' }];

                    setCustomerToData(tempArray);
                    setCustomerTypeList(tempArray);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        if (memorialId) {
                            getData(memorialId);
                        }
                        else {
                            getDateNow();
                            GetExRate(updateDateFormat(new Date()));
                        }
                    }
                }
                else {
                    // Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const GetExRate = (date) => {
        ApiService.PostByExRateDate(date)
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);
                }
                else {
                    // Back();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetFormatCurrency = () => {
        let totalUSD = 0;
        let totalIDR = 0;
        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);

        setTotalDebitUSD(formatUSD);
        setTotalDebitIDR(formatIDR);
        setTotalDebitInIDR(formatIDR);
        setTotalCreditUSD(formatUSD);
        setTotalCreditIDR(formatIDR);
        setTotalCreditInIDR(formatIDR);
        setTotalDifference(formatIDR);

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    function Save(isPrint = false) {
        if (isLoadingBackDrop) return;
        setIsLoadingBackDrop(true);
        let id = memorialId ? parseFloat(memorialId) : 0;
        let datePrint = updateDateFormat(PrintDate, true);
        let dateVerify = updateDateFormat(VerifyDate, true);
        let dateDelete = updateDateFormat(DeleteDate, true);
        let dateEntry = updateDateFormat(MemorialDate, true);

        var data = {
            "memorial": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,
                'noInfoss': infossNo,
                "id": id,
                "exRateId": ExRateId,
                "memorialNo": MemorialNo,
                "reference": Reference,
                "memorialTypeId": Type,
                "settlement": SettlementTo,
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "verifyAcc": verifyAcc,
                "verifyAccOn": dateVerify,
                "printing": printing,
                "printedOn": datePrint,
                "totalDebitUSD": TotalDebitUSDReal,
                "totalDebitIDR": TotalDebitIDRReal,
                "totalCreditUSD": TotalCreditUSDReal,
                "totalCreditIDR": TotalCreditIDRReal,
                "difference": TotalDifferenceReal,
                "period": period,
                "yearPeriod": yearPeriod,
                "entryDate": dateEntry,
                "customerTo": CustomerToInt,
                "customerId": CustomerId,
                "customerCode": CustomerCode,
                "customerName": CustomerName,
                "deleted": deleted,
                "deletedOn": dateDelete,
                "memorialNo2": MemorialNo2,
                "jobOwnerId": PrincipleBy,
                "isCost": isCost,
                "costMemorialId": costMemorialId,
                "costMemorialNo": costMemorialNo,
                "costMemorialRef": costMemorialRef,

                "idLama": 0,
            },
            "memorialDetails": MemorialDetailList,
        };

        let errorMessage = undefined;

        if (data.memorialDetails.length === 0) {
            errorMessage = 'The Memorial Detail Need to be Fill!';
            autoFocusField(detailRef);
        }
        else if (data.memorial.difference !== 0) {
            errorMessage = 'The Data Need To Be Balance!';
            autoFocusField(balanceRef);
        }
        else if (data.memorial.noInfoss === '' && isInfossNo) {
            errorMessage = 'Please Fill Infoss Number!';
            autoFocusField(infossNoRef);
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(data, paramMemorial, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (id === 0) {
                            ApiService.CreateFinAcc(paramMemorial, data).then((res) => {
                                if (res.data.code === 200) {
                                    setIsLoadingBackDrop(false);
                                    message = 'Create Data Success'
                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Majura/Memorial/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    message = res.data.message;

                                    showToast({ type: 'error', message: message });
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramMemorial, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isPrint === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Update Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                history('/Majura/Memorial/' + memorialId, { replace: true });
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                    else {
                                        if (titleConfirmation === 'Print') {
                                            if (isDraft === false) {
                                                Print();
                                            }
                                            else {
                                                let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + memorialId + '&isDraft=' + isDraft + '&typeReport=pdf';
                                                PrintPDF(setIsLoadingBackDrop, 'Memorial', reportLink, [], true);
                                            }
                                        }
                                        else {
                                            let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + memorialId + '&isDraft=false&typeReport=xls';
                                            PrintExcel(setIsLoadingBackDrop, 'Memorial', reportLink, [], true);
                                        }
                                    }
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    message = res.data.message;

                                    showToast({ type: 'error', message: message });
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);

                        showToast({ type: 'error', message: 'Failed to Create Help Data!, ' + res.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);

                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!, ' + error.toString() });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    function Back() {
        history('/Majura/Memorial');
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationME() {
        if (titleConfirmation === 'Print') {
            checkDataCondition('Print');
        }
        else if (titleConfirmation === 'Print Excel') {
            checkDataCondition('PrintXLS');
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Un Approve') {
            UnApprove();
        }
        setIsModalConfirmation(false);
    }

    const checkDataCondition = async (isAction) => {
        if (!memorialId && isAction !== 'Save') {
            showToast({ type: 'error', message: 'Create The Data First!' });
            return;
        }

        setIsLoadingBackDrop(true);

        try {
            const response = memorialId ? await ApiService.FinAccPostById(paramMemorial, memorialId) : null;

            if (response && response.data.code !== 200) {
                throw new Error(response.data.message || 'Failed to fetch data');
            }

            const data = response ? response.data.data.memorial : null;

            const checkBalance = () => {
                const totalDiff = formatCurrency === 'USD'
                    ? parseFloat(convertMask(TotalDifference))
                    : parseFloat(convertMaskIDR(TotalDifference));
                return totalDiff === 0;
            };

            const actionHandlers = {
                Save: () => {
                    if (!data || data.verifyAcc === false) {
                        Save(false);
                    } else {
                        getData(memorialId);
                        showToast({ type: 'error', message: 'Data Already Been Verified!' });
                    }
                },
                Print: () => {
                    if (data.verifyAcc === false) {
                        Save(true);
                    } else {
                        getData(memorialId);
                        isDraft ?
                            PrintPDF(setIsLoadingBackDrop, 'Memorial', `Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=${memorialId}&isDraft=${isDraft}&typeReport=pdf'`, [], true) :
                            Print();
                    }
                },
                PrintXLS: () => {
                    if (data.verifyAcc === false) {
                        Save(true);
                    } else {
                        getData(memorialId);
                        let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + memorialId + '&isDraft=false&typeReport=xls';
                        PrintExcel(setIsLoadingBackDrop, 'Memorial', reportLink, [], true);
                    }
                },
                PrintPDF: () => {
                    setIsLoadingBackDrop(false);
                    if (checkBalance()) {
                        setTitleConfirmation('Print');
                        setTitleModalConfirmation('Memorial');
                        handleClickConfirmation();
                    } else {
                        showToast({ type: 'error', message: "Can't Print Memorial Because Data Not Balance!" });
                    }
                },
                PrintExcel: () => {
                    setIsLoadingBackDrop(false);
                    if (checkBalance()) {
                        setTitleConfirmation('Print Excel');
                        setTitleModalConfirmation('Memorial');
                        handleClickConfirmation();
                    } else {
                        showToast({ type: 'error', message: "Can't Print Excel Memorial Because Data Not Balance!" });
                    }
                },
                Approve: () => {
                    setIsLoadingBackDrop(false);
                    getData(memorialId);
                    if (data.printing > 0) {
                        if (data.verifyAcc === false) {
                            setTitleConfirmation('Approve');
                            setTitleModalConfirmation('Memorial');
                            handleClickConfirmation();
                        } else {
                            showToast({ type: 'error', message: 'Data Already Been Approved!' });
                        }
                    } else {
                        showToast({ type: 'error', message: 'Please Print The Data First!' });
                    }
                },
                UnApprove: () => {
                    setIsLoadingBackDrop(false);
                    getData(memorialId);
                    if (data.printing > 0) {
                        if (data.verifyAcc === true) {
                            setTitleConfirmation('Un Approve');
                            setTitleModalConfirmation('Memorial');
                            handleClickConfirmation();
                        } else {
                            showToast({ type: 'error', message: "Data Hasn't Been Approved Yet!" });
                        }
                    } else {
                        showToast({ type: 'error', message: 'Please Print The Data First!' });
                    }
                },
            };

            actionHandlers[isAction]?.();

        } catch (error) {
            console.error('Error during data fetching:', error);
            showToast({ type: 'error', message: `Error When Reading Data: ${error.message}` });
        }
    };

    const Print = () => {
        ApiService.FinAccUpdateStatusPrint(paramMemorial, memorialId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let reportLink = 'Transactions/Finance/MemorialReport/MemorialReport/PrintMemorial?Id=' + memorialId + '&isDraft=' + isDraft + '&typeReport=pdf';
                PrintPDF(setIsLoadingBackDrop, 'Memorial', reportLink, [], true);
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    // const CreateGL = (id) => {
    //     var data = {
    //         "generalLedger": {
    //             "source": "ME",
    //             "refId": id,
    //             "methodPost": "AUTO",
    //             "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
    //         }
    //     }

    //     ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //         let message = '';
    //         message = res.data.message;
    //         if (res.data.code !== 200) {
    //             showToast({ type: 'error', message: message });
    //         }
    //     });
    // }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusApprovedAcc(paramMemorial, memorialId)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    // CreateGL(memorialId);
                    showToast({ type: 'success', message: message });

                    setTimeout(
                        function () {
                            history('/Majura/Memorial/' + memorialId, { replace: true });
                            window.location.reload();
                        },
                        1250);

                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Approve Memorial!' });
            });
    }

    const UnApprove = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusUnApprovedAcc(paramMemorial, memorialId)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    // CreateGL(res.data.data.ContraId);
                    showToast({ type: 'success', message: message });

                    setTimeout(
                        function () {
                            history('/Majura/Memorial/' + memorialId, { replace: true });
                            window.location.reload();
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Un Approve Memorial!' });
            });
    }

    function AddNew() {
        window.open('/Majura/Memorial/Create', newWindowConfig);
    }

    function handleCheckType(val, val2 = '') {
        if (val === 3) {
            setIsCostSettle(false);
        }
        else {
            setIsCostSettle(true);
        }

        if (isEdit !== true) {
            if (val === 2) {
                setIsSettlement(true);
                setIsCustomer(false);

                setSettlementTo('');
                setIsCost(true);
            }
            else if (val === 3) {
                setIsSettlement(true);
                setIsCustomer(false);

                setSettlementTo('');
                setIsCost(false);
            }
            else if (val === 4) {
                setIsSettlement(true);
                setIsCustomer(true);

                setSettlementTo('');
                setCustomerToInt();
                setCustomerTo();

                handleResetCustomer();
                handleCheckNone(0);
                setIsCost(false);
            }
            else {
                setIsSettlement(false);
                setIsCustomer(false);
                setIsCost(false);

                if (val2 === 'P05') {
                    setIsCustomer(true);
                    setCustomerToInt();
                    setCustomerTo();

                    handleResetCustomer();
                    handleCheckNone(0);
                }

                if (val2 === '') {
                    setSettlementTo('P01');
                }
            }

            if (CustomerToInt === undefined || CustomerToInt === null) {
                setCustomerTo(0);
                setCustomerToInt('N');
                handleResetCustomer();
                handleCheckNone(0);
            }
        }
        else {
            setIsSettlement(false);
            setIsSettlement(false);
        }
    }

    function handleCheckJobOwner(val) {
        setPrincipleBy(val);
        if (isEdit === false) {
            if (val !== undefined || val !== 0 || val !== null || val !== '') {
                setIsJobOwner(false);
            }
            else {
                setIsJobOwner(true);
            }
        }
        else {
            setIsJobOwner(true);
        }
    }

    function handleCheckNone(val) {
        if (val === 0) {
            setIsNone(true);
        }
        else {
            setIsNone(false);
        }
    }

    function handleResetCustomer() {
        setCustomerId(0);
        setCustomerCode('');
        setCustomerName('');
        setMemorialDetailList([]);
        CalculateTotal([]);
    }

    function checkInput() {
        let check = true;
        if (PrincipleBy !== 0) {
            if (isNone !== true) {
                if (CustomerId === 0) {
                    check = false;
                    showToast({ type: 'error', message: 'Please Select Customer First!' });
                }
            }
        }
        else {
            check = false;
            showToast({ type: 'error', message: 'Please Select Principle By First!' });
        }

        return check;
    }

    function CalculateTotal(result) {
        let debitUSD = 0;
        let debitIDR = 0;
        let debitInIDR = 0;

        let creditUSD = 0;
        let creditIDR = 0;
        let creditInIDR = 0;

        let totalDifference = 0;

        for (let i = 0; i < result.length; i++) {
            if (result[i].dcNote === 'D') {
                debitUSD += parseFloat(result[i].amountUSD);
                debitIDR += parseFloat(result[i].amountIDR);

                debitInIDR += (parseFloat(result[i].amountUSD) * parseFloat(result[i].refRate)) + parseFloat(result[i].amountIDR);
            }
            else if (result[i].dcNote === 'C') {
                creditUSD += parseFloat(result[i].amountUSD);
                creditIDR += parseFloat(result[i].amountIDR);

                creditInIDR += (parseFloat(result[i].amountUSD) * parseFloat(result[i].refRate)) + parseFloat(result[i].amountIDR);
            }
        }

        debitUSD = parseFloat(debitUSD.toFixed(2));
        debitIDR = parseFloat(debitIDR.toFixed(2));
        debitInIDR = parseFloat(debitInIDR.toFixed(2));

        creditUSD = parseFloat(creditUSD.toFixed(2));
        creditIDR = parseFloat(creditIDR.toFixed(2));
        creditInIDR = parseFloat(creditInIDR.toFixed(2));

        totalDifference = parseFloat((debitInIDR - creditInIDR).toFixed(2));

        setTotalDebitUSDReal(debitUSD);
        setTotalDebitIDRReal(debitIDR);
        setTotalDebitInIDRReal(debitInIDR);
        setTotalDebitUSD(convertToNominal(debitUSD));
        setTotalDebitIDR(convertToNominal(debitIDR));
        setTotalDebitInIDR(convertToNominal(debitInIDR));

        setTotalCreditUSDReal(creditUSD);
        setTotalCreditIDRReal(creditIDR);
        setTotalCreditInIDRReal(creditInIDR);
        setTotalCreditUSD(convertToNominal(creditUSD));
        setTotalCreditIDR(convertToNominal(creditIDR));
        setTotalCreditInIDR(convertToNominal(creditInIDR));

        setTotalDifferenceReal(totalDifference);
        setTotalDifference(convertToNominal(totalDifference));
        if (parseFloat(totalDifference) === 0) {
            setLabelDifference('Balanced');
        }
        else {
            setLabelDifference('Difference (Credit)');
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditMemorial
                Save={() => checkDataCondition('Save')}
                Back={Back}
                Print={() => checkDataCondition('PrintPDF')}
                PrintExcel={() => checkDataCondition('PrintExcel')}
                Approve={() => checkDataCondition('Approve')}
                UnApprove={() => checkDataCondition('UnApprove')}
                AddNew={AddNew}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                verifyAcc={verifyAcc}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#FAFAFA', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            memorialId ?
                                'Memorial (' + Reference + ')'
                                :
                                'Memorial ( New Document )'
                        }
                        BreadcrumbsItems={memorialId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                        <CardContent>
                            <MemorialHeader
                                infossNoRef={infossNoRef}
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                memorialId={memorialId}
                                CalculateTotal={CalculateTotal}
                                isEdit={isEdit}
                                isJobOwner={isJobOwner}
                                isSettlement={isSettlement}
                                isCustomer={isCustomer}
                                isNone={isNone}
                                selectPrincipleBy={selectPrincipleBy}
                                memorialTypeList={memorialTypeList}
                                CustomerToData={CustomerToData}
                                handleCheckType={handleCheckType}
                                handleCheckNone={handleCheckNone}
                                handleResetCustomer={handleResetCustomer}
                                MemorialNo={MemorialNo}
                                Reference={Reference}
                                Type={Type}
                                setType={setType}
                                GetExRate={GetExRate}
                                MemorialDate={MemorialDate}
                                setMemorialDate={setMemorialDate}
                                Rate={Rate}
                                ExRateDate={ExRateDate}
                                PrintDate={PrintDate}
                                VerifyDate={VerifyDate}
                                PrincipleBy={PrincipleBy}
                                setPrincipleBy={setPrincipleBy}
                                SettlementTo={SettlementTo}
                                setSettlementTo={setSettlementTo}
                                CustomerTo={CustomerTo}
                                setCustomerTo={setCustomerTo}
                                CustomerToInt={CustomerToInt}
                                setCustomerToInt={setCustomerToInt}
                                Customer={Customer}
                                setCustomer={setCustomer}
                                CustomerId={CustomerId}
                                setCustomerId={setCustomerId}
                                CustomerCode={CustomerCode}
                                setCustomerCode={setCustomerCode}
                                CustomerName={CustomerName}
                                setCustomerName={setCustomerName}
                                verifyAcc={verifyAcc}
                                customerTypeList={customerTypeList}
                                setCustomerTypeList={setCustomerTypeList}
                                setMemorialDetailList={setMemorialDetailList}
                                setCostMemorialRef={setCostMemorialRef}
                                setCostMemorialNo={setCostMemorialNo}
                                setCostMemorialId={setCostMemorialId}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                        <CardContent>
                            <MemorialDetail
                                detailRef={detailRef}
                                balanceRef={balanceRef}
                                isEdit={isEdit}
                                memorialId={memorialId}
                                isCostSettle={isCostSettle}
                                checkInput={checkInput}
                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                CalculateTotal={CalculateTotal}
                                verifyAcc={verifyAcc}
                                MemorialDetailList={MemorialDetailList}
                                setMemorialDetailList={setMemorialDetailList}
                                setCostMemorialRef={setCostMemorialRef}
                                setCostMemorialNo={setCostMemorialNo}
                                setCostMemorialId={setCostMemorialId}
                                costMemorialRef={costMemorialRef}
                                costMemorialNo={costMemorialNo}
                                SettlementTo={SettlementTo}
                                Type={Type}
                                Rate={RateReal}
                                ExRateId={ExRateId}
                                PrincipleBy={PrincipleBy}
                                TotalDebitUSD={TotalDebitUSD}
                                TotalDebitIDR={TotalDebitIDR}
                                TotalDebitInIDR={TotalDebitInIDR}
                                TotalCreditUSD={TotalCreditUSD}
                                TotalCreditIDR={TotalCreditIDR}
                                TotalCreditInIDR={TotalCreditInIDR}
                                TotalDifference={TotalDifference}
                                LabelDifference={LabelDifference}
                                TotalDebitInIDRReal={TotalDebitInIDRReal}
                                TotalCreditInIDRReal={TotalCreditInIDRReal}
                                CustomerTo={CustomerTo}
                                CustomerId={CustomerId}
                                CustomerToInt={CustomerToInt}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationME}
                            isDraft={isDraft}
                            setIsDraft={setIsDraft}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditMemorial;