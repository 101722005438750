import { Avatar, Box, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import majuraIconBlue from '../../../Assets/Icon/majuraIconBlue.svg';
import { Icon } from '../../../components/ui';

function PrivacyPolicy() {
    const majuraMail = 'info@majura.ai';

    useEffect(() => {
        document.title = 'Majura - Privacy Policy'
    }, []);

    return (
        <div style={{ background: '#E4E8EC', minHeight: '100%', height: 'auto', width: '100%', paddingTop: '64px', paddingBottom: '64px' }}>
            <Box
                className="container"
                sx={{
                    display: 'flex',
                    width: { xs: '100%', sm: '100%', md: '960px' }, // Adjust width based on screen size
                    padding: { xs: '16px', sm: '24px', md: '40px' }, // Smaller padding for mobile
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: { xs: '16px', sm: '20px', md: '24px' }, // Reduce gap for mobile
                    flexShrink: 0,
                    alignSelf: 'stretch',
                    borderRadius: '12px',
                    background: 'rgba(255, 255, 255, 0.80)',
                }}
            >
                <section id="content">
                    <section className="section page-heading animate-onscroll">
                        <Grid container direction="row" alignItems={'center'} sx={{ marginTop: '-6px' }}>
                            <Grid item xs={12} sm={6}>
                                <Stack direction="row" spacing="16px" sx={{ marginTop: '6px !important', alignItems: 'center' }}>
                                    <IconButton
                                        sx={{
                                            background: '#0087C2',
                                            height: '20px',
                                            width: '20px',
                                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                            '&:hover': {
                                                background: '#0087C2',
                                                boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
                                            },
                                        }}
                                        href={'/'}
                                    >
                                        <Icon
                                            iconName="arrow-left"
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#F2F2F2',
                                                sx: { height: '12px', width: '12px' },
                                            }}
                                        />
                                    </IconButton>

                                    <Typography
                                        sx={{
                                            color: '#000',
                                            fontFamily: 'Nunito',
                                            fontSize: '24px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        Our Privacy Policy
                                    </Typography>
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xs={6}
                                className='text-end'
                                sx={{
                                    display: { xs: 'none', sm: 'flex' },
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Avatar
                                    src={majuraIconBlue}
                                    sx={{
                                        marginTop: '6px',
                                        width: '120px',
                                        height: 'fit-content',
                                    }}
                                    variant='square'
                                    alt='.'
                                />
                            </Grid>
                        </Grid>
                    </section>

                    <hr style={{ margin: '24px 0px' }} />

                    <section className="section full-width-bg gray-bg">
                        <div className="row" style={{ rowGap: '16px' }}>
                            <div className="col-lg-8 col-md-8 col-sm-12">
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        // lineHeight: '19.6px',
                                    }}
                                    className="animate-onscroll no-margin-top"
                                >
                                    Our Privacy
                                </Typography>

                                <Typography
                                    style={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        textAlign: 'justify',
                                        // lineHeight: '19.6px',
                                    }}
                                    className="animate-onscroll"
                                >
                                    Sudah menjadi tekad kami untuk selalu memberikan layanan terbaik, melalui pengembangan keahlian dan profesionalisme SDM, serta melakukan inovasi teknologi serta solusi untuk mendukung tercapainya visi untuk menjadi yang terdepan di bidang jasa teknologi informasi.
                                    Untuk itu dalam pengembangan usahanya, kami menitikberatkan pada beberapa kompetensi (core business) yang saling mendukung di bidang layanan teknologi informasi.
                                </Typography>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 sidebar">
                                <div
                                    style={{
                                        display: 'flex',
                                        padding: '16px 24px 16px 24px',
                                        flexDirection: 'column',
                                        alignItems: 'flex-start',
                                        borderRadius: '16px',
                                        background: 'var(--Mjr-green-4, #EBFDFF)',
                                    }}
                                    className="sidebar-box lightblue animate-onscroll"
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--Primary-One, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            // lineHeight: '19.6px',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        Consult With Us
                                    </Typography>
                                    <Typography
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            textAlign: 'justify',
                                            fontWeight: '400',
                                            // lineHeight: '19.6px',
                                        }}
                                    >
                                        Let’s discuss the solution for your IT solution.
                                    </Typography>

                                    <Stack direction="row" spacing={'6px'} alignItems={'center'}>
                                        <Icon
                                            iconName="phone"
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#083A50',
                                                sx: { height: '20px', width: '20px', marginTop: '7px !important' },
                                            }}
                                        />

                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                // lineHeight: '19.6px',
                                            }}
                                        >
                                            +6221-xxx-xxxx
                                        </Typography>
                                    </Stack>

                                    <Stack direction="row" spacing={'6px'} alignItems={'center'}>
                                        <Icon
                                            iconName="mail"
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#083A50',
                                                sx: { height: '20px', width: '20px', marginTop: '6px !important' },
                                            }}
                                        />

                                        <Typography
                                            component={'a'}
                                            href={`mailto:${majuraMail}`}
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '19.6px',
                                                '&:hover': {
                                                    fontStyle: 'underline',
                                                },
                                            }}
                                        >
                                            {majuraMail}
                                        </Typography>
                                    </Stack>
                                </div>
                            </div>
                        </div>

                        <div className="row section-row" style={{ marginTop: '16px' }}>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="tab-content">
                                    <div>
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                textAlign: 'justify',
                                                // lineHeight: '19.6px',
                                            }}
                                        >
                                            Majura Digital Solusi built the Majura Digital Solusi app as a Free app. This SERVICE is provided by Majura Digital Solusi at no cost and is intended for use as is.
                                            This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
                                            If you choose to use our Service, then you agree to the collection and use of information in relation to this policy.
                                        </Typography>
                                    </div>

                                    <hr style={{ margin: '24px 0px' }} />
                                    <div>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontsize: '16px',
                                                fontstyle: 'normal',
                                                fontWeight: '700',
                                                // lineHeight: '19.6px',
                                            }}
                                        >
                                            Information Collection and Use
                                        </Typography>

                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '16px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                textAlign: 'justify',
                                                // lineHeight: '19.6px',
                                            }}
                                        >
                                            For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information.
                                        </Typography>

                                        <div>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    textAlign: 'justify',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                The app does use third-party services that may collect information used to identify you.
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    textAlign: 'justify',
                                                }}
                                            >
                                                Link to the privacy policy of third-party service providers used by the app:&nbsp;
                                                <a
                                                    href="https://www.google.com/policies/privacy/"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                        fontWeight: '700',
                                                        textDecoration: 'underline',
                                                        textDecorationStyle: 'solid',
                                                        textDecorationSkipInk: 'none',
                                                        textDecorationThickness: 'auto',
                                                        textUnderlineOffset: 'auto',
                                                        textUnderlinePosition: 'from-font',
                                                    }}
                                                >
                                                    Google Play Services
                                                </a>
                                            </Typography>

                                        </div>
                                    </div>

                                    <hr style={{ margin: '24px 0px 16px' }} />

                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Log Data
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    textAlign: 'justify',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                We want to inform you that whenever you use our Service.
                                            </Typography>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Security
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    textAlign: 'justify',
                                                    fontWeight: '400',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                We value your trust in providing us your Personal Information.
                                            </Typography>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Cookies
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    textAlign: 'justify',
                                                    fontWeight: '400',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Cookies are files with a small amount of data.
                                            </Typography>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Children’s Privacy
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    textAlign: 'justify',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                These Services do not address anyone under the age of 13.
                                            </Typography>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Service Providers
                                            </Typography>
                                            <ul style={{ marginBottom: '0px' }}>
                                                <li>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            textAlign: 'justify',
                                                            // lineHeight: '19.6px',
                                                        }}
                                                    >
                                                        To facilitate our Service;
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            textAlign: 'justify',
                                                            // lineHeight: '19.6px',
                                                        }}
                                                    >
                                                        To provide the Service on our behalf;
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            textAlign: 'justify',
                                                            // lineHeight: '19.6px',
                                                        }}
                                                    >
                                                        To perform Service-related services; or
                                                    </Typography>
                                                </li>
                                                <li>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Blue-Primary-1, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '16px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            textAlign: 'justify',
                                                            // lineHeight: '19.6px',
                                                        }}
                                                    >
                                                        To assist us in analyzing how our Service is used.
                                                    </Typography>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Changes to This Privacy Policy
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    textAlign: 'justify',
                                                    fontWeight: '400',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                We may update our Privacy Policy from time to time.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    textAlign: 'justify',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                This policy is effective as of 2025-01-01
                                            </Typography>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                                            <Typography
                                                sx={{
                                                    color: 'var(--Primary-One, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                Contact Us
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    textAlign: 'justify',
                                                    // lineHeight: '19.6px',
                                                }}
                                            >
                                                If you have any questions, contact us at&nbsp;
                                                <a
                                                    href={`mailto:${majuraMail}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                        color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                        fontWeight: '600',
                                                        textDecoration: 'underline',
                                                        textDecorationStyle: 'solid',
                                                        textDecorationSkipInk: 'none',
                                                        textDecorationThickness: 'auto',
                                                        textUnderlineOffset: 'auto',
                                                        textUnderlinePosition: 'from-font',
                                                    }}
                                                >
                                                    {majuraMail}
                                                </a>.
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </Box>
        </div>
    );
}

export default PrivacyPolicy;