import { Backdrop, Box, Grid, MenuItem, Card, CardContent, Typography, Stack, InputAdornment, IconButton } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react';

import ApiService from '../../../../services/ApiService';
import SelectDataPeriod from './Selects/SelectDataPeriod';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonGeneralTrialBalanceView from './Buttons/ButtonGeneralTrialBalanceView';
import { handleDataTable, PrintExcel, PrintPDF, GetReportVersionLog } from '../../../../utils/reusableFunction';
import ReportVersionLog from '../../../../components/Modals/ReportVersionLog';
import { useNavigate } from 'react-router-dom';
import { getDefaultDateFormat } from '../../../../utils/date';

const GeneralTrialBalanceView = forwardRef((props, ref) => {
    const menuCode = 'RGENTB';
    const history = useNavigate();
    const [isLoadingPrint, setIsLoadingPrint] = useState(true);
    const [isAllAccount, setIsAllAccount] = useState(true);
    const [accountLevel, setAccountLevel] = useState(5);
    const [isRangePeriod, setIsRangePeriod] = useState(false);
    const [bPeriod, setBPeriod] = useState('');
    const [bYearPeriod, setBYearPeriod] = useState('');
    const [bStartPeriod, setBStartPeriod] = useState('');
    const [bEndPeriod, setBEndPeriod] = useState('');
    const [lPeriod, setLPeriod] = useState('');
    const [lYearPeriod, setLYearPeriod] = useState('');
    const [lStartPeriod, setLStartPeriod] = useState('');
    const [lEndPeriod, setLEndPeriod] = useState('');
    const [periodSelected, setPeriodSelected] = useState('');

    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

    const paramP = "regClosingPeriod/regClosingPeriod";
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [isModalP, setIsModalP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [titleModalP, setTitleModalP] = useState('');
    const [columnDataP, setColumnDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [dataMapP, setDataMapP] = useState([]);
    const [dataTableP, setDataTableP] = useState([]);

    const [isModalLog, setIsModalLog] = useState(false);
    const [fullDataLog, setFullDataLog] = useState([]);
    const [dataLog, setDataLog] = useState([]);

    const allAccountList = [
        { id: 1, code: true, name: 'Yes' },
        { id: 2, code: false, name: 'No' },
    ];

    const accountLevelList = [
        { id: 1, code: 0, name: '1 up 5' },
        { id: 2, code: 1, name: '1 Only' },
        { id: 2, code: 5, name: '5 Only' },
    ];

    const periodTypeList = [
        { id: 1, code: false, name: 'Single Period' },
        { id: 2, code: true, name: 'Range Period' },
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Trial Balance Report', link: '/Majura/GeneralTrialBalanceReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'General Trial Balance - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingPrint(true);

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === menuCode)
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                {
                    attr: "permissionId",
                    value: "" + pId
                },
                {
                    attr: "userId",
                    value: "" + JSON.parse(localStorage.getItem("userId"))
                },
                {
                    attr: "roleId",
                    value: "" + JSON.parse(localStorage.getItem("roleId"))
                }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        setIsLoadingPrint(false);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        setIsLoadingPrint(false);
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history('/Majura');
                });
        }
        else {
            history('/Majura');
        }
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationGL() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls")
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (bPeriod === '') {
            check = 1;
        }
        else if (isRangePeriod === true && lPeriod === '') {
            check = 2;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Closing Period!' });
        }
        else if (check === 2) {
            showToast({ type: 'error', message: 'Please Select Last Closing Period!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('General Trial Balance Report');
            handleClickConfirmation();
        }
    };

    const PrintExcelData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('General Trial Balance Report');
            handleClickConfirmation();
        }
    };

    const handleIsRangePeriod = (value) => {
        setIsRangePeriod(value);

        setLPeriod('');
        setLYearPeriod('');
        setLStartPeriod('');
        setLEndPeriod('');
    }

    const handleClickPeriod = (code) => {
        if (isModalP === false) {
            if (code === 'Beginning') {
                setTitleModalP('Closing Period');
            }
            else if (code === 'Ending') {
                setTitleModalP('Last Closing Period');
            }

            setPeriodSelected(code);
            getDataPeriod(1, 50);
            setIsModalP(true);
        }
        else {
            setIsModalP(false);
        }
    };

    const EmptyPeriod = (code) => {
        if (code === 'Beginning') {
            setBPeriod('');
            setBYearPeriod('');
            setBStartPeriod('');
            setBEndPeriod('');
        }
        else if (code === 'Ending') {
            setLPeriod('');
            setLYearPeriod('');
            setLStartPeriod('');
            setLEndPeriod('');
        }
    }

    const getDataPeriod = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.closingPeriod);
                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableP, 'id');
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)
                    setIsLoadingP(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingP(false)
            })
    }

    const PrintReport = (code) => {
        setIsLoadingPrint(true);

        let allAccount = 0;
        let beginPeriod = '';
        let endPeriod = '';
        let accountLevels = '';

        if (isAllAccount === true) {
            allAccount = 1;
        }

        if (isRangePeriod === false) {
            beginPeriod = bStartPeriod;
            endPeriod = bEndPeriod;
        }
        else {
            beginPeriod = bStartPeriod;
            endPeriod = lEndPeriod;
        }

        if (accountLevel === 0) {
            accountLevels = "1, 2, 3, 4, 6";
        }
        else if (accountLevel === 1) {
            accountLevels = '1';
        }
        else {
            accountLevels = '6';
        }

        let params = [
            {
                "attr": "viewAllAccount",
                "value": "" + allAccount
            },
            {
                "attr": "beginningPeriod",
                "value": beginPeriod
            },
            {
                "attr": "endDatePeriod",
                "value": endPeriod
            },
            {
                "attr": "accountLevels",
                "value": accountLevels
            }
        ];

        let reportLink = 'Reports/Finance/Print/FinanceReport/GeneralTrialBalanceReport/GeneralTrialBalanceReport/PrintGeneralTrialBalance?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(setIsLoadingPrint, 'General Trial Balance', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'General Trial Balance', reportLink, params);
        }
    };

    const handleClickLog = useCallback(() => {
        if (isModalLog === false) {
            setIsModalLog(true);
        }
        else {
            setIsModalLog(false);
        }
    }, [isModalLog]);

    const handleDataLog = useCallback((isPrint = true, date = new Date(), isResume = false) => {
        setIsLoadingPrint(true);
        const today = new Date(date);
        const month = today.getMonth();
        const year = today.getFullYear();
        const startDate = getDefaultDateFormat(new Date(year, month, 1));
        const endDate = getDefaultDateFormat(new Date(year, month + 1, 0));

        const params = [
            { "attr": "reportCode", "value": menuCode },
            ...(
                JSON.parse(isPrint) ?
                    [
                        { "attr": "printedOn, printedOn", "mathSign": ">=, <=", "value": `${startDate}, ${endDate}` }
                    ]
                    : [
                        { "attr": "period", "value": '' + (parseInt(month) + parseInt(1)) },
                        { "attr": "yearPeriod", "value": '' + year }
                    ]
            )
        ];

        GetReportVersionLog(setIsLoadingPrint, menuCode, params, handleFullDataLog, !isResume ? handleClickLog : () => { });
    }, [handleClickLog]);

    const handleFullDataLog = (data) => {
        setFullDataLog(data);
        setDataLog(data);
    }

    const ButtonComponents = () => {
        return (
            <ButtonGeneralTrialBalanceView
                PrintData={PrintData}
                PrintExcel={PrintExcelData}
                handleClickLog={() => handleDataLog()}
                dataRegUserAccess={dataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'General Trial Balance Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'32px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={isAllAccount}
                                                    label="View All Account (Include Amount > 0)"
                                                    fullWidth={true}
                                                    formLabelProps={{
                                                        sx: {
                                                            fontSize: '12px !important'
                                                        }
                                                    }}
                                                    placeholder={'Select All Account'}
                                                    onChange={(e) => setIsAllAccount(e.target.value)}
                                                >
                                                    {
                                                        allAccountList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={accountLevel}
                                                    label="Account Level"
                                                    fullWidth={true}
                                                    formLabelProps={{
                                                        sx: {
                                                            fontSize: '12px !important'
                                                        }
                                                    }}
                                                    placeholder={'Select Account Level'}
                                                    onChange={(e) => setAccountLevel(e.target.value)}
                                                >
                                                    {
                                                        accountLevelList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>

                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px', marginTop: '24px' }}>
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Closing Period
                                        </Typography>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                label={'Period'}
                                                value={bPeriod}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                }}
                                                disabled
                                            />

                                            <Input
                                                label="Year Period"
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={bYearPeriod}
                                                type='text'
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                disabled
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyPeriod('Beginning')}
                                                                size='small'
                                                                hidden={bPeriod ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleClickPeriod('Beginning')}
                                                                size='small'
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label="Beginning Period"
                                                    value={bStartPeriod}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label="Ending Period"
                                                    value={bEndPeriod}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            value={isRangePeriod}
                                            label="Period Type"
                                            fullWidth={true}
                                            placeholder={'Select Period Type'}
                                            onChange={(e) => handleIsRangePeriod(e.target.value)}
                                        >
                                            {
                                                periodTypeList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>
                                    </CardContent>
                                </Card>

                                <Card hidden={!isRangePeriod} component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px', marginTop: '24px' }}>
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Last Closing Period
                                        </Typography>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                label={'Period'}
                                                value={lPeriod}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                }}
                                                disabled
                                            />

                                            <Input
                                                label="Year Period"
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={lYearPeriod}
                                                type='text'
                                                disabled
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => EmptyPeriod('Ending')}
                                                                size='small'
                                                                hidden={lPeriod ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => handleClickPeriod('Ending')}
                                                                size='small'
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label="Beginning Period"
                                                    value={lStartPeriod}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    label="Ending Period"
                                                    value={lEndPeriod}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => 10000000 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalP && (
                    <SelectDataPeriod
                        isModal={isModalP}
                        setIsModal={setIsModalP}
                        titleModal={titleModalP}
                        handleClick={handleClickPeriod}
                        isLoading={isLoadingP}
                        data={dataP}
                        getData={getDataPeriod}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        dataTable={dataTableP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}

                        bPeriod={bPeriod}
                        setBPeriod={setBPeriod}
                        bYearPeriod={bYearPeriod}
                        setBYearPeriod={setBYearPeriod}
                        setBStartPeriod={setBStartPeriod}
                        setBEndPeriod={setBEndPeriod}

                        lPeriod={lPeriod}
                        setLPeriod={setLPeriod}
                        lYearPeriod={lYearPeriod}
                        setLYearPeriod={setLYearPeriod}
                        setLStartPeriod={setLStartPeriod}
                        setLEndPeriod={setLEndPeriod}

                        periodSelected={periodSelected}
                        isRangePeriod={isRangePeriod}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationGL}
                    />
                )
            }

            {
                isModalLog && (
                    <ReportVersionLog
                        data={dataLog}
                        setData={setDataLog}
                        isModal={isModalLog}
                        fullData={fullDataLog}
                        handleClick={handleClickLog}
                        handleDataLog={handleDataLog}
                        title={'General Trial Balance'}
                        setIsLoading={setIsLoadingPrint}
                        reportLink={'Reports/Finance/Print/FinanceReport/GeneralTrialBalanceReport/GeneralTrialBalanceReport/PrintGeneralTrialBalance?typeReport=pdf'}
                    />
                )
            }
        </Box>
    )
}
)
export default GeneralTrialBalanceView