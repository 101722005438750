import { FormControl, FormLabel, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { memo } from "react";

const TextInfo = memo(({
    title = '',
    value = '',
    isHidden = false,
}) => {
    return (
        <FormControl variant='standard' sx={{ width: '100%' }} fullWidth hidden={isHidden}>
            <Stack direction={'column'} spacing={'4px'} sx={{ width: '100%' }}>
                {
                    title && (
                        <FormLabel
                            sx={{
                                color: 'var(--Mjr-black-2, #747475)',
                                fontFamily: 'Switzer',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            {title}
                        </FormLabel>
                    )
                }

                {
                    value !== undefined && value !== null && (
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                color: 'var(--Mjr-black-1, #171717)',
                                textOverflow: 'ellipsis',
                                fontFamily: 'Switzer',
                                fontSize: '12px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}
                        >
                            {value}
                        </Typography>
                    )
                }

            </Stack>
        </FormControl>
    )
});

TextInfo.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string
};

export default memo(TextInfo);