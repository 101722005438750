import { forwardRef, memo, useRef, useState } from "react";
import { FullPopUp, Icon, showToast } from "../../../../components/ui";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import LoadingSpinner from "../../../../components/Components/LoadingSpinner";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import TableX from "../../../../components/Tables/TableX";
import ModalConfirmRequest from "./ModalConfirmRequest";
import ApiService, { isListAsc } from "../../../../services/ApiService";

const TitleDetailComp = forwardRef(({ subTitle = '', handleChange, handleButtonClick, fileInputRef }, ref) => {
    return (
        <div ref={ref}>
            <Stack direction={'row'} alignItems={'center'} spacing={'8px'}>
                <Typography
                    sx={{
                        color: 'var(--Mjr-black-1, #171717)',
                        fontFamily: 'Switzer',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                    }}
                >
                    {subTitle}
                </Typography>

                <div>
                    <Button
                        size='small'
                        variant="text"
                        className='d-flex align-items-center'
                        sx={{
                            padding: '8px 16px',
                            gap: '6px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '18px !important',
                            minHeight: '28px !important',
                            borderRadius: '12px',
                            border: '1px solid #96B9FF',
                            '&:hover': {
                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                            }
                        }}
                        onClick={handleButtonClick}
                    >
                        <Icon
                            iconName="convert"
                            svgIconProps={{
                                fontSize: "small",
                                htmlColor: "#00286D",
                                sx: { height: "12px", width: "12px" },
                            }}
                        />

                        <Typography
                            sx={{
                                color: 'var(--Mjr-blue-2, #00286D)',
                                textAlign: 'center',
                                fontFamily: 'Switzer',
                                fontSize: '8px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                            }}
                        >
                            CHANGE
                        </Typography>
                    </Button>

                    <input
                        type="file"
                        accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ref={fileInputRef}
                        onChange={handleChange}
                        style={{ display: "none" }}
                    />
                </div>
            </Stack>
        </div>
    )
})

const ModalConfirmImport = memo(({
    isModal,
    handleClick,
    subTitle,
    setTotalRows,
    getData,
    columnData,
    isLoading,
    dataMap,
    SelectedData,
    setSelectedData,
    dataTable,
    totalRows,
    handleImportData,
    dataImport,
    setIsLoadingBackDrop,
    emptyStateParent,
    getDataParent,
    fetchDataTab
}) => {
    const modalTableRef = useRef(null);
    const param = 'invoice/invoice';
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const fileInputRef = useRef(null);
    const [documentData, setDocumentData] = useState({});

    const [isModalConfirm, setIsModalConfirm] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        let fileDoc = null;
        if (file) {
            setDocumentData({
                url: URL.createObjectURL(file),
                name: file.name,
            });

            fileDoc = {
                url: URL.createObjectURL(file),
                name: file.name,
            };

            handleImportData(fileDoc, 'continue');
        }

        event.target.value = '';
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    const Cancel = () => {
        emptyState();
        handleClick();
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50);
    };

    const CellDoubleClick = () => {

    }

    const handleClickConfirm = () => {
        if (isModalConfirm) {
            setIsModalConfirm(false);
        } else {
            setIsModalConfirm(true);
        }
    }

    const handleUpdate = async () => {
        setIsLoadingBackDrop(true);

        try {
            const response = await fetch(dataImport.url);
            const blob = await response.blob();

            const file = new File([blob], dataImport.name, { type: blob.type });

            const formData = new FormData();
            formData.append("fakturCoretax", file);

            const uploadResponse = await ApiService.OperationUploadFile(param + '/ImportEFaktur', formData)

            if (uploadResponse.data.code === 200) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'success', message: 'Import File Success!' });
                handleClick();

                emptyStateParent();
                getDataParent(isListAsc === true ? 0 : 1, 50, []);
                fetchDataTab();
            }
            else {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: `Import File Failed, ${uploadResponse.data.error}!` });
            }
        }
        catch (error) {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: `Import File Failed, ${error.toString()}!` });
        }
    }

    const getContainerHeight = () => {
        const hTitle = 21
        const hPadding = 64
        const hBtnPage = 56
        const hTabPage = 64
        const actionPage = 110

        const totalHeight = hPadding + hTitle + hBtnPage + hTabPage + actionPage

        return `${totalHeight}px`
    }

    const scrollToBottom = () => {
        if (modalTableRef.current) {
            modalTableRef.current.scrollBy({ top: 10000, behavior: "smooth" });
        }
    };

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    style={{
                        display: 'flex',
                        padding: '8px 16px',
                        flexDirection: 'column',
                        alignSelf: 'stretch',
                        borderRadius: '12px',
                        border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                        background: 'var(--Mjr-white-4, #FFF)',
                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        marginTop: filterBy.length > 0 ? '16px' : '0px',
                    }}
                >
                    <div
                        ref={modalTableRef}
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                        }}
                    >
                        {
                            isLoading ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <TableX
                                            uniqueKey={'invoiceId'}
                                            goTo={goTo}
                                            numPage={numPage}
                                            filter={filter}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                            uniqueCol={'TaxNumberGeneration'}
                                        />
                                    </>
                                )
                        }
                    </div>
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <FullPopUp
                id='modal-id'
                title={'Tax Invoice Number Preview'}
                isOpen={isModal}
                handleClose={Cancel}
                titleDetail={
                    <TitleDetailComp
                        fileInputRef={fileInputRef}
                        handleChange={handleFileChange}
                        handleButtonClick={handleButtonClick}
                        subTitle={documentData?.name !== undefined ? documentData.name : subTitle}
                    />
                }
            >
                <Box sx={{ marginTop: '16px', width: '100%' }}>
                    {mainContainer}

                    <div style={{ marginTop: '32px' }}>
                        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={8}>
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-black-1, #171717)',
                                        fontFamily: 'Switzer',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '140%',
                                        letterSpacing: '0.1px',
                                    }}
                                >
                                    You will update the above invoices by adding tax numbers imported from the uploaded Excel file
                                </Typography>

                                <div style={{ marginTop: '16px' }}>
                                    {ButtonComponent.CustomButton(handleClickConfirm, 'save', 'UPDATE INVOICE', false, true)}
                                </div>
                            </Grid>

                            <Grid item xs={4} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        display: 'flex',
                                        padding: '12px 20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '16px',
                                        border: '1px solid var(--Mjr-Random-Light, #96B9FF)',
                                        maxHeight: '42px',
                                        maxWidth: '58px'
                                    }}
                                    onClick={scrollToBottom}
                                >
                                    <Icon
                                        iconName="arrow-down"
                                        svgIconProps={{
                                            fontSize: "small",
                                            htmlColor: "#00286D",
                                            sx: { height: "18px", width: "18px" },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </FullPopUp>

            {
                isModalConfirm && (
                    <ModalConfirmRequest
                        isModal={isModalConfirm}
                        handleClick={handleClickConfirm}
                        handleExportData={handleUpdate}
                        isImport={true}
                    />
                )
            }
        </>
    );
})

export default memo(ModalConfirmImport);