import React from 'react'
import PropTypes from 'prop-types';
import {
    Pagination, PaginationItem, Stack,
    Typography, styled,
    // useTheme 
} from '@mui/material'

import Selection from '../Selection/Selection'

const StyledPaginationItem = styled(PaginationItem)(({ theme }) => ({
    '&:not(.MuiPaginationItem-ellipsis)': {
        padding: '8px 12px',
        color: '#00286D',
        fontSize: '10px',
        fontFamily: 'Switzer',
        fontWeight: '600',
        border: '1px solid transparent',
        '&:hover': {
            // background: alpha('#56CCF2', .40),
        },
    },
    '&.MuiPaginationItem-rounded': {
        borderRadius: '12px',
    },
    '&.Mui-selected': {
        borderRadius: '12px',
        border: '1px solid var(--Mjr-black-4, #D1D1D2)',
        color: '#083A50',
        cursor: 'default',
        background: 'transparent',
        pointerEvents: 'none',
    },
    '&.Mui-disabled': {
        background: '#EAEAEA',
        color: '#A3A3A3'
    }
}))

const SIZING = {
    fontSize: ['8px', '10px', '12px'],
    itemSize: ['26px', '24px', '40px'],
    limitSize: ['110px', '70px', '130px']
}

const Paginate = ({
    direction = 'row',
    page = 1,
    limit = 50,
    totalData = 100,
    shape = 'rounded',
    size = 'medium',
    onChange,
    onChangeLimit,
    disabled,
    // *** Custom Props
    showGoToPage = true,
    isCustom = false,
}) => {
    const pageCount = Math.ceil(totalData / limit)
    // const theme = useTheme()

    const getSizing = (target) => {
        switch (size) {
            case 'small':
                return SIZING[target][0];
            case 'medium':
                return SIZING[target][1];
            case 'large':
                return SIZING[target][2];
            default:
                return SIZING[target][1];
        }
    }

    return (
        <Stack
            direction={direction}
            gap='10px'
            justifyContent='space-between'
            flexWrap='wrap'
            alignItems={['row', 'row-reverse'].includes(direction) ? 'center' : 'flex-start'}
        >
            <Stack direction={'row'} spacing={'8px'} alignItems={'center'}>
                <Typography
                    sx={{
                        fontSize: getSizing('fontSize'),
                        color: 'var(--Mjr-black-2, #747475)',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Switzer',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        letterSpacing: '0.1px',
                    }}
                >
                    Show
                </Typography>
                <Selection
                    formControlProps={{ sx: { width: getSizing('limitSize') } }}
                    selectBaseProps={{
                        sx: {
                            height: getSizing('itemSize'),
                            fontSize: getSizing('fontSize'),
                            display: 'flex',
                            padding: '8px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '6px',
                            borderRadius: '12px',
                            border: '1px solid var(--Mjr-black-4, #D1D1D2)',
                        }
                    }}
                    selectInnerStyle={{
                        padding: '8px',
                    }}
                    menuItemProps={{
                        sx: {
                            color: '#00286D',
                            fontWeight: '400',
                            fontFamily: 'Switzer',
                            fontSize: getSizing('fontSize'),
                        }
                    }}
                    value={limit}
                    onChange={(e) => onChangeLimit(e.target.value)}
                    options={
                        isCustom === true ?
                            [
                                {
                                    label: '10',
                                    value: 10
                                },
                                {
                                    label: '25',
                                    value: 25
                                },
                                {
                                    label: '50',
                                    value: 50
                                }
                            ]
                            :
                            [
                                {
                                    label: '50',
                                    value: 50
                                },
                                {
                                    label: '100',
                                    value: 100
                                },
                                {
                                    label: '150',
                                    value: 150
                                }
                            ]
                    }
                />
                <Typography
                    sx={{
                        fontSize: getSizing('fontSize'),
                        color: '#747475',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Switzer',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        letterSpacing: '0.1px',
                    }}
                >
                    {`of ${new Intl.NumberFormat('ID-id').format(totalData)} Data`}
                </Typography>
            </Stack>

            <Pagination
                disabled={disabled}
                shape={shape}
                size={size}
                count={pageCount}
                page={page}
                onChange={(e, targetPage) => onChange(targetPage)}
                showFirstButton
                showLastButton
                renderItem={
                    (item) => {
                        return (
                            <>
                                <StyledPaginationItem
                                    key={item.page}
                                    {...item}
                                />
                            </>
                        );
                    }
                }
            />
        </Stack>
    )
}

Paginate.propTypes = {
    direction: PropTypes.string,
    page: PropTypes.number.isRequired,
    limit: PropTypes.number,
    totalData: PropTypes.number,
    isCustom: PropTypes.bool,
    shape: PropTypes.string,
    size: PropTypes.string,
    showGoToPage: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onChangeLimit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

export default Paginate