import { Backdrop, Box, Grid, MenuItem, Card, CardContent } from '@mui/material';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import ModalISReport from './Modals/ModalISReport';
import CustomToastContainer, { CustomDatePicker, Input, Selection, showToast } from '../../../../components/ui';
import ApiService from '../../../../services/ApiService';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ButtonIncomeStatementReport from './Buttons/ButtonIncomeStatementReport';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import { GetReportVersionLog, PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';
import { useNavigate } from 'react-router-dom';
import { getDefaultDateFormat } from '../../../../utils/date';
import ReportVersionLog from '../../../../components/Modals/ReportVersionLog';

const IncomeStatementReport = forwardRef((props, ref) => {
    const menuCode = 'RINST';
    const history = useNavigate();

    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [period, setPeriod] = useState(new Date());
    const [period2, setPeriod2] = useState(new Date());
    const [disablePeriod, setDisablePeriod] = useState(true)
    const [single, setSingle] = useState(0)
    const labelPeriod = "First Period";
    const labelPeriod2 = "Second Period";
    const [dataJobOwner, setDataJobOwner] = useState([])
    const [JobOwner, setJobOwner] = useState(0)
    const [isModal, setIsModal] = useState(false)
    const periodList = [
        { id: 1, code: 0, name: 'Single Period' },
        { id: 2, code: 1, name: 'Range Period' }
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isModalLog, setIsModalLog] = useState(false);
    const [fullDataLog, setFullDataLog] = useState([]);
    const [dataLog, setDataLog] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Income Statement (Standard) Report', link: '/Majura/IncomeStatementReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Income Statement Report - ' + JSON.parse(localStorage.getItem('branchName'));
        GetDataJobOwner()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingPrint(true);

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === menuCode)
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                {
                    attr: "permissionId",
                    value: "" + pId
                },
                {
                    attr: "userId",
                    value: "" + JSON.parse(localStorage.getItem("userId"))
                },
                {
                    attr: "roleId",
                    value: "" + JSON.parse(localStorage.getItem("roleId"))
                }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        setIsLoadingPrint(false);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        setIsLoadingPrint(false);
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history('/Majura');
                });
        }
        else {
            history('/Majura');
        }
    };

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
        setPeriod2(`${year}-${month}`);
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRC() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls");
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Income Statement Report');
        handleClickConfirmation();
    };

    const PrintExcelData = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Income Statement Report');
        handleClickConfirmation();
    }

    const PrintReport = (code) => {
        const bln = new Date(period).getMonth();
        let bulan = bln + 1;
        const tahun = new Date(period).getFullYear();
        bulan = bulan.toString();
        if (bulan.length === 1) {
            bulan = ['0', bulan].join('');
        }
        const begin = [tahun, bulan, '01'].join('-')

        const bln2 = new Date(period2).getMonth();
        let bulan2 = bln2 + 1;
        const tahun2 = new Date(period2).getFullYear();
        var lastDay = new Date(tahun2, bulan2, 0);
        bulan2 = bulan2.toString();
        if (bulan2.length === 1) {
            bulan2 = ['0', bulan2].join('');
        }
        const end = [tahun2, bulan2, lastDay.getDate()].join('-')

        setIsLoadingPrint(true);

        let params = [
            {
                "attr": "beginningPeriod",
                "value": '' + begin
            },
            {
                "attr": "endDatePeriod",
                "value": '' + end
            },
            {
                "attr": "jobOwnerId",
                "value": "" + JobOwner
            },
            {
                "attr": "isQuarterly",
                "value": "false"
            }
        ];

        let reportLink = 'Reports/Finance/Print/FinanceReport/IncomeStatementReport/IncomeStatementReport/PrintIncomeStatementStandard?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(setIsLoadingPrint, 'Income Statement (Standard)', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'Income Statement (Standard)', reportLink, params);
        }
    };

    const handlePeriod = (value) => {
        setSingle(value)
        if (value === 0) {
            setDisablePeriod(true)
            setPeriod2(period)
        }
        else {
            setDisablePeriod(false)
        }
    }

    const GetDataJobOwner = () => {
        setIsLoadingPrint(true);
        let tempArray = [
            { id: 0, masterCode: 'All Principle' }
        ]
        setDataJobOwner([])
        ApiService.PostByPage("jobOwner/jobOwner", 1, 1250, [],)
            .then((response) => {
                if (response.data.code === 200) {
                    let array = response.data.data.jobOwner;

                    array.forEach(element => {
                        tempArray = [...tempArray, element];
                    });

                    setDataJobOwner(tempArray);
                    getDefaultMonth();
                    getDataAccess();
                }
                else {
                    ApiService.logOut();
                }
            })
            .catch(function (error) {
                setIsLoadingPrint(false);
                console.error('error saat fetch', error)
            })
    }

    const handleClickLog = useCallback(() => {
        if (isModalLog === false) {
            setIsModalLog(true);
        }
        else {
            setIsModalLog(false);
        }
    }, [isModalLog]);

    const handleDataLog = useCallback((isPrint = true, date = new Date(), isResume = false) => {
        setIsLoadingPrint(true);
        const today = new Date(date);
        const month = today.getMonth();
        const year = today.getFullYear();
        const startDate = getDefaultDateFormat(new Date(year, month, 1));
        const endDate = getDefaultDateFormat(new Date(year, month + 1, 0));

        const params = [
            { "attr": "reportCode", "value": menuCode },
            ...(
                JSON.parse(isPrint) ?
                    [
                        { "attr": "printedOn, printedOn", "mathSign": ">=, <=", "value": `${startDate}, ${endDate}` }
                    ]
                    : [
                        { "attr": "period", "value": '' + (parseInt(month) + parseInt(1)) },
                        { "attr": "yearPeriod", "value": '' + year }
                    ]
            )
        ];

        GetReportVersionLog(setIsLoadingPrint, menuCode, params, handleFullDataLog, !isResume ? handleClickLog : () => { });
    }, [handleClickLog]);

    const handleFullDataLog = (data) => {
        setFullDataLog(data);
        setDataLog(data);
    }

    const ButtonComponents = () => {
        return (
            <ButtonIncomeStatementReport
                PrintData={PrintData}
                PrintExcel={PrintExcelData}
                PrintQuarter={handleClick}
                handleClickLog={() => handleDataLog()}
                dataRegUserAccess={dataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Income Statement (Standard) Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Input
                                            label={'Company'}
                                            value={JSON.parse(localStorage.getItem('companyName'))}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%'
                                                }
                                            }}
                                        />

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={JobOwner}
                                                    label="Principle By"
                                                    fullWidth={true}
                                                    placeholder={'Select Principle By'}
                                                    onChange={(e) => setJobOwner(e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                >
                                                    {
                                                        dataJobOwner.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} key={data.id} value={data.id}>{data.masterCode}</MenuItem>
                                                        ))
                                                    }
                                                </Selection>

                                                <div style={{ marginTop: "16px" }}>
                                                    <CustomDatePicker
                                                        label={labelPeriod}
                                                        name="period"
                                                        value={period}
                                                        onChange={(e) => { setPeriod(e); setPeriod2(e) }}
                                                        type={'month'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    value={single}
                                                    label="Period"
                                                    fullWidth={true}
                                                    placeholder={'Select Period'}
                                                    onChange={(e) => handlePeriod(e.target.value)}
                                                    formControlProps={{
                                                        sx: {
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                >
                                                    {
                                                        periodList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>

                                                <div style={{ marginTop: "16px" }}>
                                                    <CustomDatePicker
                                                        label={labelPeriod2}
                                                        name="period"
                                                        value={period2}
                                                        onChange={(e) => { setPeriod2(e) }}
                                                        type={'month'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                            }
                                                        }}
                                                        disabled={disablePeriod}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => 10000 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <ModalISReport
                        isModal={isModal}
                        handleClick={handleClick}
                        JobOwner={JobOwner}
                        setIsLoadingPrint={setIsLoadingPrint}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRC}
                    />
                )
            }

            {
                isModalLog && (
                    <ReportVersionLog
                        data={dataLog}
                        setData={setDataLog}
                        isModal={isModalLog}
                        fullData={fullDataLog}
                        handleClick={handleClickLog}
                        handleDataLog={handleDataLog}
                        title={'Income Statement (Standard)'}
                        setIsLoading={setIsLoadingPrint}
                        reportLink={'Reports/Finance/Print/FinanceReport/IncomeStatementReport/IncomeStatementReport/PrintIncomeStatementStandard?typeReport=pdf'}
                    />
                )
            }
        </Box>
    )
})

export default IncomeStatementReport