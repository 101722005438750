import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import { Grid, Box } from '@mui/material';

import ButtonInvoice from './Buttons/ButtonInvoice';
import TableZ from '../../../components/Tables/TableZ';
import ModalDelete from '../../../components/Modals/ModalDelete';
import ModalDeliveryInvoice from './Modals/ModalDeliveryInvoice';
import ModalContra from '../../../components/Modals/ModalContra';
import ButtonComponent from '../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ApiService, { branchId, companyId, countryId, userId, userCode, parentCompanyId, isListAsc } from '../../../services/ApiService';

import freezeContainer from '../../../Styles/freezeContainer';
import ModalApproveGeneral from './Modals/ModalApproveGeneral';
import ModalUsageData from '../../../components/Modals/ModalUsageData';
import TableX from '../../../components/Tables/TableX';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../components/ui';
import { getDefaultDateFormat, setDateFormat, updateDateFormat } from '../../../utils/date';
import { newWindowConfig } from '../../../utils/masks.jsx';
import ModalRePostGL from '../../../components/Modals/ModalRePostGL.jsx';
import { handleFailedGL, handleRePost } from '../../../utils/constantVariable.jsx';
import { handleDataTable, PrintPDF } from '../../../utils/reusableFunction.jsx';

const ListInvoice = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramInvoice = 'invoice/invoice';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramGL = 'generalLedger/generalLedger';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(isListAsc === true ? 0 : 1);

    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const [jobType, setJobType] = useState('' + JSON.parse(localStorage.getItem("jobTypeId")) === '0' ? '1' : '' + JSON.parse(localStorage.getItem("jobTypeId")));
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [idInvoice, setIdInvoice] = useState(0);

    const [isModalDelivery, setIsModalDelivery] = useState(false);
    const [titleModalDelivery, setTitleModalDelivery] = useState('');
    const [DeliveryStatus, setDeliveryStatus] = useState(false);
    const [DeliveryDate, setDeliveryDate] = useState('');
    const [DeliveryRemarks, setDeliveryRemarks] = useState('');

    const [dataJobType, setDataJobType] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');
    const [isCustomNote, setIsCustomNote] = useState(false);
    const [CustomNote, setCustomNote] = useState('');
    const [DeleteRemarks, setDeleteRemarks] = useState('');
    const [isPrinting, setIsPrinting] = useState(false);
    const [typeDelete, setTypeDelete] = useState('H');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const [isDraft, setIsDraft] = useState(true);
    const [containerInfo, setContainerInfo] = useState(true);
    const [shipperInfo, setShipperInfo] = useState(true);

    const [isModalContra, setIsModalContra] = useState(false);
    const [titleModalContra, setTitleModalContra] = useState('');
    const [typeEdit, setTypeEdit] = useState('H');
    const [DropDownTitle, setDropDownTitle] = useState('All');

    const [isModalUsage, setIsModalUsage] = useState(false);
    const [titleModalUsage, setTitleModalUsage] = useState('');
    const [usageDataList, setUsageDataList] = useState([]);

    const [isModalGInv, setIsModalGInv] = useState(false);

    const [accessLog, setAccessLog] = useState(false);

    const [isModalRePost, setIsModalRePost] = useState(false);
    const [failedData, setFailedData] = useState({});

    const exceptions = ["id", "isPosted", "isOversea", "sfpId", "deleteTypeRefInvId", "pvId", "orId", "approvedGeneral", "glStatusCode", "vatId", "percentVAT"];

    const [DataEditType, setDataEditType] = useState([
        { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
        { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
        { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
    ]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice', link: '/Majura/Invoice', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const dropDownList = [
        { label: 'All', icon: 'search-doc' },
        { label: 'Active', icon: 'documents' },
        { label: 'Posted', icon: 'posted' },
        { label: 'Settled', icon: 'journal' },
        { label: 'Deleted', icon: 'status-deleted' },
    ]

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Invoice - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDateNow() {
        setDeliveryDate(getDefaultDateFormat(new Date()));
    };

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let filter;
        let params = [];
        params = [{ 'attr': 'jobTypeId', 'value': '' + jobType }];
        if (data) {
            if (data === 'All') {
                filter = [...filters]
            }
            else if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (data === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [...params, { 'attr': 'deleteType', 'value': '0' }, { 'attr': 'isPosted', 'value': 'false' }];
            }
            else if (data === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [
                    ...params,
                    { 'attr': 'deleteType', 'value': '0' },
                    { 'attr': 'orId', 'value': '0' },
                    { 'attr': 'pvId', 'value': '0' },
                    { 'attr': 'isPosted', 'value': 'true' }
                ];
            }
            else if (data === 'Settled') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [
                    ...params,
                    { 'attr': 'orId', 'value': '0', 'mathSign': '!=' },
                    { 'attr': 'pvId', 'value': '0', 'mathSign': '!=' },
                    { 'attr': 'isPosted', 'value': 'true' }
                ]
            }
        }
        else {
            if (DropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (DropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (DropDownTitle === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [...params, { 'attr': 'deleteType', 'value': '0' }, { 'attr': 'isPosted', 'value': 'false' }];
            }
            else if (DropDownTitle === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [...params, { 'attr': 'deleteType', 'value': '0' }, { 'attr': 'isPosted', 'value': 'true' }];
            }
            else if (data === 'Settled') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                ];
                params = [
                    ...params,
                    { 'attr': 'orId', 'value': '0', 'mathSign': '!=' },
                    { 'attr': 'pvId', 'value': '0', 'mathSign': '!=' },
                    { 'attr': 'isPosted', 'value': 'true' }
                ]
            }
        }

        ApiService.ListOperationDynamicPostByPage(paramInvoice, 'PAGE', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.invoices
                    let dataColumn = response.data.data.columns.headerColumns;
                    dataColumn = dataColumn.filter((item) => item.hidden !== true);

                    const validKeys = dataColumn.map((item) => item.column);
                    const finalKeys = new Set([...validKeys, ...exceptions])

                    temp = temp.map((data) => {
                        const filteredData = {};
                        finalKeys.forEach((key) => {
                            if (data.hasOwnProperty(key)) {
                                filteredData[key] = data[key];
                            }
                        });
                        return filteredData;
                    });

                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    localStorage.setItem('finAccTemp', JSON.stringify(indexed));
                    setDataMap(indexed)
                    setColumnData(dataColumn);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OINVO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.userAccess;
                        setDataRegUserAccess(response.data.data.userAccess);

                        data.forEach(element => {
                            if (element.accessCode === 'LOG') {
                                setAccessLog(true);
                            }
                        });

                        if (hasAccess) {
                            GetDataJobType();
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getData2 = (pageNumber, pageSize, filters, jobType) => {
        setIsLoading(true);
        let params = [];
        let filter;

        params = [{ 'attr': 'jobTypeId', 'value': '' + jobType }];
        if (DropDownTitle === 'All') {
            filter = [...filters]
        }
        else if (DropDownTitle === 'Deleted') {
            filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
        }
        else if (DropDownTitle === 'Active') {
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
            params = [...params, { 'attr': 'deleteType', 'value': '0' }, { 'attr': 'isPosted', 'value': 'false' }];
        }
        else if (DropDownTitle === 'Posted') {
            filter = [
                ...filters,
                { 'field': 'rowStatus', 'data': 'ACT' },
            ];
            params = [...params, { 'attr': 'deleteType', 'value': '0' }, { 'attr': 'isPosted', 'value': 'true' }];
        }

        ApiService.ListOperationDynamicPostByPage(paramInvoice, 'PAGE', pageNumber, pageSize, params, filter)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.invoices
                    let dataColumn = response.data.data.columns.headerColumns;
                    dataColumn = dataColumn.filter((item) => item.hidden !== true);
                    const validKeys = dataColumn.map((item) => item.column);
                    const finalKeys = new Set([...validKeys, ...exceptions])

                    temp = temp.map((data) => {
                        const filteredData = {};
                        finalKeys.forEach((key) => {
                            if (data.hasOwnProperty(key)) {
                                filteredData[key] = data[key];
                            }
                        });
                        return filteredData;
                    });

                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    localStorage.setItem('finAccTemp', JSON.stringify(indexed));
                    setDataMap(indexed)
                    setColumnData(dataColumn);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    setIsLoading(false)
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    setIsLoading(false);
                }

                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);

                    getData(isListAsc === true ? 0 : 1, 50, []);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleChange = (e) => {
        localStorage.setItem("jobTypeId", JSON.stringify(e.target.value));
        emptyState();
        setJobType(e.target.value);
        getData2(isListAsc === true ? 0 : 1, 50, filter, e.target.value);
    };

    const handleReset = () => {
        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, []);
    };

    const handleClickDelivery = () => {
        if (isModalDelivery === false) {
            setIsModalDelivery(true);
        }
        else {
            setIsModalDelivery(false);
        }
    };

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
            if (SelectedData.printing > 0) {
                setIsPrinting(true);
            }
            else {
                setIsPrinting(false);
            }
            setDataSelected(SelectedData);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setIsDraft(true);
            setContainerInfo(true);
            setShipperInfo(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsLoadingBackDrop(false);
            setIsModalConfirmation(false);
            setIsCustomNote(false);
            setCustomNote('');
        }
    }

    const handleClickContra = () => {
        if (isModalContra === false) {
            if (parentCompanyId !== 0 && SelectedData.debetCredit !== 'C' && SelectedData.sfpId !== 0) {
                setTypeEdit('R');
            }
            else {
                if (SelectedData.sfpId === 0) {
                    setDataEditType([
                        { id: 5, code: 'G', name: 'General' },
                    ]);
                    setTypeEdit('G');
                }
                else {
                    setDataEditType([
                        { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
                        { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
                        { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
                    ]);
                    setTypeEdit('H');
                }
            }

            setIsModalContra(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalContra(false);
        }
    }

    const handleDelivery = () => {
        setIsLoadingBackDrop(true);
        ApiService.OperationReadDelivery(paramInvoice, SelectedData.id)
            .then((res) => {
                if (res.data.code === 200) {
                    let data = res.data.data.invoice;
                    setDeliveryStatus(data.isDelivered);
                    setDateFormat(data.deliveredOn, setDeliveryDate, false, true);
                    setDeliveryRemarks(data.deliveredRemarks);
                }
                else {
                    setDeliveryStatus(false);
                    setDeliveryDate(getDefaultDateFormat(new Date()));
                }

                setTitleModalDelivery('Delivered Status');
                setIsLoadingBackDrop(false)
                handleClickDelivery();
            })
    }

    const handleClickGInv = () => {
        if (isModalGInv === false) {
            setIsModalGInv(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalGInv(false);
        }
    }

    const handleClickUsage = () => {
        if (isModalUsage === false) {
            setIsModalUsage(true);
        }
        else {
            setIsModalUsage(false);
        }
    }

    const OpenLogData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.isPosted === true) {
                setIsLoadingBackDrop(true);
                ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, getDefaultDateFormat(SelectedData.printedOn))
                    .then((response) => {
                        let data = response.data.data.closingPeriod;
                        history('/Majura/TransactionLogs/Invoice/' + SelectedData.id + '/' + data.period + '/' + data.yearPeriod);
                    })
                    .catch(function (error) {
                        setIsLoadingBackDrop(false);
                        // console.error('error saat fetch', error);
                    })
            }
            else {
                showToast({ type: 'error', message: 'Please Print This Invoice First!' });
            }
        }
    }

    const AddInv = () => {
        if (jobType !== '10') {
            window.open('/Majura/Invoice/Create/' + jobType, newWindowConfig);
        }
        else {
            showToast({ type: 'error', message: 'Coming Soon!' });
        }
    }

    const EditInv = () => {
        let check = 0;
        DataRegUserAccess.forEach(element => {
            if (element.accessCode === 'REI') {
                check = 1;
            }
        });

        if (check !== 0) {
            if (!SelectedData?.id) {
                showToast({ type: 'error', message: 'Please Select Data!' });
            }
            else {
                if (SelectedData.rowStatus !== 'ACT') {
                    showToast({ type: 'error', message: 'Data Already Deleted!' });
                }
                else {
                    if (SelectedData.paid === false) {
                        if (SelectedData.printing > 0 && SelectedData.isPosted) {
                            setTitleModalContra('Invoice');
                            handleClickContra();
                        }
                        else {
                            if (jobType !== '10') {
                                window.open('/Majura/Invoice/' + jobType + '/' + SelectedData.id, newWindowConfig);
                            }
                            else {
                                showToast({ type: 'error', message: 'Coming Soon!' });
                            }
                        }
                    }
                    else {
                        showToast({ type: 'error', message: 'Invoice Has Been Paid!' });
                    }
                }
            }
        }
        else {
            showToast({ type: 'error', message: 'You Don\'t Have Access!' });
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data!' });
        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, []);
    }

    const DeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.orId === 0 && SelectedData.pvId === 0 && SelectedData.deleteTypeRefInvId === 0) {
                    if (SelectedData.printing > 0) {
                        if (parentCompanyId !== 0 && SelectedData.debetCredit !== 'C' && SelectedData.sfpId !== 0) {
                            setTypeDelete('R');
                            setDataEditType([{ id: 4, code: 'R', name: 'Request Delete' },]);
                        }
                        else {
                            if (SelectedData.sfpId === 0) {
                                setDataEditType([
                                    { id: 5, code: 'G', name: 'General' },
                                ]);
                                setTypeDelete('G');
                            }
                            else {
                                setDataEditType([
                                    { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
                                    { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
                                    { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
                                ]);
                                setTypeDelete('H');
                            }
                        }

                        setTitleModalDelete('Invoice');
                        handleClickDelete();
                    }
                    else {
                        setTitleModalDelete('Invoice');
                        handleClickDelete();
                    }
                }
                else {
                    if (SelectedData.orId !== 0) {
                        setIsLoadingBackDrop(true);
                        getDataRelated(SelectedData.orId, 'OR');
                    }
                    else {
                        setIsLoadingBackDrop(true);
                        getDataRelated(SelectedData.pvId, 'PV');
                    }
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const CheckRequestInv = (DeleteRemarks = '') => {
        setIsLoadingBackDrop(true);
        handleClickDelete();

        if (typeDelete === 'R') {
            let params = [
                { 'attr': 'invoiceId', 'value': '' + SelectedData.id },
            ]
            ApiService.OperationCustomPostByPage('invoice/invoice/PostByPageInvReqDel?', 'PAGE', 1, 1, params)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.invoiceReqDels;

                        if (data.length > 0 && data[0].approvedStatusCode === 'DCL') {
                            setDeleteRemarks(DeleteRemarks);
                            setTitleConfirmation('Request Delete');
                            setTitleModalConfirmation('Invoice');
                            setIsCustomNote(true);
                            setCustomNote('Are You Sure Want To Request Delete This Invoice? This Invoice Already Being Denied Before Because "' + data[0].approvedRemarks + '"!');
                            handleClickConfirmation();
                        }
                        else {
                            DeleteInvoice(DeleteRemarks);
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Request Delete Failed!, ' + response.data.message });
                        setIsModalDelete(false);
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Request Delete Failed!, ' + error });
                    setIsModalDelete(false);
                })
        }
        else {
            DeleteInvoice(DeleteRemarks);
        }
    }

    const DeleteInvoice = (DeleteRemarks = '') => {
        setDataEditType([
            { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
            { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
            { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
        ]);

        var oldData = {};
        let deleteTypes = 0;
        let rowStatus = 'DEL';

        let etd = '';
        let eta = '';
        let datePaid = '';
        let dateBadDebt = '';
        let dateReBadDebts = '';
        let datePrint = '';
        let dateDeleted = '';
        let dateRePrintApproved = '';
        let dateDelivered = '';
        let dateApprovedCredit = '';
        let dateTransaction = '';
        let dateCreated = '';
        let dateRateEx = '';

        if (dataSelected.printing > 0) {
            if (typeDelete === 'H') {
                deleteTypes = 1;
            }
            else if (typeDelete === 'S') {
                deleteTypes = 2;
            }
            else if (typeDelete === 'P') {
                deleteTypes = 3;
            }

            if (typeDelete === 'R') {
                rowStatus = 'ACT';
            }
        }

        ApiService.OperationPostById(paramInvoice, dataSelected.id)
            .then((response) => {
                if (response.status === 200) {
                    etd = updateDateFormat(response.data.data.invoice.etd, true);
                    eta = updateDateFormat(response.data.data.invoice.eta, true);
                    datePaid = updateDateFormat(response.data.data.invoice.paidOn, true);
                    dateBadDebt = updateDateFormat(response.data.data.invoice.badDebtOn, true);
                    dateReBadDebts = updateDateFormat(response.data.data.invoice.dateReBadDebt, true);
                    datePrint = updateDateFormat(response.data.data.invoice.printedOn, true);
                    dateDeleted = updateDateFormat(new Date(), true);
                    dateRePrintApproved = updateDateFormat(response.data.data.invoice.rePrintApprovedOn, true);
                    dateDelivered = updateDateFormat(response.data.data.invoice.deliveredOn, true);
                    dateApprovedCredit = updateDateFormat(response.data.data.invoice.approvedCreditOn, true);
                    dateTransaction = updateDateFormat(response.data.data.invoice.transactionOn, true);
                    dateRateEx = updateDateFormat(response.data.data.invoice.exRateDate, true);
                    dateCreated = updateDateFormat(response.data.data.invoice.createdOn, true);

                    oldData = {
                        "invoice": {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": rowStatus,
                            "id": dataSelected.id,
                            "ticketId": response.data.data.invoice.ticketId,
                            "invoiceNo": response.data.data.invoice.invoiceNo,
                            "debetCredit": response.data.data.invoice.debetCredit,
                            "eplId": response.data.data.invoice.eplId,
                            "shipmentId": response.data.data.invoice.shipmentId,
                            "shipmentNo": response.data.data.invoice.shipmentNo,
                            "bookingId": response.data.data.invoice.bookingId,
                            "bookingNo": response.data.data.invoice.bookingNo,
                            "jobTypeCode": response.data.data.invoice.jobTypeCode,
                            "jobTypeId": response.data.data.invoice.jobTypeId,
                            "etd": etd,
                            "eta": eta,
                            "invoicesTo": response.data.data.invoice.invoicesTo,
                            "customerTypeId": response.data.data.invoice.customerTypeId,
                            "customerId": response.data.data.invoice.customerId,
                            "customerName": response.data.data.invoice.customerName,
                            "customerAddress": response.data.data.invoice.customerAddress,
                            "billId": response.data.data.invoice.billId,
                            "billName": response.data.data.invoice.billName,
                            "billAddress": response.data.data.invoice.billAddress,
                            "invoicesToCode": response.data.data.invoice.invoicesToCode,
                            "invoiceStatus": response.data.data.invoice.invoiceStatus,
                            "paymentUSD": response.data.data.invoice.paymentUSD,
                            "paymentIDR": response.data.data.invoice.paymentIDR,
                            "totalPaymentUSD": response.data.data.invoice.totalPaymentUSD,
                            "totalPaymentIDR": response.data.data.invoice.totalPaymentIDR,
                            "totalVatUSD": response.data.data.invoice.totalVatUSD,
                            "totalVatIDR": response.data.data.invoice.totalVatIDR,
                            "rate": response.data.data.invoice.rate,
                            "jobOwnerId": response.data.data.invoice.jobOwnerId,
                            "isPosted": response.data.data.invoice.isPosted,
                            "exRateDate": dateRateEx,
                            "period": response.data.data.invoice.period,
                            "yearPeriod": response.data.data.invoice.yearPeriod,
                            "invoicesAgent": response.data.data.invoice.invoicesAgent,
                            "isEdited": response.data.data.invoice.isEdited,
                            "isGeneral": response.data.data.invoice.isGeneral,
                            "linkTo": response.data.data.invoice.linkTo,
                            "dueDate": response.data.data.invoice.dueDate,
                            "paid": response.data.data.invoice.paid,
                            "createdOn": dateCreated,
                            "paidOn": datePaid,
                            "saveOR": response.data.data.invoice.saveOR,
                            "badDebt": response.data.data.invoice.badDebt,
                            "badDebtOn": dateBadDebt,
                            "reBadDebt": response.data.data.invoice.reBadDebt,
                            "dateReBadDebt": dateReBadDebts,
                            "printing": response.data.data.invoice.printing,
                            "printedOn": datePrint,
                            "deleted": true,
                            "deletedOn": dateDeleted,
                            "invoiceNo2": response.data.data.invoice.invoiceNo2,
                            "invHeader": response.data.data.invoice.invHeader,
                            "exRateId": response.data.data.invoice.exRateId,
                            "rePrintApproved": response.data.data.invoice.rePrintApproved,
                            "rePrintApprovedOn": dateRePrintApproved,
                            "rePrintApprovedBy": response.data.data.invoice.rePrintApprovedBy,
                            "deletedRemarks": DeleteRemarks,
                            "isCostToCost": response.data.data.invoice.isCostToCost,
                            "sfpId": response.data.data.invoice.sfpId,
                            "sfpNoFormat": response.data.data.invoice.sfpNoFormat,
                            "sfpDetailId": response.data.data.invoice.sfpDetailId,
                            "uniqueKeySFP": response.data.data.invoice.uniqueKeySFP,
                            "uniqueKeyInvoice": response.data.data.invoice.uniqueKeyInvoice,
                            "deleteType": deleteTypes,
                            "deleteTypeRefInvId": response.data.data.invoice.deleteTypeRefInvId,
                            "kursKMK": response.data.data.invoice.kursKMK,
                            "kursKMKId": response.data.data.invoice.kursKMKId,
                            "isDelivered": response.data.data.invoice.isDelivered,
                            "deliveredOn": dateDelivered,
                            "deliveredRemarks": response.data.data.invoice.deliveredRemarks,
                            "sfpReference": response.data.data.invoice.sfpReference,
                            "approvedCredit": response.data.data.invoice.approvedCredit,
                            "approvedCreditBy": response.data.data.invoice.approvedCreditBy,
                            "approvedCreditOn": dateApprovedCredit,
                            "approvedCreditRemarks": response.data.data.invoice.approvedCreditRemarks,
                            "packingListNo": response.data.data.invoice.packingListNo,
                            "siCustomerNo": response.data.data.invoice.siCustomerNo,
                            "reference": response.data.data.invoice.reference,
                            "isStampDuty": response.data.data.invoice.isStampDuty,
                            "stampDutyAmount": response.data.data.invoice.stampDutyAmount,
                            "pejkpNumber": response.data.data.invoice.pejkpNumber,
                            "pejkpReference": response.data.data.invoice.pejkpReference,
                            "transactionOn": dateTransaction,
                            "vatId": response.data.data.invoice.vatId,
                            "percentVAT": response.data.data.invoice.percentVAT,
                            "requester": response.data.data.invoice.requester,

                            "idLama": 0,
                        },
                        "invoiceDetails": response.data.data.invoice.invoiceDetails
                    };

                    ApiService.EditOperation(paramInvoice, oldData).then((res) => {
                        if (res.data.code === 200) {
                            setIsLoadingBackDrop(false);
                            if (oldData?.invoice?.printing > 0 && typeDelete !== 'R') {
                                // CreateGL(res.data.data.Id);
                            }
                            getData(numPage, rowsCount, filter);
                            showToast({ type: 'success', message: oldData.invoice.rowStatus === 'DEL' ? 'Delete Data Success!' : res.data.message });
                            setIsModalDelete(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Delete Data Failed!, ' + res.data.message });
                            setIsModalDelete(false);
                        }
                    })
                        .catch(function (error) {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Delete Data Failed!, ' + error });
                            // console.error('error saat fetch', error);
                        });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Delete Data Failed!, ' + error });
                // console.error('error saat fetch', error);
            })
    }

    function ConfirmationInvoice() {
        if (titleConfirmation === 'Print') {
            if (isDraft === false) {
                let check = 0;

                if (SelectedData.isGeneral === true && SelectedData.approvedGeneral !== true) {
                    check = 1;
                    showToast({ type: 'error', message: 'Please Approve This General Inv First!' });
                }

                if (check === 0) {
                    Print();
                }
            }
            else {
                PrintDataPDF();
            }
        }
        else if (titleConfirmation === 'Reprint') {
            RePrint();
        }
        else if (titleConfirmation === 'Print PEJKP') {
            PrintPEJKPData();
        }
        else if (titleConfirmation === 'Request Delete') {
            DeleteInvoice(DeleteRemarks);
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Print')
                setTitleModalConfirmation('Invoice');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const Print = () => {
        let check = 0;

        if (dataSelected.rePrintApproved === false && isDraft === false) {
            check = 1;
            showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
        }

        if (check === 0) {
            setIsLoadingBackDrop(true);
            let data = {
                'invoice': {
                    'id': dataSelected.id,
                    'printing': dataSelected.printing,
                    'debetCredit': dataSelected.debetCredit,
                    'vatId': dataSelected.vatId,
                    'percentVAT': dataSelected.percentVAT,
                    'OriginalInvId': dataSelected.originalInvoiceId
                }
            };
            ApiService.OperationUpdateStatusPrint(paramInvoice, dataSelected.id, data).then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    if (dataSelected.printing === 0 && isDraft !== true) {
                        // CreateGL(dataSelected.id);
                    }

                    PrintDataPDF();
                }
                else if (res.data.code === 204) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            });
        }
    };

    const PrintDataPDF = () => {
        let check = 0;

        if (dataSelected.rePrintApproved === false && isDraft === false) {
            check = 1;
            showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
        }

        if (check === 0) {
            setIsLoadingBackDrop(true);
            getData(numPage, rowsCount, filter, sortArr);
            let reportLink = 'Transactions/Operation/InvoiceReport/InvoiceReport/PrintInvoice?Id=' + dataSelected.id
                + '&isDraft=' + isDraft + '&viewContainerInfo=' + containerInfo + '&viewShipperInfo=' + shipperInfo;
            PrintPDF(setIsLoadingBackDrop, 'Invoice', reportLink);
        }
    }

    const RePrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                setTitleConfirmation('Reprint')
                setTitleModalConfirmation('Invoice');
                handleClickConfirmation();
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const RePrint = () => {
        setIsLoadingBackDrop(true);
        ApiService.OperationUpdateStatusRePrint(paramInvoice, dataSelected.id).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                setIsLoadingBackDrop(false);
                showToast({ type: 'success', message: message });
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    };

    const Approve = () => {

    };

    const ApproveGenInv = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.isGeneral === true) {
                    if (SelectedData.isPosted === false) {
                        if (!SelectedData.approvedGeneral) {
                            handleClickGInv();
                        }
                        else {
                            showToast({ type: 'error', message: 'General Invoice Already Been Approved!' });
                        }
                    }
                    else {
                        showToast({ type: 'error', message: 'General Invoice Already Been Posted!' });
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Only For General Invoice!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const ApproveGenInvData = (remarks) => {
        let data = {
            "invoice": {
                "id": dataSelected.id,
                "approvedGeneral": true,
                "approvedGeneralRemarks": remarks
            }
        }

        ApiService.ApproveGeneralOperation(paramInvoice, data)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: 'Approve General Inv Success!' });
                    handleClickGInv();
                    getData(numPage, rowsCount, filter);
                }
                else {
                    showToast({ type: 'error', message: message })
                }
            });
    }

    const Delivery = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.printing > 0) {
                    setIdInvoice(SelectedData.id);
                    handleDelivery();
                }
                else {
                    showToast({ type: 'error', message: 'Please Print the Data First!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    };

    const PrintPEJKP = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.printing > 0) {
                    if (SelectedData.isOversea === true) {
                        setTitleConfirmation('Print PEJKP')
                        setTitleModalConfirmation('Invoice');
                        handleClickConfirmation();
                    }
                    else {
                        showToast({ type: 'error', message: 'Contact Isn\'t From Overseas!' });
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Invoice Need To Be Print Fixed First!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const PrintPEJKPData = () => {
        setIsLoadingBackDrop(true);
        let reportLink = 'Transactions/Operation/InvoiceReport/InvoiceReport/PrintPEJKP?Id=' + dataSelected.id;
        PrintPDF(setIsLoadingBackDrop, 'Invoice PEJKP', reportLink);
    }

    const Journal = () => {

    };

    // const CreateGL = (id) => {
    //   var data = {
    //     "generalLedger": {
    //       "source": "INV",
    //       "refId": id,
    //       "methodPost": "AUTO",
    //       "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
    //     }
    //   }

    //   ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //     let message = '';
    //     message = res.data.message;
    //     if (res.data.code !== 200) {
    //       showToast({ type: 'error', message: message });
    //     }
    //   });
    // }

    const emptyState = () => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setStateX({})
        setGoTo(1);
        setSortArr([])
    };

    function CellDoubleClick(row) {
        EditInv();
    };

    function deliveryInvoice(data) {
        handleClickDelivery();
        setIsLoadingBackDrop(true);
        ApiService.OperationDelivery(paramInvoice, data).then((res) => {
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Update Delivery Data Success!' });
            }
            else {
                showToast({ type: 'error', message: 'Update Delivery Data Failed!' });
            }
            setIsLoadingBackDrop(false);
        });
    }

    const getDataRelated = (refId, source) => {
        let sequence = 1;
        let tempArray = [];

        if (source === 'OR') {
            ApiService.FinAccPostById('officialReceipt/officialReceipt', refId)
                .then((response) => {
                    if (response.data.code === 200) {
                        let temp = response.data.data.officialReceipt;

                        tempArray = [
                            {
                                'sequence': sequence,
                                'usedOn': 'Official Receipt',
                                'transactionNumber': temp.reference
                            }
                        ];

                        setUsageDataList(tempArray);
                        setTitleModalUsage('Payment Request');
                        handleClickUsage();

                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Invoice Already Used in Official Receipt!' });
                    }
                })
                .catch(function (error) {
                    setIsLoading(false);
                    setIsLoadingBackDrop(false);

                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (source === 'PV') {
            ApiService.FinAccPostById('paymentVoucher/paymentVoucher', refId)
                .then((response) => {
                    if (response.data.code === 200) {
                        let temp = response.data.data.paymentVoucher;

                        tempArray = [
                            {
                                'sequence': sequence,
                                'usedOn': 'Payment Voucher',
                                'transactionNumber': temp.reference
                            }
                        ];

                        setUsageDataList(tempArray);
                        setTitleModalUsage('Payment Request');
                        handleClickUsage();

                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Invoice Already Used in Payment Voucher!' });
                    }
                })
                .catch(function (error) {
                    setIsLoading(false);
                    setIsLoadingBackDrop(false);

                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    }

    function EditContraData() {
        if (dataSelected.orId === 0 && dataSelected.pvId === 0) {
            history('/Majura/Invoice/' + typeEdit + '/' + jobType + '/' + dataSelected.id);
        }
        else {
            if (dataSelected.orId !== 0) {
                showToast({ type: 'error', message: 'Invoice Already Used in Official Receipt!' });
            }
            else {
                showToast({ type: 'error', message: 'Invoice Already Used in Payment Voucher!' });
            }
        }
    }

    function ViewOnly() {
        history('/Majura/Invoice/' + jobType + '/' + dataSelected.id);
    }

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(numPage, rowsCount, filter, sortArr, data);
    }

    const handleClickRePost = () => {
        if (isModalRePost === false) {
            setIsModalRePost(true);
        }
        else {
            setIsModalRePost(false);
        }
    }

    const handleGL = (data) => {
        let selectedData = dataMap.find((el) => el.id === data.id);
        handleFailedGL({
            id: selectedData.id,
            glStatusCode: selectedData.glStatusCode,
            setIsLoadingBackDrop: setIsLoadingBackDrop,
            paramGL: paramGL,
            setFailedData: setFailedData,
            handleClickRePost: handleClickRePost,
            source: 'INV',
        });
    }

    const handlePost = () => {
        handleRePost({
            handleClickRePost: handleClickRePost,
            setIsLoadingBackDrop: setIsLoadingBackDrop,
            failedData: failedData,
            paramGL: paramGL,
            getData: getData,
            numPage: numPage,
            rowsCount: rowsCount,
            filter: filter,
        })
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 120
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <ButtonInvoice
                AddInv={AddInv}
                EditInv={EditInv}
                ReloadData={ReloadData}
                DeleteData={DeleteData}
                Print={PrintData}
                RePrint={RePrintData}
                Delivery={Delivery}
                Approve={Approve}
                Journal={Journal}
                ApproveGenInv={ApproveGenInv}
                PrintPEJKP={PrintPEJKP}
                DataRegUserAccess={DataRegUserAccess}
                OpenLogData={OpenLogData}
                DropDownTitle={DropDownTitle}
                HandleStatus={HandleStatus}
                DropDownList={dropDownList}
            />
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(value);
        getData(isListAsc === true ? 0 : 1, value, filter, sortArr);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '16px 32px 24px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Invoice'}
                    isJobType={true}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                    jobTypeId={jobType}
                    handleSelect={handleChange}
                    dataJobType={dataJobType}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        style={{
                            marginTop: '16px',
                            padding: '2px 0px 16px 16px',
                            borderRadius: '12px',
                            border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                            background: 'var(--Mjr-white-4, #FFF)',
                            boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        }}
                    >
                        <div
                            className="rounded-10 table-responsive"
                            style={{
                                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            }}
                        >
                            {
                                isLoading ?
                                    <LoadingSpinner />
                                    :
                                    <div className='mt-1'>
                                        {
                                            accessLog ?
                                                <TableZ
                                                    rowsCount={rowsCount}
                                                    source={'INV'}
                                                    isLoadingBackDrop={isLoadingBackDrop}
                                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={getData}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    setDataMap={setDataMap}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    CellDoubleClick={CellDoubleClick}
                                                    localVar={'finAccTemp'}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    goTo={goTo}
                                                    setGoTo={setGoTo}
                                                    handleFailedGL={handleGL}
                                                    uniqueKey={'id'}
                                                    dataTable={dataTable}
                                                />
                                                :
                                                <TableX
                                                    uniqueKey={'id'}
                                                    rowsCount={rowsCount}
                                                    setRowsCount={setRowsCount}
                                                    setNumPage={setNumPage}
                                                    getData={getData}
                                                    columnData={columnData}
                                                    dataMap={dataMap}
                                                    dataTable={dataTable}
                                                    SelectedData={SelectedData}
                                                    setSelectedData={setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    CellDoubleClick={CellDoubleClick}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    goTo={goTo}
                                                    setGoTo={setGoTo}
                                                    handleFailedGL={handleGL}
                                                />
                                        }
                                    </div>
                            }
                        </div>

                        <div style={{ paddingRight: '16px' }}>
                            <Box sx={{ borderTop: 1, borderColor: 'divider' }} />
                            <div style={{ marginTop: '8px' }}>
                                <Paginate
                                    page={numPage}
                                    limit={rowsCount}
                                    totalData={totalRows}
                                    onChange={onChangePaging}
                                    onChangeLimit={onChangeLimit}
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClickDelete}
                        DeleteData={CheckRequestInv}
                        typeDelete={typeDelete}
                        setTypeDelete={setTypeDelete}
                        isRemarks={true}
                        isPrinting={isPrinting}
                        DataEditType={DataEditType}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationInvoice}

                        isDraft={isDraft}
                        setIsDraft={setIsDraft}
                        containerInfo={containerInfo}
                        setContainerInfo={setContainerInfo}
                        shipperInfo={shipperInfo}
                        setShipperInfo={setShipperInfo}
                        isCustomNote={isCustomNote}
                        CustomNote={CustomNote}
                    />
                )
            }

            {
                isModalDelivery && (
                    <ModalDeliveryInvoice
                        isModal={isModalDelivery}
                        setIsModal={setIsModalDelivery}
                        handleClick={handleClickDelivery}
                        titleModal={titleModalDelivery}
                        deliveryInvoice={deliveryInvoice}
                        getDateNow={getDateNow}
                        idInvoice={idInvoice}
                        setIdInvoice={setIdInvoice}
                        DeliveryStatus={DeliveryStatus}
                        setDeliveryStatus={setDeliveryStatus}
                        DeliveryDate={DeliveryDate}
                        setDeliveryDate={setDeliveryDate}
                        DeliveryRemarks={DeliveryRemarks}
                        setDeliveryRemarks={setDeliveryRemarks}
                    />
                )
            }

            {
                isModalGInv && (
                    <ModalApproveGeneral
                        isModal={isModalGInv}
                        handleClick={handleClickGInv}
                        ApproveGenInvData={ApproveGenInvData}
                    />
                )
            }

            {
                isModalContra && (
                    <ModalContra
                        isModal={isModalContra}
                        setIsModal={setIsModalContra}
                        handleClick={handleClickContra}
                        titleModal={titleModalContra}
                        typeEdit={typeEdit}
                        setTypeEdit={setTypeEdit}
                        DataEditType={DataEditType}
                        EditContraData={EditContraData}
                        ViewOnly={ViewOnly}
                    />
                )
            }

            {
                isModalUsage && (
                    <ModalUsageData
                        isModal={isModalUsage}
                        titleModal={titleModalUsage}
                        handleClick={handleClickUsage}
                        usageDataList={usageDataList}
                    />
                )
            }

            {
                isModalRePost && (
                    <ModalRePostGL
                        isModal={isModalRePost}
                        handleRePost={handlePost}
                        transactionData={failedData}
                        handleClick={handleClickRePost}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default ListInvoice