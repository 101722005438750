import { Grid, Stack, Backdrop, Box } from '@mui/material';
import { HubConnectionState } from '@microsoft/signalr';
import React, { useEffect } from 'react';

import '../../../../../Styles/scrollbar.css';
import ChartComponent from '../../Components/ChartComponent';
import IncomeStatement from '../../Components/IncomeStatement';
import PercentComponent from '../../Components/PercentComponent';
import { signalRConnectionDashboard } from '../../../../../services/ApiService';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import ApiService, { signalRConnectionBS } from '../../../../../services/ApiService';
import { showToast } from '../../../../../components/ui';

const ProfitAndLoss = ((props) => {
    useEffect(() => {
        document.title = 'Dashboard - Profit and Loss - ' + JSON.parse(localStorage.getItem('branchName'))
        props.setIsLoadingBackDrop(false);
        props.getDataIncomeStatement();
        signalRConnectionBS.stop();
        if (signalRConnectionDashboard.state === HubConnectionState.Disconnected) {
            getConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getConnection() {
        try {
            ApiService.refreshToken();
            signalRConnectionDashboard.on("ReceiveMessage", (res) => {
                if (res === 'FinanceDashboardNotif') {
                    ApiService.refreshToken();

                    let period = new Date(JSON.parse(localStorage.getItem("bsPeriod")));
                    props.setDate(new Date());
                    let params = [
                        {
                            'attr': 'period',
                            'value': '' + (period.getMonth() + 1)
                        },
                        {
                            'attr': 'yearPeriod',
                            'value': '' + period.getFullYear()
                        }
                    ];

                    ApiService.ReportPostByPage(props.paramDashboard, 'PAGE', params)
                        .then((response) => {
                            if (response.data.code === 200) {
                                let data = response.data.data;
                                let dataSum = [];
                                let tempArray3 = [];
                                let tempArray4 = [];

                                data.forEach(element => {
                                    if (element.category === 'MART') {
                                        let tempObj = {
                                            'id': 999,
                                            'name': '',
                                            'sumIDR': 0,
                                            'dataMartGroups': [],
                                            'incomeStatements': []
                                        };
                                        let tempArray = [];
                                        let tempArray1 = [];
                                        let tempArray2 = [];

                                        tempArray1 = element.data.incomeStatement;
                                        tempArray = element.data.summary;

                                        tempArray.forEach(el => {
                                            if (el.name === 'Ebit') {
                                                tempObj.name = el.name.toUpperCase();
                                                tempObj.sumIDRDisplay = el.amount;
                                            }
                                            else {
                                                tempArray2 = [...tempArray2, el];
                                            }
                                        });

                                        for (let i = 0; i < tempArray1.length; i++) {
                                            if (tempArray1[i].code === 'ISNO') {
                                                tempArray3 = [...tempArray3, tempArray1[i]];
                                                tempArray3 = [...tempArray3, tempObj];
                                            }
                                            else {
                                                tempArray3 = [...tempArray3, tempArray1[i]];
                                            }
                                        }

                                        props.setIncomeStatementTitle(element.name.toUpperCase());
                                        props.setDataIncomeStatementSummary(tempArray2);
                                    }
                                    else if (element.category === 'CHART') {
                                        if (element.data.datasets) {
                                            tempArray4 = [...tempArray4, element];
                                        }
                                    }
                                    else if (element.category === 'SUM') {
                                        if (element.hidden === false) {
                                            element.name = element.name.toUpperCase();
                                            dataSum = [...dataSum, element];
                                        }
                                    }
                                });

                                props.setDataPercent(dataSum);

                                let dataC = JSON.parse(localStorage.getItem('chartIncomeStatement'));
                                handleDataChart(tempArray4, dataC);

                                localStorage.setItem('chartIncomeStatement', JSON.stringify(dataC));
                                props.setDataChart(JSON.parse(localStorage.getItem('chartIncomeStatement')));

                                let temp = 0;
                                temp = parseFloat(12) / parseFloat(dataSum.length);
                                props.setDataPercentSize(temp);

                                let dataA = JSON.parse(localStorage.getItem(props.varIncomeStatement));
                                handleInsertRecursive(dataA, tempArray3);

                                localStorage.setItem(props.varIncomeStatement, JSON.stringify(dataA));
                                props.setDataIncomeStatement(dataA);
                            }

                            props.setIsLoadingBackDrop(false);
                        })
                        .catch(function (error) {
                            props.setIsLoadingBackDrop(false);
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() });
                        })
                }
            });

            signalRConnectionDashboard.onclose(e => { });

            if (signalRConnectionDashboard.state === HubConnectionState.Disconnected) {
                await signalRConnectionDashboard.start();
                await signalRConnectionDashboard.invoke("Show", JSON.parse(localStorage.getItem("username")));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function handleInsertRecursive(data, arrayData) {
        if (data !== undefined && arrayData !== undefined && data.length !== undefined && arrayData.length !== undefined && data !== null && arrayData !== null && data.length !== null && arrayData.length !== null) {
            if (data.length > 0 && arrayData.length > 0) {
                for (let i = 0; i < arrayData.length; i++) {
                    if (data[i].id !== undefined && data[i].id !== null) {
                        if (data[i].id === arrayData[i].id) {
                            data[i].sumIDRDisplay = arrayData[i].sumIDRDisplay;

                            if (data[i].dataMartGroups.length > 0 && arrayData[i].dataMartGroups.length > 0) {
                                handleInsertRecursive(data[i].dataMartGroups, arrayData[i].dataMartGroups);
                            }
                            else if (data[i].incomeStatements.length > 0 && arrayData[i].incomeStatements.length > 0) {
                                handleInsertRecursive(data[i].incomeStatements, arrayData[i].incomeStatements);
                            }
                        }
                    }
                    else {
                        if (data[i].coaId === arrayData[i].coaId) {
                            data[i].sumIDRDisplay = arrayData[i].sumIDRDisplay;
                            data[i].actualIDR = arrayData[i].actualIDR;

                            if (data[i].incomeStatements.length > 0 && arrayData[i].incomeStatements.length > 0) {
                                handleInsertRecursive(data[i].incomeStatements, arrayData[i].incomeStatements);
                            }
                        }
                    }
                }
            }
        }
    }

    function handleDataChart(dataA, data) {
        for (let i = 0; i < dataA.length; i++) {
            if (dataA[i].data.datasets !== undefined) {
                for (let j = 0; j < dataA[i].data.datasets.length; j++) {
                    data[i].data.datasets[j].data = dataA[i].data.datasets[j].data;
                }
            }
        }
    }

    return (
        <Box sx={{ width: '100%', marginTop: '12px', backgroundColor: '#FAFAFA' }}>
            <Grid container spacing={0} direction="column">
                <Grid className='h-100' item xs={12}>
                    <div>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={8.5}
                                lg={8.5}
                                sm={8.5}
                                xs={12}
                            >
                                <Stack spacing={'16px'}>
                                    <div>
                                        <PercentComponent data={props.DataPercent} size={props.DataPercentSize} />
                                    </div>

                                    <div style={{ borderRadius: '12px', border: '1px solid #F2F2F2 !important', backgroundColor: '#FFF', padding: '24px' }}>
                                        <ChartComponent data={props.DataChart} setData={props.setDataChart} />
                                    </div>
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={3.5}
                                lg={3.5}
                                sm={3.5}
                                xs={12}
                            >
                                <div className='table-responsive scrollbar' style={{ height: '84vh', borderRadius: '12px', border: '1px solid #F2F2F2 !important', backgroundColor: '#FFF', padding: '0px 24px' }}>
                                    <IncomeStatement
                                        dataI={props.DataIncomeStatement}
                                        setData={props.setDataIncomeStatement}
                                        title={props.IncomeStatementTitle}
                                        localVar={props.varIncomeStatement}
                                        dataJ={props.DataIncomeStatementSummary}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            
            {
                props.isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default ProfitAndLoss