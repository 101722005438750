import { Backdrop, Box, Grid, Card, CardContent } from '@mui/material'
import { HubConnectionState } from '@microsoft/signalr';
import React, { useEffect } from 'react'

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import ApiService, { signalRConnectionBS, signalRConnectionDashboard } from '../../../../../services/ApiService';
import { showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const BalanceSheet = ((props) => {
    useEffect(() => {
        document.title = 'Dashboard - Balance Sheet - ' + JSON.parse(localStorage.getItem('branchName'))
        signalRConnectionDashboard.stop();
        props.setIsLoadingBackDrop(false);
        props.getData();
        if (signalRConnectionBS.state === HubConnectionState.Disconnected) {
            setTimeout(function () { getConnection() }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getConnection() {
        try {
            ApiService.refreshToken();
            signalRConnectionBS.on("ReceiveMessage", (res) => {
                if (res === 'FinanceDashboardNotif') {
                    if (props.check === 1) {
                        setTimeout(
                            function () {
                                let period = new Date(JSON.parse(localStorage.getItem("bsPeriod")));
                                ApiService.refreshToken();
                                props.setDate(new Date());
                                let params = [
                                    {
                                        'attr': 'period',
                                        'value': '' + (period.getMonth() + 1)
                                    },
                                    {
                                        'attr': 'yearPeriod',
                                        'value': '' + period.getFullYear()
                                    }
                                ];

                                ApiService.ReportPostByPage(props.paramBalanceSheet, 'PAGE', params)
                                    .then((response) => {
                                        if (response.data.code === 200) {
                                            let data = response.data.data[0].data.balanceSheet;

                                            let oldDataAsset = JSON.parse(localStorage.getItem('dataAsset'));
                                            let dataA = data[0].dataMartGroups;
                                            insertData(oldDataAsset, dataA);
                                            props.setDataAsset(oldDataAsset);
                                            props.setSumAssetIDR(data[0].sumIDR);
                                            props.setSumAssetUSD(data[0].sumUSD);

                                            let oldDataLiabilities = JSON.parse(localStorage.getItem('dataLiability'));
                                            let dataB = data[1].dataMartGroups;
                                            insertData(oldDataLiabilities, dataB);
                                            props.setDataLiabilities(oldDataLiabilities);
                                            props.setSumLiabiIDR(data[1].sumIDR);
                                            props.setSumLiabiUSD(data[1].sumUSD);
                                        }
                                    })
                                    .catch(function (error) {
                                        console.error('error saat fetch', error);
                                        showToast({ message: error.toString(), type: 'error' })
                                    })
                            },
                            2500
                        );
                    }
                    else {
                        let period = new Date(JSON.parse(localStorage.getItem("bsPeriod")));
                        ApiService.refreshToken();
                        props.setDate(new Date());
                        let params = [
                            {
                                'attr': 'period',
                                'value': '' + (period.getMonth() + 1)
                            },
                            {
                                'attr': 'yearPeriod',
                                'value': '' + period.getFullYear()
                            }
                        ];

                        ApiService.ReportPostByPage(props.paramBalanceSheet, 'PAGE', params)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let data = response.data.data[0].data.balanceSheet;

                                    let oldDataAsset = JSON.parse(localStorage.getItem('dataAsset'));
                                    let dataA = data[0].dataMartGroups;
                                    insertData(oldDataAsset, dataA);
                                    props.setDataAsset(oldDataAsset);
                                    props.setSumAssetIDR(data[0].sumIDR);
                                    props.setSumAssetUSD(data[0].sumUSD);

                                    let oldDataLiabilities = JSON.parse(localStorage.getItem('dataLiability'));
                                    let dataB = data[1].dataMartGroups;
                                    insertData(oldDataLiabilities, dataB);
                                    props.setDataLiabilities(oldDataLiabilities);
                                    props.setSumLiabiIDR(data[1].sumIDR);
                                    props.setSumLiabiUSD(data[1].sumUSD);
                                }
                            })
                            .catch(function (error) {
                                console.error('error saat fetch', error);
                                showToast({ message: error.toString(), type: 'error' })
                            })
                    }
                }
            });

            signalRConnectionBS.onclose(e => { });

            if (signalRConnectionBS.state === HubConnectionState.Disconnected) {
                await signalRConnectionBS.start();
                await signalRConnectionBS.invoke("Show", JSON.parse(localStorage.getItem("username")));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function insertData(dataA, data) {
        for (let i = 0; i < dataA.length; i++) {
            dataA[i] = data[i];

            // dataA[i].balanceSheets.sort(function (dataB, dataC) {
            //     return dataB.displayOrder - dataC.displayOrder
            // });
        }
    }

    return (
        <Box className='w-100' sx={{ width: '100%', marginTop: '12px' }}>
            <Grid>
                <Grid className='h-100' item xs={12} style={{ 'borderRadius': '3px' }}>
                    <div className='mt-0 h-100'>
                        <Grid container style={{ marginTop: '-8px' }}>
                            <Grid
                                container
                                spacing={'32px'}
                            >
                                <Grid
                                    item
                                    xs={6}
                                    border={true}
                                    className='mt-2'
                                >
                                    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '8px 16px' }}>
                                        <CardContent>
                                            <Grid
                                                container
                                                className='border-bottom border-secondary'
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    container
                                                    justifyContent="flex-start"
                                                >
                                                    <h6><b>ASSETS</b></h6>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <h6><b>IDR</b></h6>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <h6><b>USD</b></h6>
                                                </Grid>
                                            </Grid>

                                            {
                                                props.dataAsset.length > 0 ?
                                                    props.dataAsset.map((el, index) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                    container
                                                                    key={index}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        border={true}
                                                                        mt={index === 0 ? 1 : 2}
                                                                    >
                                                                        <b>{el.name.toUpperCase()}</b>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        container
                                                                        justifyContent="flex-end"
                                                                        mt={index === 0 ? 1 : 2}
                                                                    >
                                                                        {
                                                                            el.sumIDR < 0 ?
                                                                                <b>({convertToNominal(Math.abs(el.sumIDR))})</b>
                                                                                :
                                                                                <b>{convertToNominal(Math.abs(el.sumIDR))}</b>
                                                                        }
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        container
                                                                        justifyContent="flex-end"
                                                                        mt={index === 0 ? 1 : 2}
                                                                    >
                                                                        {
                                                                            el.sumUSD < 0 ?
                                                                                <b>({convertToNominal(Math.abs(el.sumUSD))})</b>
                                                                                :
                                                                                <b>{convertToNominal(Math.abs(el.sumUSD))}</b>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    props.dataAsset[index].balanceSheets.map((elm, idx) => {
                                                                        return (
                                                                            <>
                                                                                <Grid
                                                                                    container
                                                                                    key={idx}
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        xs={6}
                                                                                        border={true}
                                                                                        mt={idx === 0 ? 1 : 2}
                                                                                    >
                                                                                        <div className='ms-2'>
                                                                                            <b>-</b> {elm.accountTitle}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={3}
                                                                                        container
                                                                                        justifyContent="flex-end"
                                                                                        mt={idx === 0 ? 1 : 2}
                                                                                    >
                                                                                        {
                                                                                            elm.sumIDR < 0 ?
                                                                                                <span>({convertToNominal(Math.abs(elm.sumIDR))})</span>
                                                                                                :
                                                                                                <span>{convertToNominal(Math.abs(elm.sumIDR))}</span>
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={3}
                                                                                        container
                                                                                        justifyContent="flex-end"
                                                                                        mt={idx === 0 ? 1 : 2}
                                                                                    >
                                                                                        {
                                                                                            elm.sumUSD < 0 ?
                                                                                                <span>({convertToNominal(Math.abs(elm.sumUSD))})</span>
                                                                                                :
                                                                                                <span>{convertToNominal(Math.abs(elm.sumUSD))}</span>
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <div className="text-center mt-3 text-muted">
                                                        Data Empty
                                                    </div>
                                            }

                                            {
                                                props.dataAsset.length > 0 ?
                                                    <Grid
                                                        container
                                                        className='border-top border-secondary mt-3'
                                                    >
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            container
                                                            justifyContent="flex-start"
                                                            mt={1}
                                                        >
                                                            <b>TOTAL</b>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            container
                                                            justifyContent="flex-end"
                                                            mt={1}
                                                        >
                                                            {
                                                                props.sumAssetIDR < 0 ?
                                                                    <b>({convertToNominal(Math.abs(props.sumAssetIDR))})</b>
                                                                    :
                                                                    <b>{convertToNominal(Math.abs(props.sumAssetIDR))}</b>
                                                            }
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            container
                                                            justifyContent="flex-end"
                                                            mt={1}
                                                        >
                                                            {
                                                                props.sumAssetUSD < 0 ?
                                                                    <b>({convertToNominal(Math.abs(props.sumAssetUSD))})</b>
                                                                    :
                                                                    <b>{convertToNominal(Math.abs(props.sumAssetUSD))}</b>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <></>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid
                                    item
                                    xs={6}
                                    className='mt-2'
                                >
                                    <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '8px 16px' }}>
                                        <CardContent>
                                            <Grid
                                                container
                                                className='border-bottom border-secondary'
                                            >
                                                <Grid
                                                    item
                                                    xs={6}
                                                    container
                                                    justifyContent="flex-start"
                                                >
                                                    <h6><b>LIABILITIES AND EQUITY</b></h6>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <h6><b>IDR</b></h6>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    container
                                                    justifyContent="flex-end"
                                                >
                                                    <h6><b>USD</b></h6>
                                                </Grid>
                                            </Grid>

                                            {
                                                props.dataLiabilities.length > 0 ?
                                                    props.dataLiabilities.map((el, index) => {
                                                        return (
                                                            <>
                                                                <Grid
                                                                    container
                                                                    key={index}
                                                                >
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        border={true}
                                                                        mt={index === 0 ? 1 : 2}
                                                                    >
                                                                        <b>{el.name.toUpperCase()}</b>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        container
                                                                        justifyContent="flex-end"
                                                                        mt={index === 0 ? 1 : 2}
                                                                    >
                                                                        {
                                                                            el.sumIDR < 0 ?
                                                                                <b>({convertToNominal(Math.abs(el.sumIDR))})</b>
                                                                                :
                                                                                <b>{convertToNominal(Math.abs(el.sumIDR))}</b>
                                                                        }
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={3}
                                                                        container
                                                                        justifyContent="flex-end"
                                                                        mt={index === 0 ? 1 : 2}
                                                                    >
                                                                        {
                                                                            el.sumUSD < 0 ?
                                                                                <b>({convertToNominal(Math.abs(el.sumUSD))})</b>
                                                                                :
                                                                                <b>{convertToNominal(Math.abs(el.sumUSD))}</b>
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                                {
                                                                    props.dataLiabilities[index].balanceSheets.map((elm, idx) => {
                                                                        return (
                                                                            <>
                                                                                <Grid
                                                                                    container
                                                                                    key={idx}
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        xs={6}
                                                                                        border={true}
                                                                                        mt={idx === 0 ? 1 : 2}
                                                                                    >
                                                                                        <div className='ms-2'>
                                                                                            <b>-</b> {elm.accountTitle}
                                                                                        </div>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={3}
                                                                                        container
                                                                                        justifyContent="flex-end"
                                                                                        mt={idx === 0 ? 1 : 2}
                                                                                    >
                                                                                        {
                                                                                            elm.sumIDR < 0 ?
                                                                                                <span>({convertToNominal(Math.abs(elm.sumIDR))})</span>
                                                                                                :
                                                                                                <span>{convertToNominal(Math.abs(elm.sumIDR))}</span>
                                                                                        }
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={3}
                                                                                        container
                                                                                        justifyContent="flex-end"
                                                                                        mt={idx === 0 ? 1 : 2}
                                                                                    >
                                                                                        {
                                                                                            elm.sumUSD < 0 ?
                                                                                                <span>({convertToNominal(Math.abs(elm.sumUSD))})</span>
                                                                                                :
                                                                                                <span>{convertToNominal(Math.abs(elm.sumUSD))}</span>
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <div className="text-center mt-3 text-muted">
                                                        Data Empty
                                                    </div>
                                            }

                                            {
                                                props.dataLiabilities.length > 0 ?
                                                    <Grid
                                                        container
                                                        className='border-top border-secondary mt-3'
                                                    >
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            container
                                                            justifyContent="flex-start"
                                                            mt={1}
                                                        >
                                                            <b>TOTAL</b>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            container
                                                            justifyContent="flex-end"
                                                            mt={1}
                                                        >
                                                            {
                                                                props.sumLiabiIDR < 0 ?
                                                                    <b>({convertToNominal(Math.abs(props.sumLiabiIDR))})</b>
                                                                    :
                                                                    <b>{convertToNominal(Math.abs(props.sumLiabiIDR))}</b>
                                                            }
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            container
                                                            justifyContent="flex-end"
                                                            mt={1}
                                                        >
                                                            {
                                                                props.sumLiabiUSD < 0 ?
                                                                    <b>({convertToNominal(Math.abs(props.sumLiabiUSD))})</b>
                                                                    :
                                                                    <b>{convertToNominal(Math.abs(props.sumLiabiUSD))}</b>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    :
                                                    <></>
                                            }
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            
            {
                props.isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={props.isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default BalanceSheet