import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import Icon from "../Icon/Icon";
import PropTypes from 'prop-types';

const FullPopUp = ({
    id,
    isOpen,
    handleClose,
    title,
    titleDetail,
    children,
    actions,
}) => {

    return (
        <Dialog
            aria-labelledby={id}
            open={isOpen}
            fullScreen
            className="w-100"
            onClose={(e, reason) => handleClose(e, reason)}
            PaperProps={{
                sx: {
                    background: 'var(--Mjr-white-3, #FAFAFA)',
                    maxHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }
            }}
        >
            <DialogTitle sx={{ padding: '32px 240px 16px', }}>
                <Stack direction="row" alignItems="flex-start">
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                        <IconButton
                            sx={{
                                height: "20px",
                                width: "20px",
                            }}
                            onClick={handleClose}
                        >
                            <Icon
                                iconName="arrow-left"
                                svgIconProps={{
                                    fontSize: "small",
                                    htmlColor: "#00286D",
                                    sx: { height: "12px", width: "12px" },
                                }}
                            />
                        </IconButton>
                    </div>

                    <div style={{ marginLeft: "16px", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
                        <Typography
                            sx={{
                                color: "var(--Mjr-black-1, #171717)",
                                fontFamily: "Switzer",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: "600",
                                lineHeight: "normal",
                            }}
                        >
                            {title}
                        </Typography>

                        {titleDetail && (
                            <div style={{ marginTop: "8px" }}>
                                {titleDetail}
                            </div>
                        )}
                    </div>
                </Stack>
            </DialogTitle>

            {children && (
                <DialogContent sx={{ padding: '0px 240px' }}>
                    {children}
                </DialogContent>
            )}

            {actions && (
                <DialogActions>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
}

FullPopUp.propTypes = {
    // *** Base Props
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,

    // *** Custom Props
    children: PropTypes.node,
    actions: PropTypes.node,

    // *** MUI Dialog Base Props
    baseProps: PropTypes.shape({
        'aria-describedby': PropTypes.string,
        BackdropComponent: PropTypes.elementType,
        BackdropProps: PropTypes.object,
        classes: PropTypes.object,
        className: PropTypes.string,
        disableEscapeKeyDown: PropTypes.bool,
        fullScreen: PropTypes.bool,
        onBackdropClick: PropTypes.func,
        PaperProps: PropTypes.object,
        sx: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])),
            PropTypes.func,
            PropTypes.object
        ]),
        TransitionComponent: PropTypes.elementType,
        transitionDuration: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number
        })]),
        TransitionProps: PropTypes.object
    })
}

export default FullPopUp;