import { Chip, Box, Stack, Button, Typography, Grid, IconButton } from '@mui/material';
import { Icon } from '../ui';
import React from 'react';

const handleDeleteChip = (
    chipToDelete, setFilterBy, filterBy,
    setFilter, setNumPage, setRowsCount,
    getData, stateX, setStateX, setGoTo,
    columnData, rowsCount, sortArr
) => {
    setFilterBy((chips) => chips.filter((chip) => chip !== chipToDelete));
    var dataFilter = filterBy.filter((data) => data !== chipToDelete)

    var dataArr = []
    let dataState = {}
    if (dataFilter.length > 0) {
        dataFilter.forEach(el => {
            let value = el.split(': ')
            let column = columnData.find(data => data.text === value[0]);
            let realValue = stateX[column.column];
            dataArr = [...dataArr, { field: column.column, data: realValue }];
            dataState[column.column] = realValue;
        });
    }

    setStateX(dataState)
    setFilter(dataArr)
    setNumPage(1)
    setRowsCount(rowsCount)
    setGoTo(1)
    getData(1, rowsCount, dataArr, sortArr)
};

const ButtonTemplate = ({ Func, Name = '', IconName = '', isHidden = false, isDisabled = false, ButtonColor = '', TextColor = '', isForm = false }) => {
    return (
        <Button
            size="small"
            variant="outlined"
            className="d-flex align-items-center"
            sx={{
                padding: '8px 16px',
                gap: !isForm ? '6px' : '8px',
                alignItems: 'center',
                justifyContent: 'center',
                height: !isForm ? '18px !important' : '32px !important',
                minHeight: '28px !important',
                borderRadius: '12px',
                display: isHidden ? 'none !important' : undefined,
                background: isForm ? ButtonColor : '',
                border: !isForm ? `1px solid ${ButtonColor}` : '',
                '&:hover': {
                    backgroundColor: isForm ? `${ButtonColor} !important` : '',
                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                }
            }}
            onClick={Func}
            disabled={isDisabled}
        >
            {
                IconName !== '' && (
                    <Icon
                        svgIconProps={{
                            htmlColor: isDisabled ? 'inherit' : TextColor,
                            sx: {
                                height: !isForm ? '12px' : '16px',
                                width: !isForm ? '12px' : '16px',
                            }
                        }}
                        iconName={IconName}
                    />
                )
            }
            <Typography
                sx={{
                    fontFamily: 'Switzer',
                    fontSize: '8px',
                    fontWeight: '600',
                    lineHeight: '12px',
                    letterSpacing: '0em',
                    textAlign: 'center',
                    color: isDisabled ? 'inherit' : TextColor,
                }}
            >
                {Name}
            </Typography>
        </Button>
    );
};

class ButtonComponent {
    RefreshButton(sortArr, rowsCount, columnData, setGoTo, handleReset, setFilterBy, filterBy, setFilter, setNumPage, setRowsCount, getData, stateX, setStateX, isCard = true) {
        return (
            <>
                {
                    filterBy.length > 0 ?
                        <Box
                            alignItems="center"
                            justifyContent="left"
                            sx={{
                                marginTop: '16px',
                                padding: isCard ? '4px 16px 12px' : '4px 0px 12px',
                                background: '#FFF',
                                boxShadow: isCard ? '0px 8px 8px 0px #0000001A' : '',
                                borderRadius: '12px',
                                maxWidth: '100%',
                            }}
                        >
                            <Stack spacing={0} direction="row" alignItems={'center'}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: '700',
                                        lineHeight: '20px',
                                        textAlign: 'left',
                                        paddingTop: '8px',
                                        marginInlineEnd: '8px',
                                    }}
                                >
                                    Keyword:
                                </Typography>

                                <Grid container direction="row">
                                    {
                                        filterBy.map((data, i) => {
                                            return (
                                                <div key={i}>
                                                    <Chip
                                                        label={data}
                                                        color="primary"
                                                        sx={{
                                                            marginTop: '8px',
                                                            marginInlineStart: '8px',
                                                            background: '#B3E3FF',
                                                            border: '2px solid #0087C2',
                                                            color: '#0087C2',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontWeight: '600',
                                                            '&:hover': {
                                                                background: '#EAEAEA',
                                                                color: '#FF3030',
                                                                border: '2px solid #FF3030',
                                                            }
                                                        }}
                                                        deleteIcon={
                                                            <IconButton
                                                                sx={{
                                                                    width: '16px',
                                                                    height: '16px',
                                                                    background: '#EAEAEA',
                                                                    '&:hover': {
                                                                        background: '#FF3030',
                                                                    }
                                                                }}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        sx: {
                                                                            padding: '4px',
                                                                            width: '20px',
                                                                            height: '20px',
                                                                            color: '#0087C2',
                                                                            '&:hover': {
                                                                                color: '#EAEAEA'
                                                                            }
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        }
                                                        onDelete={
                                                            () => handleDeleteChip(
                                                                data, setFilterBy, filterBy,
                                                                setFilter, setNumPage, setRowsCount,
                                                                getData, stateX, setStateX, setGoTo, columnData,
                                                                rowsCount, sortArr
                                                            )
                                                        }
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </Grid>

                                <div style={{ paddingTop: '8px' }}>
                                    <Button
                                        size='sm'
                                        variant='outlined'
                                        onClick={() => handleReset()}
                                        sx={{
                                            padding: '6px 8px',
                                            borderRadius: '8px',
                                            color: '#EAEAEA',
                                            gap: '4px',
                                            marginInlineStart: '16px',
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }}
                                    >
                                        <Icon
                                            svgIconProps={{
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                            iconName={'refresh'}
                                        />
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito',
                                                marginTop: '2px !important',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: '#EAEAEA',
                                            }}
                                        >
                                            Reset
                                        </Typography>
                                    </Button>
                                </div>
                            </Stack>
                        </Box>
                        :
                        <></>
                }
            </>
        )
    }

    AddButton(Add, name, isDisabled, isHidden, isForm = false) {
        return (
            <ButtonTemplate
                Func={Add}
                Name={`ADD ${name !== undefined && name !== '' ? name.toUpperCase() : ''}`}
                IconName={'add'}
                isHidden={isHidden}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#6692DE' : '#96B9FF'}
                TextColor={isForm ? '#FFF' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    EditButton(Edit, name, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={Edit}
                Name={`EDIT ${name !== undefined && name !== '' ? name.toUpperCase() : ''}`}
                IconName={'edit'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    DeleteButton(Delete, name, isDisabled, isHidden = false, isForm = false) {
        return (
            <ButtonTemplate
                Func={Delete}
                Name={`DELETE ${name !== undefined && name !== '' ? name.toUpperCase() : ''}`}
                IconName={'delete'}
                isHidden={isHidden}
                isDisabled={isDisabled}
                ButtonColor={isForm ? 'transparent' : '#96B9FF'}
                TextColor={isForm ? '#BE2957' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    UnDeleteButton(UnDelete, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={UnDelete}
                Name={`UN DELETE ${name !== undefined && name !== '' ? name.toUpperCase() : ''}`}
                IconName={'delete'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ReloadButton(ReloadData, isForm = false) {
        return (
            <ButtonTemplate
                Func={ReloadData}
                Name={'RELOAD DATA'}
                IconName={'convert'}
                ButtonColor={'#96B9FF'}
                TextColor={'#00286D'}
                isForm={isForm}
            />
        )
    }

    FindButton(FindData, name = 'Find', isForm = false) {
        return (
            <ButtonTemplate
                Func={FindData}
                Name={name !== undefined && name !== '' ? name.toUpperCase() : ''}
                IconName={'search'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    SaveButton(Save, isDisabled, isHidden, isForm = false, name = '', isNewName = false) {
        return (
            <ButtonTemplate
                Func={Save}
                Name={isNewName === false ? `SAVE ${name !== undefined && name !== '' ? name.toUpperCase() : ''}` : name !== undefined && name !== '' ? name.toUpperCase() : ''}
                IconName={'save'}
                isHidden={isHidden}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#0087C2' : '#96B9FF'}
                TextColor={isForm ? '#E4E8EC' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    CloseButton(Cancel) {
        return (
            <ButtonTemplate
                Func={Cancel}
                Name={`CANCEL`}
                IconName={'close'}
                ButtonColor={'#D20C0C'}
                TextColor={'#D20C0C'}
                isForm={true}
            />
        )
    }

    BackButton(Back, isForm = false) {
        return (
            <ButtonTemplate
                Func={Back}
                Name={`BACK`}
                IconName={'return'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    PrintButton(Print, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Print}
                Name={`PRINT ${name !== undefined && name !== '' ? name.toUpperCase() : ''}`}
                IconName={'printer'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ApproveButton(Approve, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Approve}
                Name={name !== undefined && name !== '' ? name.toUpperCase() : ''}
                IconName={'approve-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    UnApproveButton(UnApprove, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={UnApprove}
                Name={name !== undefined && name !== '' ? name.toUpperCase() : ''}
                IconName={'unApprove-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    CloseOpenJobButton(CloseJob, name, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={CloseJob}
                Name={`${name !== undefined && name !== '' ? name.toUpperCase() : ''} JOB`}
                IconName={'briefcase'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    SelectButton(Select, isForm = false) {
        return (
            <ButtonTemplate
                Func={Select}
                Name={`SELECT`}
                IconName={'select'}
                ButtonColor={isForm ? '#0087C2' : '#96B9FF'}
                TextColor={isForm ? '#E4E8EC' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    CancelButton(Cancel, isForm = false) {
        return (
            <ButtonTemplate
                Func={Cancel}
                Name={`CANCEL`}
                IconName={'cancel'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    SubmitButton(Submit, isForm = false) {
        return (
            <ButtonTemplate
                Func={Submit}
                Name={`SUBMIT`}
                IconName={'submit-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    UnApprovalButton(UnApprove, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={UnApprove}
                Name={name !== undefined && name !== '' ? name.toUpperCase() : 'UN-APPROVAL'}
                IconName={'reject'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    UnVerifyButton(UnVerify, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={UnVerify}
                Name={name !== undefined && name !== '' ? name.toUpperCase() : ''}
                IconName={'reject'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ReprintButton(RePrint, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={RePrint}
                Name={`REPRINT ${name !== undefined ? name.toUpperCase() : ''}`}
                IconName={'print-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    CopyButton(CopyData, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={CopyData}
                Name={`COPY ${name !== undefined ? name.toUpperCase() : ''}`}
                IconName={'copy'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    PPH23Button(PPh23, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={PPh23}
                Name={`PPh23`}
                IconName={'tax'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ResetButton(Reset, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={Reset}
                Name={`RESET`}
                IconName={'refresh'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    DeliveryButton(Delivery, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Delivery}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'delivery'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    JournalButton(Journal, isForm = false) {
        return (
            <ButtonTemplate
                Func={Journal}
                Name={`JOURNAL`}
                IconName={'journal'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ChangeButton(Change, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Change}
                Name={`CHANGE ${name !== undefined ? name.toUpperCase() : ''}`}
                IconName={'change'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    AddTimeButton(AddTime, name, isHidden, isForm = false) {
        return (
            <ButtonTemplate
                Func={AddTime}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'add-time'}
                isHidden={isHidden}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    IgnoreButton(Ignore, isForm = false) {
        return (
            <ButtonTemplate
                Func={Ignore}
                Name={`IGNORE`}
                IconName={'ignore-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    DemurrageButton(Demurrage, isHidden, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={Demurrage}
                Name={`DEMURRAGE`}
                IconName={'dollar-circle'}
                isHidden={isHidden}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    OpenButton(Open, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Open}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'folder-open'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ExcelButton(PrintExcel, name, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={PrintExcel}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'excel'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    DetailButton(Detail, name, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={Detail}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'detail'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    MemorialButton(Send, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Send}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'add'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    AdjustButton(Adjust, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Adjust}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'adjust'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    AssessmentButton(Assessment, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Assessment}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'add'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    FilterButton(Filter, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Filter}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'filter'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    UpdateSubOrderButton(Update, name, isHidden, isForm = false) {
        return (
            <ButtonTemplate
                Func={Update}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'add'}
                isHidden={isHidden}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    FinishButton(Finish, isForm = false) {
        return (
            <ButtonTemplate
                Func={Finish}
                Name={`FINISH`}
                IconName={'finish'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ConfigButton(Config, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Config}
                Name={`CONFIG ${name !== undefined ? name.toUpperCase() : ''}`}
                IconName={'config'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ArchiveButton(Archive, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Archive}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'archive'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    RemoveButton(Remove, isForm = false) {
        return (
            <ButtonTemplate
                Func={Remove}
                Name={`REMOVE`}
                IconName={'remove'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    InsertButton(Insert, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Insert}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'insert'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    SettingsButton(Settings, isForm = false) {
        return (
            <ButtonTemplate
                Func={Settings}
                Name={`SETTINGS`}
                IconName={'config'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    RestoreButton(Restore, name, isHidden, isForm = false) {
        return (
            <ButtonTemplate
                Func={Restore}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'restore'}
                isHidden={isHidden}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    UploadButton(Upload, name = 'Upload', isForm = false) {
        return (
            <ButtonTemplate
                Func={Upload}
                Name={name}
                IconName={'upload'}
                isHidden={false}
                isDisabled={false}
                ButtonColor={isForm ? '#6692DE' : '#96B9FF'}
                TextColor={isForm ? '#FFF' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    DownloadButton(Download, name = 'Download', isForm = false) {
        return (
            <ButtonTemplate
                Func={Download}
                Name={name}
                IconName={'download'}
                isHidden={false}
                isDisabled={false}
                ButtonColor={'#96B9FF'}
                TextColor={'#00286D'}
                isForm={isForm}
            />
        )
    }

    ConvertButton(Convert, isDisabled = false, isHidden = false, isForm = false) {
        return (
            <ButtonTemplate
                Func={Convert}
                Name={`CONVERT`}
                IconName={'add'}
                isHidden={isHidden}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ProcessButton(Process, isDisabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={Process}
                Name={`PROCESS`}
                IconName={'convert'}
                isDisabled={isDisabled}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    PutAwayButton(PutAway, isForm = false) {
        return (
            <ButtonTemplate
                Func={PutAway}
                Name={`PUT AWAY`}
                IconName={'add'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    PickingButton(Picking, isForm = false) {
        return (
            <ButtonTemplate
                Func={Picking}
                Name={`PICKING`}
                IconName={'hand'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    ViewButton(View, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={View}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'search-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    DeclineButton(Decline, name, isForm = false) {
        return (
            <ButtonTemplate
                Func={Decline}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={'decline-doc'}
                ButtonColor={isForm ? '#B3E3FF' : '#96B9FF'}
                TextColor={isForm ? '#0087C2' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    CustomButton(action, icon = '', name, disabled, isForm = false) {
        return (
            <ButtonTemplate
                Func={action}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={icon}
                isDisabled={disabled}
                ButtonColor={isForm ? '#6692DE' : '#96B9FF'}
                TextColor={isForm ? '#FFF' : '#00286D'}
                isForm={isForm}
            />
        )
    }

    OpenCollapseButton(isOpen, setIsOpen, name, isDisabled) {
        return (
            <ButtonTemplate
                Func={() => setIsOpen(!isOpen)}
                Name={name !== undefined ? name.toUpperCase() : ''}
                IconName={isOpen ? 'arrow-up' : 'arrow-down'}
                isDisabled={isDisabled}
                ButtonColor={'#96B9FF'}
                TextColor={'#00286D'}
            />
        )
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ButtonComponent()