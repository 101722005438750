import { Backdrop, Box, Grid, Card, CardContent } from '@mui/material'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react'
import { useState } from 'react';
import ApiService, { companyName } from '../../../../services/ApiService'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner'
import ButtonBalanceSheet from './Buttons/ButtonBalanceSheet'
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation'
import HeaderInfo from '../../../../components/Components/HeaderInfo'
import CustomToastContainer, { CustomDatePicker, Input, showToast } from '../../../../components/ui'
import { GetReportVersionLog, PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';
import ReportVersionLog from '../../../../components/Modals/ReportVersionLog';
import { getDefaultDateFormat } from '../../../../utils/date';
import { useNavigate } from 'react-router-dom';

const BalanceSheet = forwardRef((props, ref) => {
    const menuCode = 'RBSHT';
    const history = useNavigate();
    const [period, setPeriod] = useState(new Date());
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const labelPeriod = "Period";

    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isModalLog, setIsModalLog] = useState(false);
    const [fullDataLog, setFullDataLog] = useState([]);
    const [dataLog, setDataLog] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Balance Sheet (Standard) Report', link: '/Majura/BalanceSheetReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {
            initializeView();
        }
    }));

    useEffect(() => {
        document.title = 'Balance Sheet Report - ' + JSON.parse(localStorage.getItem('branchName'));
        initializeView();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingPrint(true);

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === menuCode)
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                {
                    attr: "permissionId",
                    value: "" + pId
                },
                {
                    attr: "userId",
                    value: "" + JSON.parse(localStorage.getItem("userId"))
                },
                {
                    attr: "roleId",
                    value: "" + JSON.parse(localStorage.getItem("roleId"))
                }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        setIsLoadingPrint(false);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        setIsLoadingPrint(false);
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history('/Majura');
                });
        }
        else {
            history('/Majura');
        }
    };

    function initializeView() {
        setIsLoadingPrint(true);
        getDefaultMonth();
        getDataAccess();
    }

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRC() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport(false, "pdf");
        }
        else if (titleConfirmation === 'Print PDF Explanation') {
            PrintReport(true, "pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport(false, "xls")
        }
        else if (titleConfirmation === 'Print Excel Explanation') {
            PrintReport(true, "xls")
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintDataExplanation = () => {
        setTitleConfirmation('Print PDF Explanation')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintExcelData = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintExcelExplanation = () => {
        setTitleConfirmation('Print Excel Explanation')
        setTitleModalConfirmation('Balance Sheet Report');
        handleClickConfirmation();
    };

    const PrintReport = (type, code) => {
        setIsLoadingPrint(true);
        const date = new Date(period).getMonth();
        const month = date + 1;
        const year = new Date(period).getFullYear();
        let params = [
            {
                "attr": "period",
                "value": "" + month
            },
            {
                "attr": "yearPeriod",
                "value": "" + year
            },
            {
                "attr": "isExplanation",
                "value": "" + type
            }
        ]

        let reportLink = 'Reports/Finance/Print/FinanceReport/BalanceSheetReport/BalanceSheetReport/PrintBalanceSheetStandard?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(setIsLoadingPrint, 'Balance Sheet (Standard)', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'Balance Sheet (Standard)', reportLink, params);
        }
    };

    const handleClickLog = useCallback(() => {
        if (isModalLog === false) {
            setIsModalLog(true);
        }
        else {
            setIsModalLog(false);
        }
    }, [isModalLog]);

    const handleDataLog = useCallback((isPrint = true, date = new Date(), isResume = false) => {
        setIsLoadingPrint(true);
        const today = new Date(date);
        const month = today.getMonth();
        const year = today.getFullYear();
        const startDate = getDefaultDateFormat(new Date(year, month, 1));
        const endDate = getDefaultDateFormat(new Date(year, month + 1, 0));

        const params = [
            { "attr": "reportCode", "value": menuCode },
            ...(
                JSON.parse(isPrint) ?
                    [
                        { "attr": "printedOn, printedOn", "mathSign": ">=, <=", "value": `${startDate}, ${endDate}` }
                    ]
                    : [
                        { "attr": "period", "value": '' + (parseInt(month) + parseInt(1)) },
                        { "attr": "yearPeriod", "value": '' + year }
                    ]
            )
        ];

        GetReportVersionLog(setIsLoadingPrint, menuCode, params, handleFullDataLog, !isResume ? handleClickLog : () => { });
    }, [handleClickLog]);

    const handleFullDataLog = (data) => {
        setFullDataLog(data);
        setDataLog(data);
    }

    const ButtonComponents = () => {
        return (
            <ButtonBalanceSheet
                cetak={PrintData}
                excel={PrintExcelData}
                cetak2={PrintDataExplanation}
                excel2={PrintExcelExplanation}
                handleClickLog={() => handleDataLog()}
                dataRegUserAccess={dataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Balance Sheet (Standard) Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Input
                                            label="Company"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={companyName || ''}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                            disabled
                                        />

                                        <CustomDatePicker
                                            label={labelPeriod}
                                            name="period"
                                            value={period}
                                            onChange={setPeriod}
                                            type={'month'}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important',
                                                }
                                            }}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRC}
                    />
                )
            }

            {
                isModalLog && (
                    <ReportVersionLog
                        data={dataLog}
                        setData={setDataLog}
                        isModal={isModalLog}
                        fullData={fullDataLog}
                        handleClick={handleClickLog}
                        handleDataLog={handleDataLog}
                        title={'Balance Sheet (Standard)'}
                        setIsLoading={setIsLoadingPrint}
                        reportLink={'Reports/Finance/Print/FinanceReport/BalanceSheetReport/BalanceSheetReport/PrintBalanceSheetStandard?typeReport=pdf'}
                    />
                )
            }

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => 10000 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
}
)
export default BalanceSheet