import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { Icon } from "../ui";
import { memo } from "react";

const FormInfo = memo(({
    icon = 'approve-doc',
    children = null,
    isTop = false,
}) => {
    return (
        <div style={{ marginTop: isTop ? '0px' : '24px', borderTop: isTop ? '' : '1px solid #EDEDEE', width: '100%' }}>
            <Stack direction={'row'} spacing={'16px'} sx={{ marginTop: isTop ? '0px' : '24px', width: '100%' }}>
                <Icon
                    iconName={icon}
                    svgIconProps={{
                        htmlColor: '#96B9FF',
                        sx: {
                            width: '24px',
                            height: '24px',
                        }
                    }}
                />

                {
                    children && (
                        <>
                            {children}
                        </>
                    )
                }
            </Stack>
        </div>
    )
})

FormInfo.propTypes = {
    children: PropTypes.node,
}

export default memo(FormInfo);