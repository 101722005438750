import Icon from '../Icon/Icon';
import { getDefaultDateFormat } from '../../../utils/date';
import {
    LocalizationProvider,
    PickersDay,
    // PickersDay 
} from '@mui/x-date-pickers';
import React, { forwardRef, memo, useRef, useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    alpha, FormControl, FormHelperText, FormLabel, IconButton, InputAdornment, InputBase, styled,
    // ThemeProvider, createTheme
} from '@mui/material';
import { isSaturday, isSunday } from 'date-fns';

const CustomInputBase = styled(InputBase)(({ theme, align }) => ({
    border: '1.5px solid',
    borderColor: '#989898',
    borderRadius: '8px',
    fontSize: 12,
    backgroundColor: 'transparent',
    display: 'flex',
    minHeight: '32px',
    padding: '0px 12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    background: 'transparent',
    marginTop: '3px !important',
    transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
    ]),
    '&.Mui-focused': {
        boxShadow: `${alpha('#989898', 0.25)} 0 0 0 0.2rem`,
        borderColor: '#989898',
    },
    '&.Mui-error': {
        borderColor: theme.palette.error.main,
        '&.Mui-focused': {
            boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.error.main,
        }
    },
    '&.Mui-readOnly': {
        cursor: 'default',
    },
    '&.Mui-disabled': {
        backgroundColor: '#EAEAEA',
        cursor: 'not-allowed',
    },
    'label + &': {
        marginTop: theme.spacing(1),
    },
    '& .MuiInputBase-input': {
        padding: 0,
        textAlign: align,
        cursor: 'inherit',
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.575) !important',
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.575) !important'
        },
    },
}));

const CustomInputBaseWithRef = forwardRef((props, ref) => {
    return <CustomInputBase {...props} inputRef={ref} />;
});

const handleKeyDown = (e) => {
    e.preventDefault();
};

const CustomDatePicker = memo(({
    id,
    label,
    error,
    disabled,
    hidden,
    value,
    type = 'date',
    onChange,
    required,
    message,
    textAlign = 'left',
    formControlProps,
    formLabelProps,
    inputBaseProps,
    customDateProps,
}) => {
    // const newTheme = (theme) => createTheme({
    //     ...theme,
    //     components: {
    //         MuiPickersDay: {
    //             styleOverrides: {
    //                 root: {
    //                     color: '#000000',
    //                 }
    //             }
    //         }
    //     }
    // })

    const inputRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    }

    const WeekendMarkerDay = (props) => {
        const isSaturdayHighlighted = isSaturday(props.day) || isSunday(props.day);
        const matchedStyles = isSaturdayHighlighted ? { color: "#BE2957" } : {};
        return <PickersDay {...props} sx={{ ...matchedStyles }} />;
    };

    return (
        <FormControl
            error={error}
            ref={inputRef}
            hidden={hidden}
            disabled={disabled}
            required={required}
            variant={"standard"}
            {...formControlProps}
        >
            {/* CUSTOM FORM LABEL */}
            {label && (
                <FormLabel
                    htmlFor={formLabelProps?.htmlFor || id || ''}
                    {...formLabelProps}
                    sx={{
                        fontFamily: 'Nunito',
                        fontSize: '14px',
                        fontWeight: '600',
                        color: '#083A50 !important',
                        textAlign: textAlign,
                        ...formLabelProps?.sx,
                    }}
                >
                    {label}
                </FormLabel>
            )}

            {/* CUSTOM DATEPICKER */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/* <ThemeProvider theme={newTheme}> */}
                <DatePicker
                    id={id}
                    open={open}
                    disabled={disabled}
                    yearsOrder={'desc'}
                    {...customDateProps}
                    value={new Date(value)}
                    onKeyDown={handleKeyDown}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={(newValue) => { onChange(getDefaultDateFormat(newValue)) }}
                    views={type === 'month' ? ['month', 'year'] : type === 'year' ? ['year'] : undefined}
                    slotProps={{
                        popper: {
                            anchorEl: inputRef.current,
                            placement: 'bottom-start',
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, 8],
                                    },
                                },
                                {
                                    name: 'preventOverflow',
                                    options: {
                                        altAxis: true,
                                    },
                                },
                            ],
                        },
                        textField: {
                            error: false,
                            placeholder: '',
                        },
                        layout: {
                            sx: {
                                '& .MuiDayCalendar-weekDayLabel:nth-of-type(1), & .MuiDayCalendar-weekDayLabel:nth-of-type(7)': {
                                    color: "#BE2957",
                                },
                            },
                        },
                    }}
                    slots={{
                        day: WeekendMarkerDay,
                        textField: (params) =>
                            <CustomInputBaseWithRef
                                {...params}
                                readOnly={true}
                                {...inputBaseProps}
                                disabled={disabled}
                                onKeyDown={handleKeyDown}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            hidden={disabled}
                                            onClick={handleClick}
                                        >
                                            <Icon
                                                iconName={'calendar'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#00286D',
                                                    sx: {
                                                        height: '14px',
                                                        width: '14px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />,
                    }}
                />
                {/* </ThemeProvider> */}
            </LocalizationProvider>

            {message && (
                <FormHelperText error={error}>
                    {message}
                </FormHelperText>
            )}
        </FormControl>
    );
});

export default memo(CustomDatePicker);