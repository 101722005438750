import { Box, Typography, Grid, Button, Stack } from '@mui/material';
import React, { memo, useCallback, useRef, useState } from 'react';
import { PopUp, showToast } from '../../../../components/ui';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../Functions/CropImage';
import noImagePreview from '../../../../Assets/Background/noImagePreview.png';
import ApiService from '../../../../services/ApiService';

const ModalChangePhoto = memo(({ isModal, handleClick, setIsLoadingBackDrop, setPhotoProfile }) => {
    const [image, setImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [imageName, setImageName] = useState('');
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!file.type.startsWith("image/")) {
                showToast({ type: 'error', message: 'Invalid File Type. Please Upload Only Image Files!' });
                return;
            }

            processFile(file);
        }
    };

    const processFile = (file) => {
        let url = URL.createObjectURL(file);
        setImageName(file.name);
        setImage(url);
    };

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    function base64ToFile(base64String, fileName) {
        const arr = base64String.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const byteString = atob(arr[1]);

        let byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }

        return new File([byteArray], fileName, { type: mime });
    }

    const showCroppedImage = async () => {
        if (!image) return showToast({ type: 'error', message: 'Please Select an Image First!' });

        try {
            setIsLoadingBackDrop(true);
            const croppedImage = await getCroppedImg(
                image,
                croppedAreaPixels,
            )
            setImage(croppedImage)

            const file = base64ToFile(croppedImage, imageName);
            const formFile = new FormData();
            formFile.append("file", file);

            ApiService.IdentityUploadFile('ProfilePicture/ProfilePicture/Upload', formFile)
                .then((response) => {
                    if (response.data.code === 200) {
                        setIsLoadingBackDrop(false);
                        setPhotoProfile(croppedImage);
                        localStorage.setItem("profilePicture", croppedImage);
                        window.location.reload();
                        showToast({ type: 'success', message: 'Photo Updated Successfully!' });
                        handleClick();
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Update Photo, Please Contact Administrator, ' + response.data.message });
                    }
                })
                .catch((error) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Failed to Update Photo, Please Contact Administrator, ' + error.toString() });
                });

            setCrop({ x: 0, y: 0 });
            setZoom(1);
        } catch (e) {
            setIsLoadingBackDrop(false);
            console.error(e)
        }
    }

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();

        const file = event.dataTransfer.files[0];

        if (file) {
            if (!file.type.startsWith("image/")) {
                showToast({ type: 'error', message: 'Invalid File Type. Please Upload Only Image Files!' });
                return;
            }

            processFile(file);
        }
    }, []);


    const handleDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <>
            <PopUp
                id='modal-id'
                title={'Photo Preview'}
                isOpen={isModal}
                onClose={handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'md'}
                fullWidth={true}
                topButton={false}
            >
                <Box
                    display="flex"
                    sx={{
                        marginTop: '24px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%'
                    }}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    {
                        image && (
                            <Stack direction={'column'} spacing={'16px'} sx={{ width: '100%' }}>
                                <div
                                    style={{
                                        position: "relative",
                                        width: "100%",
                                        height: "500px",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: '12px',
                                        border: '1px solid var(--existing-Disable-Grey-1, #EAEAEA)',
                                    }}
                                >
                                    <Cropper
                                        image={image}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={4 / 4}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                </div>

                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-black-2, #747475)',
                                        'font-feature-settings': 'liga off, clig off',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Use the mouse scroll to zoom in and out of the picture
                                </Typography>
                            </Stack>
                        )
                    }

                    {
                        !image && (
                            <div
                                style={{
                                    display: 'flex',
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    background: '#FAFAFA',
                                    borderRadius: '12px',
                                    border: '1px solid var(--existing-Disable-Grey-1, #EAEAEA)',
                                    userSelect: 'none',
                                }}
                            >
                                <img
                                    src={noImagePreview}
                                    alt="Preview"
                                    draggable={false}
                                    style={{ width: "500px", height: "auto", justifyContent: "center", alignItems: "center", userSelect: "none" }}
                                />
                            </div>
                        )
                    }
                </Box>

                <Box display="flex" alignItems="center" sx={{ marginTop: '32px' }}>
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    marginInlineStart: '12px',
                                    height: '28px !important',
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '6px',
                                    borderRadius: '12px',
                                    background: 'var(--Mjr-Random-Dark, #6692DE)',
                                    '&:hover': {
                                        backgroundColor: 'var(--Blue-Button-Primary-1, #6692DE) !important',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={showCroppedImage}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-white-4, #FFF)',
                                        textAlign: 'center',
                                        fontFamily: 'Switzer',
                                        fontSize: '8px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '150%',
                                        letterSpacing: '0.16px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Update
                                </Typography>
                            </Button>

                            <div>
                                <Button
                                    size="small"
                                    variant="outlined"
                                    className="d-flex align-items-center"
                                    sx={{
                                        marginInlineStart: '12px',
                                        height: '28px !important',
                                        display: 'flex',
                                        padding: '8px 16px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '6px',
                                        borderRadius: '12px',
                                        "&:hover": {
                                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.20)",
                                        },
                                    }}
                                    onClick={handleButtonClick}
                                >
                                    <Typography
                                        sx={{
                                            color: 'var(--Mjr-white-4, #6692DE)',
                                            textAlign: 'center',
                                            fontFamily: 'Switzer',
                                            fontSize: '8px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '150%',
                                            letterSpacing: '0.16px',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Change Photo
                                    </Typography>
                                </Button>

                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    onChange={handleImageChange}
                                    style={{ display: "none" }}
                                />
                            </div>

                            <Button
                                size='small'
                                variant="text"
                                className='d-flex align-items-center'
                                sx={{
                                    marginInlineStart: '12px',
                                    height: '28px !important',
                                    display: 'flex',
                                    padding: '8px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '6px',
                                    borderRadius: '12px',
                                }}
                                onClick={handleClick}
                            >
                                <Typography
                                    sx={{
                                        color: 'var(--Mjr-white-4, #6692DE)',
                                        textAlign: 'center',
                                        fontFamily: 'Switzer',
                                        fontSize: '8px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '150%',
                                        letterSpacing: '0.16px',
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </PopUp>
        </>
    )
})

export default memo(ModalChangePhoto);