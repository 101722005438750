import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { MenuItem, Grid, Card, CardContent, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import FakturPajakHeader from './Components/FakturPajakHeader';
import FakturPajakDetail from './Components/FakturPajakDetail';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ButtonCreateEditFakturPajak from './Buttons/ButtonCreateEditFakturPajak';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../services/ApiService';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../components/ui';
import { setDateFormat } from '../../../utils/date';

const CreateEditFakturPajak = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataFP"));

    const { sJobTypeId } = useParams();
    const { fpId } = useParams();

    const [check, setCheck] = useState(0);

    const paramFakturPajak = 'FakturPajak/FakturPajak';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState([]);
    const [typeJobId, setTypeJobId] = useState('1');

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const paramVAT = 'vat/vat';
    const [VATData, setVATData] = useState([]);

    const paramCountry = 'country/country';
    const [currencyData, setCurrencyData] = useState([]);

    const history = useNavigate();

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    //PaymentRequest Variable
    const [vatValue, setVatValue] = useState(11);
    const [invoiceId, setInvoiceId] = useState(0);
    const [invoiceNo, setInvoiceNo] = useState('');
    const [shipmentId, setShipmentId] = useState(0);
    const [shipmentNo, setShipmentNo] = useState('');
    const [jobOwnerId, setJobOwnerId] = useState(0);
    const [customerId, setCustomerId] = useState(0);
    const [customerName, setCustomerName] = useState('');
    const [customerAddress, setCustomerAddress] = useState('');
    const [fakturDate, setFakturDate] = useState('');
    const [seriFPS1, setSeriFPS1] = useState('');
    const [seriFPS2, setSeriFPS2] = useState('');
    const [NPWPData, setNPWPData] = useState('');
    const [NPPPKPData, setNPPPKPData] = useState('-');
    const [currencyType, setCurrencyType] = useState('USD');
    const [currency, setCurrency] = useState('IDR');
    const [kursValue, setKursValue] = useState(0);
    const [isIDRCurrency, setIsIDRCurrency] = useState(false);
    const [invoiceDetailList, setInvoiceDetailList] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [documentStatus, setDocumentStatus] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Faktur Pajak', link: '/Majura/FakturPajak', style: '' },
        { label: 'Create Faktur Pajak', link: '/Majura/FakturPajak/Create/' + sJobTypeId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Faktur Pajak', link: '/Majura/FakturPajak', style: '' },
        { label: 'Detail Faktur Pajak', link: '/Majura/FakturPajak/' + sJobTypeId + '/' + fpId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        var data = {
            "FakturPajak": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "idLama": 0,
            },
        };

        localStorage.setItem(username + "-DataFP", JSON.stringify(data));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal();
        }
    }));

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }


    useEffect(() => {
        document.title = 'Faktur - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataLocal = () => {
        let dataDetailPR = dataLocal.FakturPajakDetails;

        calculateTotal(dataDetailPR);
        handleDocumentStatus();

        localStorage.removeItem(username + '-DataFP');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id, typeJob) => {
        let dataPRDetail = [];
        ApiService.OperationPostById(paramFakturPajak, id)
            .then((response) => {
                if (response.data.code === 200) {

                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .then(() => {
                calculateTotal(dataPRDetail);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OPARE')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        for (let i = 0; i < tempArray.length; i++) {
                            if (fpId) {
                                if (tempArray[i].accessCode === 'UPD' && tempArray[i].hidden === false) {
                                    checked = 1;
                                    break;
                                }
                            }
                            else {
                                if (tempArray[i].accessCode === 'CRE') {
                                    checked = 1;
                                    break;
                                }
                            }
                        };

                        setCheck(checked);

                        if (hasAccess) {
                            handleDocumentStatus();
                            GetJobOwnerList(1, 20);
                        }
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false)
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobType = (id) => {
        let typeJob = '';
        let dataId = '';
        if (id === undefined) {
            dataId = sJobTypeId;
        }
        else {
            dataId = id;
        }
        setTypeJobId(dataId);

        ApiService.PostById(paramRegJobType, dataId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    typeJob = response.data.data.jobType;

                    getDateNow();
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .then(() => {
                if (fpId) {
                    getData(fpId, typeJob);
                }
                else {
                    if (!dataLocal) {
                        setIsLoadingBackDrop(false);
                    }
                }

                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    function checkExportImport(jobType) {
        if (jobType.portType === 'SEA') {

        }
        else if (jobType.portType === 'AIR') {

        }
        else {

        }

        if (jobType.serviceType === 'Export') {

        }
        else if (jobType.serviceType === 'Import') {

        }
        else {

        }
    }

    const GetExRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {

                    GetCurrencyFormat();
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const GetCurrencyFormat = () => {
        GetVATData(1, 20);
    }

    //Function for Principle By
    const GetJobOwnerList = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobOwnerList(response.data.data.jobOwner);

                    GetExRate();
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectPrincipleBy = (PrincipleBy, setPrincipleBy) => (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={PrincipleBy}
                onChange={(e) => setPrincipleBy(e.target.value)}
                disabled
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for VAT
    const GetVATData = (pageNumber, pageSize) => {
        let arrayData = [];
        ApiService.PostByPage(paramVAT, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    for (let i = 0; i < response.data.data.vat.length; i++) {
                        arrayData = [...arrayData, response.data.data.vat[i]];
                    }
                    setVATData(arrayData);

                    GetCurrencyData(1, 1000);
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectVATData = (Vat, setVat, isDisabled, title) => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={Vat}
                onChange={(e) => setVat(e.target.value)}
                disabled={isDisabled}
            >
                {
                    VATData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.value}%</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for Currency
    const GetCurrencyData = (pageNumber, pageSize) => {
        let arrayData = [];
        ApiService.CurrencyPostBySearch(paramCountry, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    let tempArr = response.data.data.country;
                    tempArr.forEach(element => {
                        if (element.currency !== 'IDR') {
                            arrayData = [...arrayData, element];
                        }
                    });
                    setCurrencyData(arrayData);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        GetJobType();
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectCurrency = (curData, setCurData, isDisabled, title) => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={curData}
                onChange={(e) => setCurData(e.target.value)}
                disabled={isDisabled}
            >
                {
                    currencyData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.currency}>{data.currency}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationPR() {
        if (titleConfirmation === 'Print') {
            PrintPDF();
        }
        setIsModalConfirmation(false);
    }

    function Save() {
        if (isLoadingBackDrop) return
        setIsLoadingBackDrop(true);

        let id = 0;

        var data = {
            "FakturPajak": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "idLama": 0,
            },
        };

        ApiService.HelpCreateData(data, paramFakturPajak, id)
            .then((res) => {
                if (res.data.code === 200) {
                    let message = '';
                    if (id === 0) {
                        ApiService.CreateOperation(paramFakturPajak, data).then((res) => {
                            if (res.data.code === 200) {
                                setIsLoadingBackDrop(false);

                                message = 'Create Data Success'
                                showToast({ type: 'success', message: message });

                                setTimeout(
                                    function () {
                                        localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                        history('/Majura/PaymentRequest/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")));
                                        window.location.reload();
                                        localStorage.removeItem('tempId');
                                    },
                                    1250);
                            }
                            else {
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'error', message: 'Error When Create Data, Please Contact The Administrator, ' + res.data.message });
                            }
                        });
                    }
                    else {
                        ApiService.EditOperation(paramFakturPajak, data).then((res) => {
                            if (res.data.code === 200) {
                                setIsLoadingBackDrop(false);

                                message = 'Update Data Success';
                                showToast({ type: 'success', message: message });

                                setTimeout(
                                    function () {
                                        history('/Majura/PaymentRequest/' + sJobTypeId + '/' + fpId);
                                        window.location.reload();
                                    },
                                    1250);
                            }
                            else {
                                setIsLoadingBackDrop(false);

                                message = 'Updated Data Failed';
                                showToast({ type: 'error', message: 'Error When Create Data, Please Contact The Administrator, ' + res.data.message });
                            }
                        });
                    }
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Error When Create Data, Please Contact The Administrator, ' + res.data.message });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Create Data, Please Contact The Administrator, ' + error });
            })
    }

    function getDateNow() {
        setDateFormat(new Date(), setFakturDate);
    };

    function Back() {
        history('/Majura/FakturPajak');
    }

    function PrintData() {
        if (fpId) {
            setTitleConfirmation('Print')
            setTitleModalConfirmation('Faktur Pajak');
            handleClickConfirmation();
        }
        else {
            showToast({ type: 'error', message: 'Create The Data First!' });
        }
    }

    const PrintPDF = () => {
        setIsLoadingBackDrop(true);
        let variable = 'PrintPR?Id=' + fpId;
        ApiService.ReportPrint('operation/FakturPajakReport/', variable)
            .then(response => {
                if (response.status === 200) {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' }
                    );

                    const fileURL = URL.createObjectURL(file);

                    setIsLoadingBackDrop(false);
                    showToast({ type: 'success', message: 'Print Faktur Pajak Success' });
                    window.open(fileURL);
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Can\'t Print Faktur Pajak, ' + response.data.message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Print Faktur Pajak, ' + error });
            });
    }

    function calculateTotal(result) {

    }

    return (
        <Box sx={{ backgroundColor: '#FAFAFA', width: '100%', p: '24px 32px' }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={
                        fpId ?
                            'Faktur Pajak - ' + jobType.name + ' (' + jobType.name + ')'
                            :
                            'Faktur Pajak - ' + jobType.name + ' (New Document)'
                    }
                    BreadcrumbsItems={fpId ? BreadcrumbsEditItems : BreadcrumbsItems}
                    type={'Form'}
                    ticketId={0}
                    BackFunction={Back}
                    documentStatus={documentStatus}
                />

                <fieldset>
                    <Card component="div" style={{ marginTop: '32px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                        <CardContent>
                            <FakturPajakHeader
                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                fpId={fpId}
                                jobType={jobType}
                                typeJobId={typeJobId}
                                selectCurrency={selectCurrency}
                                selectPrincipleBy={selectPrincipleBy}
                                selectVATData={selectVATData}
                                calculateTotal={calculateTotal}

                                vatValue={vatValue}
                                setVatValue={setVatValue}
                                invoiceId={invoiceId}
                                setInvoiceId={setInvoiceId}
                                invoiceNo={invoiceNo}
                                setInvoiceNo={setInvoiceNo}
                                shipmentId={shipmentId}
                                setShipmentId={setShipmentId}
                                shipmentNo={shipmentNo}
                                setShipmentNo={setShipmentNo}
                                jobOwnerId={jobOwnerId}
                                setJobOwnerId={setJobOwnerId}
                                customerId={customerId}
                                setCustomerId={setCustomerId}
                                customerName={customerName}
                                setCustomerName={setCustomerName}
                                customerAddress={customerAddress}
                                setCustomerAddress={setCustomerAddress}
                                fakturDate={fakturDate}
                                setFakturDate={setFakturDate}
                                seriFPS1={seriFPS1}
                                setSeriFPS1={setSeriFPS1}
                                seriFPS2={seriFPS2}
                                setSeriFPS2={setSeriFPS2}
                                NPWPData={NPWPData}
                                setNPWPData={setNPWPData}
                                NPPPKPData={NPPPKPData}
                                setNPPPKPData={setNPPPKPData}
                                currencyType={currencyType}
                                setCurrencyType={setCurrencyType}
                                currency={currency}
                                setCurrency={setCurrency}
                                kursValue={kursValue}
                                setKursValue={setKursValue}
                                isIDRCurrency={isIDRCurrency}
                                setIsIDRCurrency={setIsIDRCurrency}
                                setInvoiceDetailList={setInvoiceDetailList}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '32px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                        <CardContent>
                            <FakturPajakDetail
                                invoiceDetailList={invoiceDetailList}
                                setInvoiceDetailList={setInvoiceDetailList}
                            />
                        </CardContent>
                    </Card>

                    <ButtonCreateEditFakturPajak
                        Save={Save}
                        Back={Back}
                        PrintPR={PrintData}
                        DataRegUserAccess={DataRegUserAccess}
                        check={check}
                    />
                </fieldset>
            </Grid>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationPR}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default CreateEditFakturPajak;