import { Grid, MenuItem } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Input, PopUp, Selection } from '../../../../../components/ui';

const ModalPPH23 = (props) => {
    const IncludePPH = [
        { id: 1, code: 1, name: 'Yes' },
        { id: 2, code: 0, name: 'No' },
    ];

    const handleClose = () => {
        props.setInclude(0);
        props.setAmount(0);
        props.setRemarks('');
        props.handleClick();
    };

    function handleInclude(val) {
        props.setInclude(val);
        if (val === 1) {
            props.setIsInclude(false);
            let countAmount = 0;
            countAmount = parseFloat(parseFloat(parseFloat(props.TotalUSD) * parseFloat(props.RateCount)) + parseFloat(props.TotalIDR)) * parseFloat(props.Tax) / parseFloat(100);
            props.setAmount(countAmount);
        }
        else {
            props.setIsInclude(true);
            props.setAmount(0);
            props.setRemarks('');
        }
    }

    const mainContainer = (
        <>
            <Input
                label="Invoice Number"
                value={props.InvoiceNo}
                disabled
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                inputBaseProps={{
                    sx: {
                        marginTop: '3px !important',
                        display: 'flex',
                        height: '41px',
                        padding: '0px 12px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignSelf: 'stretch',
                        borderRadius: '8px',
                        border: '1.5px solid var(--Grey-Outline-2, #989898)',
                        background: '#FFF',
                    },
                }}
                formLabelProps={{
                    sx: {
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontWeight: '700',
                        color: '#083A50 !important',
                        textAlign: 'left'
                    }
                }}
            />

            <div style={{ marginTop: '16px' }}>
                <Grid
                    container
                    spacing={'64px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Include PPh 23'}
                            fullWidth={true}
                            placeholder={'Select Include PPh 23'}
                            value={props.Include}
                            onChange={(e) => handleInclude(e.target.value)}
                            disabled={props.isViewOnly}
                        >
                            {
                                IncludePPH.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Amount"
                            textAlign='right'
                            fullWidth
                            value={props.Amount}
                            onChange={(e) => { props.setAmount(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                            disabled={props.isInclude}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '16px' }}>
                <Input
                    label={'Remarks'}
                    value={props.Remarks}
                    onChange={(e) => props.setRemarks(e.target.value)}
                    disabled={props.isInclude}
                    multiline
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        rows: 4,
                        sx: {
                            padding: '8px',
                        },
                    }}
                />
            </div>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(props.handleSave, false, false)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalPPH23;