import {
    FormHelperText, MenuItem,
    // Grid, IconButton, InputAdornment, Typography 
} from '@mui/material';
import React, {
    useImperativeHandle, forwardRef,
    // useState 
} from 'react';
import { userCode, username } from '../../../services/ApiService';
import ButtonService from '../ButtonService';

import { useFormik } from 'formik';
import * as Yup from 'yup'
import CreateEditModalMaster from '../CreateEditModalMaster';
import {
    Input, Selection, showToast,
    // Icon, TickBox 
} from '../../../components/ui';

const CreateEditUser = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props
    // const [isPassword, setIsPassword] = useState(false)

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "user": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    userCode,
                }
            }
            localStorage.setItem(username + "-DataUser", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const validationSchema = Yup.object().shape({
        code: Yup.string().trim().required('This field is required.'),
        name: Yup.string().trim().required('This field is required.'),
        email: Yup.string().trim().email('Please enter valid email.').required('This field is required.'),
        password: selectedData?.id ? Yup.string().trim().nullable() : Yup.string().trim().required('This field is required.'),
        department: Yup.string().trim().required('This field is required.'),
        title: Yup.string().trim().required('This field is required.'),
        apprAcc: Yup.boolean(),
        apprOpr: Yup.boolean(),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ? { ...selectedData } : {
            code: '',
            name: '',
            email: '',
            password: '',
            department: '',
            title: '',
            apprAcc: false,
            apprOpr: false,
        },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const Save = (payload) => {
        if (payload?.name === '') {
            return showToast({ type: 'error', message: 'Name is Required!' });
        }

        if (payload?.code === '') {
            return showToast({ type: 'error', message: 'Code is Required!' });
        }

        if (payload?.department === '') {
            return showToast({ type: 'error', message: 'Department is Required!' });
        }

        if (payload?.title === '') {
            return showToast({ type: 'error', message: 'Title is Required!' });
        }

        const data =
        {
            "user": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "code": payload?.code,
                "password": payload?.password,
                "name": payload?.name,
                "deleted": false,
                "companyId": payload?.companyId,
                "userTypeId": payload?.userTypeId || 0,
                "department": payload?.department,
                "title": payload?.title,
                "apprAcc": payload?.apprAcc,
                "apprOpr": payload?.apprOpr,
                "idLama": payload?.idLama || 0,
                "email": payload?.email,
                "user": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    // function handleChange(name, value) {
    //     formik.setFieldValue(name, !value);
    // }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Input
                name="code"
                label="Code"
                value={formik.values.code}
                onBlur={formik.handleBlur}
                error={formik.touched.code && Boolean(formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code}
                onChange={(e) => formik.setFieldValue('code', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
            />

            <Input
                name="name"
                label="Name"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                onChange={(e) => formik.setFieldValue('name', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            <Input
                name="email"
                label="Email"
                type={'email'}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            />

            {/* <Input
                name="password"
                label={'Password'}
                type={isPassword ? 'text' : 'password'}
                value={formik.values.password}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                onChange={(e) => formik.setFieldValue('password', e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setIsPassword(!isPassword)}
                                size='small'
                                sx={{ padding: '0px' }}
                            >
                                {
                                    isPassword ?
                                        <Icon
                                            iconName={'eye-on'}
                                            svgIconProps={{
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                        :
                                        <Icon
                                            iconName={'eye-off'}
                                            svgIconProps={{
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                }
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            /> */}

            <Selection
                id="department"
                name='department'
                fullWidth={true}
                label="Department"
                labelId="department"
                value={formik.values.department}
                placeholder={'Select Department'}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => formik.setFieldValue('department', e.target.value)}
                error={formik.touched.department && Boolean(formik.errors.department)}
            >
                <MenuItem sx={{ fontSize: '12px' }} key={0} value={'ACC'}>Accounting</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={1} value={'MKT'}>Marketing</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={2} value={'OPR'}>Operational</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={3} value={'CSV'}>Cust Service</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={4} value={'TAX'}>Tax</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={5} value={'EDP'}>EDP</MenuItem>
                <FormHelperText>{formik.touched.department && formik.errors.department}</FormHelperText>
            </Selection>

            <Selection
                id="title"
                name='title'
                fullWidth={true}
                label="Title"
                labelId="title"
                value={formik.values.title}
                placeholder={'Select Title'}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                onChange={(e) => formik.setFieldValue('title', e.target.value)}
                error={formik.touched.title && Boolean(formik.errors.title)}
            >
                <MenuItem sx={{ fontSize: '12px' }} key={1} value={'1'}>Director</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={2} value={'2'}>General Mgr</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={3} value={'3'}>Manager</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key={4} value={'4'}>Staff</MenuItem>
                <FormHelperText>{formik.touched.title && formik.errors.title}</FormHelperText>
            </Selection>

            {/* <Grid container spacing={'16px'}>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            size={'small'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Approve Acc
                                </Typography>
                            }
                            checked={formik.values.apprAcc}
                            onChange={() => handleChange('apprAcc', formik.values.apprAcc)}
                        />
                    </div>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            size={'small'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Approve Opr
                                </Typography>
                            }
                            checked={formik.values.apprOpr}
                            onChange={() => handleChange('apprOpr', formik.values.apprOpr)}
                        />
                    </div>
                </Grid>
            </Grid> */}
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }
        </>
    )
})
export default CreateEditUser