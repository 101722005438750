import { Backdrop, Box, Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import CustomToastContainer, { Paginate, showToast } from "../../../components/ui";
import HeaderInfo from "../../../components/Components/HeaderInfo";
import ButtonComponent from "../../../components/Buttons/ButtonComponent";
import LoadingSpinner from "../../../components/Components/LoadingSpinner";
import TableEmptyInfo from "../../../components/Components/TableEmptyInfo";
import freezeContainer from "../../../Styles/freezeContainer";
import ApiService, { isListAsc } from "../../../services/ApiService";
import { useNavigate } from "react-router-dom";
import { handleDataTable } from "../../../utils/reusableFunction";
import TableCheck from "../../../components/Tables/TableCheck";
import ButtonBulkCancelRequestPage from "./Buttons/ButtonBulkCancelRequestPage";
import ModalCancelRequest from "./Modals/ModalCancelRequest";

const BulkCancelRequestPage = forwardRef((props, ref) => {
    const btnPageRef = useRef(null);
    const btnTableRef = useRef(null);

    const history = useNavigate();

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const param = 'invoice/invoice';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(isListAsc === true ? 0 : 1);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [dataCheck, setDataCheck] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalCancel, setIsModalCancel] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice', link: '/Majura/Invoice', style: '' },
        { label: 'Tax Invoice Number Generation', link: '/Majura/TaxNumberGeneration', style: '' },
        { label: 'Cancel Request', link: '/Majura/TaxNumberGeneration/BulkCancel', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Cancel Request Tax Invoice Number - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FINVTNG')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.userAccess;
                        setDataRegUserAccess(data);

                        let check = false;

                        if (data.find((data) => data.accessCode === 'UPP')) {
                            check = true;
                        }
                        else if (hasAccess) {
                            check = true;
                        }

                        if (check) {
                            getData(1, 50, []);
                        }
                        else {
                            history('/Majura');
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        let params = [
            { 'attr': 'statusCode', 'value': 'RQS' }
        ];

        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        ApiService.OperationCustomPostByPage(param + '/PostByPageEFaktur?', 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.invoiceExportFakturs;

                    setSelectedData({})

                    let temp = data
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'invoiceId');
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }
                    setIsLoading(false);
                    setIsLoadingBackDrop(false);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    setIsLoading(false);
                    setIsLoadingBackDrop(false);
                }
                else {
                    setIsLoading(false);
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: response.data.message });
                }
            })
            .catch(function (error) {
                setIsLoading(false);
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const emptyState = () => {
        setNumPage(isListAsc === true ? 0 : 1);
        setDataCheck([]);
        setRowsCount(50);
        setTotalRows(50);
        setGoTo(1);
        setFilter([]);
        setFilterBy([]);
        setStateX({})
        setSortArr([])
    };

    const handleReset = () => {
        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, []);
    };

    const handleClickCancel = () => {
        if (isModalCancel) {
            setIsModalCancel(false);
        }
        else {
            setIsModalCancel(true);
        }
    }

    const handleCancelRequest = async () => {
        if (!dataCheck.length) {
            return showToast({ type: 'error', message: 'Please Select Data First!' });
        }

        setIsLoading(true);

        const exportedItems = dataCheck.filter((item) => item.isExported === true);

        if (!exportedItems.length) {
            setIsLoading(false);
            return showToast({ type: 'error', message: "Unverified Data Failed Because Data Hasn't Been Verified!" });
        }

        try {
            const requests = exportedItems.map(async (item) => {
                const body = {
                    invoice: {
                        id: String(item.invoiceId),
                        isNonJob: item.isNonJob,
                    },
                };

                const response = await ApiService.OperationCustomPost(`${param}/UnApproveEFaktur`, body);

                if (response.data.code !== 200) {
                    throw new Error(`Cancel Request Failed!, ${response.data.message}`);
                }
            });

            await Promise.all(requests);
            showToast({ type: 'success', message: 'Cancel Request Success!' });
        } catch (error) {
            showToast({ type: 'error', message: error.message || 'Cancel Request Failed!' });
        } finally {
            handleClickCancel();
            emptyState();
            getData(1, 50, []);
        }
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 120
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(value);
        getData(isListAsc === true ? 0 : 1, value, filter, sortArr);
    }

    const ButtonComponents = () => {
        return (
            <ButtonBulkCancelRequestPage
                handleCancel={handleClickCancel}
                isDisabled={dataCheck.length === 0}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '16px 32px 24px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Cancel Request Tax Invoice Number'}
                    isJobType={false}
                    type={'Form'}
                    isButton={true}
                    BackFunction={() => history('/Majura/TaxNumberGeneration')}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    {
                        dataTable.length > 0 && (
                            <div
                                style={{
                                    marginTop: '16px',
                                    padding: '2px 0px 16px 16px',
                                    borderRadius: '12px',
                                    border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                                    background: 'var(--Mjr-white-4, #FFF)',
                                    boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                                }}
                            >
                                <div
                                    className="rounded-10 table-responsive"
                                    style={{
                                        maxHeight: `calc(100vh - ${getContainerHeight()})`,
                                    }}
                                >
                                    {
                                        isLoading ?
                                            <LoadingSpinner />
                                            :
                                            <TableCheck
                                                uniqueKey={'invoiceId'}
                                                rowsCount={rowsCount}
                                                setNumPage={setNumPage}
                                                setRowsCount={setRowsCount}
                                                getData={getData}
                                                columnData={columnData}
                                                dataMap={dataMap}
                                                SelectedData={SelectedData}
                                                setSelectedData={setSelectedData}
                                                setFilter={setFilter}
                                                setFilterBy={setFilterBy}
                                                dataCheck={dataCheck}
                                                setDataCheck={setDataCheck}
                                                stateX={stateX}
                                                setStateX={setStateX}
                                                sortArr={sortArr}
                                                setSortArr={setSortArr}
                                                setGoTo={setGoTo}
                                                goTo={goTo}
                                                dataTable={dataTable}
                                            />
                                    }
                                </div>

                                <div style={{ paddingRight: '16px' }}>
                                    <Box sx={{ borderTop: 1, borderColor: 'divider' }} />
                                    <div style={{ marginTop: '8px' }}>
                                        <Paginate
                                            page={numPage}
                                            limit={rowsCount}
                                            totalData={totalRows}
                                            onChange={onChangePaging}
                                            onChangeLimit={onChangeLimit}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <TableEmptyInfo
                        data={dataTable}
                        getContainerHeight={getContainerHeight}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }


            {
                isModalCancel && (
                    <ModalCancelRequest
                        isModal={isModalCancel}
                        handleClick={handleClickCancel}
                        handleCancelRequest={handleCancelRequest}
                    />
                )
            }
        </Box>
    )
})

export default BulkCancelRequestPage;