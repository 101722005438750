import {
    Backdrop, Box, Grid,
    Typography,
    // Button, Typography 
} from "@mui/material"
import CustomToastContainer, {
    // Icon, 
    showToast
} from "../../../components/ui"
import HeaderInfo from "../../../components/Components/HeaderInfo"
import { forwardRef, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ProcessView from "./Components/ProcessView"
import ApiService from "../../../services/ApiService"
import LoadingSpinner from "../../../components/Components/LoadingSpinner"

const RestorePostingClosingProcess = forwardRef((props, ref) => {
    const { batchGroupId } = useParams();
    const history = useNavigate();
    const param = 'Help/RegBatchGroupId/RegBatchGroupId';
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [lastStep, setLastStep] = useState(0);
    const [stepsCompleted, setStepsCompleted] = useState([]);
    const [batchGroupData, setBatchGroupData] = useState([]);
    const [openAccess, setOpenAccess] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Restore Posting & Closing Process', link: '/Majura/RestorePostingClosing/' + batchGroupId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Restore Posting & Closing Process - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataBatch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function Back() {
        history('/Majura/RestorePostingClosing');
    }

    const getDataBatch = () => {
        setIsLoadingBackDrop(true);
        ApiService.OperationPostById(param, batchGroupId)
            .then((res) => {
                if (res.status === 200) {
                    let arr = [];
                    let data = res.data.data.regBatchGroupId.regBatchGroupIdDetails;
                    setBatchGroupData(data);

                    data.forEach(element => {
                        if (element.statusCode !== 'SCS') {
                            arr.push(false);
                        }
                        else {
                            arr.push(true);
                        }
                    });
                    setStepsCompleted(arr);

                    const lastCompletedIndex = data.reduce((lastIndex, data, idx) => {
                        return data.statusCode === 'SCS' ? idx : lastIndex;
                    }, -1)

                    if (lastCompletedIndex !== -1) {
                        setCurrentStep(lastCompletedIndex + 1);
                    }

                    setIsLoadingBackDrop(false);
                }
                else {
                    console.log(res)
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + res.data.message });
                }
            })
            .catch((err) => {
                console.log(err)
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!, ' + err });
            })
    }

    const handleProcessCompletion = (idx) => {
        let arr = [];
        batchGroupData.forEach(element => {
            if (element.statusCode !== 'SCS') {
                arr.push(false);
            }
            else {
                arr.push(true);
            }
        });
        if (lastStep === 0) {
            arr[idx] = true;
            batchGroupData[idx].statusCode = 'SCS';
            setBatchGroupData(batchGroupData);
        }
        setOpenAccess(false);
        setStepsCompleted(arr);
        setCurrentStep(lastStep > 0 ? lastStep : (prevStep) => prevStep + 1);
        setLastStep(0);
    };

    const handleProcessBackward = (idx) => {
        setLastStep(currentStep);
        setCurrentStep(idx);
    };

    const areAllStepsCompleted = stepsCompleted.every((step) => step === true);

    const mainContainer = (
        <>
            {
                batchGroupData.length > 0 ?
                    <>
                        {
                            batchGroupData.map((data, idx) => {
                                const isStepCompleted = data.statusCode === 'SCS';
                                const isDisabled = !isStepCompleted && idx > currentStep;
                                const isDisabledBackward = openAccess;
                                return (
                                    <ProcessView
                                        key={idx}
                                        data={data}
                                        isDisabled={isDisabled}
                                        monthName={data.periodName}
                                        batchStatus={data.statusCode}
                                        onProcessComplete={() => handleProcessCompletion(idx)}
                                        handleProcessBackward={() => handleProcessBackward(idx)}
                                        stepsCompleted={stepsCompleted}
                                        isDisabledBackward={isDisabledBackward}
                                        setOpenAccess={setOpenAccess}
                                        isHidden={idx + 1 >= batchGroupData.length ? (batchGroupData[idx].statusCode === 'SCS' || batchGroupData[idx].statusCode === 'UCM') : (batchGroupData[idx + 1].statusCode === 'SCS' || batchGroupData[idx+1].statusCode === 'UCM')}
                                    />
                                );
                            })
                        }

                        {/* {
                            areAllStepsCompleted && (
                                <Grid container item direction="row">
                                    <Grid item xs={3.5}>
                                        <Button
                                            size='small'
                                            variant="outlined"
                                            className='d-flex align-items-center'
                                            sx={{
                                                padding: '4px 12px !important',
                                                gap: '4px',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                height: '32px !important',
                                                borderRadius: '8px',
                                                marginTop: '24px',
                                                border: '1px solid var(--Red-Alert, #168444)',
                                                background: 'var(--Blue-Button-Primary-1, #168444)',
                                                '&:hover': {
                                                    border: '1px solid var(--Red-Alert, #168444)',
                                                    backgroundColor: 'var(--Blue-Button-Primary-1, #168444) !important',
                                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                                }
                                            }}
                                            onClick={handleOpenAccess}
                                        >
                                            <Icon
                                                svgIconProps={{
                                                    htmlColor: '#E4E8EC',
                                                    sx: {
                                                        height: '14px',
                                                        width: '14px'
                                                    }
                                                }}
                                                iconName={'unlock'}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    marginTop: '1px !important',
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    lineHeight: '20px',
                                                    textAlign: 'center',
                                                    color: '#E4E8EC',
                                                }}
                                            >
                                                Re-Open User Access
                                            </Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            )
                        } */}
                    </>
                    :
                    <Box
                        sx={{
                            borderRadius: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: `calc(100vh - 77px)`,
                            marginTop: '-154px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                fontSize: '24px',
                                fontWeight: '700',
                                lineHeight: '12px',
                                color: '#001A4F',
                                userSelect: 'none',
                                textAlign: 'center',
                            }}
                        >
                            Loading Data...
                        </Typography>
                    </Box>
            }
        </>
    );

    return (
        <Box sx={{ backgroundColor: '#FAFAFA', width: '100%', p: '24px 32px' }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    ticketId={0}
                    type={'Form'}
                    BackFunction={Back}
                    documentStatus={areAllStepsCompleted ? 'FINISH' : 'ONGOING'}
                    BreadcrumbsItems={BreadcrumbsItems}
                    title={'Restore Posting & Closing Process #' + batchGroupId}
                />

                {mainContainer}
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default RestorePostingClosingProcess