import { Grid, Stack, InputAdornment, IconButton, MenuItem, Typography, } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { formatCurrency, isInfossNo } from '../../../../services/ApiService';
import TabsContact from '../Tabs/TabsContact';
import TabsDetailShipping from '../Tabs/TabsDetailShipping';

import SelectDataBooking from '../Selects/SelectDataBooking';
import SelectDataContact from '../Selects/SelectDataContact';
import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { CustomDatePicker, Icon, Input, Selection, showToast, TickBox } from '../../../../components/ui';
import DollarInput, { convertMask } from '../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../components/Masks/RupiahInput';
import { handleDataTable } from '../../../../utils/reusableFunction';

const ShipmentOrderDomestic = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramB = 'bookingConfirmation/bookingConfirmation';
    const [isLoadingB, setIsLoadingB] = useState(false);
    const [dataB, setDataB] = useState([]);
    const [dataMapB, setDataMapB] = useState([]);
    const [dataTableB, setDataTableB] = useState([]);
    const [columnDataB, setColumnDataB] = useState([]);
    const [SelectedDataB, setSelectedDataB] = useState({});
    const [totalRowsB, setTotalRowsB] = useState(50);
    let countErrB = 1;

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);
    const [tipe, setTipe] = useState(0);
    const [tipe2, setTipe2] = useState(1);

    const paramC = 'regContact/regContact';
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    let countErrC = 1;

    const [titleModal3, setTitleModal3] = useState('');
    const [isModal3, setIsModal3] = useState(false);
    const [typeNonContact, setTypeNonContact] = useState(0);

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [dataTableN, setDataTableN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    let countErrN = 1;

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataBooking1(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchBooking = () => {
        setTitleModal('Booking Confirmation');
        handleClick();
    };

    const handleClick2 = (type1, type2) => {
        if (isModal2 === false) {
            setIsModal2(true);
            localStorage.setItem("type1", JSON.stringify(type1));
            if (type2 !== undefined) {
                localStorage.setItem("type2", JSON.stringify(type2));
            }
            else {
                localStorage.removeItem("type2");
            }
            getDataContact(1, 50);
        }
        else {
            setIsModal2(false);
            localStorage.removeItem("type1");
            localStorage.removeItem("type2");
        }
    };

    const searchSSLine = (data) => {
        if (data === 'search') {
            setTipe(4);
            setTitleModal2('SSLine');
            handleClick2(4);
        }
        else if (data === 'delete') {
            props.setSSLineId(0);
            props.setSSLineName('');
        }
    };

    const searchEMKL = (data) => {
        if (data === 'search') {
            setTipe(6);
            setTitleModal2('EMKL');
            handleClick2(6);
        }
        else if (data === 'delete') {
            props.setEMKLId(0);
            props.setEMKLName('');
        }
    };

    const searchIATA = (data) => {
        if (data === 'search') {
            setTipe(5);
            setTitleModal2('IATA');
            handleClick2(5);
        }
        else if (data === 'delete') {
            props.setIATAId(0);
            props.setIATAName('');
        }
    };

    const handleClick3 = (title) => {
        if (isModal3 === false) {
            setIsModal3(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal3(false);
            localStorage.removeItem("title");
        }
    };

    const searchMarketing = (data) => {
        if (data === 'search') {
            setTitleModal3('Marketing');
            handleClick3('Marketing');
        }
        else if (data === 'delete') {
            props.setMarketId(0);
            props.setMarketName('');
        }
    }

    const dataLoadStatus = [
        { id: 1, name: 'FCL' },
        { id: 2, name: 'LCL' },
    ];

    const getDataBooking1 = (pageNumber, pageSize, filters) => {
        setIsLoadingB(true);
        let params = [];
        params = [...params,
        { 'attr': 'jobTypeId', 'value': '' + props.typeJobId },
        { 'attr': 'approved', 'value': 'true' },
        { 'attr': 'shipmentId', 'value': '0' },
        { 'attr': 'rowStatus', 'value': 'ACT' },
        ];
        ApiService.OperationDynamicPostByPage(paramB, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataB({});
                    setDataB(response.data.data.bookingConfirmation);

                    let temp = response.data.data.bookingConfirmation;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp;
                    })

                    setDataMapB(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableB, 'id');
                    setColumnDataB(response.data.data.columns.headerColumns);
                    setTotalRowsB(response.data.totalRowCount);

                    setIsLoadingB(false);
                }
                setIsLoadingB(false);
            })
            .catch(function (error) {
                setIsLoadingB(false);
                countErrB++
                if (countErrB < 3) {
                    getDataBooking1(1, 50);
                }
                else {
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                    setIsModal(false);
                }
            })
    };

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        if (JSON.parse(localStorage.getItem("type1"))) {
            let type = parseInt(JSON.parse(localStorage.getItem("type1")));

            if (JSON.parse(localStorage.getItem("type2"))) {
                var type2 = parseInt(JSON.parse(localStorage.getItem("type2")));
                ApiService.BookingPContactPostByPage(paramC, type, type2, pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        // console.error('error saat fetch', error);

                        countErrC++
                        if (countErrC < 3) {
                            getDataContact(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                            setIsModal2(false);
                        }
                    })
            }
            else {
                ApiService.ContactPostByPage(paramC, type, pageNumber, pageSize, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.contact);

                            let temp = response.data.data.contact
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        // console.error('error saat fetch', error);

                        countErrC++
                        if (countErrC < 3) {
                            getDataContact(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                            setIsModal2(false);
                        }
                    })
            }
        }
        else {
            showToast({ type: 'error', message: 'Error' });
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        let title = JSON.parse(localStorage.getItem("title"));

        if (title === 'Marketing') {
            param = 'employee/employee';
        }
        else if (title === 'Vessel') {
            param = 'vessel/vessel';
        }

        else if (title === 'Flight') {
            param = 'airLine/airLine';
        }

        else if (title === 'AirPort') {
            param = 'airPort/airPort';
        }

        else if (
            title === 'Place Of Receipt' ||
            title === 'Place Of Delivery' ||
            title === 'City' ||
            title === 'Freight Collect'
        ) {
            param = 'city/city';
        }

        else if (title === 'Port Of Discharge' || title === 'Port Of Loading' || title === 'Port') {
            param = 'seaport/seaport';
        }

        else if (title === 'BL') {
            param = 'billOfLading/billOfLading';
        }

        else if (title === 'Company') {
            param = 'company/company';
        }

        else if (title === 'AirPort of Destination' || title === 'AirPort of Departure') {
            param = 'airPort/airPort';
        }

        if (param !== '') {
            if (param === 'employee/employee') {
                let params = [{ 'attr': 'departmentCode', 'value': 'MKT' }];
                ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataN({})
                            let temp = [];
                            switch (title) {
                                case 'Marketing':
                                    setDataN(response.data.data.employees);
                                    temp = response.data.data.employees;
                                    break;

                                default:
                            }

                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapN(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableN, 'id');
                            setColumnDataN(response.data.data.columns)
                            setTotalRowsN(response.data.totalRowCount)

                            setIsLoadingN(false)
                        }
                        setIsLoadingN(false)
                    })
                    .catch(function (error) {
                        setIsLoadingN(false);
                        // console.error('error saat fetch', error);

                        countErrN++
                        if (countErrN < 3) {
                            getDataNonContact(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                            setIsModal3(false);
                        }
                    })
            }
            else {
                ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataN({})
                            let temp = [];
                            switch (title) {
                                case 'Vessel':
                                    setDataN(response.data.data.vessel);
                                    temp = response.data.data.vessel;
                                    break;
                                case 'Flight':
                                    setDataN(response.data.data.airLine);
                                    temp = response.data.data.airLine;
                                    break;
                                case 'AirPort':
                                    setDataN(response.data.data.airPort);
                                    temp = response.data.data.airPort;
                                    break;
                                case 'Place Of Receipt':
                                    setDataN(response.data.data.city);
                                    temp = response.data.data.city;
                                    break;
                                case 'Place Of Delivery':
                                    setDataN(response.data.data.city);
                                    temp = response.data.data.city;
                                    break;
                                case 'City':
                                    setDataN(response.data.data.city);
                                    temp = response.data.data.city;
                                    break;
                                case 'Freight Collect':
                                    setDataN(response.data.data.city);
                                    temp = response.data.data.city;
                                    break;
                                case 'Port Of Discharge':
                                    setDataN(response.data.data.seaPort);
                                    temp = response.data.data.seaPort;
                                    break;
                                case 'Port Of Loading':
                                    setDataN(response.data.data.seaPort);
                                    temp = response.data.data.seaPort;
                                    break;
                                case 'Port':
                                    setDataN(response.data.data.seaPort);
                                    temp = response.data.data.seaPort;
                                    break;
                                case 'BL':
                                    setDataN(response.data.data.billOfLading);
                                    temp = response.data.data.billOfLading;
                                    break;
                                case 'Company':
                                    setDataN(response.data.data.company);
                                    temp = response.data.data.company;
                                    break;
                                case 'AirPort of Destination':
                                    setDataN(response.data.data.airPort);
                                    temp = response.data.data.airPort;
                                    break;
                                case 'AirPort of Departure':
                                    setDataN(response.data.data.airPort);
                                    temp = response.data.data.airPort;
                                    break;

                                default:
                            }

                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapN(indexed)
                            handleDataTable(indexed, response.data.data.columns, setDataTableN, 'id');
                            setColumnDataN(response.data.data.columns)
                            setTotalRowsN(response.data.totalRowCount)

                            setIsLoadingN(false)
                        }
                        setIsLoadingN(false)
                    })
                    .catch(function (error) {
                        setIsLoadingN(false);
                        // console.error('error saat fetch', error);

                        countErrN++
                        if (countErrN < 3) {
                            getDataNonContact(1, 50);
                        }
                        else {
                            showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                            setIsModal3(false);
                        }
                    })
            }
        }
    };

    return (
        <div>
            <form>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={4}
                        lg={4}
                        sm={4}
                        xs={12}
                    >
                        <div ref={props.infossNoRef}>
                            <Input
                                label={'Infoss Number'}
                                value={props.infossNo}
                                onChange={(e) => props.setInfossNo(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                disabled={props.isJobClose}
                                hidden={!isInfossNo}
                                message={props.infossNo !== '' ? '' : 'This Field is Required'}
                            />
                        </div>
                    </Grid>
                </Grid>

                <Box>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <Input
                                label={'Shipment Order No'}
                                value={props.ShipmentNo}
                                disabled
                                hidden={!props.isEdit}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label="Ref. PEB No."
                                value={props.SIReference}
                                onChange={(e) => props.setSIReference(e.target.value)}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <CustomDatePicker
                                label="Date PEB"
                                value={props.SIDate}
                                onChange={props.setSIDate}
                                type={'date'}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <CustomDatePicker
                                label={'Goods Received at Origin'}
                                value={props.GoodsRecDate}
                                onChange={props.setGoodsRecDate}
                                type={'date'}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <div style={{ marginTop: '16px' }}>
                                <Selection
                                    id='0'
                                    label={'Load Status'}
                                    fullWidth={true}
                                    placeholder={'Select Load Status'}
                                    value={props.LoadStatus}
                                    onChange={(e) => props.setLoadStatus(e.target.value)}
                                >
                                    {
                                        dataLoadStatus.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>
                            </div>

                            <div style={{ marginTop: '16px' }}>
                                {props.selectJobType}
                            </div>

                            <div style={{ marginTop: '16px' }} ref={props.principleByRef}>
                                {props.selectPrincipleBy(false)}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={8}
                            lg={8}
                            sm={8}
                            xs={12}
                        >
                            <Stack direction={'row'} spacing={'32px'}>
                                <Input
                                    label="Booking Confirmation No."
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.BookingConfirmationNumber}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    props.ShipmentId === 0 && props.bookingId === undefined ?
                                                        <IconButton onClick={searchBooking}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                        :
                                                        <IconButton hidden>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <TickBox
                                    baseCheckboxProps={{
                                        sx: {
                                            marginTop: '36px !important'
                                        }
                                    }}
                                    checked={props.isWithEPL}
                                    onChange={() => props.setIsWithEPL(!props.isWithEPL)}
                                    hidden
                                    // hidden={props.isEdit? true : props.BookingConfirmationNumber === ''? true : false}
                                    disabled={props.BookingConfirmationNumber === '' ? true : false}
                                    label={
                                        <Typography
                                            sx={{
                                                color: props.BookingConfirmationNumber === '' ? '#7F888C' : 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '19.6px',
                                                marginTop: '36px !important',
                                                marginLeft: '6px !important'
                                            }}
                                        >
                                            Create Automatically EPL, Based On Booking Conf.
                                        </Typography>
                                    }
                                />
                            </Stack>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <div style={{ marginTop: '16px' }}>
                                {props.selectShipmentStatus}
                            </div>
                        </Grid>

                        <Grid
                            item
                            xl={6}
                            lg={6}
                            sm={6}
                            xs={12}
                        >
                            <div ref={props.marketingRef}>
                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label="Marketing Section"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.MarketId}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '75%',
                                            }
                                        }}
                                        disabled
                                        hidden={props.isMarketing}
                                    />

                                    <Input
                                        hidden={props.isMarketing}
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        disabled
                                        value={props.MarketName}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchMarketing('delete')}
                                                        disabled={props.isMarketing}
                                                        hidden={props.MarketName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton onClick={() => searchMarketing('search')} disabled={props.isMarketing} hidden={props.isJobClose}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        onChange={(e) => props.setMarketName(e.target.value)}
                                    />
                                </Stack>
                            </div>
                        </Grid>
                    </Grid>

                    <br></br>
                    <TabsContact
                        agentRef={props.agentRef}
                        shipperRef={props.shipperRef}
                        consigneeRef={props.consigneeRef}
                        jobType={props.jobType}
                        tabValue={props.tabValueContact}
                        setTabValue={props.setTabValueContact}
                        contactTypeId={props.contactTypeId}
                        setContactTypeId={props.setContactTypeId}
                        contactType={'MainShipmentOrder'}
                        principleBy={props.principleBy}

                        handleClickC={handleClick2}
                        setTitleModalC={setTitleModal2}
                        setTipe={setTipe}
                        setTipe2={setTipe2}

                        //Agent Data
                        AgentId={props.AgentId}
                        AgentName={props.AgentName}
                        AgentAddress={props.AgentAddress}
                        setAgentId={props.setAgentId}
                        setAgentName={props.setAgentName}
                        setAgentAddress={props.setAgentAddress}

                        //Shipper Data
                        ShipperId={props.ShipperId}
                        ShipperName={props.ShipperName}
                        ShipperAddress={props.ShipperAddress}
                        setShipperId={props.setShipperId}
                        setShipperName={props.setShipperName}
                        setShipperAddress={props.setShipperAddress}

                        //Consignee Data
                        ConsigneeId={props.ConsigneeId}
                        ConsigneeName={props.ConsigneeName}
                        ConsigneeAddress={props.ConsigneeAddress}
                        setConsigneeId={props.setConsigneeId}
                        setConsigneeName={props.setConsigneeName}
                        setConsigneeAddress={props.setConsigneeAddress}

                        //NParty Data
                        NPartyId={props.NPartyId}
                        NPartyName={props.NPartyName}
                        NPartyAddress={props.NPartyAddress}
                        setNPartyId={props.setNPartyId}
                        setNPartyName={props.setNPartyName}
                        setNPartyAddress={props.setNPartyAddress}

                        //BookingParty Data
                        BookingPartyId={props.BookingPartyId}
                        BookingPartyName={props.BookingPartyName}
                        BookingPartyAddress={props.BookingPartyAddress}
                        setBookingPartyId={props.setBookingPartyId}
                        setBookingPartyName={props.setBookingPartyName}
                        setBookingPartyAddress={props.setBookingPartyAddress}

                        //Credit Info Data
                        billToShipperId={props.billToShipperId}
                        billShipperName={props.billShipperName}
                        setBillToShipperId={props.setBillToShipperId}
                        setBillShipperName={props.setBillShipperName}

                        creditTermUSD={props.creditTermUSD}
                        creditTermDays={props.creditTermDays}
                        creditTermIDR={props.creditTermIDR}
                        ApprovedCredit={props.ApprovedCredit}
                        outstandingInIDR={props.outstandingInIDR}
                        outstandingInUSD={props.outstandingInUSD}
                        freightAmountIDR={props.freightAmountIDR}
                        setFreightAmountIDR={props.setFreightAmountIDR}
                        freightAmountUSD={props.freightAmountUSD}
                        setFreightAmountUSD={props.setFreightAmountUSD}

                        setCreditTermUSD={props.setCreditTermUSD}
                        setCreditTermDays={props.setCreditTermDays}
                        setCreditTermIDR={props.setCreditTermIDR}
                        setOutstandingInIDR={props.setOutstandingInIDR}
                        setOutstandingInUSD={props.setOutstandingInUSD}
                    />

                    <br></br>
                    <TabsDetailShipping
                        estimateTimeRef={props.estimateTimeRef}
                        jobType={props.jobType}
                        tabValue={props.tabValueDetail}
                        setTabValue={props.setTabValueDetail}
                        detailTypeId={props.detailTypeId}
                        setDetailTypeId={props.setDetailTypeId}
                        selectContainerType={props.selectContainerType}
                        selectPackagingType={props.selectPackagingType}
                        ShipmentId={props.ShipmentId}
                        setShipmentId={props.setShipmentId}

                        containerTypeData={props.containerTypeData}
                        packagingTypeData={props.packagingTypeData}

                        handleClick={handleClick3}
                        setTitleModal={setTitleModal3}
                        setTipe={setTypeNonContact}

                        dataTracking={props.dataTracking}
                        setDataTracking={props.setDataTracking}

                        //Data Tab Vessel
                        VesselFlight={props.VesselFlight}
                        setVesselFlight={props.setVesselFlight}
                        VesselId={props.VesselId}
                        VesselName={props.VesselName}
                        Voyage={props.Voyage}
                        setVoyage={props.setVoyage}
                        cityId={props.cityId}
                        PortId={props.PortId}
                        PortCode={props.PortCode}
                        PortName={props.PortName}
                        FlightId={props.FlightId}
                        FlightCode={props.FlightCode}
                        FlightName={props.FlightName}
                        FlightNo={props.FlightNo}
                        setFlightNo={props.setFlightNo}

                        ReceiptPlaceId={props.ReceiptPlaceId}
                        ReceiptPlaceCode={props.ReceiptPlaceCode}
                        ReceiptPlaceName={props.ReceiptPlaceName}

                        DeliveryPlaceId={props.DeliveryPlaceId}
                        DeliveryPlaceCode={props.DeliveryPlaceCode}
                        DeliveryPlaceName={props.DeliveryPlaceName}

                        TempLoadingPortName={props.TempLoadingPortName}
                        setTempLoadingPortName={props.setTempLoadingPortName}
                        TempReceiptPlaceName={props.TempReceiptPlaceName}
                        setTempReceiptPlaceName={props.setTempReceiptPlaceName}
                        TempDischargePortName={props.TempDischargePortName}
                        setTempDischargePortName={props.setTempDischargePortName}
                        TempDeliveryPlaceName={props.TempDeliveryPlaceName}
                        setTempDeliveryPlaceName={props.setTempDeliveryPlaceName}

                        ETD={props.ETD}
                        setETD={props.setETD}
                        ATD={props.ATD}
                        setATD={props.setATD}
                        ETA={props.ETA}
                        setETA={props.setETA}
                        ATA={props.ATA}
                        setATA={props.setATA}

                        period={props.period}
                        setPeriod={props.setPeriod}
                        yearPeriod={props.yearPeriod}
                        setYearPeriod={props.setYearPeriod}
                        isClosed={props.isClosed}
                        setIsClosed={props.setIsClosed}

                        //Data Tab Container
                        data20={props.data20}
                        setData20={props.setData20}
                        data40={props.data40}
                        setData40={props.setData40}
                        data45={props.data45}
                        setData45={props.setData45}
                        dataAll={props.dataAll}
                        setDataAll={props.setDataAll}

                        containerData={props.containerData}
                        setContainerData={props.setContainerData}
                        getContainerSizeData={props.getContainerSizeData}

                        CopyContainer={props.CopyContainer}
                        setCopyContainer={props.setCopyContainer}
                        createCopyContainer={props.createCopyContainer}
                        totalNumberCont={props.totalNumberCont}
                        setTotalNumberCont={props.setTotalNumberCont}

                        //Data Tab Freight
                        freightStatusHBL={props.freightStatusHBL}
                        setFreightStatusHBL={props.setFreightStatusHBL}
                        freightCollectIdHBL={props.freightCollectIdHBL}
                        freightCollectCodeHBL={props.freightCollectCodeHBL}
                        freightCollectNameHBL={props.freightCollectNameHBL}
                        payableConsigneeIdHBL={props.payableConsigneeIdHBL}
                        setPayableConsigneeIdHBL={props.setPayableConsigneeIdHBL}
                        payableConsigneeNameHBL={props.payableConsigneeNameHBL}
                        setPayableConsigneeNameHBL={props.setPayableConsigneeNameHBL}

                        handlingInf={props.handlingInf}
                        setHandlingInf={props.setHandlingInf}

                        //Data Tab Description
                        MarkNumberDescription={props.MarkNumberDescription}
                        setMarkNumberDescription={props.setMarkNumberDescription}
                        NumberTypeDescription={props.NumberTypeDescription}
                        setNumberTypeDescription={props.setNumberTypeDescription}
                        GoodDescription={props.GoodDescription}
                        setGoodDescription={props.setGoodDescription}
                        GrossWeightDescription={props.GrossWeightDescription}
                        setGrossWeightDescription={props.setGrossWeightDescription}
                        MeasurementDescription={props.MeasurementDescription}
                        setMeasurementDescription={props.setMeasurementDescription}

                        TempMarkNumberDescription={props.TempMarkNumberDescription}
                        setTempMarkNumberDescription={props.setTempMarkNumberDescription}
                        TempNumberTypeDescription={props.TempNumberTypeDescription}
                        setTempNumberTypeDescription={props.setTempNumberTypeDescription}
                        TempGoodDescription={props.TempGoodDescription}
                        setTempGoodDescription={props.setTempGoodDescription}
                        TempGrossWeightDescription={props.TempGrossWeightDescription}
                        setTempGrossWeightDescription={props.setTempGrossWeightDescription}
                        TempMeasurementDescription={props.TempMeasurementDescription}
                        setTempMeasurementDescription={props.setTempMeasurementDescription}

                        setShippingMarksDesc={props.setShippingMarksDesc}
                        setNoPackageDesc={props.setNoPackageDesc}
                        setSIGoodDesc={props.setSIGoodDesc}
                        setWeightMeaDesc={props.setWeightMeaDesc}

                        //Data Tab Con Note
                        ConNoteData={props.ConNoteData}
                        setConNoteData={props.setConNoteData}

                        setVesselId={props.setVesselId}
                        setVesselName={props.setVesselName}
                        setCityId={props.setCityId}
                        setPortId={props.setPortId}
                        setPortCode={props.setPortCode}
                        setPortName={props.setPortName}
                        setFlightId={props.setFlightId}
                        setFlightCode={props.setFlightCode}
                        setFlightName={props.setFlightName}
                        setReceiptPlaceId={props.setReceiptPlaceId}
                        setReceiptPlaceCode={props.setReceiptPlaceCode}
                        setReceiptPlaceName={props.setReceiptPlaceName}
                        setDeliveryPlaceId={props.setDeliveryPlaceId}
                        setDeliveryPlaceCode={props.setDeliveryPlaceCode}
                        setDeliveryPlaceName={props.setDeliveryPlaceName}
                        setFreightCollectIdHBL={props.setFreightCollectIdHBL}
                        setFreightCollectCodeHBL={props.setFreightCollectCodeHBL}
                        setFreightCollectNameHBL={props.setFreightCollectNameHBL}
                    />

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={8}
                        >
                            <Input
                                label={'Ocean/Mst. B/L Nr.'}
                                value={props.OceanMSTBLNo}
                                onChange={(e) => props.setOceanMSTBLNo(e.target.value)}
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={8}
                        >
                            <Input
                                label={'Volume B/L'}
                                value={props.VolumeBL}
                                onChange={(e) => props.setVolumeBL(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                textAlign='right'
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={8}
                        >
                            <Input
                                label={'Volume Invoice'}
                                value={props.VolumeInvoice}
                                onChange={(e) => props.setVolumeInvoice(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                textAlign='right'
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={8}
                            lg={8}
                            sm={8}
                            xs={18}
                        >
                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: "16px" }}>
                                <Input
                                    label="SSLine Id"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.SSLineId}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    disabled
                                />

                                <Input
                                    label="SSLine Name"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled
                                    value={props.SSLineName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchSSLine('delete')}
                                                    hidden={props.SSLineName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => searchSSLine('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => props.setMarketName(e.target.value)}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: "16px" }}>
                                <Input
                                    label="IATA Agent Id"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.IATAId}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    disabled
                                />

                                <Input
                                    label="IATA Agent Name"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled
                                    value={props.IATAName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchIATA('delete')}
                                                    hidden={props.IATAName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => searchIATA('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: "16px" }}>
                                <Input
                                    label="EMKL Id"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.EMKLId}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    disabled
                                />

                                <Input
                                    label="EMKL Name"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    disabled
                                    value={props.EMKLName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchEMKL('delete')}
                                                    hidden={props.EMKLName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => searchEMKL('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={6}
                        >
                            <Input
                                label="Value for Carriage"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.CarriageValue}
                                onChange={(e) => props.setCarriageValue(e.target.value)}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label="Value for Custom"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.CustomValue}
                                onChange={(e) => props.setCustomValue(e.target.value)}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label="Truck"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.Truck}
                                onChange={(e) => props.setTruck(e.target.value)}
                                type='text'
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataBooking
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        isEdit={props.isEdit}
                        BookingConfirmationId={props.BookingConfirmationId}

                        getData={getDataBooking1}
                        isLoading={isLoadingB}
                        setIsLoading={setIsLoadingB}
                        data={dataB}
                        setData={setDataB}
                        dataMap={dataMapB}
                        dataTable={dataTableB}
                        setDataMap={setDataMapB}
                        columnData={columnDataB}
                        setColumnData={setColumnDataB}
                        SelectedData={SelectedDataB}
                        setSelectedData={setSelectedDataB}
                        totalRows={totalRowsB}
                        setTotalRows={setTotalRowsB}

                        setBookingConfirmationNumber={props.setBookingConfirmationNumber}
                        setBookingConfirmationId={props.setBookingConfirmationId}
                        GetBookingData={props.GetBookingData}
                        jobType={props.jobType}
                        typeJobId={props.typeJobId}
                        setTicketId={props.setTicketId}
                        setRequester={props.setRequester}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataContact
                        jobType={props.jobType}
                        setTipe={setTipe}
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        tipe={tipe}
                        setIsModal={setIsModal2}
                        tipe2={tipe2}
                        setTipe2={setTipe2}
                        contactTypeId={props.contactTypeId}

                        getData={getDataContact}
                        isLoading={isLoadingC}
                        setIsLoading={setIsLoadingC}
                        data={dataC}
                        setData={setDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setDataMap={setDataMapC}
                        columnData={columnDataC}
                        setColumnData={setColumnDataC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}

                        setAgentId={props.setAgentId}
                        setAgentName={props.setAgentName}
                        setAgentAddress={props.setAgentAddress}
                        //SEA
                        setPayableAgentIdOBL={props.setPayableAgentIdOBL}
                        setPayableAgentNameOBL={props.setPayableAgentNameOBL}
                        //AIR
                        setPayableAgentIdMAWB={props.setPayableAgentIdMAWB}
                        setPayableAgentNameMAWB={props.setPayableAgentNameMAWB}

                        setSIAgentId={props.setSIAgentId}
                        setSIAgentName={props.setSIAgentName}
                        setSIAgentAddress={props.setSIAgentAddress}

                        setDeliveryId={props.setDeliveryId}
                        setDeliveryName={props.setDeliveryName}
                        setDeliveryAddress={props.setDeliveryAddress}

                        setTranshipmentId={props.setTranshipmentId}
                        setTranshipmentName={props.setTranshipmentName}
                        setTranshipmentAddress={props.setTranshipmentAddress}

                        ShipperId={props.ShipperId}
                        setShipperId={props.setShipperId}
                        setShipperName={props.setShipperName}
                        setShipperAddress={props.setShipperAddress}
                        billToShipperId={props.billToShipperId}
                        setBillToShipperId={props.setBillToShipperId}
                        setBillShipperName={props.setBillShipperName}

                        ConsigneeId={props.ConsigneeId}
                        setConsigneeId={props.setConsigneeId}
                        setConsigneeName={props.setConsigneeName}
                        setConsigneeAddress={props.setConsigneeAddress}
                        setTempConsigneeId={props.setTempConsigneeId}
                        setTempConsigneeName={props.setTempConsigneeName}
                        setTempConsigneeAddress={props.setTempConsigneeAddress}
                        billToConsigneeId={props.billToConsigneeId}
                        setBillToConsigneeId={props.setBillToConsigneeId}
                        setBillConsigneeName={props.setBillConsigneeName}
                        //SEA
                        setPayableConsigneeIdHBL={props.setPayableConsigneeIdHBL}
                        setPayableConsigneeNameHBL={props.setPayableConsigneeNameHBL}
                        //AIR
                        setPayableConsigneeIdHAWB={props.setPayableConsigneeIdHAWB}
                        setPayableConsigneeNameHAWB={props.setPayableConsigneeNameHAWB}

                        setCreditTermUSD={props.setCreditTermUSD}
                        setCreditTermDays={props.setCreditTermDays}
                        setCreditTermIDR={props.setCreditTermIDR}
                        setOutstandingInIDR={props.setOutstandingInIDR}
                        setOutstandingInUSD={props.setOutstandingInUSD}

                        setNPartyId={props.setNPartyId}
                        setNPartyName={props.setNPartyName}
                        setNPartyAddress={props.setNPartyAddress}

                        setBookingPartyId={props.setBookingPartyId}
                        setBookingPartyName={props.setBookingPartyName}
                        setBookingPartyAddress={props.setBookingPartyAddress}

                        setSSLineId={props.setSSLineId}
                        setSSLineName={props.setSSLineName}
                        setIATAId={props.setIATAId}
                        setIATAName={props.setIATAName}
                        setBrokerId={props.setBrokerId}
                        setBrokerName={props.setBrokerName}

                        //BL Data
                        setBLAgentId={props.setBLAgentId}
                        setBLAgentName={props.setBLAgentName}
                        setBLAgentAddress={props.setBLAgentAddress}

                        setBLShipperId={props.setBLShipperId}
                        setBLShipperName={props.setBLShipperName}
                        setBLShipperAddress={props.setBLShipperAddress}

                        setBLConsigneeId={props.setBLConsigneeId}
                        setBLConsigneeName={props.setBLConsigneeName}
                        setBLConsigneeAddress={props.setBLConsigneeAddress}

                        setBLNPartyId={props.setBLNPartyId}
                        setBLNPartyName={props.setBLNPartyName}
                        setBLNPartyAddress={props.setBLNPartyAddress}

                        //SI Data
                        setSIConsigneeId={props.setSIConsigneeId}
                        setSIConsigneeName={props.setSIConsigneeName}
                        setSIConsigneeAddress={props.setSIConsigneeAddress}

                        setSINPartyId={props.setSINPartyId}
                        setSINPartyName={props.setSINPartyName}
                        setSINPartyAddress={props.setSINPartyAddress}
                    />
                )
            }

            {
                isModal3 && (
                    <SelectDataNonContact
                        jobType={props.jobType}
                        tipe={typeNonContact}
                        isModal={isModal3}
                        handleClick={handleClick3}
                        titleModal={titleModal3}
                        setIsModal={setIsModal3}
                        setTitleModal={setTitleModal3}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        dataTable={dataTableN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setVesselId={props.setVesselId}
                        setVesselName={props.setVesselName}
                        setCityId={props.setCityId}
                        setPortId={props.setPortId}
                        setPortCode={props.setPortCode}
                        setPortName={props.setPortName}
                        setFlightId={props.setFlightId}
                        setFlightCode={props.setFlightCode}
                        setFlightName={props.setFlightName}
                        setReceiptPlaceId={props.setReceiptPlaceId}
                        setReceiptPlaceCode={props.setReceiptPlaceCode}
                        setReceiptPlaceName={props.setReceiptPlaceName}
                        setDeliveryPlaceId={props.setDeliveryPlaceId}
                        setDeliveryPlaceCode={props.setDeliveryPlaceCode}
                        setDeliveryPlaceName={props.setDeliveryPlaceName}
                        setFreightCollectIdHBL={props.setFreightCollectIdHBL}
                        setFreightCollectCodeHBL={props.setFreightCollectCodeHBL}
                        setFreightCollectNameHBL={props.setFreightCollectNameHBL}
                        setTempLoadingPortName={props.setTempLoadingPortName}
                        setTempReceiptPlaceName={props.setTempReceiptPlaceName}
                        setTempDischargePortName={props.setTempDischargePortName}
                        setTempDeliveryPlaceName={props.setTempDeliveryPlaceName}

                        setBLReceiptPlaceName={props.setBLReceiptPlaceName}
                        setBLDischargePortName={props.setBLDischargePortName}
                        setBLLoadingPortName={props.setBLLoadingPortName}
                        setBLDeliveryPlaceName={props.setBLDeliveryPlaceName}

                        setMarketId={props.setMarketId}
                        setMarketName={props.setMarketName}
                    />
                )
            }
        </div>
    )
}

export default ShipmentOrderDomestic;