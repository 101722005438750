import { Box, Grid, Breadcrumbs, Typography, Link, Stack, IconButton, FormControl, Select, MenuItem } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { Icon, Input } from '../ui';
import { baseUrlTracking } from '../../services/ApiService';

const DropdownIcon = ({ className }) => {
    return (
        <Icon
            svgIconProps={{
                className: `${className}`,
                fontSize: '8px',
                sx: {
                    color: '#00286D !important',
                    visibility: 'visible',
                }
            }}
            iconName={'arrow-down'}
        />
    )
}

const HeaderInfo = ({
    title,
    type = 'List',
    ticketId = 0,
    BackFunction = null,
    documentStatus = '',
    isJobType = false,
    jobTypeId = 0,
    handleSelect = null,
    dataJobType = [],
    ButtonComponents = null,
    BreadcrumbsItems = [],
    btnPageRef,
    isSticky = false,
    Period,
    setPeriod,
    isPeriod = true,
    isButton = false,
}) => {
    const selectRef = useRef(null);
    const [isAtTop, setIsAtTop] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        setIsAtTop(window.scrollY === 0);
    };

    const renderDocumentStatus = () => {
        if (!documentStatus) return null;

        const statusStyles = {
            ONGOING: {
                borderColor: '#FFA800',
                backgroundColor: '#FFE288',
                color: '#083A50',
            },
            REJECT: {
                borderColor: '#D20C0C',
                backgroundColor: '#F88',
                color: '#D20C0C',
            },
            APPROVED: {
                borderColor: '#007330',
                backgroundColor: '#B1FF81',
                color: '#007330',
            },
            PAID: {
                borderColor: '#007330',
                backgroundColor: '#B1FF81',
                color: '#007330',
            },
            FINISH: {
                borderColor: '#007330',
                backgroundColor: '#B1FF81',
                color: '#007330',
            },
            PREVIEW: {
                borderColor: '#FC7F17',
                backgroundColor: '#FFC527',
                color: '#FC7F17',
            },
            SAVED: {
                borderColor: 'var(--Orange-Outline-1, #FEA51E)',
                backgroundColor: 'rgba(254, 165, 30, 0.15)',
                color: 'var(--Orange-Outline-1, #FEA51E)',
            },
            SUBMITTED: {
                borderColor: 'var(--Orange-Outline-1, #FEA51E)',
                backgroundColor: 'rgba(254, 165, 30, 0.15)',
                color: 'var(--Orange-Outline-1, #FEA51E)',
            },
            DRAFT: {
                borderColor: '#585858',
                backgroundColor: 'rgba(88, 88, 88, 0.15)',
                color: '#585858',
            },
            DEFAULT: {
                borderColor: '#585858',
                backgroundColor: '#CDCDCD',
                color: '#585858',
            },
        };

        const { borderColor, backgroundColor, color } = statusStyles[documentStatus] || statusStyles.DEFAULT;

        return (
            <Box
                sx={{
                    display: 'flex',
                    height: '24px',
                    padding: '0px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    borderRadius: '8px',
                    border: `1.5px solid ${borderColor}`,
                    backgroundColor,
                    marginTop: '2px',
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Nunito',
                        fontSize: '12px',
                        fontWeight: '800',
                        lineHeight: 'normal',
                        color,
                        userSelect: 'none',
                        cursor: 'default',
                    }}
                >
                    {documentStatus}
                </Typography>
            </Box>
        );
    };

    return (
        <>
            {
                type === 'Form' && isSticky && (
                    <Box
                        sx={{
                            marginLeft: '-32px',
                            marginRight: '-32px',
                            height: isAtTop ? '0px' : '88px',
                            background: 'rgba(250, 250, 250, 0.975)',
                            position: 'sticky',
                            top: 0,
                            zIndex: 999,
                        }}
                    />
                )
            }

            <div
                style={{
                    background: 'rgba(250, 250, 250, 0.975)',
                    marginLeft: type === 'Form' ? '-32px' : '0px',
                    marginRight: type === 'Form' ? '-32px' : '0px',
                    paddingBottom: isSticky ? '24px' : '0px',
                    position: type === 'Form' && isSticky ? 'sticky' : undefined,
                    top: type === 'Form' && isSticky ? '88px' : undefined,
                    zIndex: type === 'Form' && isSticky ? 1000 : undefined,
                    borderRadius: '-12px',
                }}
            >
                <Box
                    sx={{
                        padding: '0px 16px 8px',
                        background: '#FFF',
                        borderRadius: '12px',
                        marginLeft: type === 'Form' ? '32px' : '0px',
                        marginRight: type === 'Form' ? '32px' : '0px',
                        boxShadow: '0px 8px 8px 0px #0000001A',
                        height: type === 'Form' && ticketId !== 0 ? '100px' : 'fit-content',
                    }}
                >
                    <Grid container spacing={0} direction="column">
                        <Grid item xs={12}>
                            <Grid container item direction="row" sx={{ maxHeight: type !== 'Form' ? '62px' : 'fit-content', alignItems: 'center' }}>
                                <Grid item xs={!isPeriod || ticketId !== 0 ? 8 : 12} sx={{ marginTop: type === 'Form' && ticketId !== 0 ? '1px' : '4px' }}>
                                    <Stack direction="column" spacing="3px">
                                        <Breadcrumbs
                                            separator={
                                                <Icon
                                                    iconName="arrow-right"
                                                    svgIconProps={{
                                                        sx: {
                                                            htmlColor: '#D1D1D2',
                                                            width: '6px',
                                                            height: '6px',
                                                            alignItems: 'center',
                                                            padding: '0px',
                                                            margin: '12px 0px 8px',
                                                        },
                                                    }}
                                                />
                                            }
                                            sx={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            aria-label="breadcrumb"
                                        >
                                            {
                                                BreadcrumbsItems.map((element, index) => (
                                                    <Link
                                                        key={index}
                                                        underline="hover"
                                                        color="inherit"
                                                        href={element.link}
                                                        sx={{
                                                            fontFamily: 'Switzer',
                                                            fontSize: '10px',
                                                            fontWeight: BreadcrumbsItems.length !== index + 1 ? '400' : '600',
                                                            letterSpacing: '0em',
                                                            userSelect: 'none',
                                                            color: '#747475'
                                                        }}
                                                    >
                                                        {element.label}
                                                    </Link>
                                                ))
                                            }
                                        </Breadcrumbs>

                                        <Box sx={{ borderTop: '1px solid #DDDDDD', width: 'auto' }} />

                                        {
                                            type === 'List' ?
                                                (
                                                    <Stack direction={'row'} alignItems={'center'} spacing={'6px'} sx={{ marginTop: isJobType ? '-0.5px !important' : '0px !important' }}>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Switzer',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                                letterSpacing: '0em',
                                                                textAlign: 'left',
                                                                color: '#000',
                                                                marginTop: isJobType && dataJobType.length !== 0 ? '0px !important' : '4px !important',
                                                                userSelect: 'none',
                                                            }}
                                                        >
                                                            {title}
                                                        </Typography>

                                                        {
                                                            isJobType && dataJobType.length !== 0 && (
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Switzer',
                                                                        fontSize: '16px',
                                                                        fontWeight: '600',
                                                                        letterSpacing: '0em',
                                                                        textAlign: 'left',
                                                                        color: '#000',
                                                                        marginTop: isJobType ? '0px !important' : '4px !important',
                                                                        userSelect: 'none',
                                                                    }}
                                                                >
                                                                    -
                                                                </Typography>
                                                            )
                                                        }

                                                        {
                                                            isJobType && dataJobType.length !== 0 && (
                                                                <FormControl variant="standard" sx={{ padding: '0px !important' }} ref={selectRef}>
                                                                    <Select
                                                                        labelId="lblJobType"
                                                                        id="LblJobType"
                                                                        value={dataJobType.length > 0 ? jobTypeId : ''}
                                                                        defaultValue='0'
                                                                        disableUnderline
                                                                        onChange={(e, c) => { handleSelect(e, c); selectRef.current?.blur(); }}
                                                                        IconComponent={({ className: iconClassName }) => (<DropdownIcon className={iconClassName} />)}
                                                                        sx={{
                                                                            textAlign: 'left',
                                                                            fontFamily: 'Switzer',
                                                                            fontWeight: '600',
                                                                            fontSize: '16px',
                                                                            borderRadius: '16px',
                                                                            marginTop: '1px !important',
                                                                            padding: '0px !important',
                                                                        }}
                                                                        MenuProps={{
                                                                            PaperProps: {
                                                                                sx: {
                                                                                    borderRadius: '16px',
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {
                                                                            dataJobType.map((data) => (
                                                                                <MenuItem
                                                                                    id={data.id}
                                                                                    key={data.code}
                                                                                    value={data.id}
                                                                                    sx={{
                                                                                        'textAlign': 'center',
                                                                                        fontSize: '10px !important',
                                                                                        fontFamily: 'Switzer',
                                                                                        color: '#00286D',
                                                                                        fontWeight: '600',
                                                                                    }}
                                                                                >
                                                                                    {data.name.toUpperCase()}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        }
                                                    </Stack>
                                                )
                                                :
                                                (
                                                    <Stack
                                                        direction="row"
                                                        spacing="16px"
                                                        sx={{
                                                            alignItems: 'center',
                                                            width: '100%',
                                                            marginTop: '6px !important',
                                                        }}
                                                    >
                                                        <IconButton
                                                            sx={{
                                                                background: '#6692DE',
                                                                height: '20px',
                                                                width: '20px',
                                                                alignItems: 'center',
                                                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                                                '&:hover': {
                                                                    background: '#6692DE',
                                                                    boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.20)',
                                                                },
                                                            }}
                                                            onClick={BackFunction}
                                                        >
                                                            <Icon
                                                                iconName="sort-left"
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#F2F2F2',
                                                                    sx: { height: '12px', width: '12px' },
                                                                }}
                                                            />
                                                        </IconButton>

                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Switzer',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                                letterSpacing: '0em',
                                                                textAlign: 'left',
                                                                color: '#000',
                                                                userSelect: 'none',
                                                            }}
                                                        >
                                                            {title}
                                                        </Typography>

                                                        {renderDocumentStatus()}
                                                    </Stack>
                                                )
                                        }
                                    </Stack>
                                </Grid>

                                {
                                    (!isPeriod || ticketId !== 0) && (
                                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                            {
                                                !isPeriod ?
                                                    (
                                                        <Box sx={{ marginTop: '8px' }}>
                                                            <Stack direction={'row'} spacing={'16px'}>
                                                                <Input
                                                                    label={'Period'}
                                                                    fullWidth
                                                                    type={'month'}
                                                                    value={Period}
                                                                    hidden={!isPeriod}
                                                                    onChange={(e) => setPeriod(e.target.value)}
                                                                    formControlProps={{
                                                                        sx: {
                                                                            width: '100%',
                                                                        }
                                                                    }}
                                                                    formLabelProps={{
                                                                        sx: {
                                                                            fontSize: '12px !important',
                                                                        }
                                                                    }}
                                                                    inputBaseProps={{
                                                                        sx: {
                                                                            marginTop: '3px !important',
                                                                            border: '2px solid #0087C2',
                                                                            backgroundColor: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(88.4deg, #0087C2 0%, #00D1FF 100%) !important',
                                                                            borderRadius: '8px',
                                                                            height: '32px',
                                                                            fontSize: '12px !important',
                                                                            boxShadow: '0px 4px 8px 0px #01C2FF40',
                                                                            textAlign: 'left',
                                                                            '&.Mui-focused': {
                                                                                border: '2px solid #0049FF',
                                                                                borderRadius: '8px',
                                                                                boxShadow: '0px 6px 8px 0px #0000001A',
                                                                            }
                                                                        },
                                                                    }}
                                                                />
                                                            </Stack>
                                                        </Box>
                                                    )
                                                    :
                                                    (
                                                        ticketId !== 0 && (
                                                            <Stack direction="column" sx={{ alignItems: 'center', justifyContent: 'center', width: 'fit-content' }}>
                                                                <QRCode
                                                                    style={{ maxHeight: "70px", width: "fit-content", marginTop: '8px', padding: '0px' }}
                                                                    value={`${baseUrlTracking}${ticketId}`}
                                                                />
                                                                <Typography
                                                                    sx={{
                                                                        color: 'var(--Blue-Secondary-1, #2B4957)',
                                                                        fontFamily: 'Nunito',
                                                                        fontSize: '8px',
                                                                        fontWeight: '400',
                                                                        lineHeight: 'normal',
                                                                        cursor: 'default',
                                                                        marginTop: '5px',
                                                                    }}
                                                                >
                                                                    Scan here for shipment tracking
                                                                </Typography>
                                                            </Stack>
                                                        )
                                                    )
                                            }
                                        </Grid>
                                    )
                                }
                            </Grid>
                        </Grid>

                        {
                            (type === 'List' || isButton) && ButtonComponents && (
                                <Box ref={btnPageRef} sx={{ marginTop: '8px', height: 'fit-content !important' }}>
                                    <ButtonComponents />
                                </Box>
                            )
                        }
                    </Grid>
                </Box>
            </div>
        </>
    );
};

export default HeaderInfo;