import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import ModalContainerInfo from '../Modals/ModalContainerInfo';
import { CustomDatePicker, Input, showToast } from '../../../../components/ui';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { getDefaultDateFormat } from '../../../../utils/date';

const Tracking = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [detailEdit, setDetailEdit] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const [containerNo, setContainerNo] = useState('');
    const [sealNo, setSealNo] = useState(0);
    const [contSizeType, setContSizeType] = useState('');
    const [statusId, setStatusId] = useState(1);
    const [status, setStatus] = useState('');
    const [statusUpdateOn, setStatusUpdateOn] = useState('');
    const [description, setDescription] = useState('');
    const [portId, setPortId] = useState(0);
    const [portCode, setPortCode] = useState('');
    const [portName, setPortName] = useState('');
    const [airportId, setAirportId] = useState(0);
    const [airportCode, setAirportCode] = useState('');
    const [airportName, setAirportName] = useState('');
    const [cityId, setCityId] = useState(0);
    const [cityCode, setCityCode] = useState('');
    const [cityName, setCityName] = useState('');
    const [countryName, setCountryName] = useState('');
    const [vessel, setVessel] = useState('');
    const [flight, setFlight] = useState('');
    const [conNote, setConNote] = useState('');
    const [receiptName, setReceiptName] = useState('');
    const [conNoteSequence, setConNoteSequence] = useState(0);
    const [routingSequence, setRoutingSequence] = useState(0);
    const [seaContainerSequence, setSeaContainerSequence] = useState(0);

    useEffect(() => {
        getDateNow();
    }, [props]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    function getDateNow() {
        setStatusUpdateOn(getDefaultDateFormat(new Date()));
    };

    const ShowAddContainerInfo = () => {
        if (props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR') {
            setTitleModal('Add Container Info');
        }
        else {
            setTitleModal('Add Con. Note Info');

            if (props.VesselFlight !== 'N') {
                if (props.VesselFlight === 'V') {
                    setVessel(props.VesselName + ' V.' + props.Voyage);
                }
                else {
                    setFlight(props.FlightName + ' ' + props.FlightNo);
                }
            }
        }

        if (props.dataTracking.length > 0) {
            let data = props.dataTracking.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
            setDetailSequence(data.sequence);
        }
        else {
            setDetailSequence(0);
        }
        setDetailEdit(false);
        handleClick();
    };


    const ShowEditContainerInfo = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                if (props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR') {
                    setTitleModal('Edit  Container Info');
                }
                else {
                    setTitleModal('Edit Con. Note Info');
                }

                setDetailEdit(true);

                setContainerNo(selectedDetail.containerNo);
                setSealNo(selectedDetail.sealNo);
                setContSizeType(selectedDetail.sizeType);
                setVessel(selectedDetail.vessel);
                setFlight(selectedDetail.flight);
                setStatus(selectedDetail.status);
                setStatusId(selectedDetail.statusId);
                setStatusUpdateOn(getDefaultDateFormat(selectedDetail.statusUpdateOn));
                setCityId(selectedDetail.cityId);
                setCityCode(selectedDetail.cityCode);
                setCityName(selectedDetail.cityName);
                setCountryName(selectedDetail.countryName);
                setDescription(selectedDetail.description);
                setPortId(selectedDetail.portId);
                setPortCode(selectedDetail.portCode);
                setPortName(selectedDetail.portName);
                setAirportId(selectedDetail.airportId);
                setAirportCode(selectedDetail.airportCode);
                setAirportName(selectedDetail.airportName);

                setConNoteSequence(selectedDetail.sequenceConNote);
                setConNote(selectedDetail.conNote);
                setReceiptName(selectedDetail.receiptName);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted' });
            }
        }
    }

    const DeleteContainerInfo = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (dataCost) => {
                return dataCost.sequence !== tempSequence;
            }

            const result = props.dataTracking.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setDataTracking(result);

            setSelectedDetail({});

            showToast({ type: 'success', message: 'Tracking Deleted Successfully' });
        }

    }

    return (
        <div>
            <form>
                <Box sx={{ marginTop: '24px' }}>
                    <Typography
                        sx={{
                            color: 'var(--Mjr-black-1, #171717)',
                            fontFamily: 'Switzer',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                        }}
                    >
                        Shipment
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            padding: '16px 16px',
                            flexDirection: 'column',
                            gap: '16px',
                            borderRadius: '12px',
                            background: '#FAFAFA',
                            marginTop: '12px'
                        }}
                    >
                        <Input
                            label="Shipment No"
                            value={props.ShipmentNo}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Box sx={{ marginTop: '24px' }}>
                    <Typography
                        sx={{
                            color: 'var(--Mjr-black-1, #171717)',
                            fontFamily: 'Switzer',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                        }}
                    >
                        Basic Information
                    </Typography>

                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            padding: '16px 16px',
                            flexDirection: 'column',
                            gap: '16px',
                            borderRadius: '12px',
                            background: '#FAFAFA',
                            marginTop: '12px'
                        }}
                    >
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Place of Receipt"
                                    value={props.ReceiptPlaceName}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                {
                                    props.jobType.portType !== 'AIR' ?
                                        <Input
                                            label="Port of Discharge"
                                            value={props.DischargePortName}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                        :
                                        <Input
                                            label="Airport of Destination"
                                            value={props.DestinationAirportName}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                }

                                <CustomDatePicker
                                    label="ETD"
                                    value={props.ETD}
                                    disabled
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputProps: {
                                            max: props.ETA,
                                        }
                                    }}
                                />

                                {
                                    props.jobType.portType !== 'AIR' ?
                                        <Input
                                            label="HBL/HAWB"
                                            value={props.HouseBLNo}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                        :
                                        <Input
                                            label="HBL/HAWB"
                                            value={props.HAWBNo}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                }
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                {
                                    props.jobType.portType !== 'AIR' ?
                                        <Input
                                            label="Port of Loading"
                                            value={props.LoadingPortName}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />
                                        :
                                        <Input
                                            label="	Airport of Departure"
                                            value={props.DepartureAirPortName}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />
                                }

                                <Input
                                    label="Place Of Delivery"
                                    value={props.DeliveryPlaceName}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <CustomDatePicker
                                    label="ETA"
                                    value={props.ETA}
                                    disabled
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    customDateProps={{
                                        minDate: new Date(props.ETD),
                                    }}
                                />

                                {
                                    props.jobType.portType !== 'AIR' ?
                                        <Input
                                            label="MBL/MAWB"
                                            value={props.OceanMSTBLNo}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                        :
                                        <Input
                                            label="MBL/MAWB"
                                            value={props.MAWBNo}
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box sx={{ marginTop: '24px' }}>
                    {
                        props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Container Info
                            </Typography>
                            :
                            <Typography
                                sx={{
                                    color: 'var(--Blue-Primary-1, #083A50)',
                                    fontFamily: 'Nunito',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Con. Note Info
                            </Typography>
                    }

                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            padding: '8px 16px 16px',
                            flexDirection: 'column',
                            gap: '16px',
                            borderRadius: '12px',
                            background: '#FAFAFA',
                            marginTop: '12px'
                        }}
                    >
                        <TableContainer sx={{ maxHeight: 235, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {
                                            props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                                                <StyledTableCell sx={{ textAlign: 'center' }}>Container No</StyledTableCell>
                                                :
                                                <StyledTableCell sx={{ textAlign: 'center' }}>Con. Note</StyledTableCell>
                                        }
                                        {
                                            props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                                                <StyledTableCell sx={{ textAlign: 'center' }}>Size/Type</StyledTableCell>
                                                :
                                                <></>
                                        }
                                        <StyledTableCell>Status</StyledTableCell>
                                        {
                                            props.jobType.portType !== 'AIR' ?
                                                <StyledTableCell sx={{ textAlign: 'center' }}>Vessel</StyledTableCell>
                                                :
                                                <StyledTableCell sx={{ textAlign: 'center' }}>Flight</StyledTableCell>
                                        }
                                        {
                                            props.jobType.portType !== 'SEA' && props.jobType.portType !== 'AIR' ?
                                                <>
                                                    <StyledTableCell>Port</StyledTableCell>
                                                    <StyledTableCell>Airport</StyledTableCell>
                                                </>
                                                :
                                                props.jobType.portType === 'SEA' ?
                                                    <StyledTableCell>Port</StyledTableCell>
                                                    :
                                                    <StyledTableCell>Airport</StyledTableCell>
                                        }
                                        <StyledTableCell>Current Loc.</StyledTableCell>
                                        <StyledTableCell>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>Updated On</StyledTableCell>
                                        {
                                            props.jobType.portType !== 'SEA' && props.jobType.portType !== 'AIR' ?
                                                <StyledTableCell>Receipt By</StyledTableCell>
                                                :
                                                <></>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.dataTracking.length > 0 ?
                                            props.dataTracking.map((el) => {
                                                return (
                                                    <TableRow
                                                        key={el.sequence}
                                                        onClick={() => setSelectedDetail(el)}
                                                        onDoubleClick={() => ShowEditContainerInfo()}
                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                        hover={true}
                                                    >
                                                        {
                                                            props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.containerNo}</StyledTableBodyCell>
                                                                :
                                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.conNote}</StyledTableBodyCell>
                                                        }
                                                        {
                                                            props.jobType.portType === 'SEA' || props.jobType.portType === 'AIR' ?
                                                                <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.sizeType}</StyledTableBodyCell>
                                                                :
                                                                <></>
                                                        }
                                                        <StyledTableBodyCell>{el.status}</StyledTableBodyCell>
                                                        {
                                                            props.jobType.portType === 'AIR' || props.jobType.portType === 'SEA' ?
                                                                <>
                                                                    {
                                                                        props.jobType.portType !== 'AIR' ?
                                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.vessel}</StyledTableBodyCell>
                                                                            :
                                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.flight}</StyledTableBodyCell>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        props.VesselFlight === 'V' ?
                                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.vessel}</StyledTableBodyCell>
                                                                            :
                                                                            <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.flight}</StyledTableBodyCell>
                                                                    }
                                                                </>
                                                        }
                                                        {
                                                            props.jobType.portType !== 'SEA' && props.jobType.portType !== 'AIR' ?
                                                                <>
                                                                    <StyledTableBodyCell>{el.portName}</StyledTableBodyCell>
                                                                    <StyledTableBodyCell>{el.airportName}</StyledTableBodyCell>
                                                                </>
                                                                :
                                                                props.jobType.portType === 'SEA' ?
                                                                    <StyledTableBodyCell>{el.portName}</StyledTableBodyCell>
                                                                    :
                                                                    <StyledTableBodyCell>{el.airportName}</StyledTableBodyCell>
                                                        }
                                                        <StyledTableBodyCell>{el.cityName}, {el.countryName}</StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center' }}>{new Date(el.statusUpdateOn).toLocaleDateString("id", { day: '2-digit', month: '2-digit', year: 'numeric' }).split('T')[0]}</StyledTableBodyCell>
                                                        {
                                                            props.jobType.portType !== 'SEA' && props.jobType.portType !== 'AIR' ?
                                                                <StyledTableBodyCell>{el.receiptName}</StyledTableBodyCell>
                                                                :
                                                                <></>
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div style={{ marginTop: '8px' }}>
                            {CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddContainerInfo, ShowEditContainerInfo, DeleteContainerInfo)}
                        </div>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <ModalContainerInfo
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        jobType={props.jobType}
                        selectedDetail={selectedDetail}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        dataTracking={props.dataTracking}
                        setDataTracking={props.setDataTracking}

                        selectTrackingStatus={props.selectTrackingStatus}
                        getDateNow={getDateNow}
                        ShipmentId={props.ShipmentId}
                        containerData={props.containerData}
                        vesselData={props.vesselData}
                        flightData={props.flightData}
                        ConNoteData={props.ConNoteData}

                        containerNo={containerNo}
                        setContainerNo={setContainerNo}
                        sealNo={sealNo}
                        setSealNo={setSealNo}
                        contSizeType={contSizeType}
                        setContSizeType={setContSizeType}
                        statusId={statusId}
                        setStatusId={setStatusId}
                        status={status}
                        setStatus={setStatus}
                        statusUpdateOn={statusUpdateOn}
                        setStatusUpdateOn={setStatusUpdateOn}
                        description={description}
                        setDescription={setDescription}
                        portId={portId}
                        setPortId={setPortId}
                        portCode={portCode}
                        setPortCode={setPortCode}
                        portName={portName}
                        setPortName={setPortName}
                        airportId={airportId}
                        setAirportId={setAirportId}
                        airportCode={airportCode}
                        setAirportCode={setAirportCode}
                        airportName={airportName}
                        setAirportName={setAirportName}
                        cityId={cityId}
                        setCityId={setCityId}
                        cityCode={cityCode}
                        setCityCode={setCityCode}
                        cityName={cityName}
                        setCityName={setCityName}
                        countryName={countryName}
                        setCountryName={setCountryName}
                        vessel={vessel}
                        setVessel={setVessel}
                        flight={flight}
                        setFlight={setFlight}
                        conNote={conNote}
                        setConNote={setConNote}
                        receiptName={receiptName}
                        setReceiptName={setReceiptName}
                        conNoteSequence={conNoteSequence}
                        setConNoteSequence={setConNoteSequence}
                        routingSequence={routingSequence}
                        setRoutingSequence={setRoutingSequence}
                        seaContainerSequence={seaContainerSequence}
                        setSeaContainerSequence={setSeaContainerSequence}

                        VesselFlight={props.VesselFlight}
                        setVesselFlight={props.setVesselFlight}
                        VesselId={props.VesselId}
                        setVesselId={props.setVesselId}
                        VesselName={props.VesselName}
                        setVesselName={props.setVesselName}
                        Voyage={props.Voyage}
                        setVoyage={props.setVoyage}
                        cityIdD={props.cityIdD}
                        setCityIdD={props.setCityIdD}
                        PortIdD={props.PortIdD}
                        setPortIdD={props.setPortIdD}
                        PortCodeD={props.PortCodeD}
                        setPortCodeD={props.setPortCodeD}
                        PortNameD={props.PortNameD}
                        setPortNameD={props.setPortNameD}
                        FlightId={props.FlightId}
                        setFlightId={props.setFlightId}
                        FlightCode={props.FlightCode}
                        setFlightCode={props.setFlightCode}
                        FlightName={props.FlightName}
                        setFlightName={props.setFlightName}
                        FlightNo={props.FlightNo}
                        setFlightNo={props.setFlightNo}
                    />
                )
            }
        </div>
    )
}

export default Tracking;