import { Box, Card, CardContent, Grid } from '@mui/material';
import React, { forwardRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useImperativeHandle } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { branchId, companyId, countryId, userId, userCode, username } from '../../../../services/ApiService';
import ButtonCreateEditAdjustment from './Buttons/ButtonCreateEditAdjustment';
import AdjustmentHeader from './Components/AdjustmentHeader';
import AdjustmentDetail from './Components/AdjustmentDetail';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import CustomToastContainer, { showToast } from '../../../../components/ui';

const CreateEditAdjustment = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataAdjustment"));

    const { Id } = useParams();
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramAdjustment = "Adjustment/Adjustment"
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const history = useNavigate();

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    //Variable
    const [RowStatus, setRowStatus] = useState('ACT');

    const [adjustmentDate, setAdjustmentDate] = useState(new Date().toISOString().split('T')[0]);
    const [adjustmentNo, setAdjustmentNo] = useState('');
    const [adjustmentCategoryId, setAdjustmentCategoryId] = useState('')
    const [adjustmentCategoryName, setAdjustmentCategoryName] = useState('');
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [sitesName, setSitesName] = useState('');
    const [sitesId, setSitesId] = useState('');
    const [warehousesId, setWarehousesId] = useState('');
    const [warehousesName, setWarehousesName] = useState('');
    const [warehouseLocationId, setWarehouseLocationId] = useState('');
    const [warehouseLocationName, setWarehouseLocationName] = useState('');
    const [approved, setApproved] = useState(false);
    const [listAdjustmentCategory, setListAdjustmentCategory] = useState([]);

    const [adjustmentDetailList, setAdjustmentDetailList] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Adjustment', link: '/Majura/Adjustment', style: '' },
        { label: 'Create Adjustment', link: '/Majura/Adjustment/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Adjustment', link: '/Majura/Adjustment', style: '' },
        { label: 'Detail Adjustment', link: '/Majura/Adjustment/' + Id, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    let countErr = 0;
    let checkeds = 0;

    useEffect(() => {
        document.title = 'Adjustment - ' + JSON.parse(localStorage.getItem('branchName'));
        setIsLoadingBackDrop(true)
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        // const unloadCallback = (event) => {
        //     event.preventDefault();
        //     event.returnValue = "";
        //     return "";
        //   };

        //   window.addEventListener("beforeunload", unloadCallback);
        //   return () => window.removeEventListener("beforeunload", unloadCallback);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let id = 0;

        if (Id) {
            id = parseInt(Id);
        }

        ApiService.CategoryPostByPage(paramAdjustment)
            .then((response) => {
                if (response.data.code === 200) {
                    setListAdjustmentCategory(response.data.data.adjustmentCategory);
                }
                setIsLoadingBackDrop(false)
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false)
                showToast({ type: 'error', message: error.toString() })
            })
        var data = {
            "adjustment": {
                "rowStatus": RowStatus,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "approved": approved,
                adjustmentDate,
                adjustmentNo,
                adjustmentCategoryId,
                adjustmentCategoryName,
                productCategoryId,
                productCategoryName,
                sitesId,
                sitesName,
                warehousesId,
                warehousesName,
                warehouseLocationId,
                warehouseLocationName,
                userId,
                userCode
            },
            "adjustmentDetails": adjustmentDetailList,
        };

        localStorage.setItem(username + "-DataAdjustment", JSON.stringify(data));
    }

    const getLocalData = () => {
        if (!dataLocal) {
            return;
        }
        let adjustment = dataLocal.adjustment;
        let adjustmentDetails = dataLocal.adjustmentDetails;

        setRowStatus(adjustment.rowStatus);
        setAdjustmentNo(adjustment.adjustmentNo);
        setAdjustmentCategoryId(adjustment.adjustmentCategoryId);
        setAdjustmentCategoryName(adjustment.adjustmentCategoryName);
        setProductCategoryId(adjustment.productCategoryId);
        setProductCategoryName(adjustment.productCategoryName);
        setAdjustmentDate(adjustment.adjustmentDate);
        setSitesId(adjustment.sitesId);
        setSitesName(adjustment.sitesName);
        setWarehousesId(adjustment.warehouseId);
        setWarehousesName(adjustment.warehouseName);
        setWarehouseLocationId(adjustment.warehouseLocationId);
        setWarehouseLocationName(adjustment.warehouseLocationName);
        handleDocumentStatus(adjustment.rowStatus, adjustment.approved)
        setApproved(adjustment.approved)
        if (Id) {
            setIsEdit(true);
        }

        setAdjustmentDetailList(adjustmentDetails);

        localStorage.removeItem(username + '-DataAdjustment');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1000);
    }

    const getDataAdjustmentCategory = () => {
        setIsLoadingBackDrop(true);
        ApiService.CategoryPostByPage(paramAdjustment)
            .then((response) => {
                if (response.data.code === 200) {
                    setListAdjustmentCategory(response.data.data.adjustmentCategory);
                }
                setIsLoadingBackDrop(false)
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getData = () => {
        if (Id) {
            ApiService.PostByIdWarehouse(paramAdjustment, Id)
                .then((res) => {
                    if (res.data.code === 200) {
                        const data = res.data.data.adjustment;
                        setIsEdit(true)
                        setAdjustmentDate((new Date(data.adjustmentDate)).toLocaleDateString("sv-se").split('T')[0]);
                        setAdjustmentNo(data.adjustmentNo);
                        setAdjustmentCategoryId(data.adjustmentCategoryId);
                        setAdjustmentCategoryName(data.adjustmentCategoryName);
                        setProductCategoryId(data.productCategoryId);
                        setProductCategoryName(data.productCategoryName);
                        setSitesId(data.sitesId);
                        setSitesName(data.sitesName);
                        setWarehousesId(data.warehousesId);
                        setWarehousesName(data.warehousesName);
                        setWarehouseLocationId(data.warehouseLocationId);
                        setWarehouseLocationName(data.warehouseLocationName);
                        setAdjustmentDetailList(data.adjustmentDetails);
                        handleDocumentStatus(data.rowStatus, data.approved)
                        setApproved(true)
                        setIsLoadingBackDrop(false);
                        getDataAdjustmentCategory();
                    }
                })
                .catch((error) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: error.toString() })
                })
        }
        else {
            setIsLoadingBackDrop(false)
            getDataAdjustmentCategory();
        }
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'TWHADJ')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ];

            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        for (let i = 0; i < tempArray.length; i++) {
                            if (Id) {
                                if (tempArray[i].accessCode === 'UPD' && tempArray[i].hidden === false) {
                                    checked = 1;
                                    break;
                                }
                            }
                            else {
                                if (tempArray[i].accessCode === 'CRE') {
                                    checked = 1;
                                    break;
                                }
                            }
                        };

                        setCheck(checked);

                        if (hasAccess) {
                            handleDocumentStatus();
                            setCheck(checkeds);
                            getData();
                            getLocalData();
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    countErr++
                    if (countErr < 3) {
                        getDataAccess()
                    }
                    else {
                        ApiService.logOut()
                        history('/')
                    }
                })
        }
        else {
            history('/Majura');
        }
    }


    function Save() {
        setIsLoadingBackDrop(true);

        let id = 0;

        if (Id) {
            id = parseInt(Id);
        }

        var data = {
            "adjustment": {
                "rowStatus": RowStatus,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                adjustmentDate,
                adjustmentNo,
                adjustmentCategoryId,
                adjustmentCategoryName,
                productCategoryId,
                productCategoryName,
                sitesId,
                sitesName,
                warehousesId,
                warehousesName,
                warehouseLocationId,
                warehouseLocationName,
                "userId": userId,
                "userCode": userCode
            },
            "adjustmentDetails": adjustmentDetailList,
        };

        let message = '';
        if (id === 0) {
            ApiService.createDataWarehouse(paramAdjustment, data).then((res) => {
                if (res.data.code === 200) {
                    message = 'Create Data Success';
                    showToast({ type: 'success', message: message })
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                            history('/Majura/Adjustment');
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    message = 'Create Data Failed';
                    showToast({ type: 'error', message: message })
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                        },
                        1250);
                }
            })
                .catch((err) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: err.toString() })
                })
        }
        else {
            ApiService.updateDataWarehouse(paramAdjustment, data).then((res) => {
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    message = 'Update Data Success';
                    showToast({ type: 'success', message: message })
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                            history('/Majura/Adjustment');
                        },
                        1250);
                }
                else {
                    setIsLoadingBackDrop(false);
                    message = 'Updated Data Failed';
                    showToast({ type: 'error', message: message })
                    setTimeout(
                        function () {
                            setIsLoadingBackDrop(false);
                        },
                        1250);
                }
            })
                .catch((err) => {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: err.toString() })
                })
        }
    }

    function Back() {
        history('/Majura/Adjustment');
    }

    function Approve() {
        setIsLoadingBackDrop(true)
        ApiService.ApproveWarehouse(paramAdjustment, Id)
            .then((res) => {
                setIsLoadingBackDrop(false)
                if (res.data.code === 200) {
                    showToast({ type: 'success', message: res.data.message })
                    getData();
                } else {
                    showToast({ type: 'error', message: res.data.error })
                }
            })
            .catch((err) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: err.toString() })
            })
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditAdjustment
                Save={Save}
                Back={Back}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                Id={Id}
                Approve={Approve}
                isEdit={isEdit}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#FAFAFA', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            Id ?
                                'Adjustment (' + adjustmentNo + ')'
                                :
                                'Adjustment (New Document)'
                        }
                        BreadcrumbsItems={Id ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <fieldset>
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                            <CardContent>
                                <AdjustmentHeader
                                    Id={Id}
                                    isEdit={isEdit}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    adjustmentDate={adjustmentDate}
                                    setAdjustmentDate={setAdjustmentDate}
                                    adjustmentNo={adjustmentNo}
                                    setAdjustmentNo={setAdjustmentNo}
                                    adjustmentCategoryId={adjustmentCategoryId}
                                    setAdjustmentCategoryId={setAdjustmentCategoryId}
                                    adjustmentCategoryName={adjustmentCategoryName}
                                    setAdjustmentCategoryName={setAdjustmentCategoryName}
                                    productCategoryName={productCategoryName}
                                    setProductCategoryName={setProductCategoryName}
                                    productCategoryId={productCategoryId}
                                    setProductCategoryId={setProductCategoryId}
                                    sitesName={sitesName}
                                    setSitesName={setSitesName}
                                    sitesId={sitesId}
                                    setSitesId={setSitesId}
                                    approved={approved}
                                    warehousesId={warehousesId}
                                    setWarehousesId={setWarehousesId}
                                    warehousesName={warehousesName}
                                    setWarehousesName={setWarehousesName}
                                    warehouseLocationId={warehouseLocationId}
                                    setWarehouseLocationId={setWarehouseLocationId}
                                    warehouseLocationName={warehouseLocationName}
                                    setWarehouseLocationName={setWarehouseLocationName}
                                    listAdjustmentCategory={listAdjustmentCategory}
                                    adjustmentDetailList={adjustmentDetailList}
                                    setAdjustmentDetailList={setAdjustmentDetailList}
                                />
                            </CardContent>
                        </Card>

                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                            <CardContent>
                                <AdjustmentDetail
                                    Id={Id}
                                    adjustmentDetailList={adjustmentDetailList}
                                    setAdjustmentDetailList={setAdjustmentDetailList}
                                    warehouseLocationId={warehouseLocationId}
                                    approved={approved}
                                    warehouseLocationName={warehouseLocationName}
                                    productCategoryId={productCategoryId}
                                />
                            </CardContent>
                        </Card>
                    </fieldset>
                </Grid>
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditAdjustment;