import { Backdrop, Box, Grid, MenuItem, Card, CardContent, Stack, InputAdornment, IconButton, Typography } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react';

import SelectDataCOA from './Selects/SelectDataCOA';
import SelectDataPeriod from './Selects/SelectDataPeriod';
import ButtonDailyReportView from './Buttons/ButtonDailyReportView';
import ApiService, { formatCurrency } from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import DollarInput, { convertMask } from '../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../components/Masks/RupiahInput';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import { getDefaultDateFormat, setDateFormat } from '../../../../utils/date';
import { GetReportVersionLog, handleDataTable, PrintExcel, PrintPDF } from '../../../../utils/reusableFunction';
import ReportVersionLog from '../../../../components/Modals/ReportVersionLog';
import { useNavigate } from 'react-router-dom';

const DailyReportView = forwardRef((props, ref) => {
    const menuCode = 'RDAIREP';
    const history = useNavigate();

    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isLoadingPrint, setIsLoadingPrint] = useState(true);
    const [isCompany, setIsCompany] = useState(true);
    const [isPerClosingPeriod, setIsPerClosingPeriod] = useState(true);
    const [bPeriod, setBPeriod] = useState('');
    const [bYearPeriod, setBYearPeriod] = useState('');
    const [bStartPeriod, setBStartPeriod] = useState('');
    const [bEndPeriod, setBEndPeriod] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [accountCode, setAccountCode] = useState('');
    const [accountName, setAccountName] = useState('');
    const [debitUSD, setDebitUSD] = useState(0);
    const [creditUSD, setCreditUSD] = useState(0);
    const [debitIDR, setDebitIDR] = useState(0);
    const [creditIDR, setCreditIDR] = useState(0);
    const [debitInIDR, setDebitInIDR] = useState(0);
    const [creditInIDR, setCreditInIDR] = useState(0);

    const paramP = "regClosingPeriod/regClosingPeriod";
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [isModalP, setIsModalP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [titleModalP, setTitleModalP] = useState('');
    const [columnDataP, setColumnDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [dataMapP, setDataMapP] = useState([]);
    const [dataTableP, setDataTableP] = useState([]);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [titleModalC, setTitleModalC] = useState('');
    const [isModalC, setIsModalC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([]);

    const companyTypeList = [
        { id: 1, code: true, name: 'Per Company' },
        { id: 2, code: false, name: 'Per Regional' },
    ];

    const closingTypeList = [
        { id: 1, code: true, name: 'Per Closing Period' },
        { id: 2, code: false, name: 'Custom Date' },
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isModalLog, setIsModalLog] = useState(false);
    const [fullDataLog, setFullDataLog] = useState([]);
    const [dataLog, setDataLog] = useState([]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Daily Report', link: '/Majura/DailyReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Daily Report - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingPrint(true);

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === menuCode)
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                {
                    attr: "permissionId",
                    value: "" + pId
                },
                {
                    attr: "userId",
                    value: "" + JSON.parse(localStorage.getItem("userId"))
                },
                {
                    attr: "roleId",
                    value: "" + JSON.parse(localStorage.getItem("roleId"))
                }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        setIsLoadingPrint(false);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        setIsLoadingPrint(false);
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history('/Majura');
                });
        }
        else {
            history('/Majura');
        }
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationGL() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls")
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (isPerClosingPeriod === true && bPeriod === '') {
            check = 1;
        }
        else if (accountCode === '') {
            check = 2;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Period!' });
        }
        else if (check === 2) {
            showToast({ type: 'error', message: 'Please Select Data Chart of Account (COA)!' });
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('Daily Report');
            handleClickConfirmation();
        }
    };

    const PrintExcelData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Daily Report');
            handleClickConfirmation();
        }
    };

    const handleIsClosingPeriod = (value) => {
        setIsPerClosingPeriod(value);

        if (value === false) {
            getDateNow();
            EmptyPeriod();
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setStartDate);
        setDateFormat(new Date(), setEndDate);
    }

    const EmptyPeriod = () => {
        setBPeriod('');
        setBYearPeriod('');
        setBStartPeriod('');
        setBEndPeriod('');
    }

    function handleDate(source, value) {
        if (source === 'From') {
            if (value > endDate) {
                showToast({ type: 'error', message: 'Date From Can\'t Be Greater Than Date To' });
            }
            else {
                setStartDate(value);
            }
        }
        else if (source === 'To') {
            if (value < startDate) {
                showToast({ type: 'error', message: 'Date To Can\'t Be Smaller Than Date From' });
            }
            else {
                setEndDate(value);
            }
        }
    }

    const handleClickPeriod = () => {
        if (isModalP === false) {
            setTitleModalP('Closing Period');
            getDataPeriod(1, 50);
            setIsModalP(true);
        }
        else {
            setIsModalP(false);
        }
    };

    const handleClickAccount = () => {
        if (isModalC === false) {
            setIsModalC(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModalC(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'Search') {
            setTitleModalC('Chart of Account (COA)');
            handleClickAccount();
        }
        else if (data === 'Delete') {
            setAccountCode('');
            setAccountName('');
        }
    };

    const getDataPeriod = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.closingPeriod);
                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableP, 'id');
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)
                    setIsLoadingP(false)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                setIsLoadingP(false)
            })
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Can\'t Fetch Data Chart of Account (COA), ' + error });
            })
    };

    const PrintReport = (code) => {
        setIsLoadingPrint(true);

        let isCustomDate = 0;
        let dateStart = '';
        let dateEnd = '';

        if (isPerClosingPeriod === false) {
            isCustomDate = 1;
            dateStart = startDate;
            dateEnd = endDate;
        }
        else {
            dateStart = bStartPeriod;
            dateEnd = bEndPeriod;
        }

        let params = [
            {
                "attr": "IsCustomDate",
                "value": "" + isCustomDate
            },
            {
                "attr": "StartDate",
                "value": dateStart
            },
            {
                "attr": "EndDate",
                "value": dateEnd
            },
            {
                "attr": "AccountCode",
                "value": accountCode
            },
            {
                "attr": "BeginDBIDR",
                "value": "" + debitIDR
            },
            {
                "attr": "BeginDBUSD",
                "value": "" + debitUSD
            },
            {
                "attr": "BeginDBInIDR",
                "value": "" + debitInIDR
            },
            {
                "attr": "BeginCRIDR",
                "value": "" + creditIDR
            },
            {
                "attr": "BeginCRUSD",
                "value": "" + creditUSD
            },
            {
                "attr": "BeginCRInIDR",
                "value": "" + creditInIDR
            }
        ];

        let reportLink = 'Reports/Finance/Print/FinanceReport/DailyReportGLReport/DailyReportGLReport/PrintDailyReportGL?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(setIsLoadingPrint, 'Daily Report', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingPrint, 'Daily Report', reportLink, params);
        }
    };

    const handleClickLog = useCallback(() => {
        if (isModalLog === false) {
            setIsModalLog(true);
        }
        else {
            setIsModalLog(false);
        }
    }, [isModalLog]);

    const handleDataLog = useCallback((isPrint = true, date = new Date(), isResume = false) => {
        setIsLoadingPrint(true);
        const today = new Date(date);
        const month = today.getMonth();
        const year = today.getFullYear();
        const startDate = getDefaultDateFormat(new Date(year, month, 1));
        const endDate = getDefaultDateFormat(new Date(year, month + 1, 0));

        const params = [
            { "attr": "reportCode", "value": menuCode },
            ...(
                JSON.parse(isPrint) ?
                    [
                        { "attr": "printedOn, printedOn", "mathSign": ">=, <=", "value": `${startDate}, ${endDate}` }
                    ]
                    : [
                        { "attr": "period", "value": '' + (parseInt(month) + parseInt(1)) },
                        { "attr": "yearPeriod", "value": '' + year }
                    ]
            )
        ];

        GetReportVersionLog(setIsLoadingPrint, menuCode, params, handleFullDataLog, !isResume ? handleClickLog : () => { });
    }, [handleClickLog]);

    const handleFullDataLog = (data) => {
        setFullDataLog(data);
        setDataLog(data);
    }

    const ButtonComponents = () => {
        return (
            <ButtonDailyReportView
                PrintData={PrintData}
                PrintExcel={PrintExcelData}
                handleClickLog={() => handleDataLog()}
                dataRegUserAccess={dataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Daily Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            label={'Custom Company'}
                                            value={isCompany}
                                            fullWidth={true}
                                            placeholder={'Select Custom Company'}
                                            onChange={(e) => setIsCompany(e.target.value)}
                                        >
                                            {
                                                companyTypeList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>
                                    </CardContent>
                                </Card>

                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px', marginTop: '24px' }}>
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Transaction Date
                                        </Typography>

                                        <Selection
                                            id='0'
                                            fullWidth={true}
                                            label={'Custom Period'}
                                            value={isPerClosingPeriod}
                                            placeholder={'Select Custom Period'}
                                            onChange={(e) => handleIsClosingPeriod(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '16px'
                                                }
                                            }}
                                        >
                                            {
                                                closingTypeList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <div hidden={!isPerClosingPeriod}>
                                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                                <Input
                                                    label={'Period'}
                                                    value={bPeriod}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />

                                                <Input
                                                    label="Year Period"
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={bYearPeriod}
                                                    type='text'
                                                    disabled
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => EmptyPeriod()}
                                                                    size='small'
                                                                    hidden={bPeriod ? false : true}
                                                                >
                                                                    <Icon
                                                                        iconName={'close'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>

                                                                <IconButton
                                                                    onClick={() => handleClickPeriod()}
                                                                    size='small'
                                                                >
                                                                    <Icon
                                                                        iconName={'search'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Stack>

                                            <Grid
                                                container
                                                spacing={'16px'}
                                            >
                                                <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <CustomDatePicker
                                                        label="Beginning Period"
                                                        value={bStartPeriod}
                                                        type={'date'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                marginTop: '24px !important'
                                                            }
                                                        }}
                                                        inputBaseProps={{
                                                            readOnly: true,
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>

                                                <Grid
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    sm={6}
                                                    xs={12}
                                                >
                                                    <CustomDatePicker
                                                        label="Ending Period"
                                                        value={bEndPeriod}
                                                        type={'date'}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                marginTop: '24px !important'
                                                            }
                                                        }}
                                                        inputBaseProps={{
                                                            readOnly: true,
                                                        }}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div hidden={isPerClosingPeriod}>
                                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                                <CustomDatePicker
                                                    label="Transaction Date From"
                                                    value={startDate}
                                                    type={'date'}
                                                    onChange={(e) => handleDate('From', e)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                />

                                                <CustomDatePicker
                                                    label="Transaction Date To"
                                                    value={endDate}
                                                    type={'date'}
                                                    onChange={(e) => handleDate('To', e)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                />
                                            </Stack>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Stack spacing={'16px'} direction="row">
                                            <Input
                                                label={'Account Code'}
                                                value={accountCode}
                                                inputBaseProps={{
                                                    readOnly: true,
                                                }}
                                                disabled
                                            />

                                            <Input
                                                label=""
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={accountName}
                                                type='text'
                                                disabled
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        marginTop: '23px !important'
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchAccount('Delete')}
                                                                hidden={accountName ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => searchAccount('Search')}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px', marginTop: '24px' }}>
                                    <CardContent>
                                        <Typography
                                            sx={{
                                                color: 'var(--Blue-Primary-1, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '18px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Beginning Balance
                                        </Typography>

                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                sm={4}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Debit USD"
                                                    textAlign='right'
                                                    value={debitUSD}
                                                    onChange={(e) => { setDebitUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                />

                                                <Input
                                                    label="Credit USD"
                                                    textAlign='right'
                                                    value={creditUSD}
                                                    onChange={(e) => { setCreditUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                sm={4}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Debit IDR"
                                                    textAlign='right'
                                                    value={debitIDR}
                                                    onChange={(e) => { setDebitIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                />

                                                <Input
                                                    label="Credit IDR"
                                                    textAlign='right'
                                                    value={creditIDR}
                                                    onChange={(e) => { setCreditIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={4}
                                                lg={4}
                                                sm={4}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Debit In IDR"
                                                    textAlign='right'
                                                    value={debitInIDR}
                                                    onChange={(e) => { setDebitInIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                />

                                                <Input
                                                    label="Credit In IDR"
                                                    textAlign='right'
                                                    value={creditInIDR}
                                                    onChange={(e) => { setCreditInIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1)) }}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => 100000 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalP && (
                    <SelectDataPeriod
                        isModal={isModalP}
                        setIsModal={setIsModalP}
                        titleModal={titleModalP}
                        handleClick={handleClickPeriod}
                        isLoading={isLoadingP}
                        data={dataP}
                        getData={getDataPeriod}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        dataTable={dataTableP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}

                        setBPeriod={setBPeriod}
                        setBYearPeriod={setBYearPeriod}
                        setBStartPeriod={setBStartPeriod}
                        setBEndPeriod={setBEndPeriod}
                    />
                )
            }

            {
                isModalC && (
                    <SelectDataCOA
                        isModal={isModalC}
                        handleClick={handleClickAccount}
                        titleModal={titleModalC}
                        setIsModal={setIsModalC}

                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}

                        setAccountCode={setAccountCode}
                        setAccountName={setAccountName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationGL}
                    />
                )
            }

            {
                isModalLog && (
                    <ReportVersionLog
                        data={dataLog}
                        setData={setDataLog}
                        isModal={isModalLog}
                        fullData={fullDataLog}
                        handleClick={handleClickLog}
                        handleDataLog={handleDataLog}
                        title={'Daily Report'}
                        setIsLoading={setIsLoadingPrint}
                        reportLink={'Reports/Finance/Print/FinanceReport/DailyReportGLReport/DailyReportGLReport/PrintDailyReportGL?typeReport=pdf'}
                    />
                )
            }
        </Box>
    )
})
export default DailyReportView