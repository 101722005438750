import { Grid, IconButton, InputAdornment, MenuItem, Stack } from "@mui/material";
import { CustomDatePicker, CustomRadioGroup, Icon, Input, Selection, showToast, TextInfo } from "../../../../components/ui";
import { memo, useEffect, useState } from "react";
import { handleDataTable } from "../../../../utils/reusableFunction";
import ApiService from "../../../../services/ApiService";
import FormInfo from "../../../../components/Components/FormInfo";
import SelectDataContact from "../Selects/SelectDataContact";
import SelectDataNonContact from "../Selects/SelectDataNonContact";

const ShipmentOrderTrucking = memo(({
    isEdit,
    ShipmentNo,
    TotalSub,
    BookingId,
    BookingConfirmationNumber,
    isJobClose,
    BookingDate,
    setBookingDate,
    DeliveryType,
    setDeliveryType,
    ShipmentType,
    setShipmentType,
    ShipperId,
    ShipperName,
    ShipperAddress,
    setShipperId,
    setShipperName,
    setShipperAddress,
    ConsigneeId,
    ConsigneeName,
    ConsigneeAddress,
    setConsigneeId,
    setConsigneeName,
    setConsigneeAddress,
    LoadingPortId,
    setLoadingPortId,
    setLoadingPortCode,
    LoadingPortName,
    setLoadingPortName,
    DeliveryPortId,
    setDeliveryPortId,
    setDeliveryPortCode,
    DeliveryPortName,
    setDeliveryPortName,
    ETD,
    setETD,
    ETA,
    setETA,
    PICName,
    setPICName,
    SIReference,
    setSIReference,
    VesselId,
    VesselName,
    setVesselId,
    setVesselName,
    Voyage,
    setVoyage,
    Stuffing,
    setStuffing,
    OceanMSTBLNo,
    setOceanMSTBLNo,
    freightStatusOBL,
    setFreightStatusOBL,
    freightCollectIdOBL,
    setFreightCollectIdOBL,
    freightCollectCodeOBL,
    setFreightCollectCodeOBL,
    freightCollectNameOBL,
    setFreightCollectNameOBL,
    freightStatusHBL,
    setFreightStatusHBL,
    freightCollectIdHBL,
    setFreightCollectIdHBL,
    freightCollectCodeHBL,
    setFreightCollectCodeHBL,
    freightCollectNameHBL,
    setFreightCollectNameHBL,
    ShipmentId
}) => {
    const [titleModalC, setTitleModalC] = useState('');
    const [isModalC, setIsModalC] = useState(false);
    const [contactTypeId, setContactTypeId] = useState(0);
    const paramC = 'regContact/regContact';
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const [titleModalN, setTitleModalN] = useState('');
    const [isModalN, setIsModalN] = useState(false);
    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [dataTableN, setDataTableN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);

    const dataDeliveryType = [
        { id: 1, name: 'SHIPPER - CONSIGNEE' },
        { id: 2, name: 'SHIPPER - WHS - CONSIGNEE' },
        { id: 3, name: 'WHS - CONSIGNEE' },
    ];

    const dataShipmentType = [
        { id: 1, name: 'EXPORT' },
        { id: 2, name: 'IMPORT' },
        { id: 2, name: 'DOMESTIC' },
    ];

    const dataFreight = [
        { id: 1, code: 'P', name: 'Prepaid' },
        { id: 2, code: 'C', name: 'Collect' },
    ];

    useEffect(() => {
        getDataContact(1, 50);
        // eslint-disable-next-line
    }, [contactTypeId]);

    useEffect(() => {
        getDataNonContact(1, 50);
        // eslint-disable-next-line
    }, [titleModalN]);

    const handleSelectOBL = (val) => {
        setFreightStatusOBL(val);
        searchFreightCollectOBL('delete');
    }

    const handleSelectHBL = (val) => {
        setFreightStatusHBL(val);
        searchFreightCollectHBL('delete');
    }

    const handleClickC = () => {
        if (isModalC === false) {
            setIsModalC(true);
            setIsLoadingC(true);
        }
        else {
            setIsModalC(false);
        }
    };

    const searchShipper = (type) => {
        if (type === 'search') {
            setTitleModalC('Shipper');
            setContactTypeId(2);
            handleClickC();
        } else {
            setShipperId(0);
            setShipperName('');
            setShipperAddress('');
        }
    }

    const searchConsignee = (type) => {
        if (type === 'search') {
            setTitleModalC('Consignee');
            setContactTypeId(3);
            handleClickC();
        } else {
            setConsigneeId(0);
            setConsigneeName('');
            setConsigneeAddress('');
        }
    }

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        ApiService.ContactPostByPage(paramC, contactTypeId, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.contact);

                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false);
                showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                setIsModalC(false);
            })
    }

    const handleClickN = () => {
        if (isModalN === false) {
            setIsModalN(true);
            setIsLoadingN(true);
        }
        else {
            setIsModalN(false);
        }
    };

    const searchVessel = (type) => {
        if (type === 'search') {
            setTitleModalN('Vessel');
            handleClickN();
        } else {
            setVesselId(0);
            setVesselName('');
        }
    }

    const searchLoadingPort = (type) => {
        if (type === 'search') {
            setTitleModalN('Port Of Loading');
            handleClickN();
        } else {
            setLoadingPortId(0);
            setLoadingPortCode('');
            setLoadingPortName('');
        }
    }

    const searchDeliveryPort = (type) => {
        if (type === 'search') {
            setTitleModalN('Port Of Delivery');
            handleClickN();
        } else {
            setDeliveryPortId(0);
            setDeliveryPortCode('');
            setDeliveryPortName('');
        }
    }

    const searchFreightCollectOBL = (type) => {
        if (type === 'search') {
            setTitleModalN('Freight Collect OBL');
            handleClickN();
        }
        else if (type === 'delete') {
            setFreightCollectIdOBL(0);
            setFreightCollectCodeOBL('');
            setFreightCollectNameOBL('');
        }
    };

    const searchFreightCollectHBL = (type) => {
        if (type === 'search') {
            setTitleModalN('Freight Collect HBL');
            handleClickN();
        }
        else if (type === 'delete') {
            setFreightCollectIdHBL(0);
            setFreightCollectCodeHBL('');
            setFreightCollectNameHBL('');
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        if (titleModalN === 'Vessel') {
            param = 'vessel/vessel';
        } else if (titleModalN === 'Port Of Delivery' || titleModalN === 'Port Of Loading') {
            param = 'seaport/seaport';
        } else if (titleModalN === 'Freight Collect OBL' || titleModalN === 'Freight Collect HBL') {
            param = 'city/city';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (titleModalN) {
                            case 'Vessel':
                                setDataN(response.data.data.vessel);
                                temp = response.data.data.vessel;
                                break;
                            case 'Port Of Delivery':
                                setDataN(response.data.data.seaPort);
                                temp = response.data.data.seaPort;
                                break;
                            case 'Port Of Loading':
                                setDataN(response.data.data.seaPort);
                                temp = response.data.data.seaPort;
                                break;
                            case 'Freight Collect OBL':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;
                            case 'Freight Collect HBL':
                                setDataN(response.data.data.city);
                                temp = response.data.data.city;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableN, 'id');
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                    setIsModalN(false);
                })
        }
    }

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <form>
                    <FormInfo icon={'approve-doc'} isTop={true}>
                        <Grid container sx={{ display: 'flex' }}>
                            <Grid item xs={4}>
                                <TextInfo title={'Shipment Order Number'} value={ShipmentNo} isHidden={!isEdit} />

                                <Input
                                    label="Booking Confirmation No."
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={BookingConfirmationNumber}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: isEdit ? '16px !important' : '0px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    BookingId !== 0 && (
                                                        <IconButton hidden>
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#BE2957',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={BookingId || ShipmentId}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#00286D',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <div style={{ marginTop: '16px' }}>
                                    <Selection
                                        id='0'
                                        label={'Delivery Type'}
                                        fullWidth={true}
                                        placeholder={'Select Delivery Type'}
                                        value={DeliveryType}
                                        onChange={(e) => setDeliveryType(e.target.value)}
                                        disabled={isJobClose}
                                    >
                                        {
                                            dataDeliveryType.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Selection>
                                </div>
                            </Grid>

                            <Grid item xs={4} sx={{ marginLeft: '64px' }}>
                                <TextInfo title={'Total Sub Shipment Order'} value={TotalSub} isHidden={!isEdit} />

                                <CustomDatePicker
                                    label={'Booking Date'}
                                    value={BookingDate}
                                    onChange={setBookingDate}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: isEdit ? '16px !important' : '0px !important'
                                        }
                                    }}
                                    disabled={isJobClose}
                                />

                                <div style={{ marginTop: '16px' }}>
                                    <Selection
                                        id='0'
                                        label={'Shipment Type'}
                                        fullWidth={true}
                                        placeholder={'Select Shipment Type'}
                                        value={ShipmentType}
                                        onChange={(e) => setShipmentType(e.target.value)}
                                        disabled={isJobClose}
                                    >
                                        {
                                            dataShipmentType.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Selection>
                                </div>
                            </Grid>
                        </Grid>
                    </FormInfo>

                    <FormInfo icon={'approve-doc'}>
                        <Grid container sx={{ display: 'flex' }}>
                            <Grid item xs={4}>
                                <Stack direction={'column'} spacing={'8px'}>
                                    <Input
                                        label="Shipper"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={ShipperName}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {
                                                        ShipperId !== 0 && (
                                                            <IconButton hidden={ShipperId === 0} onClick={() => searchShipper('delete')}>
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#BE2957',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )
                                                    }

                                                    <IconButton onClick={() => searchShipper('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#00286D',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                    <Input
                                        value={ShipperAddress}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            rows: 6,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                    />
                                </Stack>

                                <Input
                                    label="Port of Loading"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={LoadingPortName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    LoadingPortId !== 0 && (
                                                        <IconButton onClick={() => searchLoadingPort('delete')}>
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#BE2957',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchLoadingPort('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#00286D',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <CustomDatePicker
                                    label={'ETD'}
                                    value={ETD}
                                    onChange={setETD}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={isJobClose}
                                />
                            </Grid>

                            <Grid item xs={4} sx={{ marginLeft: '64px' }}>
                                <Stack direction={'column'} spacing={'8px'}>
                                    <Input
                                        label="Consignee"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={ConsigneeName}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px'
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {
                                                        ConsigneeId !== 0 && (
                                                            <IconButton onClick={() => searchConsignee('delete')}>
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#BE2957',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )
                                                    }

                                                    <IconButton onClick={() => searchConsignee('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#00286D',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                    <Input
                                        value={ConsigneeAddress}
                                        multiline
                                        fullWidth
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            rows: 6,
                                            sx: {
                                                padding: '8px',
                                            },
                                        }}
                                    />
                                </Stack>

                                <Input
                                    label="Port of Delivery"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={DeliveryPortName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    DeliveryPortId !== 0 && (
                                                        <IconButton onClick={() => searchDeliveryPort('delete')}>
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#BE2957',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchDeliveryPort('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#00286D',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <CustomDatePicker
                                    label={'ETA'}
                                    value={ETA}
                                    onChange={setETA}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    disabled={isJobClose}
                                />
                            </Grid>
                        </Grid>
                    </FormInfo>

                    <FormInfo>
                        <Grid container sx={{ display: 'flex' }}>
                            <Grid item xs={4}>
                                <Input
                                    label="PIC"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={PICName}
                                    onChange={(e) => setPICName(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Reference SI From Shipper"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={SIReference}
                                    onChange={(e) => setSIReference(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Input
                                    label="Vessel"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={VesselName}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    VesselId !== 0 && (
                                                        <IconButton onClick={() => searchVessel('delete')}>
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#BE2957',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton onClick={() => searchVessel('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#00286D',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Input
                                    label="Voyage"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={Voyage}
                                    onChange={(e) => setVoyage(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '8px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} sx={{ marginLeft: '64px' }}>
                                <Input
                                    label="Stuffing"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={Stuffing}
                                    onChange={(e) => setStuffing(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Ocean or Master B/L Number"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={OceanMSTBLNo}
                                    onChange={(e) => setOceanMSTBLNo(e.target.value)}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormInfo>

                    <FormInfo>
                        <Grid container sx={{ display: 'flex' }}>
                            <Grid item xs={4}>
                                <CustomRadioGroup
                                    title={'Freight Status per Ocean or Master B/L'}
                                    isChecked={freightStatusOBL}
                                    setIsChecked={handleSelectOBL}
                                    isDisabled={isEdit}
                                    dataGroup={dataFreight}
                                    dataLength={dataFreight.length}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={freightStatusOBL === 'C' ? freightCollectCodeOBL + ' - ' + freightCollectNameOBL : ''}
                                    type='text'
                                    disabled={freightStatusOBL === 'C' ? false : true}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '8px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    freightCollectIdOBL !== 0 && (
                                                        <IconButton hidden={freightStatusOBL === 'C' ? false : true} onClick={() => searchFreightCollectOBL('delete')}>
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#BE2957',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={freightStatusOBL === 'C' ? false : true} onClick={() => searchFreightCollectOBL('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#00286D',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid item xs={4} sx={{ marginLeft: '64px' }}>
                                <CustomRadioGroup
                                    title={'Freight House B/L'}
                                    isChecked={freightStatusHBL}
                                    setIsChecked={handleSelectHBL}
                                    isDisabled={isEdit}
                                    dataGroup={dataFreight}
                                    dataLength={dataFreight.length}
                                />

                                <Input
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={freightStatusHBL === 'C' ? freightCollectCodeHBL + ' - ' + freightCollectNameHBL : ''}
                                    type='text'
                                    disabled={freightStatusHBL === 'C' ? false : true}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '8px',
                                            marginBottom: '24px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    freightCollectIdHBL !== 0 && (
                                                        <IconButton hidden={freightStatusHBL === 'C' ? false : true} onClick={() => searchFreightCollectHBL('delete')}>
                                                            <Icon
                                                                iconName={'close'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#BE2957',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    )
                                                }

                                                <IconButton hidden={freightStatusHBL === 'C' ? false : true} onClick={() => searchFreightCollectHBL('search')}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#00286D',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </FormInfo>
                </form>
            </div>

            {
                isModalC && (
                    <SelectDataContact
                        isModal={isModalC}
                        handleClick={handleClickC}
                        titleModal={titleModalC}
                        tipe={contactTypeId}
                        setTipe={setContactTypeId}
                        setIsModal={setIsModalC}

                        getData={getDataContact}
                        isLoading={isLoadingC}
                        setIsLoading={setIsLoadingC}
                        data={dataC}
                        setData={setDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setDataMap={setDataMapC}
                        columnData={columnDataC}
                        setColumnData={setColumnDataC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}

                        setShipperId={setShipperId}
                        setShipperName={setShipperName}
                        setShipperAddress={setShipperAddress}

                        setConsigneeId={setConsigneeId}
                        setConsigneeName={setConsigneeName}
                        setConsigneeAddress={setConsigneeAddress}
                    />
                )
            }

            {
                isModalN && (
                    <SelectDataNonContact
                        isModal={isModalN}
                        handleClick={handleClickN}
                        titleModal={titleModalN}
                        setIsModal={setIsModalN}
                        setTitleModal={setTitleModalN}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        dataTable={dataTableN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setVesselId={setVesselId}
                        setVesselName={setVesselName}

                        setLoadingPortId={setLoadingPortId}
                        setLoadingPortCode={setLoadingPortCode}
                        setLoadingPortName={setLoadingPortName}

                        setDeliveryPortId={setDeliveryPortId}
                        setDeliveryPortCode={setDeliveryPortCode}
                        setDeliveryPortName={setDeliveryPortName}

                        setFreightCollectIdOBL={setFreightCollectIdOBL}
                        setFreightCollectCodeOBL={setFreightCollectCodeOBL}
                        setFreightCollectNameOBL={setFreightCollectNameOBL}

                        setFreightCollectIdHBL={setFreightCollectIdHBL}
                        setFreightCollectCodeHBL={setFreightCollectCodeHBL}
                        setFreightCollectNameHBL={setFreightCollectNameHBL}
                    />
                )
            }
        </>
    )
})

export default memo(ShipmentOrderTrucking);