import React, { useEffect, useRef, useState } from 'react'
import { Grid, Box, Button, Typography, Stack, tableCellClasses, TableCell, TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import { forwardRef } from 'react';
import Papa from "papaparse";
import { styled } from '@mui/material/styles';
import freezeContainer from '../../../Styles/freezeContainer';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import CustomToastContainer, { Icon, showToast } from '../../../components/ui';

const SandboxPage = forwardRef((props, ref) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));
    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));
    // const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    // const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const btnPageRef = useRef(null);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Sandbox', link: '/Majura/Sandbox', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Sandbox - ' + JSON.parse(localStorage.getItem('branchName'));
    }, []);

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file && file.type !== "text/csv") {
            showToast({ type: 'error', message: 'Invalid file type. Please upload a CSV file.' });
            return;
        }

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const rows = result.data;

                if (rows.length === 0) {
                    showToast({ type: 'error', message: 'No data found in the file.' });
                    return;
                }

                const columnHeaders = Object.keys(rows[0]);
                setColumns(columnHeaders);
                setData(rows);
            }
        });
    };

    const getContainerHeight = () => {
        const totalHeight = 20 + 60 + 110 + 10 + 40
        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                        <div>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '3px 6px',
                                    gap: '4px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '18px !important',
                                    minHeight: '28px !important',
                                    borderRadius: '8px',
                                    '&:hover': {
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                            >
                                <input
                                    type="file"
                                    // accept="image/*"
                                    id="raised-button-file"
                                    onChange={handleFileChange}
                                    style={{ display: 'none', cursor: 'pointer' }}
                                />
                                <label htmlFor="raised-button-file" style={{ cursor: 'pointer' }}>
                                    <Stack direction={'row'} sx={{ justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                                        <Icon
                                            svgIconProps={{
                                                sx: {
                                                    height: '12px',
                                                    width: '12px',
                                                }
                                            }}
                                            iconName={'add'}
                                        />
                                        <Typography
                                            sx={{
                                                fontFamily: 'Nunito',
                                                marginTop: '2px !important',
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                lineHeight: '20px',
                                                letterSpacing: '0em',
                                                textAlign: 'center',
                                                color: 'inherit',
                                            }}
                                        >
                                            Upload File CSV
                                        </Typography>
                                    </Stack>
                                </label>
                            </Button>
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '16px 32px 24px', ...freezeContainer }}>
            <CustomToastContainer />

            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Sandbox'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px", marginTop: '16px' }}>
                    <TableContainer sx={{ maxHeight: `calc(100vh - ${getContainerHeight()})`, borderRadius: '12px', background: '#FFF', marginTop: '8px' }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.length > 0 ?
                                            columns.map((el, index) => {
                                                return (
                                                    <StyledTableCell key={index}>{el}</StyledTableCell>
                                                )
                                            })
                                            :
                                            null
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    data.length > 0 ?
                                        data.map((el) => {
                                            return (
                                                <TableRow
                                                    key={el.sequence}
                                                    // onClick={() => setSelectedDetail(el)}
                                                    // onDoubleClick={() => ShowEditContainer()}
                                                    // sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                    hover={true}
                                                >
                                                    {
                                                        columns.map((col, index) => {
                                                            return (
                                                                <StyledTableBodyCell key={index}>{el[col]}</StyledTableBodyCell>
                                                            )
                                                        })
                                                    }
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow>
                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px',
                                                        fontstyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal'
                                                    }}
                                                >
                                                    Data Empty
                                                </Typography>
                                            </StyledTableBodyCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </Box>
    )
});

export default SandboxPage