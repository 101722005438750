import React from 'react'
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';

import Grid from '@mui/material/Grid';

const ButtonBalanceSheet = (props) => {
    return (
        <div className="row" spacing={'12px'}>
            <div className='col-12' spacing={'12px'}>
                <Grid container item spacing={'12px'} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {ButtonComponent.PrintButton(props.cetak, 'PDF')}
                    {ButtonComponent.PrintButton(props.cetak2, "PDF Explanation")}
                    {ButtonComponent.ExcelButton(props.excel, "Print Excel")}
                    {ButtonComponent.ExcelButton(props.excel2, "Print Excel Explanation")}
                    {
                        props.dataRegUserAccess.length !== 0 && props.dataRegUserAccess.find(elm => elm.accessCode === 'LOG') && (
                            <>
                                {ButtonComponent.CustomButton(props.handleClickLog, 'clock', 'LOG')}
                            </>
                        )
                    }
                </Grid>
            </div>
        </div>
    )
}

export default ButtonBalanceSheet;