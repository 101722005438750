import { ReactComponent as AddIcon } from "./addnew.icon.svg";
import { ReactComponent as AddTimeIcon } from "./addtime.icon.svg";
import { ReactComponent as AdjustIcon } from "./adjust.icon.svg";
import { ReactComponent as ApproveDocIcon } from "./document-approved.icon.svg";
import { ReactComponent as ArchiveIcon } from "./archieve.icon.svg";
import { ReactComponent as ArrowDownIcon } from "./arrowdown.icon.svg";
import { ReactComponent as ArrowLeftIcon } from "./arrow.icon.svg";
import { ReactComponent as ArrowUpIcon } from "./arrowup.icon.svg";
import { ReactComponent as BriefcaseIcon } from "./briefcase.icon.svg";
import { ReactComponent as CancelIcon } from "./cancel.icon.svg";
import { ReactComponent as CargoIcon } from "./cargo.icon.svg";
import { ReactComponent as ChangeIcon } from "./change.icon.svg";
import { ReactComponent as ChartIcon } from "./finance.icon.svg";
import { ReactComponent as CheckboxCheckedIcon } from "./checkbox-checked.svg";
import { ReactComponent as CheckboxEmptyIcon } from "./checkbox-empty.svg";
import { ReactComponent as ChecklistIcon } from "./checklist.icon.svg";
import { ReactComponent as CloseIcon } from "./close.icon.svg";
import { ReactComponent as ConfigIcon } from "./config.icon.svg";
import { ReactComponent as ConvertIcon } from "./convert.icon.svg";
import { ReactComponent as CopyIcon } from "./copy.icon.svg";
import { ReactComponent as CSIcon } from "./custservice.icon.svg";
import { ReactComponent as DashboardIcon } from "./dashboard.icon.svg";
import { ReactComponent as DatabaseIcon } from "./master-data.icon.svg";
import { ReactComponent as DeclineDocIcon } from "./decline.icon.svg";
import { ReactComponent as DeleteIcon } from "./delete.icon.svg";
import { ReactComponent as DeliveryIcon } from "./delivery.icon.svg";
import { ReactComponent as DetailIcon } from "./detail.icon.svg";
import { ReactComponent as DollarCircleIcon } from "./dollar.icon.svg";
import { ReactComponent as EPLIcon } from "./epl.icon.svg";
import { ReactComponent as EditIcon } from "./edit.icon.svg";
import { ReactComponent as ExcelIcon } from "./excel.icon.svg";
import { ReactComponent as EyeOffIcon } from "./hidepassword.icon.svg";
import { ReactComponent as EyeOnIcon } from "./seepassword.icon.svg";
import { ReactComponent as FilterIcon } from "./filter.icon.svg";
import { ReactComponent as FinishIcon } from "./finish.icon.svg";
import { ReactComponent as FolderOpenIcon } from "./open.icon.svg";
import { ReactComponent as HandIcon } from "./picking.icon.svg";
import { ReactComponent as IgnoreDocIcon } from "./ignore.icon.svg";
import { ReactComponent as InsertIcon } from "./insert.icon.svg";
import { ReactComponent as JournalIcon } from "./journal.icon.svg";
import { ReactComponent as LoginIcon } from "./login.icon.svg";
import { ReactComponent as PeoplesIcon } from "./peoples.icon.svg";
import { ReactComponent as PrintDocIcon } from "./reprint.icon.svg";
import { ReactComponent as PrinterIcon } from "./printer.icon.svg";
import { ReactComponent as RefreshIcon } from "./reset.icon.svg";
import { ReactComponent as RejectIcon } from "./reject.icon.svg";
import { ReactComponent as RemoveIcon } from "./remove.icon.svg";
import { ReactComponent as RestoreIcon } from "./restore.icon.svg";
import { ReactComponent as ReturnIcon } from "./return.icon.svg";
import { ReactComponent as SaveIcon } from "./save.icon.svg";
import { ReactComponent as SearchDocIcon } from "./document-search.icon.svg";
import { ReactComponent as SearchIcon } from "./search.icon.svg";
import { ReactComponent as SelectIcon } from "./select.icon.svg";
import { ReactComponent as SignalIcon } from "./reports.icon.svg";
import { ReactComponent as SubmitDocIcon } from "./submit.icon.svg";
import { ReactComponent as TaxIcon } from "./tax.icon.svg";
import { ReactComponent as UnApproveDocIcon } from "./unapprove.icon.svg";
import { ReactComponent as UploadIcon } from "./upload.icon.svg";
import { ReactComponent as WarehouseIcon } from "./warehouse.icon.svg";
import { ReactComponent as BellIcon } from "./bell.icon.svg";
import { ReactComponent as BookIcon } from "./book.icon.svg";
import { ReactComponent as ThreeDotIcon } from "./optiondots.icon.svg";
import { ReactComponent as PDFIcon } from "./pdfexport.icon.svg";
import { ReactComponent as PostedIcon } from "./posted.icon.svg";
import { ReactComponent as StatusDeletedIcon } from "./statusdeleted.icon.svg";
import { ReactComponent as DocumentIcon } from "./document.icon.svg";
import { ReactComponent as DocumentAllIcon } from "./document-all.icon.svg";
import { ReactComponent as AccountIcon } from './account.icon.svg';
import { ReactComponent as DocumentShareIcon } from './document-share.icon.svg';
import { ReactComponent as UnlockIcon } from './unlock.icon.svg';
import { ReactComponent as LockIcon } from './lock.icon.svg';
import { ReactComponent as LineGraphIcon } from './line-graph.icon.svg';
import { ReactComponent as MailIcon } from './mail.icon.svg';
import { ReactComponent as PhoneIcon } from './phone.icon.svg';
import { ReactComponent as ThreeDotVertIcon } from './three-dot.icon.svg';
import { ReactComponent as PasswordIcon } from './password.icon.svg';
import { ReactComponent as ChangeUserIcon } from './change-user.icon.svg';
import { ReactComponent as LogOutIcon } from './log-out.icon.svg';
import { ReactComponent as DoubleArrowIcon } from './double-arrow.icon.svg';
import { ReactComponent as ActiveIcon } from './active.icon.svg';
import { ReactComponent as InActiveIcon } from './inactive.icon.svg';
import { ReactComponent as WarningIcon } from './warning.icon.svg';
import { ReactComponent as ArrowRightIcon } from './arrowright.icon.svg';
import { ReactComponent as OutlineSelectIcon } from './outlineselect.icon.svg';
import { ReactComponent as CheckCircleIcon } from './checkcircle.icon.svg';
import { ReactComponent as CalendarIcon } from './calendar.icon.svg';
import { ReactComponent as InfoIcon } from './info.icon.svg';
import { ReactComponent as NotificationIcon } from './notification.icon.svg';
import { ReactComponent as DownloadIcon } from './download.icon.svg';
import { ReactComponent as SortArrowIcon } from './sort-arrow.icon.svg';
import { ReactComponent as SortUpIcon } from './sort-up.icon.svg';
import { ReactComponent as SortDownIcon } from './sort-down.icon.svg';
import { ReactComponent as PopUpIcon } from './pop-up.icon.svg';
import { ReactComponent as DocumentOutlineIcon } from './document-outline.icon.svg';
import { ReactComponent as MaximizeIcon } from './maximize.icon.svg';
import { ReactComponent as MinimizeIcon } from './minimize.icon.svg';
import { ReactComponent as SortLeftIcon } from './sort-left.icon.svg';
import { ReactComponent as AccountOutlineIcon } from './account-outline.icon.svg';
import { ReactComponent as ClockIcon } from './clock.icon.svg';

export const icons = {
  'add': AddIcon,
  'add-time': AddTimeIcon,
  'adjust': AdjustIcon,
  'approve-doc': ApproveDocIcon,
  'archive': ArchiveIcon,
  'arrow-down': ArrowDownIcon,
  'arrow-left': ArrowLeftIcon,
  'arrow-up': ArrowUpIcon,
  'briefcase': BriefcaseIcon,
  'cancel': CancelIcon,
  'cargo': CargoIcon,
  'change': ChangeIcon,
  'chart': ChartIcon,
  'checkbox-checked': CheckboxCheckedIcon,
  'checkbox-empty': CheckboxEmptyIcon,
  'checklist': ChecklistIcon,
  'close': CloseIcon,
  'config': ConfigIcon,
  'convert': ConvertIcon,
  'copy': CopyIcon,
  'cs': CSIcon,
  'dashboard': DashboardIcon,
  'database': DatabaseIcon,
  'decline-doc': DeclineDocIcon,
  'delete': DeleteIcon,
  'delivery': DeliveryIcon,
  'detail': DetailIcon,
  'dollar-circle': DollarCircleIcon,
  'epl': EPLIcon,
  'edit': EditIcon,
  'excel': ExcelIcon,
  'eye-off': EyeOffIcon,
  'eye-on': EyeOnIcon,
  'filter': FilterIcon,
  'finish': FinishIcon,
  'folder-open': FolderOpenIcon,
  'hand': HandIcon,
  'ignore-doc': IgnoreDocIcon,
  'insert': InsertIcon,
  'journal': JournalIcon,
  'login': LoginIcon,
  'peoples': PeoplesIcon,
  'print-doc': PrintDocIcon,
  'printer': PrinterIcon,
  'refresh': RefreshIcon,
  'reject': RejectIcon,
  'remove': RemoveIcon,
  'restore': RestoreIcon,
  'return': ReturnIcon,
  'save': SaveIcon,
  'search-doc': SearchDocIcon,
  'search': SearchIcon,
  'select': SelectIcon,
  'signal': SignalIcon,
  'submit-doc': SubmitDocIcon,
  'tax': TaxIcon,
  'unApprove-doc': UnApproveDocIcon,
  'upload': UploadIcon,
  'warehouse': WarehouseIcon,
  'bell': BellIcon,
  'book': BookIcon,
  'three-dot': ThreeDotIcon,
  'pdf-export': PDFIcon,
  'posted': PostedIcon,
  'status-deleted': StatusDeletedIcon,
  'documents': DocumentIcon,
  'documents-all': DocumentAllIcon,
  'account': AccountIcon,
  'documents-share': DocumentShareIcon,
  'unlock': UnlockIcon,
  'lock': LockIcon,
  'line-graph': LineGraphIcon,
  'mail': MailIcon,
  'phone': PhoneIcon,
  'three-dot-vert': ThreeDotVertIcon,
  'password': PasswordIcon,
  'change-user': ChangeUserIcon,
  'log-out': LogOutIcon,
  'double-arrow': DoubleArrowIcon,
  'active': ActiveIcon,
  'inactive': InActiveIcon,
  'warning': WarningIcon,
  'arrow-right': ArrowRightIcon,
  'outline-select': OutlineSelectIcon,
  'check-circle': CheckCircleIcon,
  'calendar': CalendarIcon,
  'info': InfoIcon,
  'notification': NotificationIcon,
  'download': DownloadIcon,
  'sort-arrow': SortArrowIcon,
  'sort-up': SortUpIcon,
  'sort-down': SortDownIcon,
  'pop-up': PopUpIcon,
  'document-outline': DocumentOutlineIcon,
  'maximize': MaximizeIcon,
  'minimize': MinimizeIcon,
  'sort-left': SortLeftIcon,
  'account-outline': AccountOutlineIcon,
  'clock': ClockIcon,
};

export {
  AddIcon,
  AddTimeIcon,
  AdjustIcon,
  ApproveDocIcon,
  ArchiveIcon,
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowUpIcon,
  BriefcaseIcon,
  CancelIcon,
  CargoIcon,
  ChangeIcon,
  ChartIcon,
  CheckboxCheckedIcon,
  CheckboxEmptyIcon,
  ChecklistIcon,
  CloseIcon,
  ConfigIcon,
  ConvertIcon,
  CopyIcon,
  CSIcon,
  DashboardIcon,
  DatabaseIcon,
  DeclineDocIcon,
  DeleteIcon,
  DeliveryIcon,
  DetailIcon,
  DollarCircleIcon,
  EPLIcon,
  EditIcon,
  ExcelIcon,
  EyeOffIcon,
  EyeOnIcon,
  FilterIcon,
  FinishIcon,
  FolderOpenIcon,
  HandIcon,
  IgnoreDocIcon,
  InsertIcon,
  JournalIcon,
  LoginIcon,
  PeoplesIcon,
  PrintDocIcon,
  PrinterIcon,
  RefreshIcon,
  RejectIcon,
  RemoveIcon,
  RestoreIcon,
  ReturnIcon,
  SaveIcon,
  SearchDocIcon,
  SearchIcon,
  SelectIcon,
  SignalIcon,
  SubmitDocIcon,
  TaxIcon,
  UnApproveDocIcon,
  UploadIcon,
  WarehouseIcon,
  BellIcon,
  BookIcon,
  ThreeDotIcon,
  PDFIcon,
  PostedIcon,
  StatusDeletedIcon,
  DocumentIcon,
  DocumentAllIcon,
  AccountIcon,
  DocumentShareIcon,
  UnlockIcon,
  LockIcon,
  LineGraphIcon,
  MailIcon,
  PhoneIcon,
  ThreeDotVertIcon,
  PasswordIcon,
  ChangeUserIcon,
  LogOutIcon,
  DoubleArrowIcon,
  ActiveIcon,
  InActiveIcon,
  WarningIcon,
  ArrowRightIcon,
  OutlineSelectIcon,
  CheckCircleIcon,
  CalendarIcon,
  InfoIcon,
  NotificationIcon,
  DownloadIcon,
  SortArrowIcon,
  SortUpIcon,
  SortDownIcon,
  PopUpIcon,
  DocumentOutlineIcon,
  MaximizeIcon,
  MinimizeIcon,
  SortLeftIcon,
  AccountOutlineIcon,
  ClockIcon,
};