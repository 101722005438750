import React, { useState, useEffect, useRef } from "react";
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, Stack, InputAdornment, IconButton } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonRekapMutasi from './Buttons/ButtonRekapMutasi';
import SelectDataMaster from '../../../Master/MasterWarehouse/SelectDataMaster';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import TableX from '../../../../components/Tables/TableX';
import freezeContainer from "../../../../Styles/freezeContainer";
import CustomToastContainer, { CustomDatePicker, Icon, Input, Paginate, showToast } from "../../../../components/ui";
import HeaderInfo from "../../../../components/Components/HeaderInfo";
import { setDateFormat } from "../../../../utils/date";
import { handleDataTable } from "../../../../utils/reusableFunction";

const WarehouseRekapMutasi = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramProduct = "warehouse/product/product";
    const paramUnit = "warehouse/RegProductUnits/RegProductUnits";
    const paramReport = "reports/RekapMutasi/RekapMutasi";

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [isModalProductCategory, setIsModalProductCategory] = useState(false);
    const [isLoadingModalProductCategory, setIsLoadingModalProductCategory] = useState(false);
    const [dataModalProductCategory, setDataModalProductCategory] = useState([]);
    const [dataMapModalProductCategory, setDataMapModalProductCategory] = useState([]);
    const [dataTableModalProductCategory, setDataTableModalProductCategory] = useState([]);
    const [columnDataModalProductCategory, setColumnDataModalProductCategory] = useState([]);
    const [SelectedDataModalProductCategory, setSelectedDataModalProductCategory] = useState({});
    const [totalRowsModalProductCategory, setTotalRowsModalProductCategory] = useState(50);

    const [isModalProduct, setIsModalProduct] = useState(false);
    const [isLoadingModalProduct, setIsLoadingModalProduct] = useState(false);
    const [dataModalProduct, setDataModalProduct] = useState([]);
    const [dataMapModalProduct, setDataMapModalProduct] = useState([]);
    const [dataTableModalProduct, setDataTableModalProduct] = useState([]);
    const [columnDataModalProduct, setColumnDataModalProduct] = useState([]);
    const [SelectedDataModalProduct, setSelectedDataModalProduct] = useState({});
    const [totalRowsModalProduct, setTotalRowsModalProduct] = useState(50);

    const [isModalUnit, setIsModalUnit] = useState(false);
    const [isLoadingModalUnit, setIsLoadingModalUnit] = useState(false);
    const [dataModalUnit, setDataModalUnit] = useState([]);
    const [dataMapModalUnit, setDataMapModalUnit] = useState([]);
    const [dataTableModalUnit, setDataTableModalUnit] = useState([]);
    const [columnDataModalUnit, setColumnDataModalUnit] = useState([]);
    const [SelectedDataModalUnit, setSelectedDataModalUnit] = useState({});
    const [totalRowsModalUnit, setTotalRowsModalUnit] = useState(50);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [dtFrom, setDtFrom] = useState('');
    const [dtTo, setDtTo] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [unitId, setUnitId] = useState('');
    const [unitName, setUnitName] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Rekap Mutasi Report', link: '/Majura/ReportRekapMutasi', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Rekap Mutasi Report - ' + JSON.parse(localStorage.getItem('branchName'));
        setIsLoadingBackDrop(false);
        getDateNow();
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDtFrom);
        setDateFormat(new Date(), setDtTo);
    }

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'RWHRM')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            getDateNow();
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRB() {
        if (titleConfirmation === 'Print PDF') {
            PrintPDF();
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Report InBound');
        handleClickConfirmation();
    };

    const PrintExcel = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Report InBound');
        handleClickConfirmation();
    }

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        ApiService.DynamicPostByPageWarehouse(paramReport, 'PAGE', pageNumber, pageSize, getParams(), filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});

                    let temp = response.data.data.rekapMutasi;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index,
                        };
                        return indexedTemp;
                    });

                    setDataMap(indexed);
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns);
                    setTotalRows(response.data.totalRowCount);

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    setIsLoadingBackDrop(false);
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
                console.error("error saat fetch", error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            });
    }

    function PrintPDF() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouse(paramReport, "PDF", [], getParams())
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Buat URL objek untuk blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Buat tautan unduhan
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = 'Report_RekapMutasi.pdf';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();

                // Hapus elemen tautan setelah pengunduhan selesai
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);

                getData(numPage, rowsCount, filter, sortArr);
                setIsLoadingBackDrop(false);
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Print PDF, Please Contact The Administrator, ' + error });
            });
    }

    function PrintExl() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouse(paramReport, "Excel", [], getParams())
            .then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Buat URL objek untuk blob
                    const blobUrl = window.URL.createObjectURL(blob);

                    // Buat tautan unduhan
                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = 'Report_RekapMutasi.xlsx';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();

                    // Hapus elemen tautan setelah pengunduhan selesai
                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);

                    setIsLoadingBackDrop(false);
                } else {
                    setIsLoadingBackDrop(false);
                    throw new Error("Failed, " + response.status);
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Error When Print Excel, Please Contact The Administrator, ' + error });
            });
    }

    const handleClickProductCategory = () => {
        if (isModalProductCategory === false) {
            setIsModalProductCategory(true);
            getDataProductCategory(1, 5);
        }
        else {
            setIsModalProductCategory(false);
        }
    };

    const handleClickProduct = () => {
        if (isModalProduct === false) {
            setIsModalProduct(true);
            getDataProduct(1, 5);
        } else {
            setIsModalProduct(false);
        }
    }

    const handleClickUnit = () => {
        if (isModalUnit === false) {
            setIsModalUnit(true);
            getDataUnit(1, 5);
        } else {
            setIsModalUnit(false);
        }
    }

    const getDataProductCategory = (pageNumber, pageSize, filters) => {
        setIsLoadingModalProduct(true);
        ApiService.PostByPage(paramProductCategory, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalProductCategory({})
                    setDataModalProductCategory(response.data.data.productCategory);
                    let temp = response.data.data.productCategory;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalProductCategory(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableModalProductCategory, 'id');
                    setColumnDataModalProductCategory(response.data.data.columns)
                    setTotalRowsModalProductCategory(response.data.totalRowCount)

                    setIsLoadingModalProductCategory(false)
                }
                setIsLoadingModalProductCategory(false)
            })
            .catch(function (error) {
                setIsLoadingModalProductCategory(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataProduct = (pageNumber, pageSize, filters) => {
        setIsLoadingModalProduct(true);
        if (productCategoryId !== "") {
            let filter = {
                "field": "productCategoryId",
                "data": productCategoryId.toString()
            };
            if (filters) {
                filters = [...filters, filter];
            } else {
                filters = [filter];
            }
        }
        ApiService.PostByPage(paramProduct, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalProduct({})
                    setDataModalProduct(response.data.data.product);
                    let temp = response.data.data.product;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalProduct(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableModalProduct, 'id');
                    setColumnDataModalProduct(response.data.data.columns)
                    setTotalRowsModalProduct(response.data.totalRowCount)

                    setIsLoadingModalProduct(false)
                }
                setIsLoadingModalProduct(false)
            })
            .catch(function (error) {
                setIsLoadingModalProduct(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataUnit = (pageNumber, pageSize, filters) => {
        setIsLoadingModalUnit(true);
        if (productId !== "") {
            let filter = {
                "field": "productId",
                "data": productId.toString()
            };
            if (filters) {
                filters = [...filters, filter];
            } else {
                filters = [filter];
            }
        }
        ApiService.PostByPage(paramUnit, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalUnit({})
                    setDataModalUnit(response.data.data.productUnits);
                    let temp = response.data.data.productUnits;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalUnit(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableModalUnit, 'id');
                    setColumnDataModalUnit(response.data.data.columns)
                    setTotalRowsModalUnit(response.data.totalRowCount)

                    setIsLoadingModalUnit(false)
                }
                setIsLoadingModalUnit(false)
            })
            .catch(function (error) {
                setIsLoadingModalUnit(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectHandlerProductCategory = (selectedData) => {
        if (selectedData.id !== props.productCategoryId) {
            setProductId('');
            setProductName('');
        }
        setProductCategoryId(selectedData.id);
        setProductCategoryName(selectedData.name);
    }

    const selectHandlerUnit = (selectedData) => {
        setUnitId(selectedData.unitId);
        setUnitName(selectedData.unitsName);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(1, 50, []);
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({});
        setSortArr([]);
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    const CellDoubleClick = () => {

    }

    const getParams = () => {
        let params = [
            {
                "attr": "StartDate",
                "value": (new Date(dtFrom)).toLocaleDateString("sv-se").split('T')[0],
                "mathSign": ">="
            },
            {
                "attr": "EndDate",
                "value": (new Date(dtTo)).toLocaleDateString("sv-se").split('T')[0],
                "mathSign": "<="
            }
        ];
        if (productCategoryId !== "") {
            params = [
                ...params,
                {
                    "attr": "productCategoryId",
                    "value": productCategoryId.toString(),
                    "mathSign": "="
                }
            ]
        }
        if (productId !== "") {
            params = [
                ...params,
                {
                    "attr": "productId",
                    "value": productId.toString(),
                    "mathSign": "="
                }
            ]
        }

        if (unitId === "") {
            params = [
                ...params,
                {
                    "attr": "issmallest",
                    "value": "1",
                    "mathSign": "="
                }
            ]
        } else {
            params = [
                ...params,
                {
                    "attr": "unitid",
                    "value": unitId.toString(),
                    "mathSign": "="
                }
            ]
        }
        return params;
    }

    useEffect(() => {
        if (productId === "") {
            setUnitName("");
            setUnitId("");
        }
    }, [productId, productCategoryId])

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 99
        const hPagination = filter.length > 0 ? 235 : 150
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter, sortArr);
    }

    const ButtonComponents = () => {
        return (
            <>
                <ButtonRekapMutasi
                    Reload={ReloadData}
                    PrintData={PrintData}
                    PrintExcel={PrintExcel}
                    DataRegUserAccess={DataRegUserAccess}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div>
                        <Grid
                            container
                            spacing={'24px'}
                        >
                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <Stack spacing={'16px'} direction="row">
                                    <CustomDatePicker
                                        fullWidth
                                        type='date'
                                        value={dtFrom}
                                        label="Period"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { setDtFrom(e); }}
                                        formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                                    />

                                    <CustomDatePicker
                                        fullWidth
                                        label="To"
                                        type='date'
                                        value={dtTo}
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { setDtTo(e); }}
                                        formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                                    />
                                </Stack>

                                <Input
                                    fullWidth
                                    variant="standard"
                                    label="Product Category"
                                    value={productCategoryName}
                                    InputLabelProps={{ shrink: true }}
                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    hidden={productCategoryId ? false : true}
                                                    onClick={() => {
                                                        setProductCategoryId("");
                                                        setProductCategoryName("");
                                                    }}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => handleClickProductCategory()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={5}
                                lg={5}
                                sm={5}
                                xs={12}
                            >
                                <Input
                                    fullWidth
                                    label="Product"
                                    variant="standard"
                                    value={productName}
                                    InputLabelProps={{ shrink: true }}
                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    hidden={productId ? false : true}
                                                    onClick={() => {
                                                        setProductId('');
                                                        setProductName('');
                                                        setUnitId('');
                                                        setUnitName('');
                                                    }}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton onClick={() => handleClickProduct()}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Input
                                    fullWidth
                                    label="Satuan"
                                    value={unitName}
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    hidden={unitName ? false : true}
                                                    onClick={() => {
                                                        setUnitId('');
                                                        setUnitName('');
                                                    }}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    hidden={productName ? false : true}
                                                    onClick={() => handleClickUnit()}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </>
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3, ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Rekap Mutasi Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#FFF'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1' id="data_result">
                                        <TableX
                                            dataTable={dataTable}
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalProductCategory && (
                    <SelectDataMaster
                        isModal={isModalProductCategory}
                        handleClick={handleClickProductCategory}
                        titleModal="ProductCategory" setIsModal={setIsModalProductCategory}

                        getData={getDataProductCategory}
                        isLoading={isLoadingModalProductCategory}
                        totalRows={totalRowsModalProductCategory}
                        setTotalRows={setTotalRowsModalProductCategory}
                        SelectedData={SelectedDataModalProductCategory}
                        setSelectedData={setSelectedDataModalProductCategory}
                        data={dataModalProductCategory}
                        columnData={columnDataModalProductCategory}
                        dataMap={dataMapModalProductCategory}
                        dataTable={dataTableModalProductCategory}

                        selectHandler={selectHandlerProductCategory}
                    />
                )
            }

            {
                isModalProduct && (
                    <SelectDataMaster
                        isModal={isModalProduct}
                        handleClick={handleClickProduct}
                        titleModal="Product" setIsModal={setIsModalProduct}

                        getData={getDataProduct}
                        isLoading={isLoadingModalProduct}
                        totalRows={totalRowsModalProduct}
                        setTotalRows={setTotalRowsModalProduct}
                        SelectedData={SelectedDataModalProduct}
                        setSelectedData={setSelectedDataModalProduct}
                        data={dataModalProduct}
                        columnData={columnDataModalProduct}
                        dataMap={dataMapModalProduct}
                        dataTable={dataTableModalProduct}

                        setId={setProductId}
                        setName={setProductName}
                    />
                )
            }

            {
                isModalUnit && (
                    <SelectDataMaster
                        isModal={isModalUnit}
                        handleClick={handleClickUnit}
                        titleModal="Satuan" setIsModal={setIsModalUnit}

                        getData={getDataUnit}
                        isLoading={isLoadingModalUnit}
                        totalRows={totalRowsModalUnit}
                        setTotalRows={setTotalRowsModalUnit}
                        SelectedData={SelectedDataModalUnit}
                        setSelectedData={setSelectedDataModalUnit}
                        data={dataModalUnit}
                        columnData={columnDataModalUnit}
                        dataMap={dataMapModalUnit}
                        dataTable={dataTableModalUnit}

                        selectHandler={selectHandlerUnit}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRB}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default WarehouseRekapMutasi