import { Grid } from '@mui/material';
import React, { useState } from 'react';

import TableBalance from '../../../../../components/Tables/TableBalance';
import { CustomDatePicker, Icon, Paginate, PopUp } from '../../../../../components/ui';

const ModalLogData = (props) => {
    const [CollapseNumPage, setCollapseNumPage] = useState(1);
    const [CollapseRowsCount, setCollapseRowsCount] = useState(50);
    const [Period, setPeriod] = useState(new Date());

    const handleClose = () => {
        emptyState();
        localStorage.removeItem('periodM');
        localStorage.removeItem('yearPeriodM');
        props.handleClick();
    };

    const emptyState = () => {
        props.setCollapseColumnData([]);
        props.setCollapseData([]);
        props.setCollapseTotalRows(50);
    };

    function setDataPeriod(data) {
        let tempPeriod = data.$d;
        setPeriod(data.$d);
        let month = tempPeriod.getMonth() + 1;
        let year = tempPeriod.getFullYear();
        localStorage.setItem("periodM", JSON.stringify(month));
        localStorage.setItem("yearPeriodM", JSON.stringify(year));
        props.getData(CollapseNumPage, CollapseRowsCount);
    }

    const onChangePaging = (value) => {
        setCollapseNumPage(value);
        props.getData(value, CollapseRowsCount, [], []);
    }

    const onChangeLimit = (value) => {
        setCollapseNumPage(1);
        setCollapseRowsCount(value);
        props.getData(1, value, [], []);
    }

    const mainContainer = (
        <>
            <div className='p-2'>
                <CustomDatePicker
                    label='Select Period'
                    value={Period}
                    onChange={(e) => setDataPeriod(e)}
                    type={'month'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                />
            </div>

            <div className='mt-4'>
                <Grid item xs={12} style={{ maxWidth: "97vw", borderRadius: "5px" }} className='mt-2'>
                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: '375px',
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#FFF'
                        }}
                    >
                        <>
                            <div className="mt-1">
                                <TableBalance Data={props.CollapseData} isLoadingBackDrop={null} maxHeight={'525px'} />
                            </div>
                        </>
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={CollapseNumPage}
                            limit={CollapseRowsCount}
                            totalData={props.CollapseTotalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </div>
        </>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}
                </div>
            </PopUp>
        </>
    )
}

export default ModalLogData;