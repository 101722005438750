import React, { useImperativeHandle, forwardRef, useEffect, useState, useRef } from 'react';
import { Box, MenuItem, Grid, Card, CardContent, Backdrop } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

import PaymentVoucherFor from './Components/PaymentVoucherFor';
import PaymentVoucherHeader from './Components/PaymentVoucherHeader';
import PaymentVoucherDetail from './Components/PaymentVoucherDetail';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import { convertToNominal, convertToNumeric, newWindowConfig } from '../../../../utils/masks.jsx';
import ButtonCreateEditPaymentVoucher from './Buttons/ButtonCreateEditPaymentVoucher';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import { defaultDate, getBackDatePeriod, nullDate, setDateFormat, updateDateFormat } from '../../../../utils/date.jsx';
import ApiService, { branchId, companyId, countryId, userId, userCode, username, isInfossNo } from '../../../../services/ApiService';
import { autoFocusField, PrintPDF } from '../../../../utils/reusableFunction.jsx';

const CreateEditPaymentVoucher = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataPV"));

    const { paymentVoucherId } = useParams();

    const bankRef = useRef(null);
    const detailRef = useRef(null);
    const pphRef = useRef(null);
    const paymentRef = useRef(null);
    const infossNoRef = useRef(null);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramPaymentVoucher = 'paymentVoucher/paymentVoucher';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    // const paramGL = 'generalLedger/generalLedger';
    const paramSD = 'selectionData/selectionData';

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerData, setJobOwnerData] = useState([]);

    const history = useNavigate();
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    const [PVNo, setPVNo] = useState(0);
    const [PVNo2, setPVNo2] = useState('');
    const [Reference, setReference] = useState('');
    const [PaymentBy, setPaymentBy] = useState('CI');
    const [PaymentTax, setPaymentTax] = useState();
    const [BankId, setBankId] = useState(0);
    const [BankCode, setBankCode] = useState('');
    const [BankIntName, setBankIntName] = useState('');
    const [BankName, setBankName] = useState('');
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [SettlementTo, setSettlementTo] = useState('G');
    const [CustomerTo, setCustomerTo] = useState(0);
    const [ContactId, setContactId] = useState(0);
    const [ContactCode, setContactCode] = useState('');
    const [ContactName, setContactName] = useState('');
    const [CityId, setCityId] = useState(0);
    const [CityInt, setCityInt] = useState('');
    const [CityName, setCityName] = useState('');
    const [Address, setAddress] = useState('');
    const [VerifyAcc, setVerifyAcc] = useState('');
    const [Verify, setVerify] = useState('No');
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateDate, setExRateDate] = useState('');
    const [PrintedOn, setPrintedOn] = useState('');
    const [Difference, setDifference] = useState();
    const [TotalPayment, setTotalPayment] = useState();
    const [JobOwnerId, setJobOwnerId] = useState(0);
    const [ExRateId, setExRateId] = useState(0);
    const [AccountId, setAccountId] = useState();
    const [IsPPh23, setIsPPh23] = useState(false);
    const [TempIsPPh23, setTempIsPPh23] = useState(false);
    const [TempSeq, setTempSeq] = useState(0);
    const [PPh23Amount, setPPh23Amount] = useState(0);
    const [PPh23AmountReal, setPPh23AmountReal] = useState(0);
    const [PPh23Remarks, setPPh23Remarks] = useState('');
    const [PPh23Percent, setPPh23Percent] = useState(2);
    const [KendaraanId, setKendaraanId] = useState(0);
    const [NoPolisi, setNoPolisi] = useState('');

    const [ticketId, setTicketId] = useState(0);
    const [approved, setApproved] = useState(false);
    const [approvedOn, setApprovedOn] = useState('');
    const [verifyAccOn, setVerifyAccOn] = useState('');
    const [printing, setPrinting] = useState(0);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setYearPeriod] = useState(0);
    const [deleted, setDeleted] = useState(false);
    const [deletedOn, setDeletedOn] = useState('');
    const [isSettlePPh23, setIsSettlePPh23] = useState(false);
    const [isSettlePPh23On, setIsSettlePPh23On] = useState('');
    const [deletedRemarks, setDeletedRemarks] = useState('');
    const [isApprovedAcc, setIsApprovedAcc] = useState(false);
    const [wfComplete, setWfComplete] = useState(0);

    const [DataPaymentDetail, setDataPaymentDetail] = useState([]);
    const [DataPaymentDetailId, setDataPaymentDetailId] = useState([]);
    const [DataPPh23, setDataPPh23] = useState([]);
    const [selectedPaymentVoucher, setSelectedPaymentVoucher] = useState({});
    const [BankData, setBankData] = useState([]);
    const [CashPayment, setCashPayment] = useState(0);

    const [isCash, setIsCash] = useState(false);
    const [isBank, setIsBank] = useState(true);
    const [isNot, setIsNot] = useState(true);
    const [isYes, setIsYes] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isInput, setIsInput] = useState(false);
    const [SettlementToName, setSettlementToName] = useState('General');
    const [isAllPPh, setIsAllPPh] = useState(false);

    const [TotalDebitUSD, setTotalDebitUSD] = useState(0);
    const [TotalDebitIDR, setTotalDebitIDR] = useState(0);
    const [TotalDebitInIDR, setTotalDebitInIDR] = useState(0);
    const [TotalCreditUSD, setTotalCreditUSD] = useState(0);
    const [TotalCreditIDR, setTotalCreditIDR] = useState(0);
    const [TotalCreditInIDR, setTotalCreditInIDR] = useState(0);
    const [TotalDifference, setTotalDifference] = useState(0);
    const [TempAmount, setTempAmount] = useState(0);
    const [TempMinus, setTempMinus] = useState(0);
    const [LabelDifference, setLabelDifference] = useState('Exchange Rate Difference (Credit)');
    const [isJobOwner, setIsJobOwner] = useState(true);
    const [isInclude, setIsInclude] = useState(true);
    const [dueDateLast, setDueDateLast] = useState('');
    const [isPosted, setIsPosted] = useState(false)

    const [SelectionData, setSelectionData] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [backDate, setBackDate] = useState('');

    const [isAddValue, setIsAddValue] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [isUnapproved, setIsUnapproved] = useState(false);

    const [infossNo, setInfossNo] = useState('');

    const [tabValuePayment, setTabValuePayment] = useState('Cash');


    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Payment Voucher', link: '/Majura/PaymentVoucher', style: '' },
        { label: 'Create Payment Voucher', link: '/Majura/PaymentVoucher/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Payment Voucher', link: '/Majura/PaymentVoucher', style: '' },
        { label: 'Detail Payment Voucher', link: '/Majura/PaymentVoucher/' + paymentVoucherId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Payment Voucher - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let id = paymentVoucherId ? paymentVoucherId : 0;
        let printDate = updateDateFormat(PrintedOn, true);
        let approveDate = updateDateFormat(approvedOn, true);
        let verifyDate = updateDateFormat(verifyAccOn, true);
        let deleteDate = updateDateFormat(deletedOn, true);
        let pph23Date = updateDateFormat(isSettlePPh23On, true);

        var data = {
            "paymentVoucher": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,
                'noInfoss': infossNo,
                "id": id,
                "ticketId": ticketId,
                "pvNo": PVNo,
                "pvNo2": PVNo2,
                "reference": Reference,
                "approved": approved,
                "approvedOn": approveDate,
                "paymentBy": PaymentBy,
                "paymentTax": PaymentTax,
                "bankId": BankId,
                "bankCode": BankCode,
                "bankIntName": BankIntName,
                "bankName": BankName,
                "settlementToCode": SettlementTo,
                "customerToId": CustomerTo,
                "contactId": ContactId,
                "contactCode": ContactCode,
                "contactName": ContactName,
                "coaCode": AccountCode,
                "coaName": AccountName,
                "cityId": CityId,
                "cityCode": CityInt,
                "cityName": CityName,
                "address": Address,
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": verifyDate,
                "printing": printing,
                "printedOn": printDate,
                "difference": Difference,
                "totalPayment": TotalPayment,
                "period": period,
                "yearPeriod": yearPeriod,
                "jobOwnerId": JobOwnerId,
                "deleted": deleted,
                "deletedOn": deleteDate,
                "exRateId": ExRateId,
                "accountId": AccountId,
                "isPPh23": IsPPh23,
                "pPh23Amount": PPh23AmountReal,
                "pPh23Remarks": PPh23Remarks,
                "isSettlePPh23": isSettlePPh23,
                "isPosted": isPosted,
                "isSettlePPh23On": pph23Date,
                "deletedRemarks": deletedRemarks,
                "pPh23Percent": PPh23Percent,
                "kendaraanId": KendaraanId,
                "noPolisi": NoPolisi,
                "dueDate": dueDateLast,
                "wfComplete": wfComplete,
                "requester": userCode,
                "isApprovedAcc": isApprovedAcc,

                "idLama": 0,
            },
            "paymentVoucherDetails": DataPaymentDetail,
            "paymentVoucherPPh23s": DataPPh23,
        };

        localStorage.setItem(username + "-DataPV", JSON.stringify(data));
    }

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const getLocalData = () => {
        let arrayData = [];
        let paymentType = '';
        let rate = 0;
        let PPh23Amount = 0;
        let paymentVoucherData = dataLocal.paymentVoucher;
        let paymentVoucherDetailData = dataLocal.paymentVoucherDetails;
        let paymentVoucherPPh23Data = dataLocal.paymentVoucherPPh23s;

        rate = paymentVoucherData.rate;
        paymentType = paymentVoucherData.paymentBy;
        PPh23Amount = paymentVoucherData.pPh23Amount;
        setInfossNo(paymentVoucherData.noInfoss);
        setPVNo(paymentVoucherData.pvNo);
        setPVNo2(paymentVoucherData.pvNo2);
        handleDocumentStatus(paymentVoucherData.rowStatus, paymentVoucherData.isApprovedAcc);
        setReference(paymentVoucherData.reference);
        setPaymentBy(paymentVoucherData.paymentBy);
        setIsPosted(paymentVoucherData.isPosted);
        setPaymentTax(paymentVoucherData.paymentTax);
        setBankId(paymentVoucherData.bankId);
        setBankCode(paymentVoucherData.bankCode);
        setBankIntName(paymentVoucherData.bankIntName);
        setBankName(paymentVoucherData.bankName);
        setSettlementTo(paymentVoucherData.settlementToCode);
        setCustomerTo(paymentVoucherData.customerToId);
        setContactId(paymentVoucherData.contactId);
        setContactCode(paymentVoucherData.contactCode);
        setContactName(paymentVoucherData.contactName);
        setCityId(paymentVoucherData.cityId);
        setCityInt(paymentVoucherData.cityCode);
        setCityName(paymentVoucherData.cityName);
        setAddress(paymentVoucherData.address);
        setVerifyAcc(paymentVoucherData.approvedAccBy);
        setAccountCode(paymentVoucherData.coaCode);
        setAccountName(paymentVoucherData.coaName);
        setIsApprovedAcc(paymentVoucherData.isApprovedAcc);
        setVerify(paymentVoucherData.isApprovedAcc === true ? 'Yes' : 'No');
        setRate(convertToNominal(paymentVoucherData.rate))
        setRateReal(paymentVoucherData.rate);
        setExRateDate(paymentVoucherData.exRateDate);
        setDifference(paymentVoucherData.difference);
        setTotalPayment(paymentVoucherData.totalPayment);
        setJobOwnerId(paymentVoucherData.jobOwnerId);
        setExRateId(paymentVoucherData.exRateId);
        setAccountId(paymentVoucherData.accountId);
        setIsPPh23(paymentVoucherData.isPPh23);
        setIsInclude(paymentVoucherData.isPPh23 === true ? false : true);
        setPPh23Amount(convertToNominal(paymentVoucherData.pPh23Amount));
        setPPh23AmountReal(paymentVoucherData.pPh23Amount);
        setPPh23Remarks(paymentVoucherData.pPh23Remarks);
        setPPh23Percent(paymentVoucherData.pPh23Percent);
        setKendaraanId(paymentVoucherData.kendaraanId);
        setNoPolisi(paymentVoucherData.noPolisi);

        setTicketId(paymentVoucherData.ticketId);
        setApproved(paymentVoucherData.approved);
        setPrinting(paymentVoucherData.printing);
        setPeriod(paymentVoucherData.period);
        setYearPeriod(paymentVoucherData.yearPeriod);
        setDeleted(paymentVoucherData.deleted);
        setIsSettlePPh23(paymentVoucherData.isSettlePPh23);
        setDeletedRemarks(paymentVoucherData.deletedRemarks);
        setWfComplete(paymentVoucherData.wfComplete);

        setDateFormat(paymentVoucherData.exRateDate, setExRateDate);
        setDateFormat(paymentVoucherData.printedOn, setPrintedOn);
        setDateFormat(paymentVoucherData.approvedOn, setApprovedOn);
        setDateFormat(paymentVoucherData.approvedAccOn, setVerifyAccOn);
        setDateFormat(paymentVoucherData.deletedOn, setDeletedOn);
        setDateFormat(paymentVoucherData.isSettlePPh23On, setIsSettlePPh23On);

        let tempCreateDate = updateDateFormat(paymentVoucherData.createdOn);
        let tempDate = updateDateFormat(paymentVoucherData.dueDate, true);
        let checkBank = 0;

        if (paymentVoucherId) {
            setIsEdit(true);
        }

        setDataPaymentDetail(paymentVoucherDetailData);
        arrayData = paymentVoucherDetailData;

        setDataPPh23(paymentVoucherPPh23Data);

        let tempArray = [];
        checkInputContact();

        arrayData.forEach(element => {
            if (tempArray.length > 0) {
                let checked = 0;
                tempArray.forEach(el => {
                    if (element.refId === el) {
                        checked = 1;
                    }
                });

                if (checked === 0) {
                    tempArray = [...tempArray, element.refId];
                }
            }
            else {
                tempArray = [...tempArray, element.refId];
            }
        });

        for (let i = 0; i < arrayData.length; i++) {
            if (tempDate === defaultDate) {
                if (arrayData[i].paymentVoucherDetailBanks.length > 0) {
                    checkBank = 1;

                    for (let l = 0; l < arrayData[i].paymentVoucherDetailBanks.length; l++) {
                        if (new Date(tempDate) < new Date(arrayData[i].paymentVoucherDetailBanks[l].dueDate)) {
                            tempDate = arrayData[i].paymentVoucherDetailBanks[l].dueDate;
                        }
                    }
                }
            }
        }

        if (checkBank === 0 && tempDate === defaultDate) {
            tempDate = tempCreateDate;
        }

        setDueDateLast(tempDate);

        setDataPaymentDetailId(tempArray);
        checkCash(paymentType);

        calculateTotalDebitCredit(arrayData, rate, paymentType, PPh23Amount);

        localStorage.removeItem(username + '-DataPV');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id) => {
        let arrayData = [];
        let arrayDataId = [];
        let paymentType = '';
        let rate = 0;
        let PPh23Amount = 0;
        ApiService.FinAccPostById(paramPaymentVoucher, id)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    rate = response.data.data.paymentVoucher.rate;
                    paymentType = response.data.data.paymentVoucher.paymentBy;
                    PPh23Amount = response.data.data.paymentVoucher.pPh23Amount;
                    setInfossNo(response.data.data.paymentVoucher.noInfoss);
                    setPVNo(response.data.data.paymentVoucher.pvNo);
                    setPVNo2(response.data.data.paymentVoucher.pvNo2);
                    setReference(response.data.data.paymentVoucher.reference);
                    setPaymentBy(response.data.data.paymentVoucher.paymentBy);
                    setIsPosted(response.data.data.paymentVoucher.isPosted);
                    setPaymentTax(response.data.data.paymentVoucher.paymentTax);
                    setBankId(response.data.data.paymentVoucher.bankId);
                    setBankCode(response.data.data.paymentVoucher.bankCode);
                    setBankIntName(response.data.data.paymentVoucher.bankIntName);
                    setBankName(response.data.data.paymentVoucher.bankName);
                    setSettlementTo(response.data.data.paymentVoucher.settlementToCode);
                    setSettlementToName(response.data.data.paymentVoucher.settlementToName);
                    setCustomerTo(response.data.data.paymentVoucher.customerToId);
                    setContactId(response.data.data.paymentVoucher.contactId);
                    setContactCode(response.data.data.paymentVoucher.contactCode);
                    setContactName(response.data.data.paymentVoucher.contactName);
                    setCityId(response.data.data.paymentVoucher.cityId);
                    setCityInt(response.data.data.paymentVoucher.cityCode);
                    setCityName(response.data.data.paymentVoucher.cityName);
                    setAddress(response.data.data.paymentVoucher.address);
                    setVerifyAcc(response.data.data.paymentVoucher.approvedAccBy);
                    setAccountCode(response.data.data.paymentVoucher.coaCode);
                    setAccountName(response.data.data.paymentVoucher.coaName);
                    setIsApprovedAcc(response.data.data.paymentVoucher.isApprovedAcc);
                    handleDocumentStatus(response.data.data.paymentVoucher.rowStatus, response.data.data.paymentVoucher.isApprovedAcc);
                    setVerify(response.data.data.paymentVoucher.isApprovedAcc === true ? 'Yes' : 'No');
                    setRateReal(response.data.data.paymentVoucher.rate);
                    setRate(convertToNominal(response.data.data.paymentVoucher.rate));
                    setDifference(response.data.data.paymentVoucher.difference);
                    setTotalPayment(response.data.data.paymentVoucher.totalPayment);
                    setJobOwnerId(response.data.data.paymentVoucher.jobOwnerId);
                    setExRateId(response.data.data.paymentVoucher.exRateId);
                    setAccountId(response.data.data.paymentVoucher.accountId);
                    setIsPPh23(response.data.data.paymentVoucher.isPPh23);
                    setIsInclude(response.data.data.paymentVoucher.isPPh23 === true ? false : true);
                    setPPh23Amount(convertToNominal(response.data.data.paymentVoucher.pPh23Amount));
                    setPPh23AmountReal(response.data.data.paymentVoucher.pPh23Amount);
                    setPPh23Remarks(response.data.data.paymentVoucher.pPh23Remarks);
                    setPPh23Percent(response.data.data.paymentVoucher.pPh23Percent);
                    setKendaraanId(response.data.data.paymentVoucher.kendaraanId);
                    setNoPolisi(response.data.data.paymentVoucher.noPolisi);

                    setTicketId(response.data.data.paymentVoucher.ticketId);
                    setApproved(response.data.data.paymentVoucher.approved);
                    setPrinting(response.data.data.paymentVoucher.printing);
                    setPeriod(response.data.data.paymentVoucher.period);
                    setYearPeriod(response.data.data.paymentVoucher.yearPeriod);
                    setDeleted(response.data.data.paymentVoucher.deleted);
                    setIsSettlePPh23(response.data.data.paymentVoucher.isSettlePPh23);
                    setDeletedRemarks(response.data.data.paymentVoucher.deletedRemarks);
                    setWfComplete(response.data.data.paymentVoucher.wfComplete);

                    arrayData = response.data.data.paymentVoucher.paymentVoucherDetails;

                    arrayData.forEach(element => {
                        if (element.paymentVoucherDetailBanks.length > 0) {
                            element.paymentVoucherDetailBanks.forEach(el => {
                                if (updateDateFormat(el.dueDate) === nullDate || updateDateFormat(el.dueDate) === '') {
                                    el.dueDate = defaultDate;
                                }
                            });
                        }

                        if (arrayDataId.length > 0) {
                            let checked = 0;
                            arrayDataId.forEach(el => {
                                if (element.refId === el) {
                                    checked = 1;
                                }
                            });

                            if (checked === 0) {
                                arrayDataId = [...arrayDataId, element.refId];
                            }
                        }
                        else {
                            arrayDataId = [...arrayDataId, element.refId];
                        }
                    });

                    setDataPaymentDetailId(arrayDataId);

                    setDataPaymentDetail(arrayData);

                    setDataPPh23(response.data.data.paymentVoucher.paymentVoucherPPh23s);

                    setDateFormat(response.data.data.paymentVoucher.exRateDate, setExRateDate);
                    setDateFormat(response.data.data.paymentVoucher.printedOn, setPrintedOn);
                    setDateFormat(response.data.data.paymentVoucher.approvedOn, setApprovedOn);
                    setDateFormat(response.data.data.paymentVoucher.approvedAccOn, setVerifyAccOn);
                    setDateFormat(response.data.data.paymentVoucher.deletedOn, setDeletedOn);
                    setDateFormat(response.data.data.paymentVoucher.isSettlePPh23On, setIsSettlePPh23On);

                    let tempCreateDate = updateDateFormat(response.data.data.paymentVoucher.createdOn);
                    let tempDate = updateDateFormat(response.data.data.paymentVoucher.dueDate, true);
                    let checkBank = 0;

                    for (let i = 0; i < arrayData.length; i++) {
                        if (tempDate === defaultDate) {
                            if (arrayData[i].paymentVoucherDetailBanks.length > 0) {
                                checkBank = 1;

                                for (let l = 0; l < arrayData[i].paymentVoucherDetailBanks.length; l++) {
                                    if (new Date(tempDate) < new Date(arrayData[i].paymentVoucherDetailBanks[l].dueDate)) {
                                        tempDate = arrayData[i].paymentVoucherDetailBanks[l].dueDate;
                                    }
                                }
                            }
                        }
                    }

                    if (checkBank === 0 && tempDate === defaultDate) {
                        tempDate = tempCreateDate;
                    }

                    setDueDateLast(tempDate);
                }
                else {
                    Back();
                }
            })
            .then(() => {
                checkInputContact();
                checkCash(paymentType);

                calculateTotalDebitCredit(arrayData, rate, paymentType, PPh23Amount);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataApproved = () => {
        let data = {};
        ApiService.FinAccCustomPost(paramPaymentVoucher + '/Create?check=true', data)
            .then((res) => {
                if (res.data.code === 200 && res.data.data !== null) {
                    let data = res.data.data;

                    if (data != null) {
                        setIsUnapproved(true);

                        if (!paymentVoucherId) {
                            Back();
                        }
                    }
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: error.toString() });
            });
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FPAV')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        GetJobOwnerData(1, 20);
                        handleDocumentStatus();
                        getDataApproved();
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetExRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);

                    GetFormatCurrency();
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const GetFormatCurrency = () => {
        let totalUSD = 0;
        let totalIDR = 0;
        let formatUSD = convertToNominal(totalUSD);
        let formatIDR = convertToNominal(totalIDR);

        setPPh23Amount(formatIDR);
        setTotalCreditUSD(formatUSD);
        setTotalCreditIDR(formatIDR);
        setTotalCreditInIDR(formatIDR);
        setTotalDebitUSD(formatUSD);
        setTotalDebitIDR(formatIDR);
        setTotalDebitInIDR(formatIDR);
        setTotalDifference(formatIDR);

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    const GetJobOwnerData = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setJobOwnerId(response.data.data.jobOwner[0].id);
                    }
                    setIsJobOwner(false);
                    setJobOwnerData(response.data.data.jobOwner);

                    GetSelectionData(1, 100);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectPrincipleBy = (
        <>
            <Selection
                id='0'
                label={'Principle By'}
                fullWidth={true}
                placeholder={'Select Principle By'}
                value={JobOwnerId}
                onChange={(e) => handleCheckJobOwner(e.target.value)}
                disabled={isApprovedAcc || isEdit}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                {
                    jobOwnerData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    const GetSelectionData = (pageNumber, pageSize) => {
        let params = [];
        params = [
            ...params,
            { 'attr': 'menuCode', 'value': 'PV' }
        ]
        ApiService.DynamicPostByPage(paramSD, 'SEARCH', pageNumber, pageSize, params)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectionData(response.data.data.selectionData);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        if (paymentVoucherId) {
                            getData(paymentVoucherId);
                        }
                        else {
                            GetExRate();
                            setDateFormat(new Date(), setDueDateLast);
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const selectSettlementTo = (SettlementTo, handlePaymentToSelect, isEdit) => {
        return (
            <>
                <Selection
                    id='0'
                    label={'Being Settled Of'}
                    fullWidth={true}
                    placeholder={'Select Being Settled Of'}
                    value={SettlementTo}
                    onChange={(e) => handlePaymentToSelect(e.target.value)}
                    disabled={isEdit}
                >
                    {
                        SelectionData.map((data, index) => (
                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                        ))
                    }
                </Selection>
            </>
        )
    };

    function Save(isDeleted, isPrint = false, result, PPhAmount) {
        if (isLoadingBackDrop) return
        if (isDeleted === false) {
            setIsLoadingBackDrop(true);
        }

        let id = paymentVoucherId ? paymentVoucherId : 0;
        let printDate = updateDateFormat(PrintedOn, true);
        let approveDate = updateDateFormat(approvedOn, true);
        let verifyDate = updateDateFormat(verifyAccOn, true);
        let deleteDate = updateDateFormat(deletedOn, true);
        let pph23Date = updateDateFormat(isSettlePPh23On, true);
        let customerToName = '';
        let settlementToName = '';
        let totalPayment = 0;

        let detailData = [];
        let totalPPh = 0;

        if (isDeleted === true) {
            detailData = result;
            totalPPh = PPhAmount;
        }
        else {
            detailData = DataPaymentDetail;
            totalPPh = PPh23AmountReal;
        }

        let customerToList = [
            { id: 1, code: 2, name: 'Shipper' },
            { id: 2, code: 3, name: 'Consigee' },
            { id: 3, code: 1, name: 'Agent' },
            { id: 4, code: 4, name: 'SSLine' },
            { id: 5, code: 6, name: 'EMKL' },
            { id: 6, code: 11, name: 'Branch' },
            { id: 7, code: 5, name: 'IATA' },
            { id: 8, code: 7, name: 'Depo' },
            { id: 9, code: 12, name: 'Personal' },
            { id: 10, code: 10, name: 'Trucking' },
            { id: 11, code: 0, name: 'None' },
        ];

        customerToList.forEach(element => {
            if (element.code === CustomerTo) {
                customerToName = element.name;
            }
        });

        SelectionData.forEach(element => {
            if (element.code === SettlementTo) {
                settlementToName = element.name;
            }
        });

        let PVDetail = detailData;
        PVDetail.forEach(element => {
            let PVDetailBank = element.paymentVoucherDetailBanks;

            PVDetailBank.forEach(el => {
                if (updateDateFormat(el.dueDate) === nullDate || updateDateFormat(el.dueDate) === '') {
                    el.dueDate = defaultDate;
                }

                if (PaymentBy === 'BU' || PaymentBy === 'BI') {
                    totalPayment += el.amount;
                }
            });

            if (PaymentBy === 'CU' || PaymentBy === 'CI') {
                totalPayment += element.paymentCash;
            }

            element.paymentVoucherDetailBanks = PVDetailBank;
        });

        var data = {
            "paymentVoucher": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,
                'noInfoss': infossNo,
                "id": parseFloat(id),
                "ticketId": ticketId,
                "pvNo": PVNo,
                "pvNo2": PVNo2,
                "reference": Reference,
                "approved": approved,
                "approvedOn": approveDate,
                "paymentBy": PaymentBy,
                "paymentTax": PaymentTax,
                "bankId": BankId,
                "bankCode": BankCode,
                "bankIntName": BankIntName,
                "bankName": BankName,
                "settlementToCode": SettlementTo,
                "settlementToName": settlementToName,
                "customerToId": CustomerTo,
                "customerToName": customerToName,
                "contactId": ContactId,
                "contactCode": ContactCode,
                "contactName": ContactName,
                "coaCode": AccountCode,
                "coaName": AccountName,
                "cityId": CityId,
                "cityCode": CityInt,
                "cityName": CityName,
                "address": Address,
                "rate": RateReal,
                "exRateDate": ExRateDate,
                "approvedAccBy": VerifyAcc,
                "approvedAccOn": verifyDate,
                "printing": printing,
                "printedOn": printDate,
                "difference": Difference,
                "totalPayment": totalPayment,
                "period": period,
                "yearPeriod": yearPeriod,
                "jobOwnerId": JobOwnerId,
                "deleted": deleted,
                "deletedOn": deleteDate,
                "exRateId": ExRateId,
                "accountId": AccountId,
                "isPPh23": IsPPh23,
                "pPh23Amount": totalPPh,
                "pPh23Remarks": PPh23Remarks,
                "isSettlePPh23": isSettlePPh23,
                "isSettlePPh23On": pph23Date,
                "deletedRemarks": deletedRemarks,
                "pPh23Percent": PPh23Percent,
                "kendaraanId": KendaraanId,
                "noPolisi": NoPolisi,
                "dueDate": dueDateLast,
                "wfComplete": wfComplete,
                "requester": userCode,
                "isApprovedAcc": isApprovedAcc,

                "idLama": 0,
            },
            "paymentVoucherDetails": PVDetail,
            "paymentVoucherPPh23s": DataPPh23,
        };

        let message = undefined;

        if (PaymentBy === 'BU' || PaymentBy === 'BI') {
            if (BankId === 0) {
                message = 'Please Add Bank Data!';
                autoFocusField(bankRef);
            }

            if (BankId !== 0 && isDeleted === false) {
                message = 'Please Add Bank Data!';
                for (let i = 0; i < data.paymentVoucherDetails.length; i++) {
                    if (data.paymentVoucherDetails[i].paymentVoucherDetailBanks.length > 0) {
                        message = undefined;
                        break;
                    }
                }

                if (message !== undefined) {
                    autoFocusField(bankRef);
                }
            }
        }

        if (message === undefined) {
            if (data.paymentVoucherDetails.length <= 0) {
                message = 'Please Input Payment Voucher Detail!';
                autoFocusField(detailRef);
            }
            else if (Difference !== 0) {
                if (PaymentBy === 'BU' || PaymentBy === 'CU') {
                    if (TotalDebitUSD !== TotalCreditUSD) {
                        message = 'Payment Need To Be Balance!';
                    }
                }
                else {
                    if (TotalDebitIDR !== TotalCreditIDR) {
                        message = 'Payment Need To Be Balance!';
                    }
                }

                if (message !== undefined) {
                    if (PaymentBy === 'BU' || PaymentBy === 'BI') {
                        setTabValuePayment('Bank');
                    }
                    else {
                        setTabValuePayment('Cash');
                    }

                    setTimeout(
                        function () {
                            autoFocusField(paymentRef);
                        },
                        500);
                }
            }
            else if (IsPPh23 === true && totalPPh === 0) {
                message = 'PPh23 is Included But The Value Still 0!';
                autoFocusField(pphRef);
            }
            else if (data.paymentVoucher.noInfoss === '' && isInfossNo) {
                message = 'Please Fill Infoss Number!';
                autoFocusField(infossNoRef);
            }
        }

        if (message === undefined) {
            ApiService.HelpCreateData(data, paramPaymentVoucher, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        if (id === 0) {
                            ApiService.CreateFinAcc(paramPaymentVoucher, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Create Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                history('/Majura/PaymentVoucher/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                window.location.reload();
                                                localStorage.removeItem('tempId');
                                            },
                                            1250);
                                    }
                                }
                                else {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Create Data Failed';

                                        showToast({ type: 'error', message: `${message} ${res.data.message}` });
                                    }
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramPaymentVoucher, data).then((res) => {
                                if (res.data.code === 200) {
                                    if (isDeleted === false && isPrint === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Update Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                history('/Majura/PaymentVoucher/' + paymentVoucherId, { replace: true });
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                    else if (isPrint) {
                                        Print();
                                    }
                                }
                                else {
                                    if (isDeleted === false) {
                                        setIsLoadingBackDrop(false);
                                        message = 'Updated Data Failed';

                                        showToast({ type: 'error', message: `${message} ${res.data.message}` });
                                    }
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);

                        showToast({ type: 'error', message: `Failed to Create Help Data, ${res.data.message}` });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: `You Don't Have Access, Please Contact The Administrator, ${error.toString()}` });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: message });
        }
    }

    function Back() {
        let check = 0;
        if (isEdit === true) {
            if (DataPaymentDetail.length === 0) {
                check = 0;
            }
        }

        if (check === 0) {
            history('/Majura/PaymentVoucher');
        }
        else {
            showToast({ type: 'error', message: 'Please Add Payment Voucher Detail!' });
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    function ConfirmationPV() {
        if (titleConfirmation === 'Print') {
            checkDataCondition('Print');
        }
        else if (titleConfirmation === 'Approve') {
            Approve();
        }
        else if (titleConfirmation === 'Un Approve') {
            UnApprove();
        }
        setIsModalConfirmation(false);
    }

    const checkDataCondition = async (isAction) => {
        if (!paymentVoucherId && isAction !== 'Save') {
            showToast({ type: 'error', message: 'Create The Data First!' });
            return;
        }

        try {
            setIsLoadingBackDrop(true);

            if (isAction === 'Save' && !paymentVoucherId) {
                Save(false);
                return;
            }

            const response = await ApiService.FinAccPostById(paramPaymentVoucher, paymentVoucherId);

            if (response.data.code !== 200) {
                throw new Error(response.data.message);
            }

            setIsLoadingBackDrop(false);
            const data = response.data.data.paymentVoucher;

            const showErrorToast = (message) => {
                showToast({ type: 'error', message });
            };

            const handleSave = () => {
                if (data.isApprovedAcc) {
                    getData(paymentVoucherId);
                    showErrorToast('Data Already Been Approved!');
                } else {
                    Save(false);
                }
            };

            const handlePrint = () => {
                getData(paymentVoucherId);
                if (data.isApprovedAcc) {
                    Print();
                } else {
                    Save(false, true);
                }
            };

            const handlePrintPDF = async () => {
                getData(paymentVoucherId);
                setIsLoadingBackDrop(true);
                setPrinting(data.printing);
                setTitleConfirmation('Print');
                setTitleModalConfirmation('Payment Voucher');

                if (data.printing === 0) {
                    getDateNow();
                } else {
                    setDateFormat(data.printedOn, setDueDate);
                }

                if (data.bankId !== 0) {
                    setIsDueDate(true);
                }

                try {
                    const backDate = await getBackDatePeriod();
                    setBackDate(backDate);
                    handleClickConfirmation();
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoadingBackDrop(false);
                }
            };

            const handleApproveOrUnApprove = (title, condition, message) => {
                getData(paymentVoucherId);
                if (data.printing > 0) {
                    if (condition) {
                        setTitleConfirmation(title);
                        setTitleModalConfirmation('Payment Voucher');
                        handleClickConfirmation();
                    } else {
                        showErrorToast(message);
                    }
                } else {
                    showErrorToast('Please Print The Data First!');
                }
            };

            switch (isAction) {
                case 'Save':
                    handleSave();
                    break;
                case 'Print':
                    handlePrint();
                    break;
                case 'PrintPDF':
                    await handlePrintPDF();
                    break;
                case 'Approve':
                    handleApproveOrUnApprove('Approve', !data.isApprovedAcc, 'Data Already Been Approved!');
                    break;
                case 'Un Approve':
                    handleApproveOrUnApprove('Un Approve', data.isApprovedAcc, 'Data Hasn\'t Been Approved Yet!');
                    break;
                default:
                    showErrorToast('Invalid Action!');
                    break;
            }
        } catch (error) {
            setIsLoadingBackDrop(false);
            console.error('Error fetching data:', error);
            showToast({ type: 'error', message: `Error When Reading Data! ${error.message || error}` });
        }
    };

    const Print = () => {
        setIsLoadingBackDrop(true);
        let dateDue = dueDate === '' ? updateDateFormat(new Date()) : dueDate;
        ApiService.FinAccBackDatePrint(paramPaymentVoucher, paymentVoucherId, dateDue).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                let count = parseFloat(printing) + parseFloat(1);
                setPrinting(count);

                let reportLink = 'Transactions/Finance/PaymentVoucherReport/PaymentVoucherReport/PrintPaymentVoucher?Id=' + paymentVoucherId;
                PrintPDF(setIsLoadingBackDrop, 'Payment Voucher', reportLink, [], true);
                setIsDueDate(false);
            }
            else {
                setIsDueDate(false);
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: message });
            }
        });
    };

    // const CreateGL = (id) => {
    //     var data = {
    //         "generalLedger": {
    //             "source": "PV",
    //             "refId": id,
    //             "methodPost": "AUTO",
    //             "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage'
    //         }
    //     }

    //     ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //         let message = '';
    //         message = res.data.message;
    //         if (res.data.code !== 200) {
    //             showToast({ type: 'error', message: message });
    //         }
    //         else {
    //             history('/Majura/PaymentVoucher/' + paymentVoucherId, { replace: true });
    //             window.location.reload();
    //         }
    //     });
    // }

    function AddNew() {
        window.open('/Majura/PaymentVoucher/Create', newWindowConfig);
    }

    const Approve = () => {
        setIsLoadingBackDrop(true);
        let check = 0;

        DataPaymentDetail.forEach(element => {
            if (element.coaCode === '') {
                check = 1;
            }
        });

        if (check === 0) {
            ApiService.FinAccUpdateStatusApprovedAcc(paramPaymentVoucher, paymentVoucherId).then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    // CreateGL(paymentVoucherId);

                    showToast({ type: 'success', message: message });
                    history('/Majura/PaymentVoucher/' + paymentVoucherId, { replace: true });
                    window.location.reload();
                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
                .catch(error => {
                    setIsLoadingBackDrop(false);
                    console.log(error);
                    showToast({ type: 'error', message: `Can't Approve Payment Voucher!, ${error.toString()}` });
                });
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Some Detail Didn\'t Have COA, Please Complete It First Before Approve The Data!' });
        }
    }

    const UnApprove = () => {
        setIsLoadingBackDrop(true);
        ApiService.FinAccUpdateStatusUnApprovedAcc(paramPaymentVoucher, paymentVoucherId)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    setIsLoadingBackDrop(false);
                    // CreateGL(res.data.data.ContraId);

                    showToast({ type: 'success', message: message });
                    history('/Majura/PaymentVoucher/' + paymentVoucherId, { replace: true });
                    window.location.reload();

                }
                else {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: message });
                }
            })
            .catch(error => {
                setIsLoadingBackDrop(false);
                console.log(error);
                showToast({ type: 'error', message: `Can't Un Approve Payment Voucher!, ${error.toString()}` });
            });
    }

    function checkInput() {
        let check = 0;
        if (parseFloat(CustomerTo) !== 0) {
            if (parseFloat(CustomerTo) === 10) {
                if (NoPolisi === '') {
                    showToast({ type: 'error', message: 'Please Select No Polisi!' });
                    check = 1;
                }
            }

            if (ContactId === 0) {
                showToast({ type: 'error', message: 'Please Select Customer First!' });
                check = 1;
            }
        }
        else {
            if (Address === '') {
                showToast({ type: 'error', message: 'Please Input Address First!' });
                check = 1;
            }
        }
        return check;
    }

    function checkCash(val) {
        if (val === 'CI' || val === 'CU') {
            setIsCash(false);
            setIsBank(true);
        }
        else {
            setIsCash(true);
            setIsBank(false);
        }
    }

    function checkInputContact() {
        setIsNot(true);
        setIsYes(true);
    }

    function calculateTotalDebitCredit(result, rate, paymentType, PPh23Amount) {
        let debitUSD = 0;
        let debitIDR = 0;
        let debitInIDR = 0;

        let cashUSD = 0;
        let cashIDR = 0;

        let bankUSD = 0;
        let bankIDR = 0;

        let cashUSDPPh = 0;
        let cashIDRPPh = 0;
        let bankUSDPPh = 0;
        let bankIDRPPh = 0;
        let totalUSD = 0;
        let totalIDR = 0;
        let creditInIDR = 0;

        let totalDifference = 0;

        for (let i = 0; i < result.length; i++) {
            if (result[i].dcNote === 'D') {
                debitUSD = convertToNumeric(parseFloat(parseFloat(result[i].amountUSD) + parseFloat(result[i].amountTaxUSD) + parseFloat(debitUSD)).toFixed(2));
                debitIDR = convertToNumeric(parseFloat(parseFloat(result[i].amountIDR) + parseFloat(result[i].amountTaxIDR) + parseFloat(debitIDR)).toFixed(2));
                debitInIDR = convertToNumeric(parseFloat(((parseFloat(result[i].amountUSD) + parseFloat(result[i].amountTaxUSD)) * parseFloat(result[i].refRate)) + parseFloat(result[i].amountIDR) + parseFloat(result[i].amountTaxIDR) + parseFloat(debitInIDR)).toFixed(2));

                if (paymentType === 'CU') {
                    cashUSD = parseFloat(result[i].paymentCash) + parseFloat(cashUSD);
                }
                else if (paymentType === 'CI') {
                    cashIDR = parseFloat(result[i].paymentCash) + parseFloat(cashIDR);
                }

                for (let j = 0; j < result[i].paymentVoucherDetailBanks.length; j++) {
                    if (result[i].paymentVoucherDetailBanks[j].amountCrr === 0) {
                        bankUSD = parseFloat(result[i].paymentVoucherDetailBanks[j].amount) + parseFloat(bankUSD);
                    }
                    else {
                        bankIDR = parseFloat(result[i].paymentVoucherDetailBanks[j].amount) + parseFloat(bankIDR);
                    }
                }
            }
        };

        if (paymentType === 'CU') {
            cashUSDPPh = parseFloat(cashUSD) + parseFloat(PPh23Amount);
        }
        else if (paymentType === 'CI') {
            cashIDRPPh = parseFloat(cashIDR) + parseFloat(PPh23Amount);
        }
        else if (paymentType === 'BU') {
            bankUSDPPh = parseFloat(bankUSD) + parseFloat(PPh23Amount);
        }
        else if (paymentType === 'BI') {
            bankIDRPPh = parseFloat(bankIDR) + parseFloat(PPh23Amount);
        }

        totalUSD = convertToNumeric(parseFloat(parseFloat(cashUSDPPh) + parseFloat(bankUSDPPh)).toFixed(2));
        totalIDR = convertToNumeric(parseFloat(parseFloat(cashIDRPPh) + parseFloat(bankIDRPPh)).toFixed(2));
        let rates = 0;
        rates = convertToNumeric(rate);
        creditInIDR = convertToNumeric(parseFloat((parseFloat(totalUSD) * parseFloat(rates)) + parseFloat(totalIDR)).toFixed(2));

        let formatCUSD = convertToNominal(totalUSD);
        let formatCIDR = convertToNominal(totalIDR);
        let formatCInIDR = convertToNominal(creditInIDR);
        setTotalCreditUSD(formatCUSD);
        setTotalCreditIDR(formatCIDR);
        setTotalCreditInIDR(formatCInIDR);

        if (paymentType === 'CU' || paymentType === 'BU') {
            setTotalPayment(debitUSD);
        }
        else if (paymentType === 'CI' || paymentType === 'BI') {
            setTotalPayment(debitIDR);
        }

        let formatDUSD = convertToNominal(debitUSD);
        let formatDIDR = convertToNominal(debitIDR);
        let formatDInIDR = convertToNominal(debitInIDR);

        setTotalDebitUSD(formatDUSD);
        setTotalDebitIDR(formatDIDR);
        setTotalDebitInIDR(formatDInIDR);

        totalDifference = convertToNumeric(parseFloat(parseFloat(creditInIDR) - parseFloat(debitInIDR)).toFixed(2));
        setDifference(totalDifference);
        let formatBInIDR = convertToNominal(totalDifference);
        setTotalDifference(formatBInIDR);
        if (totalDifference === 0) {
            setLabelDifference('Balanced');
        }
        else if (totalDifference < 0) {
            setLabelDifference('Exchange Rate Difference (Credit)');
        }
        else {
            setLabelDifference('Exchange Rate Difference (Debit)');
        }
    }

    function handleCheckJobOwner(val) {
        setJobOwnerId(val);
        if (isEdit === false) {
            if (val !== undefined || val !== 0 || val !== null || val !== '') {
                setIsJobOwner(false);
            }
            else {
                setIsJobOwner(true);
            }
        }
        else {
            setIsJobOwner(true);
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditPaymentVoucher
                Save={() => checkDataCondition('Save')}
                Back={Back}
                Print={() => checkDataCondition('PrintPDF')}
                AddNew={AddNew}
                Approve={() => checkDataCondition('Approve')}
                UnApprove={() => checkDataCondition('Un Approve')}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                isApprovedAcc={isApprovedAcc}
                isUnapproved={isUnapproved}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#FAFAFA', width: '100%', p: '0px 32px 24px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            paymentVoucherId ?
                                'Payment Voucher (' + Reference + ')'
                                :
                                'Payment Voucher ( New Document )'
                        }
                        BreadcrumbsItems={paymentVoucherId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={ticketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                        isSticky={true}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px', height: 'fit-content !important' }}>
                        <CardContent>
                            <PaymentVoucherHeader
                                bankRef={bankRef}
                                infossNoRef={infossNoRef}
                                pphRef={pphRef}
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                selectPrincipleBy={selectPrincipleBy}
                                isJobOwner={isJobOwner}
                                isBank={isBank}
                                setIsBank={setIsBank}
                                Verify={Verify}
                                isEdit={isEdit}
                                setSettlementToName={setSettlementToName}
                                PVNo={PVNo2}
                                setPVNo={setPVNo}
                                ExRateId={ExRateId}
                                Reference={Reference}
                                setReference={setReference}
                                PaymentBy={PaymentBy}
                                setPaymentBy={setPaymentBy}
                                PaymentTax={PaymentTax}
                                setPaymentTax={setPaymentTax}
                                BankId={BankId}
                                setBankId={setBankId}
                                printing={printing}
                                isInclude={isInclude}
                                setIsInclude={setIsInclude}
                                BankCode={BankCode}
                                setBankCode={setBankCode}
                                BankIntName={BankIntName}
                                setBankIntName={setBankIntName}
                                BankName={BankName}
                                setBankName={setBankName}
                                SettlementTo={SettlementTo}
                                setSettlementTo={setSettlementTo}
                                CustomerTo={CustomerTo}
                                setCustomerTo={setCustomerTo}
                                ContactId={ContactId}
                                setContactId={setContactId}
                                ContactCode={ContactCode}
                                setContactCode={setContactCode}
                                ContactName={ContactName}
                                setContactName={setContactName}
                                CityId={CityId}
                                setCityId={setCityId}
                                isPosted={isPosted}
                                CityInt={CityInt}
                                setCityInt={setCityInt}
                                CityName={CityName}
                                setCityName={setCityName}
                                Address={Address}
                                setAddress={setAddress}
                                VerifyAcc={VerifyAcc}
                                setVerifyAcc={setVerifyAcc}
                                Rate={Rate}
                                setRate={setRate}
                                TempMinus={TempMinus}
                                ExRateDate={ExRateDate}
                                setExRateDate={setExRateDate}
                                PrintedOn={PrintedOn}
                                setPrintedOn={setPrintedOn}
                                JobOwnerId={JobOwnerId}
                                setJobOwnerId={setJobOwnerId}
                                IsPPh23={IsPPh23}
                                setIsPPh23={setIsPPh23}
                                isAllPPh={isAllPPh}
                                PPh23Amount={PPh23Amount}
                                setPPh23Amount={setPPh23Amount}
                                PPh23AmountReal={PPh23AmountReal}
                                setPPh23AmountReal={setPPh23AmountReal}
                                PPh23Remarks={PPh23Remarks}
                                setPPh23Remarks={setPPh23Remarks}
                                PPh23Percent={PPh23Percent}
                                setPPh23Percent={setPPh23Percent}
                                KendaraanId={KendaraanId}
                                setKendaraanId={setKendaraanId}
                                NoPolisi={NoPolisi}
                                setNoPolisi={setNoPolisi}
                                verifyAccOn={verifyAccOn}
                                selectSettlementTo={selectSettlementTo}
                                isInput={isInput}
                                setIsInput={setIsInput}
                                setAccountCode={setAccountCode}
                                setAccountName={setAccountName}
                                isApprovedAcc={isApprovedAcc}
                                checkCash={checkCash}
                                selectedPaymentVoucher={selectedPaymentVoucher}
                                DataPaymentDetail={DataPaymentDetail}
                                setDataPaymentDetail={setDataPaymentDetail}
                                setDataPaymentDetailId={setDataPaymentDetailId}
                                setSelectedPaymentVoucher={setSelectedPaymentVoucher}
                                setBankData={setBankData}
                                setCashPayment={setCashPayment}
                                setIsAllPPh={setIsAllPPh}
                                isNot={isNot}
                                setIsNot={setIsNot}
                                isYes={isYes}
                                setIsYes={setIsYes}
                                SelectionData={SelectionData}
                                setTempIsPPh23={setTempIsPPh23}
                                setTempAmount={setTempAmount}
                                setTempMinus={setTempMinus}
                                calculateTotalDebitCredit={calculateTotalDebitCredit}
                                isAddValue={isAddValue}
                                setIsAddValue={setIsAddValue}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                        <CardContent>
                            <PaymentVoucherDetail
                                detailRef={detailRef}
                                DataPaymentDetail={DataPaymentDetail}
                                setDataPaymentDetail={setDataPaymentDetail}
                                DataPaymentDetailId={DataPaymentDetailId}
                                setDataPaymentDetailId={setDataPaymentDetailId}
                                IsPPh23={IsPPh23}
                                PPh23AmountReal={PPh23AmountReal}
                                setPPh23AmountReal={setPPh23AmountReal}
                                PPh23Amount={PPh23Amount}
                                setPPh23Amount={setPPh23Amount}
                                PPh23Percent={PPh23Percent}
                                SettlementTo={SettlementTo}
                                SettlementToName={SettlementToName}
                                CustomerTo={CustomerTo}
                                ContactId={ContactId}
                                PaymentBy={PaymentBy}
                                Address={Address}
                                NoPolisi={NoPolisi}
                                ExRateId={ExRateId}
                                checkInput={checkInput}
                                paymentVoucherId={paymentVoucherId}
                                setIsAllPPh={setIsAllPPh}
                                selectedPaymentVoucher={selectedPaymentVoucher}
                                setSelectedPaymentVoucher={setSelectedPaymentVoucher}
                                setBankData={setBankData}
                                setCashPayment={setCashPayment}
                                isAllPPh={isAllPPh}
                                setIsCash={setIsCash}
                                setIsBank={setIsBank}
                                Rate={Rate}
                                TempAmount={TempAmount}
                                setTempAmount={setTempAmount}
                                TempMinus={TempMinus}
                                setTempMinus={setTempMinus}
                                isInput={isInput}
                                setIsInput={setIsInput}
                                printing={printing}
                                setTempIsPPh23={setTempIsPPh23}
                                Save={Save}
                                TempSeq={TempSeq}
                                setTempSeq={setTempSeq}
                                isApprovedAcc={isApprovedAcc}
                                RateReal={RateReal}
                                calculateTotalDebitCredit={calculateTotalDebitCredit}
                                isAddValue={isAddValue}
                                setIsAddValue={setIsAddValue}
                                dueDateLast={dueDateLast}
                                setDueDateLast={setDueDateLast}
                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#FFF', padding: '0px 24px' }}>
                        <CardContent>
                            <PaymentVoucherFor
                                tabValue={tabValuePayment}
                                setTabValue={setTabValuePayment}
                                paymentRef={paymentRef}
                                paymentVoucherId={paymentVoucherId}
                                PaymentBy={PaymentBy}
                                Rate={Rate}
                                PPh23AmountReal={PPh23AmountReal}
                                isCash={isCash}
                                isBank={isBank}
                                IsPPh23={IsPPh23}
                                printing={printing}
                                DataPaymentDetail={DataPaymentDetail}
                                setDataPaymentDetail={setDataPaymentDetail}
                                selectedPaymentVoucher={selectedPaymentVoucher}
                                BankId={BankId}
                                BankData={BankData}
                                setBankData={setBankData}
                                isAllPPh={isAllPPh}
                                CashPayment={CashPayment}
                                setCashPayment={setCashPayment}
                                TotalDebitUSD={TotalDebitUSD}
                                setTotalDebitUSD={setTotalDebitUSD}
                                TotalDebitIDR={TotalDebitIDR}
                                setTotalDebitIDR={setTotalDebitIDR}
                                TotalDebitInIDR={TotalDebitInIDR}
                                setTotalDebitInIDR={setTotalDebitInIDR}
                                TotalCreditUSD={TotalCreditUSD}
                                setTotalCreditUSD={setTotalCreditUSD}
                                TotalCreditIDR={TotalCreditIDR}
                                setTotalCreditIDR={setTotalCreditIDR}
                                TotalCreditInIDR={TotalCreditInIDR}
                                setTotalCreditInIDR={setTotalCreditInIDR}
                                TotalDifference={TotalDifference}
                                setTotalDifference={setTotalDifference}
                                TempAmount={TempAmount}
                                setIsInput={setIsInput}
                                TempSeq={TempSeq}
                                setTempSeq={setTempSeq}
                                LabelDifference={LabelDifference}
                                TempIsPPh23={TempIsPPh23}
                                setTempIsPPh23={setTempIsPPh23}
                                AccountCode={AccountCode}
                                AccountName={AccountName}
                                isApprovedAcc={isApprovedAcc}
                                SettlementTo={SettlementTo}
                                calculateTotalDebitCredit={calculateTotalDebitCredit}
                                isAddValue={isAddValue}
                                setIsAddValue={setIsAddValue}
                                dueDateLast={dueDateLast}
                                setDueDateLast={setDueDateLast}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationPV}
                            isDueDate={isDueDate}
                            setIsDueDate={setIsDueDate}
                            dueDate={dueDate}
                            setDueDate={setDueDate}
                            backDate={backDate}
                            PrintCount={printing}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditPaymentVoucher;