import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import { Grid, Box } from "@mui/material";

import ModalCopyBC from "./ModalBc/ModalCopyBC";
import ApiService, { isListAsc } from "../../../services/ApiService";
import TableX from "../../../components/Tables/TableX";
import BookingButton from "./ComponentBc/BookingButton";
import ModalConfirmEPL from "./ModalBc/ModalConfirmEPL";
import ModalApprovalCredit from "../../../components/Modals/ModalApprovalCredit";
import ModalDelete from "../../../components/Modals/ModalDelete";
import ButtonComponent from "../../../components/Buttons/ButtonComponent";
import LoadingSpinner from "../../../components/Components/LoadingSpinner";
import ModalConfirmation from "../../../components/Modals/ModalConfirmation";

import freezeContainer from "../../../Styles/freezeContainer";
import ModalUsageData from "../../../components/Modals/ModalUsageData";
import HeaderInfo from "../../../components/Components/HeaderInfo";
import CustomToastContainer, { showToast, Paginate } from "../../../components/ui";
import { newWindowConfig } from "../../../utils/masks.jsx";
import { handleDataTable, PrintPDF } from "../../../utils/reusableFunction.jsx";

const ListBooking = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPrint, setIsLoadingPrint] = useState(true);
    const history = useNavigate();
    const param = "bookingConfirmation/bookingConfirmation";
    const paramRegUserAccess = "regUserAccess/regUserAccess";
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(isListAsc === true ? 0 : 1);

    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState("");

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState("");
    const [titleConfirmation, setTitleConfirmation] = useState("");
    const [dataSelected, setDataSelected] = useState({});
    const [stateX, setStateX] = useState({});

    const [dataJobType, setDataJobType] = useState([]);

    const [isModalCopy, setIsModalCopy] = useState(false);
    const [titleModalCopy, setTitleModalCopy] = useState('');
    const [copyId, setCopyId] = useState(0);

    const [isModalUsage, setIsModalUsage] = useState(false);
    const [titleModalUsage, setTitleModalUsage] = useState('');
    const [usageDataList, setUsageDataList] = useState([]);

    const [isModalApproval, setIsModalApproval] = useState(false);
    const [titleModalApproval, setTitleModalApproval] = useState("");
    const [titleApproval, setTitleApproval] = useState("");
    const [customerName, setCustomerName] = useState('');
    const [creditType, setCreditType] = useState('');
    const [approvalDataList, setApprovalDataList] = useState([]);
    const [approvalReason, setApprovalReason] = useState('');

    const [isModalConfirmEPL, setIsModalConfirmEPL] = useState(false);
    const [tempTitle, setTempTitle] = useState('');
    const [tempContactName, setTempContactName] = useState('');
    const [dropDownTitle, setDropDownTitle] = useState('All');
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    const dropDownList = [
        { label: 'All', icon: 'search-doc' },
        { label: 'Active', icon: 'documents' },
        { label: 'Approved', icon: 'approve-doc' },
        { label: 'Deleted', icon: 'status-deleted' },
    ];

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Booking Confirmation', link: '/Majura/BookingConfirmation', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Booking Confirmation - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let countErr = 0;

    const setFilterData = (value) => {
        setDropDownTitle(value);
        getData(isListAsc === true ? 0 : 1, 50, [], sortArr, value);
    }

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingPrint === false) {
            setIsLoading(true);
        } else {
            setIsLoadingPrint(true);
        }

        let filter;

        if (data) {
            if (data === 'All') {
                filter = [...filters]
            }
            else if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
            }
            else if (data === 'Active') {
                filter = [...filters, { 'field': 'approved', 'data': 'false' }, { 'field': 'rowStatus', 'data': 'ACT' }]
            }
            else if (data === 'Approved') {
                filter = [...filters, { 'field': 'approved', 'data': 'true' }, { 'field': 'rowStatus', 'data': 'ACT' }]
            }
        }
        else {
            if (dropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (dropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }]
            }
            else if (dropDownTitle === 'Active') {
                filter = [...filters, { 'field': 'approved', 'data': 'false' }, { 'field': 'rowStatus', 'data': 'ACT' }]
            }
            else if (dropDownTitle === 'Approved') {
                filter = [...filters, { 'field': 'approved', 'data': 'true' }, { 'field': 'rowStatus', 'data': 'ACT' }]
            }
        }

        ApiService.ListOperationDynamicPostByPage(param, "PAGE", pageNumber, pageSize, [], filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});

                    let temp = response.data.data.bookingConfirmation;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        };
                        return indexedTemp;
                    });

                    setDataMap(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setColumnData(response.data.data.columns.headerColumns);
                    setTotalRows(response.data.totalRowCount);

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingPrint === false) {
                        setIsLoading(false);
                    } else {
                        setIsLoadingPrint(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    if (isLoadingPrint === false) {
                        setIsLoading(false);
                    } else {
                        setIsLoadingPrint(false);
                    }
                }

                if (isLoadingPrint === false) {
                    setIsLoading(false);
                } else {
                    setIsLoadingPrint(false);
                }
            })
            .catch(function (error) {
                if (isLoadingPrint === false) {
                    setIsLoading(false);
                } else {
                    setIsLoadingPrint(false);
                }
                console.error("error saat fetch", error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' });
            });
    };

    const getDataAccess = () => {
        if (isLoadingPrint === false) {
            setIsLoading(true);
        } else {
            setIsLoadingPrint(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OBOCO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                {
                    attr: "permissionId",
                    value: "" + pId
                },
                {
                    attr: "userId",
                    value: "" + JSON.parse(localStorage.getItem("userId"))
                },
                {
                    attr: "roleId",
                    value: "" + JSON.parse(localStorage.getItem("roleId"))
                }
            ];
            ApiService.IdentityPostByPage(paramRegUserAccess, "PAGE", 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            getData(isListAsc === true ? 0 : 1, 50, []);
                        }
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                        if (isLoadingPrint === false) {
                            setIsLoading(false);
                        } else {
                            setIsLoadingPrint(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error("error saat fetch", error);
                    history('/Majura');
                });
        }
        else {
            history('/Majura');
        }
    };

    const GetDataJobType = () => {
        ApiService.PostByPage('regJobType/regJobType', 1, 50)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!' });
                    handleClickCopy();
                }
            })
            .catch(function (error) {
                setIsLoading(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const emptyState = () => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setStateX({})
        setGoTo(1);
        setSortArr([])
    };

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        } else {
            setIsModalDelete(false);
        }
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        } else {
            setIsModalConfirmation(false);
        }
    };

    const handleClickCopy = () => {
        if (isModalCopy === false) {
            setIsModalCopy(true);
            GetDataJobType();
        } else {
            setIsModalCopy(false);
        }
    };

    const handleClickUsage = () => {
        if (isModalUsage === false) {
            setIsModalUsage(true);
        }
        else {
            setIsModalUsage(false);
        }
    }

    const handleClickApproval = () => {
        if (isModalApproval === false) {
            setIsModalApproval(true);
        }
        else {
            setIsModalApproval(false);
            setApprovalDataList([]);
            setApprovalReason('');
            setCustomerName('');
            setCreditType('');
        }
    }

    function ConfirmationBC() {
        if (titleConfirmation === "Approve") {
            Approval();
        } else if (titleConfirmation === "Un Approve") {
            UnApproval();
        }
        setIsModalConfirmation(false);
    }

    const AddBc = () => {
        window.open('/Majura/BookingConfirmation/Create', newWindowConfig);
    };

    const EditBc = () => {
        let check = 0;
        DataRegUserAccess.forEach((element) => {
            if (element.accessCode === "REI") {
                check = 1;
            }
        });

        if (check !== 0) {
            if (SelectedData.id === undefined) {
                showToast({ type: 'error', message: "Please Select Data!" });
            } else {
                window.open('/Majura/BookingConfirmation/' + SelectedData.id, newWindowConfig);
            }
        } else {
            showToast({ type: 'error', message: "You Don't Have Access" });
        }
    };

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        setFilter([]);
        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, []);
    };

    const DeleteData = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        } else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.approved) {
                    showToast({ type: 'error', message: 'Can not delete data, already approved!' });
                } else {
                    setTitleModalDelete("Booking Confirmation");
                    handleClick();
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    };

    const DeleteBC = () => {
        setIsLoadingPrint(true);
        ApiService.OperationDelete(param, SelectedData.id).then((res) => {
            if (res.data.code === 200) {
                setIsLoadingPrint(false);
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: 'Delete Data Success' });
                setIsModalDelete(false);
            } else {
                showToast({ type: 'error', message: 'Delete Data Failed' });
                setIsModalDelete(false);
            }
        })
            .catch((error) => {
                setIsLoadingPrint(false);
                console.log(error);
                showToast({ type: 'error', message: 'Can\'t Delete Booking' });
            });
    };

    const UpdateStatusPrint = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        } else {
            if (SelectedData.rowStatus === 'ACT') {
                setIsLoadingPrint(true);
                ApiService.OperationUpdateStatusPrint(param, SelectedData.id)
                    .then((res) => {
                        let message = '';
                        message = res.data.message;
                        if (res.data.code === 200) {
                            getData(numPage, rowsCount, filter, sortArr);
                            let reportLink = 'Transactions/Operation/GeneralReport/GeneralReport/PrintBookingConfirmation?Id=' + SelectedData.id;
                            PrintPDF(setIsLoadingPrint, 'Booking Confirmation', reportLink);

                        }
                        else if (res.data.code === 204) {
                            showToast({ type: 'error', message: message });
                        }
                        else {
                            showToast({ type: 'error', message: message });
                        }
                    });
            }
            else {
                showToast({ type: 'error', message: "Data Already Deleted!" });
            }
        }
    }

    const checkBalanceCTC = (id) => {
        setIsLoadingPrint(true);
        let dataIncome = [];
        let dataCost = [];
        let InIDR = 0;
        let InUSD = 0;
        let CoIDR = 0;
        let CoUSD = 0;

        ApiService.OperationPostById(param, id)
            .then((response) => {
                if (response.data.code === 200) {
                    var result = response.data.data.bookingConfirmation;
                    var dataDetail = result.bookingConfirmationDetails;

                    dataIncome = dataDetail.filter(row => row.isIncome === true)
                    dataCost = dataDetail.filter(row => row.isIncome === false)
                    dataIncome.forEach(element => {
                        if (element.isCostToCost === true) {
                            if (element.amountCrr === 0) {
                                InUSD = InUSD + element.amount;
                            }
                            else {
                                InIDR = InIDR + element.amount;
                            }
                        }
                    });

                    dataCost.forEach(element => {
                        if (element.isCostToCost === true) {
                            if (element.amountCrr === 0) {
                                CoUSD = CoUSD + element.amount;
                            }
                            else {
                                CoIDR = CoIDR + element.amount;
                            }
                        }
                    });

                    if (InUSD === CoUSD && InIDR === CoIDR) {
                        setIsLoadingPrint(false);
                        setTitleConfirmation("Approve");
                        setTitleModalConfirmation("Booking Confirmation");
                        handleClickConfirmation();
                    }
                    else {
                        setIsLoadingPrint(false);
                        showToast({ type: 'error', message: 'CTC Detail Not Balance!' });
                    }
                }
                else {
                    setIsLoadingPrint(false);

                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' });
                }
            })
            .catch(function (error) {
                setIsLoadingPrint(false);

                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator' });
            })
    }

    const ApprovalData = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        } else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.shipmentId === 0) {
                    if (SelectedData.approved) {
                        showToast({ type: 'error', message: 'Already Approved!' });
                    }
                    else if (SelectedData.wfComplete !== 19) {
                        showToast({ type: 'error', message: 'Please Submit The Data First!' });
                    }
                    else {
                        checkBalanceCTC(SelectedData.id);
                    }
                }
                else {
                    showToast({ type: 'error', message: 'Job Already Created Base on This Booking!' });
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted' });
            }
        }
    };

    const handleClickConfirmEPL = () => {
        if (isModalConfirmEPL === false) {
            setIsModalConfirmEPL(true);
        }
        else {
            setIsModalConfirmEPL(false);
        }
    }

    const Approval = () => {
        setIsLoadingPrint(true);
        ApiService.OperationPostById(param, dataSelected.id)
            .then((response) => {
                let data = response.data.data.bookingConfirmation;
                if (data.bookingConfirmationDetails?.length > 0) {
                    let check = 0;
                    if (data.agentId !== 0 && data.agentName !== "MASTER BL") {
                        if (check === 0) {
                            check = 1;
                            for (let i = 0; i < data.bookingConfirmationDetails?.length; i++) {
                                if (data.bookingConfirmationDetails[i].contactId === data.agentId) {
                                    check = 0;
                                    break;
                                }
                            }

                            if (check === 1) {
                                setTempTitle('Agent');
                                setTempContactName(data.agentName);
                            }
                        }
                    }
                    if (data.emklId !== 0) {
                        if (check === 0) {
                            check = 1;
                            for (let i = 0; i < data.bookingConfirmationDetails?.length; i++) {
                                if (data.bookingConfirmationDetails[i].contactId === data.emklId) {
                                    check = 0;
                                    break;
                                }
                            }

                            if (check === 1) {
                                setTempTitle('EMKL');
                                setTempContactName(data.emklName);
                            }
                        }
                    }
                    if (data.depoId !== 0) {
                        if (check === 0) {
                            check = 1;
                            for (let i = 0; i < data.bookingConfirmationDetails?.length; i++) {
                                if (data.bookingConfirmationDetails[i].contactId === data.depoId) {
                                    check = 0;
                                    break;
                                }
                            }

                            if (check === 1) {
                                setTempTitle('Depo');
                                setTempContactName(data.depoName);
                            }
                        }
                    }
                    if (data.carrierId !== 0) {
                        if (check === 0) {
                            check = 1;
                            for (let i = 0; i < data.bookingConfirmationDetails?.length; i++) {
                                if (data.bookingConfirmationDetails[i].contactId === data.carrierId) {
                                    check = 0;
                                    break;
                                }
                            }

                            if (check === 1) {
                                setTempTitle('Carrier');
                                setTempContactName(data.carrierName);
                            }
                        }
                    }

                    if (check === 0) {
                        ApiService.OutstandingInvoicePostBySearch('shipmentOrder/shipmentOrder', data.customerId, 0, dataSelected.id)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let checked = 0;
                                    let dataCreditDays = response.data.data.shipmentOrderOSInv.invoicesCreditTermDays;
                                    let dataCreditAmount = response.data.data.shipmentOrderOSInv.invoicesCreditTermAmount;
                                    let tempArray = [];

                                    for (let i = 0; i < dataCreditDays.length; i++) {
                                        if (parseFloat(dataCreditDays[i].totalInIDR) > parseFloat(data.creditTermInDays)) {
                                            checked = 1;
                                            tempArray = [
                                                ...tempArray,
                                                {
                                                    'sequence': i + 1,
                                                    'usedOn': 'Invoice',
                                                    'transactionNumber': dataCreditDays[i].invoiceNo
                                                }
                                            ];
                                            setCreditType('Days');
                                        }
                                    }

                                    if (checked === 0) {
                                        for (let j = 0; j < dataCreditAmount.length; j++) {
                                            if (parseFloat(dataCreditAmount[j].totalInIDR) > parseFloat(data.creditTermInIDR) || parseFloat(dataCreditAmount[j].totalInUSD) > parseFloat(data.creditTermInUSD)) {
                                                checked = 2;
                                                tempArray = [
                                                    ...tempArray,
                                                    {
                                                        'sequence': j + 1,
                                                        'usedOn': 'Invoice',
                                                        'transactionNumber': dataCreditAmount[j].invoiceNo
                                                    }
                                                ];
                                                setCreditType('Amount');
                                            }
                                        }
                                    }

                                    setIsLoadingPrint(false);
                                    if (checked === 0) {
                                        ApproveData();
                                    }
                                    else {
                                        setApprovalDataList(tempArray);
                                        setCustomerName(data.customerName);
                                        setTitleModalApproval('Booking Confirmation');
                                        setTitleApproval('Approve');
                                        handleClickApproval();
                                    }
                                }
                                else {
                                    console.log(response)
                                    setIsLoadingPrint(false);
                                    showToast({ type: 'error', message: response.data.error });
                                }
                            })
                            .catch(function (error) {
                                setIsLoadingPrint(false);
                                showToast({ type: 'error', message: error });
                            })
                    }
                    else {
                        setIsLoadingPrint(false);
                        handleClickConfirmEPL();
                    }
                } else {
                    setIsLoadingPrint(false);
                    showToast({ type: 'error', message: "Please Input EPL Data!" });
                }
            })
            .catch(function (error) {
                console.error("error saat fetch", error);
                countErr++;
                if (countErr < 3) {
                    getData(numPage, rowsCount, filter);
                } else {
                    ApiService.logOut();
                    history("/");
                }
            });
    };

    const ApprovalCreditData = (approvalReason) => {
        setIsLoading(true);
        setIsModalConfirmEPL(false);
        setIsModalApproval(false);

        let data = {
            "bookingConfirmation": {
                "approvedCreditLimitRemarks": approvalReason
            }
        }
        ApiService.ApprovalCreditOperation('bookingConfirmation/bookingConfirmation', dataSelected.id, data)
            .then((res) => {
                let message = '';
                message = res.data.error;
                if (res.data.code === 200) {
                    ApproveData();
                }
                else {
                    setIsLoading(false);
                    showToast({ type: 'error', message: message });
                }
            })
    }

    const ApproveData = () => {
        setIsLoadingPrint(true);
        ApiService.ApproveOperation(param, dataSelected.id).then((res) => {
            let message = "";
            message = res.data.message;
            if (res.data.code === 200) {
                setIsLoadingPrint(false);
                getData(numPage, rowsCount, filter);

                showToast({ type: 'success', message: message });

                if (isModalConfirmEPL === true) {
                    handleClickConfirmEPL();
                }

                if (isModalApproval === true) {
                    handleClickApproval();
                }
            } else {
                setIsLoadingPrint(false);
                showToast({ type: 'error', message: message });
            }
        });
    }

    const UnApprovalData = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: "Please Select Data!" });
        } else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.shipmentId === 0) {
                    if (SelectedData.approved) {
                        setTitleConfirmation("Un Approve");
                        setTitleModalConfirmation("Booking Confirmation");
                        handleClickConfirmation();
                    } else {
                        showToast({ type: 'error', message: "Data Hasn't Been Approved Yet!" });
                    }
                }
                else {
                    setIsLoadingPrint(true);
                    getDataShipment(SelectedData.shipmentId);
                }
            }
            else {
                showToast({ type: 'error', message: "Data Already Deleted" });
            }
        }
    };

    const getDataShipment = (shipmentId) => {
        let sequence = 1;
        let tempArray = [];

        ApiService.OperationPostById('shipmentOrder/shipmentOrder', shipmentId)
            .then((response) => {
                if (response.data.code === 200) {
                    let temp = response.data.data.shipmentOrder;

                    tempArray = [
                        {
                            'sequence': sequence,
                            'usedOn': 'Shipment Order',
                            'transactionNumber': temp.shipmentNo
                        }
                    ];

                    setUsageDataList(tempArray);
                    setTitleModalUsage('Booking Confirmation');
                    handleClickUsage();

                    setIsLoadingPrint(false);
                    showToast({ type: 'error', message: "Booking Confirmation Already Used in Shipment Order" });
                }
            })
            .catch(function (error) {
                setIsLoading(false);
                setIsLoadingPrint(false);

                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const UnApproval = () => {
        ApiService.UnApproveOperation(param, dataSelected.id).then((res) => {
            let message = "";
            message = res.data.message;
            if (res.data.code === 200) {
                getData(numPage, rowsCount, filter);
                showToast({ type: 'success', message: message.toString() });
            } else {
                showToast({ type: 'error', message: message.toString() });
            }
        });
    };

    const CopyData = () => {
        setTitleModalCopy("Booking Confirmation");
        handleClickCopy();
    };

    const Copy = () => {
        window.open('/Majura/BookingConfirmation/Create/' + true + '/' + copyId, newWindowConfig);
    };

    const handleReset = () => {
        setGoTo(1);
        setFilter([]);
        setFilterBy([]);
        emptyState();
        getData(isListAsc === true ? 0 : 1, 50, [], sortArr)
        setStateX({})
    }

    function CellDoubleClick(row) {
        EditBc();
    }

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 120
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <BookingButton
                AddBc={AddBc}
                EditBc={EditBc}
                ReloadData={ReloadData}
                DeleteData={DeleteData}
                PrintData={UpdateStatusPrint}
                ApprovalData={ApprovalData}
                UnApprovalData={UnApprovalData}
                CopyData={CopyData}
                DataRegUserAccess={DataRegUserAccess}
                DropDownTitle={dropDownTitle}
                HandleStatus={setFilterData}
                DropDownList={dropDownList}
            />
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(isListAsc === true ? 0 : 1);
        setRowsCount(value);
        getData(isListAsc === true ? 0 : 1, value, filter, sortArr);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '16px 32px 24px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Booking Confirmation'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        style={{
                            marginTop: '16px',
                            padding: '2px 0px 16px 16px',
                            borderRadius: '12px',
                            border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                            background: 'var(--Mjr-white-4, #FFF)',
                            boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        }}
                    >
                        <div
                            className="rounded-10 table-responsive"
                            style={{
                                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            }}
                        >
                            {isLoading ? (
                                <LoadingSpinner />
                            ) : (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr} setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        <div style={{ paddingRight: '16px' }}>
                            <Box sx={{ borderTop: 1, borderColor: 'divider' }} />
                            <div style={{ marginTop: '8px' }}>
                                <Paginate
                                    page={numPage}
                                    limit={rowsCount}
                                    totalData={totalRows}
                                    onChange={onChangePaging}
                                    onChangeLimit={onChangeLimit}
                                />
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClick}
                        DeleteData={DeleteBC}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationBC}
                    />
                )
            }

            {
                isModalCopy && (
                    <ModalCopyBC
                        isModal={isModalCopy}
                        titleModal={titleModalCopy}
                        handleClick={handleClickCopy}
                        CopyData={Copy}
                        dataJobType={dataJobType}
                        copyId={copyId}
                        setCopyId={setCopyId}
                    />
                )
            }

            {
                isModalConfirmEPL && (
                    <ModalConfirmEPL
                        isModal={isModalConfirmEPL}
                        handleClick={handleClickConfirmEPL}
                        title={tempTitle}
                        contactName={tempContactName}
                        ConfirmationData={ApproveData}
                    />
                )
            }

            {
                isModalUsage && (
                    <ModalUsageData
                        isModal={isModalUsage}
                        titleModal={titleModalUsage}
                        handleClick={handleClickUsage}
                        usageDataList={usageDataList}
                    />
                )
            }

            {
                isModalApproval && (
                    <ModalApprovalCredit
                        isModal={isModalApproval}
                        titleModal={titleModalApproval}
                        title={titleApproval}
                        customerName={customerName}
                        creditType={creditType}
                        handleClick={handleClickApproval}
                        approvalDataList={approvalDataList}
                        approvalReason={approvalReason}
                        setApprovalReason={setApprovalReason}
                        approvalData={ApprovalCreditData}
                    />
                )
            }

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    );
});

export default ListBooking;