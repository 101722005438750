import { memo, useEffect, useState } from "react";
import TableEmptyInfo from "../Components/TableEmptyInfo";
import ButtonComponent from "../Buttons/ButtonComponent";
import { CustomDatePicker, CustomRadioGroup, HalfPopUp, Icon, Input } from "../ui";
import { Box, Collapse, Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { PrintPDF } from "../../utils/reusableFunction";
import ModalConfirmation from "./ModalConfirmation";

const PrintDetail = memo(({
    printedBy,
    printedOn,
}) => {
    return (
        <Typography
            sx={{
                color: 'var(--Mjr-black-1, #171717)',
                fontFamily: 'Switzer',
                fontSize: '8px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: 'normal',
            }}
        >
            by {printedBy} - {new Date(printedOn).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })} {new Date(printedOn).toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", second: "2-digit", hourCycle: "h23" })}
        </Typography>
    )
})

const ReportVersionLog = memo(({
    isModal,
    handleClick,
    title,
    data = [],
    setData,
    fullData,
    setIsLoading,
    reportLink,
    handleDataLog
}) => {
    const [isPrint, setIsPrint] = useState(true);
    const [printDate, setPrintDate] = useState(new Date());
    const [searchSection, setSearchSection] = useState('');

    const dataFilter = [
        { id: 1, code: true, name: 'Print Activity' },
        { id: 2, code: false, name: 'Closing Period' },
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [selectedData, setSelectedData] = useState({});

    const handleChangeDate = (date) => {
        setPrintDate(date);
    }

    const handleSetIsPrint = (value) => {
        setIsPrint(value);
    }

    useEffect(() => {
        handleDataLog(isPrint, printDate, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPrint, printDate])

    const handleOpenCollapse = (itemId) => {
        setData(prevData =>
            prevData.map(item =>
                item.id === itemId ? { ...item, isOpen: !item.isOpen } : item
            )
        );
    }

    const getContainerHeight = () => {
        const hTitle = 24
        const hHeader = 200

        const totalHeight = hTitle + hHeader

        return `${totalHeight}px`
    }

    const handleSearchFilter = () => {
        const lowerSearchTerm = searchSection.toLowerCase();

        const containsSearchTerm = (value) => {
            if (typeof value === "string") {
                return value.toLowerCase().includes(lowerSearchTerm);
            } else if (Array.isArray(value)) {
                return value.some(item => containsSearchTerm(item));
            } else if (typeof value === "object" && value !== null) {
                return Object.values(value).some(val => containsSearchTerm(val));
            }
            return false;
        };

        let data = fullData.filter(item => containsSearchTerm(item));
        setData(data);
    };

    const handleDeleteFilter = () => {
        setSearchSection('');
        setData(fullData);
    }

    const handleClickConfirmation = (item) => {
        if (isModalConfirmation) {
            setSelectedData({});
            setIsModalConfirmation(false);
        }
        else {
            setSelectedData(item);
            setIsModalConfirmation(true);
        }
    }

    const handlePrintItem = () => {
        setIsLoading(true);
        let params = [
            {
                "attr": "versionId",
                "value": selectedData.versionId
            },
            {
                "attr": "reportId",
                "value": selectedData.mainFileId
            }
        ];

        selectedData.printingParams.forEach(element => {
            let data = {
                'attr': element.attr,
                'value': element.value === 'Yes' ? 'true' : element.value === 'No' ? 'false' : element.value
            }

            params.push(data);
        });

        handleClickConfirmation();
        PrintPDF(setIsLoading, title, reportLink, params);
    }

    return (
        <>
            <HalfPopUp
                id="alert-dialog-slide-description"
                title={`${title} - Activity Log`}
                isOpen={isModal}
                handleClose={handleClick}
            >
                <Box>
                    <div>
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                            <Grid item xs={6} sx={{ paddingRight: '8px' }}>
                                <CustomRadioGroup
                                    title={'Filter By'}
                                    isChecked={isPrint}
                                    setIsChecked={(value) => handleSetIsPrint(value)}
                                    dataGroup={dataFilter}
                                    dataLength={dataFilter.length}
                                />
                            </Grid>

                            <Grid item xs={6} sx={{ paddingLeft: '8px' }}>
                                <CustomDatePicker
                                    label={'Print Activity'}
                                    value={printDate}
                                    onChange={handleChangeDate}
                                    type={'month'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Input
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            placeholder={'Search any keyword related to the data'}
                            value={searchSection}
                            disabled={data.length === 0}
                            onKeyDown={(e) => e.key === 'Enter' && handleSearchFilter()}
                            onChange={(e) => setSearchSection(e.target.value)}
                            type='text'
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                            inputBaseProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {
                                            searchSection !== '' && (
                                                <IconButton onClick={handleDeleteFilter}>
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#BE2957',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            )
                                        }

                                        <IconButton onClick={handleSearchFilter} hidden={data.length === 0}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#00286D',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <Box sx={{ borderTop: 1, borderColor: '#E9E9EA', marginTop: '16px' }} />
                    </div>

                    <Box sx={{ width: '100%' }}>
                        {
                            data.length > 0 && (
                                <div
                                    className="table-responsive"
                                    style={{
                                        maxHeight: `calc(100vh - ${getContainerHeight()} - 8px)`,
                                        width: '100%',
                                        display: 'block'
                                    }}
                                >
                                    {
                                        data.map((item, index) => {
                                            return (
                                                <div style={{ width: '100%' }}>
                                                    <Box key={index} sx={{ padding: index === 0 ? '16px 16px 8px' : '8px 16px' }}>
                                                        <Grid container spacing={'8px'} alignItems="flex-start">
                                                            <Grid item xs={6}>
                                                                <Stack direction={'row'} spacing={'12px'} alignItems={item.isOpen ? 'flex-start' : 'center'}>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            padding: '0px 4px',
                                                                            flexDirection: 'column',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            borderRadius: '4px',
                                                                            minWidth: '21px',
                                                                            maxWidth: '21px',
                                                                            background: 'var(--existing-Blue-Primary-2, #F2FAFF)',
                                                                            marginTop: item.isOpen ? '6px' : '0px',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                overflow: 'hidden',
                                                                                color: 'var(--Mjr-Random-Dark, #6692DE)',
                                                                                textOverflow: 'ellipsis',
                                                                                fontFamily: 'Switzer',
                                                                                fontSize: '10px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '600',
                                                                                lineHeight: '160%',
                                                                                letterSpacing: '0.1px',
                                                                            }}
                                                                        >
                                                                            V{item.version}
                                                                        </Typography>
                                                                    </div>

                                                                    <Stack direction={'column'} spacing={'4px'}>
                                                                        <Stack direction={'row'} spacing={'4px'} alignItems={'center'}>
                                                                            <Typography
                                                                                sx={{
                                                                                    color: 'var(--Mjr-black-1, #747475)',
                                                                                    fontFamily: 'Switzer',
                                                                                    fontSize: '10px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '400',
                                                                                    lineHeight: 'normal',
                                                                                }}
                                                                            >
                                                                                Period:
                                                                            </Typography>

                                                                            <Typography
                                                                                sx={{
                                                                                    color: 'var(--Mjr-black-1, #171717)',
                                                                                    fontFamily: 'Switzer',
                                                                                    fontSize: '10px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '400',
                                                                                    lineHeight: 'normal',
                                                                                }}
                                                                            >
                                                                                {item.period}
                                                                            </Typography>
                                                                        </Stack>

                                                                        {
                                                                            !item.isOpen && (
                                                                                <PrintDetail
                                                                                    printedBy={item.printingHistories[0].printedBy}
                                                                                    printedOn={item.printingHistories[0].printedOn}
                                                                                />
                                                                            )
                                                                        }

                                                                        {
                                                                            item.isOpen && (
                                                                                <Stack direction={'column'} spacing={'4px'}>
                                                                                    {
                                                                                        item.printingHistories.map((print, index) => {
                                                                                            return (
                                                                                                <PrintDetail
                                                                                                    key={index}
                                                                                                    printedBy={print.printedBy}
                                                                                                    printedOn={print.printedOn}
                                                                                                />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </Stack>
                                                                            )
                                                                        }
                                                                    </Stack>
                                                                </Stack>
                                                            </Grid>

                                                            <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                                                <Stack direction={'row'} spacing={'12px'} alignItems={'center'}>
                                                                    {ButtonComponent.CustomButton(() => handleClickConfirmation(item), '', `RE-PRINT ${item.icon === 'printer' ? 'PDF' : 'EXCEL'}`)}

                                                                    <IconButton
                                                                        aria-label="expand row"
                                                                        size="small"
                                                                        disableFocusRipple={true}
                                                                        disableRipple={true}
                                                                        sx={{ alignItems: 'center' }}
                                                                        onClick={() => handleOpenCollapse(item.id)}
                                                                    >
                                                                        <Icon
                                                                            iconName={item.isOpen ? 'arrow-up' : 'arrow-down'}
                                                                            svgIconProps={{
                                                                                fontSize: 'small',
                                                                                htmlColor: '#00286D',
                                                                                sx: {
                                                                                    height: '12px',
                                                                                    width: '12px'
                                                                                }
                                                                            }}
                                                                        />
                                                                    </IconButton>
                                                                </Stack>
                                                            </Grid>
                                                        </Grid>
                                                    </Box>

                                                    {
                                                        item.isOpen && (
                                                            <Collapse in={item.isOpen} timeout="auto" unmountOnExit sx={{ padding: '0px 16px 8px' }}>
                                                                <Grid container spacing={'8px'} alignItems="flex-start">
                                                                    <Grid item xs={12}>
                                                                        {
                                                                            item.printingParams.map((param, index) => {
                                                                                return (
                                                                                    <Stack key={index} direction={'row'} spacing={'12px'} alignItems={'flex-start'}>
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                padding: '0px 4px',
                                                                                                flexDirection: 'column',
                                                                                                justifyContent: 'center',
                                                                                                alignItems: 'center',
                                                                                                borderRadius: '4px',
                                                                                                minWidth: '21px',
                                                                                                maxWidth: '21px',
                                                                                                background: 'transparent',
                                                                                                userSelect: 'none',
                                                                                            }}
                                                                                            hidden={param.isHidden}
                                                                                        >
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    overflow: 'hidden',
                                                                                                    color: 'transparent',
                                                                                                    textOverflow: 'ellipsis',
                                                                                                    fontFamily: 'Switzer',
                                                                                                    fontSize: '10px',
                                                                                                    fontStyle: 'normal',
                                                                                                    fontWeight: '600',
                                                                                                    lineHeight: '160%',
                                                                                                    letterSpacing: '0.1px',
                                                                                                    userSelect: 'none',
                                                                                                }}
                                                                                            >
                                                                                                V{item.version}
                                                                                            </Typography>
                                                                                        </div>

                                                                                        {
                                                                                            !param.isHidden && (
                                                                                                <Stack direction="row" spacing="16px">
                                                                                                    <Typography
                                                                                                        sx={{
                                                                                                            minWidth: '90px',
                                                                                                            color: 'var(--Mjr-black-2, #747475)',
                                                                                                            fontFamily: 'Switzer',
                                                                                                            fontSize: '10px',
                                                                                                            fontWeight: '400',
                                                                                                            lineHeight: '160%',
                                                                                                            letterSpacing: '0.1px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {param.name}
                                                                                                    </Typography>

                                                                                                    <Typography
                                                                                                        sx={{
                                                                                                            flex: 1,
                                                                                                            color: 'var(--Mjr-black-1, #171717)',
                                                                                                            fontFamily: 'Switzer',
                                                                                                            fontSize: '10px',
                                                                                                            fontWeight: '400',
                                                                                                            lineHeight: '160%',
                                                                                                            letterSpacing: '0.1px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {param.value}
                                                                                                    </Typography>
                                                                                                </Stack>
                                                                                            )
                                                                                        }
                                                                                    </Stack>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Grid>
                                                                </Grid>
                                                            </Collapse>
                                                        )
                                                    }

                                                    <Box sx={{ borderTop: 1, borderColor: '#E9E9EA', width: '100%', display: 'block' }} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }

                        {
                            data.length === 0 && (
                                <TableEmptyInfo data={data} getContainerHeight={getContainerHeight} />
                            )
                        }
                    </Box>
                </Box>
            </HalfPopUp>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        title={'Print'}
                        titleModal={title}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={handlePrintItem}
                    />
                )
            }
        </>
    )
})

export default memo(ReportVersionLog);