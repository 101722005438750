import { Grid } from "@mui/material";
import React, { memo } from "react";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";


const ButtonCoreTaxSystem = memo(({ handleCancel, isDisabled, DataRegUserAccess }) => {
    const renderButton = (accessCode) => {
        switch (accessCode) {
            case 'UPP':
                return ButtonComponent.CustomButton(handleCancel, 'return', 'CANCEL REQUEST', isDisabled);
            default:
                return null;
        }
    }

    return (
        <div className="row">
            <div className='col-12'>
                <Grid
                    container
                    item
                    spacing={1}
                    direction="row"
                    style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
                >

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data.accessCode)}
                        </React.Fragment>
                    ))}
                </Grid>
            </div>
        </div>
    )
});

export default memo(ButtonCoreTaxSystem);
