import { Stack } from '@mui/material';
import React from 'react';
import { Input } from '../../../../components/ui';

const ShipmentAdvice = (props) => {
    return (
        <div>
            <form>
                <Input
                    label="Reference"
                    value={props.SAReference}
                    disabled
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '24px !important'
                        }
                    }}
                />

                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                    <Input
                        label='Print to Agent'
                        value={props.SAPrintAgentId}
                        textAlign='right'
                        disabled
                        formControlProps={{
                            sx: {
                                marginTop: '24px',
                                width: '35%'
                            }
                        }}
                    />

                    <Input
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.SAPrintAgentName}
                        type='text'
                        label={''}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            sx: {
                                marginTop: '23px !important',
                            },
                        }}
                        disabled
                    />
                </Stack>

                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                    <Input
                        label='Print to Delivery Agent'
                        value={props.SAPrintDeliveryId}
                        disabled
                        textAlign='right'
                        formControlProps={{
                            sx: {
                                marginTop: '24px',
                                width: '35%'
                            }
                        }}
                    />

                    <Input
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={props.SAPrintDeliveryName}
                        type='text'
                        label={''}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            sx: {
                                marginTop: '23px !important',
                            },
                        }}
                        disabled
                    />
                </Stack>

                <Input
                    label={'Remarks'}
                    value={props.SARemarks}
                    onChange={(e) => props.setSARemarks(e.target.value)}
                    multiline
                    fullWidth
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    inputBaseProps={{
                        rows: 4,
                        sx: {
                            padding: '8px',
                        },
                    }}
                />
            </form>
        </div>
    )
}

export default ShipmentAdvice;