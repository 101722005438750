import { Card, CardContent, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { memo, useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "../ui";

const CardInfo = memo(({
    id = null,
    isHidden = false,
    title = '',
    isCollapse = false,
    children = null,
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleOpenCollapse = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
        else {
            setIsOpen(false);
        }
    }

    return (
        <Card
            id={id}
            component="div"
            hidden={isHidden}
            style={{
                marginTop: '24px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                borderRadius: '12px',
                border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                background: 'var(--Mjr-white-4, #FFF)',
                boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
            }}
        >
            <CardContent style={{ width: '100%', padding: '24px 40px 24px 32px', }}>
                <Grid container sx={{ width: '100%' }} alignItems={'center'}>
                    <Grid item xs={isCollapse ? 6 : 12}>
                        <Typography
                            sx={{
                                color: 'var(--Mjr-black-1, #171717)',
                                fontFamily: 'Switzer',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                            }}
                        >
                            {title}
                        </Typography>
                    </Grid>

                    {
                        isCollapse && (
                            <Grid item xs={6} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    sx={{ alignItems: 'center' }}
                                    onClick={() => handleOpenCollapse()}
                                >
                                    <Icon
                                        iconName={isOpen ? 'arrow-up' : 'arrow-down'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#00286D',
                                            sx: {
                                                height: '18px',
                                                width: '18px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        )
                    }
                </Grid>

                {
                    children && (
                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            <div style={{ width: '100%' }}>
                                {children}
                            </div>
                        </Collapse>
                    )
                }
            </CardContent>
        </Card >
    )
});

CardInfo.propTypes = {
    children: PropTypes.node,
}

export default memo(CardInfo);