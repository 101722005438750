import { Backdrop, Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../../components/Modals/ModalConfirmation';
import { CustomDatePicker, Icon, PopUp } from '../../../../../components/ui';
import { PrintExcel, PrintPDF } from '../../../../../utils/reusableFunction';

const ModalISReport = (props) => {
    const [periodFrom, setPeriodFrom] = useState(new Date());
    const [periodTo, setPeriodTo] = useState(new Date());
    const currentYear = new Date().getFullYear();
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    useEffect(() => {
        getDefaultMonth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDefaultMonth = () => {
        const currentYear = new Date().getFullYear();
        const firstMonthOfYear = `${currentYear}-01-01`;
        setPeriodFrom(firstMonthOfYear);
        setPeriodTo(calculateQuarterlyMonth(firstMonthOfYear));
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationDRC() {
        if (titleConfirmation === 'Print PDF') {
            PrintReport("pdf");
        }
        else if (titleConfirmation === 'Print Excel') {
            PrintReport("xls");
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print PDF')
        setTitleModalConfirmation('Income Statement Report');
        handleClickConfirmation();
    };

    const PrintExcelData = () => {
        setTitleConfirmation('Print Excel')
        setTitleModalConfirmation('Income Statement Report');
        handleClickConfirmation();
    }

    const PrintReport = (code) => {
        setIsLoadingPrint(true);
        props.setIsLoadingPrint(true);

        let params = [
            {
                "attr": "beginningPeriod",
                "value": `${periodFrom}`
            },
            {
                "attr": "endDatePeriod",
                "value": `${periodTo}`
            },
            {
                "attr": "jobOwnerId",
                "value": "" + props.JobOwner
            },
            {
                "attr": "isQuarterly",
                "value": "true"
            }
        ];

        let reportLink = 'Reports/Finance/Print/FinanceReport/IncomeStatementReport/IncomeStatementReport/PrintIncomeStatementStandard?typeReport=' + code;
        if (code === 'pdf') {
            PrintPDF(props.setIsLoadingPrint, 'Income Statement (Standard) Quarter', reportLink, params);
        }
        else {
            PrintExcel(props.setIsLoadingPrint, 'Income Statement (Standard) Quarter', reportLink, params);
        }
        props.handleClick();
    };

    function handleSetPeriod(value) {
        setPeriodFrom(value);
        setPeriodTo(calculateQuarterlyMonth(value));
    }

    const calculateQuarterlyMonth = (month) => {
        const date = new Date(month);
        date.setMonth(date.getMonth() + 2);
        const year = date.getFullYear();
        const monthString = (date.getMonth() + 1).toString().padStart(2, '0');
        return `${year}-${monthString}-01`;
    };

    const mainContainer = (
        <div>
            <Grid container spacing={'16px'} justifyContent="center">
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <CustomDatePicker
                        label={'Period From'}
                        name="period"
                        value={periodFrom}
                        onChange={(e) => { handleSetPeriod(e) }}
                        type={'month'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        customDateProps={{
                            minDate: new Date(`${currentYear - 10}-01`),
                            maxDate: new Date(`${currentYear}-12`),
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <CustomDatePicker
                        label={'Period To'}
                        name="period"
                        value={periodTo}
                        disabled
                        type={'month'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Income Statement Report - Quarterly'}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        <Box display="flex" alignItems="center">
                            <Grid
                                item
                                container
                                className='text-end'
                                justifyContent={'flex-end'}
                                spacing={'12px'}
                                direction="row"
                                style={{
                                    'maxWidth': '100vw',
                                    margin: '0px',
                                    columnGap: '8px',
                                    rowGap: '10px'
                                }}
                            >
                                {ButtonComponent.PrintButton(PrintData, 'PDF', true)}
                                {ButtonComponent.ExcelButton(PrintExcelData, "Print Excel", false, true)}
                            </Grid>
                        </Box>
                    </div>
                </div>
            </PopUp>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={true}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRC}
                    />
                )
            }
        </>
    )
}

export default ModalISReport