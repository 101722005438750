import { Grid } from '@mui/material';
import React, { useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../services/ApiService';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';
import { defaultDate, getDefaultDateFormat, nullDate } from '../../../../utils/date';

const SelectDataContact = (props) => {
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const param = "estimateProfitLoss/estimateProfitLoss";
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    const getDataDetail = (id) => {
        let params = [];
        params = [
            ...params,
            { 'attr': 'eplId', 'value': '' + props.EPLId },
            { 'attr': 'customerId', 'value': '' + id },
            { 'attr': 'isIncome', 'value': '' + false },
            { 'attr': 'refId', 'value': '' + 0 },
        ];
        if (!props.isCostToCost) {
            params = [
                ...params,
                { 'attr': 'isCostToCost', 'value': '' + props.isCostToCost },
            ];
        }
        let arrayData = [];
        let dataArray = [];
        ApiService.OperationPostByDetail(param, 'SEARCH', 1, 200, params)
            .then((response) => {
                if (response.data.code === 200) {
                    arrayData = response.data.data.estimateProfitLossDetail;

                    let Sequence = 1;
                    let payReqId = 0;
                    let delivery = '';

                    let amount = 0;

                    if (props.prId !== undefined || props.prId !== null) {
                        payReqId = props.prId;

                        if (payReqId === undefined) {
                            payReqId = 0;
                        }
                    }
                    else {
                        payReqId = 0;
                    }

                    if (arrayData.length > 0) {
                        props.setIsGeneralPR(false);

                        for (let i = 0; i < arrayData.length; i++) {
                            if (arrayData[i].isIncome !== true) {
                                if (arrayData[i].amountCrr === 0) {
                                    amount = parseFloat(arrayData[i].amountUSD);
                                }
                                else if (arrayData[i].amountCrr === 1) {
                                    amount = parseFloat(arrayData[i].amountIDR);
                                }

                                if (arrayData[i].deliveryDate !== nullDate) {
                                    delivery = arrayData[i].deliveryDate;
                                }
                                else {
                                    delivery = defaultDate;
                                }

                                let data = {
                                    "countryId": countryId,
                                    "companyId": companyId,
                                    "branchId": branchId,
                                    "userId": userId,
                                    "userCode": userCode,

                                    "rowStatus": "ACT",
                                    "prId": payReqId,
                                    "sequence": Sequence,
                                    "debetCredit": 'C',
                                    "accountId": arrayData[i].accountId,
                                    "accountCode": arrayData[i].accountCode,
                                    "accountName": arrayData[i].accountName,
                                    "coaCode": arrayData[i].coaCode,
                                    "coaName": arrayData[i].coaName,
                                    "description": arrayData[i].description,
                                    "type": arrayData[i].type,
                                    "containerSize": arrayData[i].containerSize,
                                    "formatContainer": arrayData[i].formatContainer,
                                    "containerTypeId": arrayData[i].containerTypeId,
                                    "containerTypeCode": arrayData[i].containerTypeCode,
                                    "containerTypeName": arrayData[i].containerTypeName,
                                    "isContainer": arrayData[i].isContainer,
                                    "codingQuantity": arrayData[i].codingQuantity,
                                    "quantity": arrayData[i].quantity,
                                    "perQty": parseFloat(arrayData[i].perQty),
                                    "amount": parseFloat(amount),
                                    "amountCrr": arrayData[i].amountCrr,
                                    "paid": false,
                                    "paidOn": defaultDate,
                                    "paidPV": false,
                                    "eplDetailId": arrayData[i].id,
                                    "statusMemo": arrayData[i].statusMemo,
                                    "memoNo": arrayData[i].memoNo,
                                    "isCostToCost": arrayData[i].isCostToCost,
                                    "isPpn": false,
                                    "persenPpn": 0,
                                    "fakturNo": '',
                                    "fakturDate": defaultDate,
                                    "isCostTrucking": arrayData[i].isCostTrucking,
                                    "kendaraanId": arrayData[i].kendaraanId,
                                    "kendaraanNopol": arrayData[i].kendaraanNopol,
                                    "employeeId": arrayData[i].employeeId,
                                    "employeeName": arrayData[i].employeeName,
                                    "mrgId": arrayData[i].mrgId,
                                    "deliveryDate": delivery,
                                    "originalUsd": parseFloat(arrayData[i].originalUsd),
                                    "originalRate": parseFloat(arrayData[i].originalRate),

                                    "idLama": 0,
                                }

                                dataArray = [...dataArray, data];

                                Sequence = parseInt(Sequence) + parseInt(1);
                            }
                        }

                        props.setPaymentRequestDetail(dataArray);

                        props.calculateTotal(dataArray);
                        props.setIsLoadingBackDrop(false);
                    }
                    else {
                        // let paramEPL = [
                        //     {
                        //         "attr": "IsForEPL",
                        //         "value": "1"
                        //     },
                        //     {
                        //         "attr": "EPLClosingDate",
                        //         "value": formatDate
                        //     }
                        // ]

                        ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, getDefaultDateFormat(new Date()))
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let data = response.data.data.closingPeriod;

                                    if (props.yearPeriod === data.yearPeriod) {
                                        if (props.period === data.period) {
                                            let currentDate = new Date();
                                            let dayOfMonth = currentDate.getDate();
                                            let currentMonth = currentDate.getMonth();
                                            if (currentMonth > props.period) {
                                                if (dayOfMonth > 5) {
                                                    props.setIsClosed(true);
                                                    localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                                    props.setIsLoadingBackDrop(false);
                                                    props.setIsGeneralPR(true);
                                                }
                                                else {
                                                    props.setIsClosed(false);
                                                    localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                                    props.setIsLoadingBackDrop(false);
                                                    props.setIsGeneralPR(false);
                                                    showToast({ type: 'error', message: 'EPL Still Open, Please Input Detail on EPL!' });
                                                }
                                            }
                                            else {
                                                props.setIsClosed(false);
                                                localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                                props.setIsLoadingBackDrop(false);
                                                props.setIsGeneralPR(false);
                                                showToast({ type: 'error', message: 'EPL Still Open, Please Input Detail on EPL!' });
                                            }
                                        }
                                        else if (props.period < data.period) {
                                            props.setIsClosed(true);
                                            localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                            props.setIsLoadingBackDrop(false);
                                            props.setIsGeneralPR(true);
                                        }
                                        else {
                                            props.setIsClosed(false);
                                            localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                            props.setIsLoadingBackDrop(false);
                                            props.setIsGeneralPR(false);
                                            showToast({ type: 'error', message: 'EPL Still Open, Please Input Detail on EPL!' });
                                        }
                                    }
                                    else {
                                        props.setIsClosed(true);
                                        localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                        props.setIsLoadingBackDrop(false);
                                        props.setIsGeneralPR(true);
                                    }
                                }
                            })
                            .catch(function (error) {
                                // console.error('error saat fetch', error);
                            })
                    }
                }
            })
            .catch(function (error) {
                props.setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    function Select() {
        props.setIsLoadingBackDrop(true);
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (props.isGeneralPR === false) {
                props.setCustomerId(props.SelectedData.customerId);
                props.setCustomerName(props.SelectedData.customerName);

                getDataDetail(props.SelectedData.customerId);

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else {
                props.setCustomerId(props.SelectedData.contactId);
                props.setCustomerName(props.SelectedData.contactName);
                props.setIsLoadingBackDrop(false);

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getDataHeader(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#FFF'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataContact;