import {
    Backdrop, Box, Grid,
    IconButton, InputAdornment,
    Card, CardContent, MenuItem
} from '@mui/material';
import React, { useImperativeHandle, forwardRef, useEffect, useState } from 'react';

import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import ButtonRestoreClosingMonthly from './Buttons/ButtonRestoreClosingMonthly';
import SelectDataPeriod from './Selects/SelectDataPeriod';
import ApiService from '../../../../services/ApiService';

const ArAgingReport = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);
    const [customCompany, setCustomCompany] = useState('C');
    const [closingType, setClosingType] = useState('GL');

    const [PeriodFrom, setPeriodFrom] = useState('');
    const [PeriodTo, setPeriodTo] = useState('');
    const [Period, setPeriod] = useState(0);
    const [YearPeriod, setYearPeriod] = useState(0);

    const customCompanyList = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Per Regional' }
    ];

    const closingTypeList = [
        { id: 1, code: 'GL', name: 'General Ledger' },
        { id: 2, code: 'ARAP', name: 'A/R & A/P' }
    ];

    const [isModalP, setIsModalP] = useState(false);
    const [titleModalP, setTitleModalP] = useState('');

    const paramP = "regClosingPeriod/regClosingPeriod";
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [dataMapP, setDataMapP] = useState([]);
    const [columnDataP, setColumnDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Restore Closing Process Per Period', link: '/Majura/RestoreClosingMonthly', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {
            initializeView();
        }
    }));

    useEffect(() => {
        document.title = 'Restore Closing Process Per Period - ' + JSON.parse(localStorage.getItem('branchName'));
        initializeView();
    }, [])

    function initializeView() {
        setIsLoadingBackDrop(true);
        setTimeout(function () { setIsLoadingBackDrop(false); }, 2000);
    }

    const handleClickP = () => {
        if (isModalP === false) {
            setIsModalP(true);
            getDataPeriod(1, 50);
        }
        else {
            setIsModalP(false);
        }
    };

    const searchDataPeriod = (val) => {
        if (val === 'search') {
            setTitleModalP('Data Period');
            handleClickP();
        }
        else {
            setPeriod(0);
            setYearPeriod(0);
            setPeriodFrom('');
            setPeriodTo('');
        }
    };

    const getDataPeriod = (pageNumber, pageSize, filters) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.closingPeriod);

                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)

                    setIsLoadingP(false)
                }
            })
            .catch(function (error) {
                setIsLoadingP(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmRestoreClosingMonthly() {
        if (titleConfirmation === 'Process') {
            Process()
        }
        setIsModalConfirmation(false);
    }

    const ProcessData = () => {
        setTitleConfirmation('Process')
        setTitleModalConfirmation('Restore Closing Process Per Period');
        handleClickConfirmation();
    };

    const Process = () => {
        showToast({ type: 'success', message: 'Restore Closing Period ' + Period + 'Year Period ' + YearPeriod + ' Success' });
    }

    const ButtonComponents = () => {
        return (
            <ButtonRestoreClosingMonthly
                Process={ProcessData}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Restore Closing Process Per Period'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={customCompany}
                                                    label="Custom Company"
                                                    placeholder={'Select Custom Company'}
                                                    onChange={(e) => setCustomCompany(e.target.value)}
                                                >
                                                    {
                                                        customCompanyList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    value={closingType}
                                                    label="Closing Type"
                                                    placeholder={'Select Closing Type'}
                                                    onChange={(e) => setClosingType(e.target.value)}
                                                >
                                                    {
                                                        closingTypeList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Grid
                                            container
                                            spacing={'16px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    label={'Period'}
                                                    value={Period}
                                                    fullWidth
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />

                                                <CustomDatePicker
                                                    label="Beginning Period"
                                                    value={PeriodFrom}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Input
                                                    label="Year Period"
                                                    variant="standard"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    value={YearPeriod}
                                                    type='text'
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                        }
                                                    }}
                                                    disabled
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => searchDataPeriod('delete')}
                                                                    size='small'
                                                                    hidden={Period ? false : true}
                                                                >
                                                                    <Icon
                                                                        iconName={'close'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>

                                                                <IconButton
                                                                    onClick={() => searchDataPeriod('search')}
                                                                    size='small'
                                                                >
                                                                    <Icon
                                                                        iconName={'search'}
                                                                        svgIconProps={{
                                                                            fontSize: 'small',
                                                                            htmlColor: '#0087C2',
                                                                            sx: {
                                                                                height: '12px',
                                                                                width: '12px'
                                                                            }
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />

                                                <CustomDatePicker
                                                    label="Ending Period"
                                                    value={PeriodTo}
                                                    type={'date'}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px !important'
                                                        }
                                                    }}
                                                    inputBaseProps={{
                                                        readOnly: true,
                                                    }}
                                                    disabled
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalP && (
                    <SelectDataPeriod
                        isModal={isModalP}
                        handleClick={handleClickP}
                        titleModal={titleModalP}
                        setIsModal={setIsModalP}

                        getData={getDataPeriod}
                        isLoading={isLoadingP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}
                        data={dataP}
                        columnData={columnDataP}
                        dataMap={dataMapP}

                        setPeriodFrom={setPeriodFrom}
                        setPeriodTo={setPeriodTo}
                        setPeriod={setPeriod}
                        setYearPeriod={setYearPeriod}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmRestoreClosingMonthly}
                    />
                )
            }
        </Box>
    )
})

export default ArAgingReport