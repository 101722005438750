import React, { forwardRef, useState, useImperativeHandle, useEffect } from 'react';
import { Avatar, Box, Stack, Grid, Backdrop, Skeleton, Card, CardContent, Typography, Button, Collapse } from '@mui/material';
import { ToastContainer } from 'react-toastify';

import ModalChangePassword from './Modals/ModalChangePassword';
import ApiService, { userCode, userId, username } from '../../../services/ApiService';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { Icon, showToast } from '../../../components/ui';
import jwtDecode from 'jwt-decode';
import { getDataMenu } from '../Login';
import ModalChangeProfile from './Modals/ModalChangeProfile';
import ModalChangePhoto from './Modals/ModalChangePhoto';
import { useNavigate } from 'react-router-dom';

const companyName = JSON.parse(localStorage.getItem("companyName"))
const branchName = JSON.parse(localStorage.getItem("branchName"))
const roleName = JSON.parse(localStorage.getItem("roleName"))
export const dataProfile = JSON.parse(localStorage.getItem("dataProfile"))

const Profile = forwardRef((props, ref) => {
    const history = useNavigate();

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [isCollapse, setIsCollapse] = useState(false);

    const [photoProfile, setPhotoProfile] = useState(localStorage.getItem('profilePicture') !== undefined ? localStorage.getItem('profilePicture') : null);

    const [isModalPass, setIsModalPass] = useState(false);
    const [CPass, setCPass] = useState('');
    const [NPass, setNPass] = useState('');
    const [RPass, setRPass] = useState('');
    const [SelectedItem, setSelectedItem] = useState({});

    const [isModal, setIsModal] = useState(false);
    const [isModalPhoto, setIsModalPhoto] = useState(false);

    useEffect(() => {
        document.title = 'Profile - ' + userCode + ' - ' + JSON.parse(localStorage.getItem('branchName'))
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    const handleClickPass = () => {
        if (isModalPass === false) {
            setIsModalPass(true);
        } else {
            setIsModalPass(false);
            emptyStatePass();
        }
    };

    const handleChangePassword = () => {
        handleClickPass()
    }

    const emptyStatePass = () => {
        setCPass('');
        setNPass('');
        setRPass('');
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
        }
    }

    const handleChangeProfile = (item) => {
        setSelectedItem(item);
        handleClick();
    }

    const ChangeUserProfile = (item) => {
        setIsLoadingBackDrop(true);

        const companyId = item.companyId;
        const countryId = item.countryId;
        const branchId = item.branchId;
        const companyName = item.companyName;
        const countryName = item.countryName;
        const branchName = item.branchName;
        const roleId = item.roleId;
        const roleLevel = item.roleLevel;
        const roleName = item.roleName;
        const groupId = item.groupId;
        const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
        //set JWT token to local
        localStorage.setItem("companyId", JSON.stringify(companyId));
        localStorage.setItem("countryId", JSON.stringify(countryId));
        localStorage.setItem("branchId", JSON.stringify(branchId));
        localStorage.setItem("companyName", JSON.stringify(companyName));
        localStorage.setItem("countryName", JSON.stringify(countryName));
        localStorage.setItem("branchName", JSON.stringify(branchName));
        localStorage.setItem("roleId", JSON.stringify(roleId));
        localStorage.setItem("roleLevel", JSON.stringify(roleLevel));
        localStorage.setItem("roleName", JSON.stringify(roleName));
        localStorage.setItem("groupId", JSON.stringify(groupId));

        //get new token
        const data = {
            "groupId": groupId,
            "countryId": countryId,
            "companyId": companyId,
            "branchId": branchId,
            "userId": userId,
            "roleId": roleId,
            "refreshToken": refreshToken,
        }
        ApiService.ChangeProfile(data).then((res) => {
            if (res.data.code === 200) {
                const data = res.data.data;
                let dataLogin = data.authentication;
                let jobTypeId = 0;
                let currency = 'USD';
                let parentCompanyId = 0;

                if (dataLogin.companyIdPusat !== undefined) {
                    parentCompanyId = dataLogin.companyIdPusat;
                }

                if (dataLogin.jobTypeId === 0) {
                    jobTypeId = 1;
                }
                else {
                    jobTypeId = dataLogin.jobTypeId;
                }

                let decode = jwtDecode(dataLogin.token);
                localStorage.setItem("page", JSON.stringify(1));
                localStorage.setItem("rows", JSON.stringify(50));
                localStorage.setItem("dataUser", JSON.stringify(data));
                localStorage.setItem('dashboardMenu', JSON.stringify(0));
                localStorage.setItem("pinStatus", JSON.stringify('false'));
                localStorage.setItem("jobTypeId", JSON.stringify(jobTypeId));
                localStorage.setItem("bsPeriod", JSON.stringify(new Date()));
                localStorage.setItem("token", JSON.stringify(dataLogin.token));
                localStorage.setItem("sidebarStatus", JSON.stringify('false'));
                localStorage.setItem("expiredToken", JSON.stringify(decode.exp));
                localStorage.setItem("userId", JSON.stringify(dataLogin.userId));
                localStorage.setItem("roleId", JSON.stringify(dataLogin.roleId));
                localStorage.setItem("username", JSON.stringify(dataLogin.code));
                localStorage.setItem("userCode", JSON.stringify(dataLogin.code));
                localStorage.setItem("groupId", JSON.stringify(dataLogin.groupId));
                localStorage.setItem('roleInt', JSON.stringify(dataLogin.roleCode));
                localStorage.setItem("roleName", JSON.stringify(dataLogin.roleName));
                localStorage.setItem("branchId", JSON.stringify(dataLogin.branchId));
                localStorage.setItem("roleLevel", JSON.stringify(dataLogin.roleLevel));
                localStorage.setItem("companyId", JSON.stringify(dataLogin.companyId));
                localStorage.setItem("idleTimer", JSON.stringify(dataLogin.idleTimer));
                localStorage.setItem("countryId", JSON.stringify(dataLogin.countryId));
                localStorage.setItem("roleCode", JSON.stringify(dataLogin.department));
                localStorage.setItem("employeeId", JSON.stringify(dataLogin.employeeId));
                localStorage.setItem('parentCompanyId', JSON.stringify(parentCompanyId));
                localStorage.setItem("branchName", JSON.stringify(dataLogin.branchName));
                localStorage.setItem(dataLogin.code + "-isLogOut", JSON.stringify(false));
                localStorage.setItem("companyName", JSON.stringify(dataLogin.companyName));
                localStorage.setItem("countryName", JSON.stringify(dataLogin.countryName));
                localStorage.setItem("refreshToken", JSON.stringify(dataLogin.refreshToken));

                if (currency === 'USD') {
                    localStorage.setItem('currency', JSON.stringify('US-en'));
                    localStorage.setItem('formatCurrency', JSON.stringify('USD'));
                }
                else if (currency === 'IDR') {
                    localStorage.setItem('currency', JSON.stringify('ID-id'));
                    localStorage.setItem('formatCurrency', JSON.stringify('IDR'));
                }

                //generate menu
                var dataMenu = data.permissions;
                getDataMenu(dataMenu)
                showToast({ type: 'success', message: 'Success Change Profile!' });

                setTimeout(
                    function () {
                        ApiService.refreshToken();
                        window.location.reload();
                        setIsLoadingBackDrop(false);
                    },
                    1250);
            }
            else if (res.data.code === 500) {
                showToast({ type: 'error', message: 'Failed to Change Profile, Please Contact Administrator, ' + res.data.message });

                setTimeout(
                    function () {
                        setIsLoadingBackDrop(false);
                    },
                    1250);
            }
            else {
                showToast({ type: 'error', message: res.data.message });

                setTimeout(
                    function () {
                        setIsLoadingBackDrop(false);
                    },
                    1250);
            }
        }).catch(function (error) {
            console.error('error', error)
            // showToast({ type: 'error', message: 'Failed to Change Profile, Please Contact Administrator, ' + error });
        })
    }

    const handleClickPhoto = () => {
        if (isModalPhoto === false) {
            setIsModalPhoto(true);
        } else {
            setIsModalPhoto(false);
        }
    }

    const handleCollapse = () => {
        if (isCollapse === false) {
            setIsCollapse(true);
        }
        else {
            setIsCollapse(false);
        }
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        name = name.toUpperCase();
        let nama = name;
        let pjg = name.split(' ').length;

        if (pjg > 1) {
            nama = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        }
        else {
            nama = `${name.split(' ')[0][0]}`
        }

        return {
            sx: {
                bgcolor: stringToColor(name),
                // bgcolor: '#45454d',
            },
            children: nama
        };
    }

    const handleLogOut = () => {
        if (isLoggingOut) return;  // Prevent multiple clicks

        setIsLoggingOut(true);
        setIsLoadingBackDrop(true);

        localStorage.setItem(username + "-lastUrl", JSON.stringify(window.location.pathname));

        ApiService.logOut().then((res) => {
            setTimeout(() => {
                localStorage.setItem(username + "-isLogOut", JSON.stringify(true));
                setIsLoadingBackDrop(false);
                history('/', { replace: true });
                setIsLoggingOut(false);
            }, 500);
        });
    };

    return (
        <>
            <ToastContainer />
            <Box sx={{ backgroundColor: '#FAFAFA', width: '100%', padding: '40px' }}>
                <Card component="div" style={{ borderRadius: '12px', width: '100%', backgroundColor: '#FFF', padding: '40px 80px' }}>
                    <CardContent style={{ padding: '0px' }}>
                        <Stack direction={'row'} justifyContent={'center'} spacing={'80px'} sx={{ width: '100%' }}>
                            <div>
                                <Stack direction={'column'} spacing={'24px'} width={'max-content'} alignContent={'center'}>
                                    <div>
                                        {
                                            isLoadingBackDrop && !isLoggingOut ?
                                                <Skeleton variant="circular" width={160} height={160} />
                                                :
                                                <>
                                                    {
                                                        photoProfile && (
                                                            <Avatar style={{ width: 160, height: 160 }} src={photoProfile} draggable={false} sx={{ userSelect: "none", pointerEvents: "none" }} />
                                                        )
                                                    }

                                                    {
                                                        !photoProfile && (
                                                            <Avatar style={{ width: 160, height: 160, fontSize: 100 }} draggable={false} {...stringAvatar(username)} />
                                                        )
                                                    }
                                                </>
                                        }
                                    </div>

                                    <Button
                                        size='small'
                                        variant="outlined"
                                        className='d-flex align-items-center'
                                        sx={{
                                            display: 'flex',
                                            padding: '8px 16px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                            borderRadius: '8px',
                                            border: '1px solid var(--existing-Grey-Outline-1, #CACED8)',
                                        }}
                                        onClick={() => handleClickPhoto()}
                                    >
                                        <Typography
                                            sx={{
                                                color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                textAlign: 'center',
                                                'font-feature-settings': 'liga off, clig off',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                                letterSpacing: '0.5px',
                                            }}
                                        >
                                            Change Photo
                                        </Typography>
                                    </Button>
                                </Stack>
                            </div>

                            <div style={{ width: '100%' }}>
                                <Grid container sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={6}
                                    >
                                        <Stack direction={'row'} spacing={'24px'} sx={{ width: '100%' }} alignItems={'center'}>
                                            <div>
                                                {
                                                    isLoadingBackDrop && !isLoggingOut ?
                                                        <Skeleton width={200} />
                                                        :
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--Mjr-black-1, #171717)',
                                                                    fontFamily: 'Switzer',
                                                                    fontSize: '24px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '600',
                                                                    lineHeight: 'normal',
                                                                }}
                                                            >
                                                                {userCode}
                                                            </Typography>
                                                        </>
                                                }
                                            </div>

                                            <div>
                                                {
                                                    isLoadingBackDrop && !isLoggingOut ?
                                                        <Skeleton width={145} />
                                                        :
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                padding: '4px 8px',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                gap: '10px',
                                                                borderRadius: '8px',
                                                                border: '2px solid var(--Green-Button, #168444)',
                                                                background: '#96F4BD',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    color: 'var(--existing-Green-Button, #168444)',
                                                                    fontFamily: 'Switzer',
                                                                    fontSize: '10px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '600',
                                                                    lineHeight: '140%',
                                                                    letterSpacing: '0.1px',
                                                                }}
                                                            >
                                                                Active User Role
                                                            </Typography>
                                                        </div>
                                                }
                                            </div>
                                        </Stack>
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={6}
                                        className='text-end'
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                    >
                                        <div>
                                            {
                                                isLoadingBackDrop && !isLoggingOut ?
                                                    <Skeleton width={225} height={40} />
                                                    :
                                                    <Button
                                                        size='small'
                                                        variant="outlined"
                                                        className='d-flex align-items-center'
                                                        sx={{
                                                            display: 'flex',
                                                            padding: '8px 16px',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: '8px',
                                                            borderRadius: '8px',
                                                            border: '1px solid var(--existing-Grey-Outline-1, #CACED8)',
                                                        }}
                                                        onClick={handleChangePassword}
                                                    >
                                                        <Icon
                                                            iconName={'password'}
                                                            svgIconProps={{
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '16px',
                                                                    width: '16px'
                                                                }
                                                            }}
                                                        />

                                                        <Typography
                                                            sx={{
                                                                color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                                textAlign: 'center',
                                                                'font-feature-settings': 'liga off, clig off',
                                                                fontFamily: 'Nunito',
                                                                fontSize: '14px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '600',
                                                                lineHeight: 'normal',
                                                                letterSpacing: '0.5px',
                                                            }}
                                                        >
                                                            Change Password
                                                        </Typography>
                                                    </Button>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>

                                <div style={{ width: '100%', marginTop: '24px' }}>
                                    <Grid container sx={{ width: '100%' }}>
                                        <Grid
                                            item
                                            xl={12}
                                            lg={12}
                                            sm={12}
                                            xs={12}
                                        >
                                            <div>
                                                {
                                                    isLoadingBackDrop && !isLoggingOut ?
                                                        <Skeleton width={300} />
                                                        :
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                                fontFamily: 'Switzer',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '600',
                                                                lineHeight: 'normal',
                                                            }}
                                                        >
                                                            {roleName}
                                                        </Typography>
                                                }
                                            </div>

                                            <div style={{ marginTop: '12px' }}>
                                                {
                                                    isLoadingBackDrop && !isLoggingOut ?
                                                        <Skeleton width={300} />
                                                        :
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Mjr-black-1, #171717)',
                                                                fontFamily: 'Switzer',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}
                                                        >
                                                            {branchName}
                                                        </Typography>
                                                }
                                            </div>

                                            <div style={{ marginTop: '12px' }}>
                                                {
                                                    isLoadingBackDrop && !isLoggingOut ?
                                                        <Skeleton width={300} />
                                                        :
                                                        <Typography
                                                            sx={{
                                                                color: 'var(--Mjr-black-2, #747475)',
                                                                fontFamily: 'Switzer',
                                                                fontSize: '16px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: 'normal',
                                                            }}
                                                        >
                                                            {companyName}
                                                        </Typography>
                                                }
                                            </div>

                                            <div style={{ marginTop: '24px' }}>
                                                <Button
                                                    disableRipple
                                                    size='small'
                                                    variant="text"
                                                    className='d-flex align-items-center'
                                                    sx={{
                                                        display: 'flex',
                                                        padding: '8px 0px',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: '8px',
                                                        borderRadius: '8px',
                                                        border: '1px solid transparent',
                                                        "&:hover": {
                                                            backgroundColor: "transparent", // Remove background fill color
                                                            boxShadow: "none", // Remove shadow
                                                        },
                                                    }}
                                                    onClick={() => handleCollapse()}
                                                >
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                            textAlign: 'center',
                                                            'font-feature-settings': 'liga off, clig off',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '14px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '600',
                                                            lineHeight: 'normal',
                                                            letterSpacing: '0.5px',
                                                        }}
                                                    >
                                                        {isCollapse ? 'Hide More Roles' : 'Show More Roles'}
                                                    </Typography>

                                                    <Icon
                                                        iconName={isCollapse ? 'arrow-up' : 'arrow-down'}
                                                        svgIconProps={{
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '16px',
                                                                width: '16px'
                                                            }
                                                        }}
                                                    />
                                                </Button>

                                                <Collapse in={isCollapse} timeout="auto" unmountOnExit sx={{ marginTop: isCollapse ? '24px' : '0px' }}>
                                                    <Card
                                                        sx={{
                                                            width: '100%',
                                                            display: 'flex',
                                                            alignItems: 'flex-start',
                                                            alignSelf: 'stretch',
                                                            backgroundColor: '#FFF',
                                                            borderRadius: '8px',
                                                            border: '1px solid var(--Mjr-black-4, #D1D1D2)',
                                                        }}
                                                    >
                                                        <CardContent sx={{ padding: '0px !important', width: '100%' }}>
                                                            {
                                                                dataProfile.map((item, index) => {
                                                                    return (
                                                                        <Card
                                                                            key={index}
                                                                            sx={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                padding: '20px 32px',
                                                                                alignItems: 'flex-start',
                                                                                alignSelf: 'stretch',
                                                                                backgroundColor: '#FFF',
                                                                                borderRadius: '8px',
                                                                                border: '1px solid var(--Mjr-black-4, #D1D1D2)',
                                                                            }}
                                                                        >
                                                                            <CardContent sx={{ padding: '0px !important', width: '100%' }}>
                                                                                <div style={{ width: '100%' }}>
                                                                                    <Grid container sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
                                                                                        <Grid
                                                                                            item
                                                                                            xl={6}
                                                                                            lg={6}
                                                                                            sm={6}
                                                                                            xs={6}
                                                                                        >
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                                                                    fontFamily: 'Switzer',
                                                                                                    fontSize: '16px',
                                                                                                    fontStyle: 'normal',
                                                                                                    fontWeight: '600',
                                                                                                    lineHeight: 'normal',
                                                                                                }}
                                                                                            >
                                                                                                {item.roleName}
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                        <Grid
                                                                                            item
                                                                                            xl={6}
                                                                                            lg={6}
                                                                                            sm={6}
                                                                                            xs={6}
                                                                                            className='text-end'
                                                                                            display={'flex'}
                                                                                            justifyContent={'flex-end'}
                                                                                        >
                                                                                            <Button
                                                                                                size='small'
                                                                                                variant="outlined"
                                                                                                className='d-flex align-items-center'
                                                                                                sx={{
                                                                                                    display: 'flex',
                                                                                                    padding: '8px 12px',
                                                                                                    justifyContent: 'center',
                                                                                                    alignItems: 'center',
                                                                                                    gap: '8px',
                                                                                                    borderRadius: '8px',
                                                                                                    border: '1px solid var(--existing-Grey-Outline-1, #CACED8)',
                                                                                                }}
                                                                                                onClick={() => handleChangeProfile(item)}
                                                                                            >
                                                                                                <Typography
                                                                                                    sx={{
                                                                                                        color: 'var(--existing-Blue-Button-Primary-1, #0087C2)',
                                                                                                        textAlign: 'center',
                                                                                                        fontFamily: 'Nunito',
                                                                                                        fontSize: '12px',
                                                                                                        fontStyle: 'normal',
                                                                                                        fontWeight: '700',
                                                                                                        lineHeight: 'normal',
                                                                                                        letterSpacing: '-0.41px',
                                                                                                    }}
                                                                                                >
                                                                                                    Switch to this Role
                                                                                                </Typography>
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    </Grid>

                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: 'var(--Mjr-black-1, #171717)',
                                                                                            fontFamily: 'Switzer',
                                                                                            fontSize: '16px',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: '400',
                                                                                            lineHeight: 'normal',
                                                                                            marginTop: '12px !important'
                                                                                        }}
                                                                                    >
                                                                                        {item.branchName}
                                                                                    </Typography>

                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: 'var(--Mjr-black-2, #747475)',
                                                                                            fontFamily: 'Switzer',
                                                                                            fontSize: '16px',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: '400',
                                                                                            lineHeight: 'normal',
                                                                                            marginTop: '12px !important'
                                                                                        }}
                                                                                    >
                                                                                        {item.companyName}
                                                                                    </Typography>
                                                                                </div>
                                                                            </CardContent>
                                                                        </Card>
                                                                    )
                                                                })
                                                            }
                                                        </CardContent>
                                                    </Card>
                                                </Collapse>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>

                <Box display="flex" alignItems="center" justifyContent={'center'} sx={{ marginTop: '40px' }}>
                    <Stack direction={'column'} justifyContent={'center'}>
                        <Typography
                            sx={{
                                color: 'var(--Mjr-black-1, #171717)',
                                textAlign: 'center',
                                fontFamily: 'Switzer',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            Version: 1.0.9
                        </Typography>

                        <Typography
                            sx={{
                                color: 'var(--Mjr-black-1, #171717)',
                                fontFamily: 'Switzer',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                textAlign: 'center',
                                marginTop: '12px !important'
                            }}
                        >
                            {/* Last Updated: {new Date().toLocaleString('us-US', { year: 'numeric', month: 'long', day: '2-digit' })} at {new Date().toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit', hour12: false })} */}
                            Last Updated: March 10, 2025 at 07.39
                        </Typography>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                            {
                                isLoadingBackDrop && !isLoggingOut ?
                                    <Skeleton width={145} height={40} sx={{ marginTop: '18px', }} />
                                    :
                                    <Button
                                        size='small'
                                        variant="outlined"
                                        className='d-flex align-items-center'
                                        sx={{
                                            display: 'flex',
                                            padding: '8px 16px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                            borderRadius: '8px',
                                            background: 'var(--Mjr-red-2, #BE2957)',
                                            "&:hover": {
                                                backgroundColor: "#BE2957", // Remove background fill color
                                                boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                            },
                                        }}
                                        onClick={handleLogOut}
                                    >
                                        <Icon
                                            iconName={'log-out'}
                                            svgIconProps={{
                                                htmlColor: '#F2FAFF',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px',
                                                }
                                            }}
                                        />

                                        <Typography
                                            sx={{
                                                color: 'var(--existing-Background-Blue-1, #E4E8EC)',
                                                textAlign: 'center',
                                                'font-feature-settings': 'liga off, clig off',
                                                fontFamily: 'Nunito',
                                                fontSize: '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                                letterSpacing: '0.5px',
                                            }}
                                        >
                                            Log Out
                                        </Typography>
                                    </Button>
                            }
                        </div>
                    </Stack>
                </Box>
            </Box>

            {
                isModalPass && (
                    <ModalChangePassword
                        isModal={isModalPass} handleClick={handleClickPass} setIsModal={setIsModalPass}

                        CPass={CPass} setCPass={setCPass}
                        NPass={NPass} setNPass={setNPass}
                        RPass={RPass} setRPass={setRPass}
                    />
                )
            }

            {
                isModal && (
                    <ModalChangeProfile
                        isModal={isModal}
                        handleClick={handleClick}
                        setIsModal={setIsModal}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}

                        ChangeUserProfile={() => ChangeUserProfile(SelectedItem)}
                    />
                )
            }

            {
                isModalPhoto && (
                    <ModalChangePhoto
                        isModal={isModalPhoto}
                        handleClick={handleClickPhoto}
                        setIsModal={setIsModalPhoto}
                        setPhotoProfile={setPhotoProfile}
                        setIsLoadingBackDrop={setIsLoadingBackDrop}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default Profile