import { Box, Button, Grid, Stack, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import { Icon, PopUp, showToast } from '../../../../components/ui';
import { styled } from '@mui/material/styles';
import React, { memo, useState } from 'react';
import Papa from "papaparse";

const ModalUploadCSV = memo(({ titleModal, isModal, handleClick, containerData, setContainerData, ShipmentId, containerTypeData, packagingTypeData, getContainerSizeData }) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));
    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [dataContainer, setDataContainer] = useState([]);
    const desiredColumns = ["ContainerNo", "SealNo", "Size", "Type", "GrossWeight", "NetWeight", "CBM", "Commodity", "NoOfPieces", "Packaging", "PartOf"];

    const handleClose = () => {
        emptyState();
        handleClick();
    };

    const emptyState = () => {
        setDataContainer([]);
    };

    const handleSave = () => {
        if (dataContainer.length > 0) {
            let newArray = containerData.length !== 0 ? containerData : [];
            let sequence = containerData.length !== 0 ? containerData[containerData.length - 1]?.sequence + 1 : 1;

            dataContainer.forEach((el) => {
                let containerTypeId = 0;
                let containerName = '';
                let packagingName = '';

                for (let i = 0; i < containerTypeData.length; i++) {
                    if (containerTypeData[i].code === el.Type) {
                        containerTypeId = containerTypeData[i].id;
                        containerName = containerTypeData[i].name;
                        break;
                    }
                }

                for (let i = 0; i < packagingTypeData.length; i++) {
                    if (packagingTypeData[i].code === el.Packaging) {
                        packagingName = packagingTypeData[i].name;
                        break;
                    }
                }

                let data = {
                    "rowStatus": "ACT",
                    "shipmentId": ShipmentId,
                    "sequence": sequence,
                    "containerNo": el.ContainerNo,
                    "sealNo": el.SealNo,
                    "containerSize": el.Size,
                    "containerTypeId": containerTypeId,
                    "containerTypeCode": el.Type,
                    "containerTypeName": containerName,
                    "grossWeight": parseInt(el.GrossWeight),
                    "netWeight": parseInt(el.NetWeight),
                    "cbm": parseFloat(el.CBM),
                    "partOf": el.PartOf,
                    "commodity": el.Commodity,
                    "noOfPieces": parseInt(el.NoOfPieces),
                    "packagingCode": el.Packaging,
                    "packagingName": packagingName,
                }

                newArray = [...newArray, data];
                sequence++;
            });

            setContainerData(newArray);
            getContainerSizeData(newArray);
            emptyState();
            handleClose();
        }
        else {
            showToast({ type: 'error', message: 'No data found in the CSV file!' });
        }
    }

    const handleUploadFile = (event) => {
        const file = event.target.files[0];

        if (file && file.type !== "text/csv") {
            showToast({ type: 'error', message: 'Invalid file type. Please upload a CSV file!' });
            return;
        }

        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
                const rows = result.data;

                if (rows.length === 0) {
                    showToast({ type: 'error', message: 'No data found in the CSV file!' });
                    return;
                }

                const csvHeaders = Object.keys(rows[0]);
                const headersMatch = desiredColumns.every(col => csvHeaders.includes(col));

                if (!headersMatch) {
                    showToast({ type: 'error', message: 'CSV headers do not match the expected format!' });
                    return;
                }

                const filteredData = rows.map(row => {
                    let filteredRow = {};
                    desiredColumns.forEach(col => {
                        if (col in row) {
                            if (col === "PartOf") {
                                filteredRow[col] = row[col] === 'true' || row[col] === 'True' ? true : false;
                            } else if (col === 'Size') {
                                filteredRow[col] = row[col] + "'";
                            } else {
                                filteredRow[col] = row[col];
                            }
                        }
                    });
                    return filteredRow;
                });

                setDataContainer(filteredData);
            }
        });
    };


    const mainContainer = (
        <Box sx={{ marginTop: '16px' }}>
            <TableContainer sx={{ maxHeight: 200, borderRadius: '12px', background: '#FFF' }}>
                <Table sx={{ minWidth: 450 }} size='small' aria-label="a dense table" stickyHeader>
                    <TableHead
                        sx={{
                            '& th:first-child': {
                                borderRadius: '12px 0 0'
                            },
                            '& th:last-child': {
                                borderRadius: '0 12px 0 0'
                            }
                        }}
                    >
                        <TableRow>
                            {
                                desiredColumns.map((col, index) => (
                                    <StyledTableCell key={index}>{col}</StyledTableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataContainer.length > 0 ?
                                dataContainer.map((el) => {
                                    return (
                                        <TableRow key={el.sequence} hover={true}>
                                            {
                                                desiredColumns.map((col, index) => (
                                                    <StyledTableBodyCell key={index}>{col !== 'PartOf' ? el[col] : el[col] === true ? 'Yes' : 'No'}</StyledTableBodyCell>
                                                ))
                                            }
                                        </TableRow>
                                    )
                                })
                                :
                                <TableRow>
                                    <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                        <Typography
                                            sx={{
                                                color: 'var(--Primary-One, #083A50)',
                                                fontFamily: 'Nunito',
                                                fontSize: '12px',
                                                fontstyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: 'normal'
                                            }}
                                        >
                                            Data Empty
                                        </Typography>
                                    </StyledTableBodyCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'upload'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '20px',
                                width: '20px'
                            }
                        }}
                    />
                }
                title={titleModal}
                isOpen={isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '16px 6px' }}>
                    <Box display="flex">
                        <Typography
                            sx={{
                                fontFamily: 'Nunito',
                                marginTop: '3px !important',
                                fontSize: '16px',
                                fontWeight: '700',
                                lineHeight: '20px',
                                letterSpacing: '0em',
                                textAlign: 'left',
                            }}
                        >
                            Note: Please follow this template below to upload CSV file (Header must included and match with table below)
                        </Typography>
                    </Box>
                    {mainContainer}
                </div>

                <div style={{ padding: '24px 6px 0px' }}>
                    <Box display="flex" alignItems="center">
                        <Grid container item direction="row">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'} sx={{ gap: '10px' }}>
                                <Button
                                    size='small'
                                    variant="outlined"
                                    className='d-flex align-items-center'
                                    sx={{
                                        padding: '4px 12px !important',
                                        gap: '8px',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '32px !important',
                                        minHeight: '28px !important',
                                        borderRadius: '8px',
                                        background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                        '&:hover': {
                                            backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                        }
                                    }}
                                >
                                    <input
                                        type="file"
                                        // accept="image/*"
                                        id="raised-button-file"
                                        onChange={handleUploadFile}
                                        style={{ display: 'none', cursor: 'pointer' }}
                                    />
                                    <label htmlFor="raised-button-file" style={{ cursor: 'pointer' }}>
                                        <Stack direction={'row'} sx={{ justifyContent: 'center', alignItems: 'center', gap: '4px' }}>
                                            <Icon
                                                svgIconProps={{
                                                    htmlColor: '#E4E8EC',
                                                    sx: {
                                                        height: '16px',
                                                        width: '16px',
                                                    }
                                                }}
                                                iconName={'add'}
                                            />
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Nunito',
                                                    marginTop: '2px !important',
                                                    fontSize: '12px',
                                                    fontWeight: '700',
                                                    lineHeight: '20px',
                                                    letterSpacing: '0em',
                                                    textAlign: 'center',
                                                    color: '#E4E8EC',
                                                }}
                                            >
                                                Select File
                                            </Typography>
                                        </Stack>
                                    </label>
                                </Button>

                                {ButtonComponent.UploadButton(handleSave, 'Upload', true)}
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </PopUp>
        </>
    )
})

export default memo(ModalUploadCSV);