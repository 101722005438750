import { Card, CardContent, Grid, IconButton, InputAdornment, Stack, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import ApiService, { userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import ModalCity from './Selects/ModalCity';
import { useState } from 'react';
import ModalCompany from './Selects/ModalCompany';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, showToast } from '../../../components/ui';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import { autoFocusField, handleDataTable } from '../../../utils/reusableFunction';
import { updateDateFormat } from '../../../utils/date';

function NPWPMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            mask={[
                /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
                ".",
                /\d/,
                "-",
                /\d/, /\d/, /\d/,
                ".",
                /\d/, /\d/, /\d/,
            ]}
            keepCharPositions
        />
    )
}

function IDTKUMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        />
    );
}

NPWPMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

IDTKUMask.propTypes = {
    inputRef: PropTypes.func.isRequired
};

const CreateEditBranch = (props) => {
    const idtkuRef = useRef(null);

    const [isModalCity, setIsModalCity] = useState(false);
    const [titleModalCity, setTitleModalCity] = useState('');

    const [isLoadingCity, setIsLoadingCity] = useState(false);
    const [dataCity, setDataCity] = useState([]);
    const [dataMapCity, setDataMapCity] = useState([])
    const [dataTableCity, setDataTableCity] = useState([]);
    const [columnDataCity, setColumnDataCity] = useState([])
    const [SelectedDataCity, setSelectedDataCity] = useState({});
    const [totalRowsCity, setTotalRowsCity] = useState(50)

    const paramCity = "city/city";

    const [isModalCompany, setIsModalCompany] = useState(false);
    const [titleModalCompany, setTitleModalCompany] = useState('');

    const [isLoadingCompany, setIsLoadingCompany] = useState(false);
    const [dataCompany, setDataCompany] = useState([]);
    const [dataMapCompany, setDataMapCompany] = useState([])
    const [dataTableCompany, setDataTableCompany] = useState([]);
    const [columnDataCompany, setColumnDataCompany] = useState([])
    const [SelectedDataCompany, setSelectedDataCompany] = useState({});
    const [totalRowsCompany, setTotalRowsCompany] = useState(50);

    const npwpRegex = /^\d{2}\.\d{3}\.\d{3}\.\d-\d{3}\.\d{3}$/;
    const [isValidNPWP, setIsValidNPWP] = useState(false);

    const idtkuRegex = /^\d{6}$/;
    const [isValidIDTKU, setIsValidIDTKU] = useState(false);

    const paramCompany = "Company/Company";

    useEffect(() => {
        setIsValidNPWP(npwpRegex.test(props.npwp));
        setIsValidIDTKU(idtkuRegex.test(props.idtku));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.npwp, props.idtku]);

    const Save = () => {
        var data =
        {
            "branch": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "initialCompany": props.initialCompany,
                "abbreviation": props.abbreviation,
                "address": props.address,
                "zipCode": props.zipCode,
                "cityId": props.cityId,
                "companyId": props.companyId,
                "phone": props.phone,
                "fax": props.fax,
                "email": props.email,
                "npwp": props.npwp,
                "nppkp": props.nppkp,
                "idtku": props.idtku,
                "approvalWP": props.approvalWP,
                "namaWP": props.namaWP,
                "alamatWP": props.alamatWP,
                "masterCode": props.masterCode,
                "timeZone": props.timeZone,
                "migrasiEmkl": props.migrasiEmkl,
                "migrasiForwarding": props.migrasiForwarding,
                "nameHeader": props.nameHeader,
                "isVat": props.isVat,
                "token": props.token,
                "migrasiRE": updateDateFormat(props.migrasiRE, true),
                "fullName": props.fullName,
                "groupId": 0,
                "emailCS": props.emailCS,
                "kantorPajakPVPPh23": props.kantorPajakPVPPh23,
                "useBookingConfirmation": props.useBookingConfirmation,
                "useBookingConfirmationDate": props.useBookingConfirmationDate,
                "refCompanyIdPusat": 0,
                "pejkpNamaWP": props.pejkpNamaWP,
                "pejkpJabatanWP": props.pejkpJabatanWP,
                "migrasiGreatherThan": props.migrasiGreatherThan,
                "deleted": false,
                "deletedOn": "2023-02-09T06:34:11.039Z",
                "userCode": userCode
            }
        }

        let errMsg = undefined;
        if (!data.branch.idtku || !idtkuRegex.test(data.branch.idtku)) {
            errMsg = 'IDTKU Format Still Wrong, Please Check It Again!';
            autoFocusField(idtkuRef);
        }

        if (errMsg === undefined) {
            ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
                props.getData(1, 50, []);
                props.handleClick();
            });
        }
        else {
            showToast({ type: 'error', message: errMsg });
        }
    }

    const CariCity = () => {
        if (isModalCity === false) {
            setIsModalCity(true);
            setTitleModalCity('City')
            getDataCity(1, 50)
        } else {
            setIsModalCity(false);
        }
    }

    const getDataCity = (pageNumber, pageSize, filters) => {
        setIsLoadingCity(true);
        ApiService.DynamicPostByPage(paramCity, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCity({})
                    setDataCity(response.data.data.city);

                    let temp = response.data.data.city
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCity(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableCity, 'id');
                    setColumnDataCity(response.data.data.columns)
                    setTotalRowsCity(response.data.totalRowCount)

                    setIsLoadingCity(false)
                }
                setIsLoadingCity(false)
            })
            .catch(function (error) {
                setIsLoadingCity(false)
                console.error('error saat fetch', error)
            })
    }

    const SearchCompany = () => {
        if (isModalCompany === false) {
            setIsModalCompany(true);
            setTitleModalCompany('Company')
            getDataCompany(1, 50)
        } else {
            setIsModalCompany(false);
        }
    }

    const getDataCompany = (pageNumber, pageSize, filters) => {
        setIsLoadingCompany(true);
        ApiService.PostByPage(paramCompany, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataCompany({})
                    setDataCompany(response.data.data.company);

                    let temp = response.data.data.company
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapCompany(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableCompany, 'id');
                    setColumnDataCompany(response.data.data.columns)
                    setTotalRowsCompany(response.data.totalRowCount)

                    setIsLoadingCompany(false)
                }
                setIsLoadingCompany(false)
            })
            .catch(function (error) {
                setIsLoadingCompany(false)
                console.error('error saat fetch', error)
            })
    }

    const handleChangeNPWP = (value) => {
        setIsValidNPWP(npwpRegex.test(value));
        props.setNpwp(value);
    };

    const handleChangeIDTKU = (value) => {
        if (!value) {
            setIsValidIDTKU(false);
        }
        else {
            setIsValidIDTKU(idtkuRegex.test(value));
        }
        props.setIdtku(value);
    };

    const mainContainer = (
        <form style={{ marginTop: '12px' }}>
            <Card
                component="div"
                style={{
                    borderRadius: '12px',
                    backgroundColor: '#FAFAFA',
                    padding: '8px 24px',
                    border: '1px solid var(--Grey-Outline-2, #989898)',
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                        }}
                    >
                        Branch Info
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={9}
                            lg={9}
                            sm={9}
                            xs={12}
                        >
                            <Input
                                label="Company Name"
                                value={props.companyName}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => { SearchCompany() }}>
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    value={props.code}
                                    label="Branch Code"
                                    onChange={(e) => props.setCode(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                />

                                <Input
                                    value={props.name}
                                    label="Branch Name"
                                    onChange={(e) => props.setName(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />
                            </Stack>

                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    readOnly
                                    label="City Code"
                                    value={props.cityCode}
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    disabled
                                />

                                <Input
                                    label="City Name"
                                    value={props.cityName}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => { CariCity() }}>
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={3}
                            lg={3}
                            sm={3}
                            xs={12}
                        >
                            <Input
                                label="Initial Company"
                                value={props.initialCompany}
                                onChange={(e) => props.setInitialCompany(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label="Abbreviation"
                                value={props.abbreviation}
                                onChange={(e) => props.setAbbreviation(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label="Zip Code"
                                value={props.zipCode}
                                onChange={(e) => props.setZipCode(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                        <Input
                            disabled
                            label="Country"
                            value={props.countryName}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />

                        <Input
                            disabled
                            label="Continent"
                            value={props.continentName}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />

                        <Input
                            type='number'
                            label="Time Zone"
                            value={props.timeZone}
                            onChange={(e) => props.setTimeZone(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                        />
                    </Stack>
                </CardContent>
            </Card>

            <Card
                component="div"
                style={{
                    borderRadius: '12px',
                    backgroundColor: '#FAFAFA',
                    padding: '8px 24px',
                    border: '1px solid var(--Grey-Outline-2, #989898)',
                    marginTop: '24px'
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                        }}
                    >
                        Branch Contact Detail
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            sm={12}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    label="Phone"
                                    value={props.phone}
                                    onChange={(e) => props.setPhone(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Fax"
                                    value={props.fax}
                                    onChange={(e) => props.setFax(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Input
                                    label="Email"
                                    type={'email'}
                                    value={props.email}
                                    onChange={(e) => props.setEmail(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />
                            </Stack>

                            <Input
                                label={'Address'}
                                value={props.address}
                                onChange={(e) => props.setAddress(e.target.value)}
                                multiline
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                                inputBaseProps={{
                                    rows: 4,
                                    sx: {
                                        padding: '8px',
                                    },
                                }}
                            />

                            <Input
                                label="Email CS"
                                type={'email'}
                                value={props.email}
                                onChange={(e) => props.setEmail(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Card
                component="div"
                style={{
                    borderRadius: '12px',
                    backgroundColor: '#FAFAFA',
                    padding: '8px 24px',
                    border: '1px solid var(--Grey-Outline-2, #989898)',
                    marginTop: '24px'
                }}
            >
                <CardContent>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                        }}
                    >
                        Wajib Pajak Info
                    </Typography>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={12}
                            lg={12}
                            sm={12}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                <Input
                                    label={'NPWP'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: NPWPMask,
                                        value: props.npwp,
                                        onChange: (e) => {
                                            let text = e.target.value
                                            if (text) { text = text.toUpperCase() }
                                            handleChangeNPWP(text)
                                        }
                                    }}
                                    message={props.npwp === '' || isValidNPWP ? '' : 'NPWP Format is Wrong'}
                                />

                                <Input
                                    label="NPPKP"
                                    value={props.nppkp}
                                    onChange={(e) => props.setNppkp(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <div ref={idtkuRef} style={{ width: '100%' }}>
                                    <Input
                                        label={'IDTKU'}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            inputComponent: IDTKUMask,
                                            value: props.idtku,
                                            onChange: (e) => {
                                                let text = e.target.value
                                                if (text) { text = text.toUpperCase() }
                                                handleChangeIDTKU(text)
                                            }
                                        }}
                                        message={isValidIDTKU ? '' : 'IDTKU Format is Wrong'}
                                    />
                                </div>
                            </Stack>

                            <Input
                                label="Nama Wajib Pajak"
                                value={props.namaWP}
                                onChange={(e) => props.setNamaWP(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label={'Alamat Wajib Pajak'}
                                value={props.address}
                                onChange={(e) => props.setAddress(e.target.value)}
                                multiline
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px'
                                    }
                                }}
                                inputBaseProps={{
                                    rows: 4,
                                    sx: {
                                        padding: '8px',
                                    },
                                }}
                            />

                            <Input
                                label="Approval Wajib Pajak"
                                value={props.namaWP}
                                onChange={(e) => props.setNamaWP(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        width={'xl'}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModalCity && (
                    <ModalCity
                        isModal={isModalCity}
                        handleClick={CariCity}
                        titleModal={titleModalCity}
                        setIsModal={setIsModalCity}

                        isLoading={isLoadingCity}
                        data={dataCity}
                        dataMap={dataMapCity}
                        dataTable={dataTableCity}
                        columnData={columnDataCity}
                        SelectedData={SelectedDataCity}
                        totalRows={totalRowsCity}
                        setSelectedData={setSelectedDataCity}
                        getData={getDataCity}

                        setCityId={props.setCityId}
                        setCityCode={props.setCityCode}
                        setCityName={props.setCityName}
                        setCountryName={props.setCountryName}
                        setContinentName={props.setContinentName}
                    />
                )
            }

            {
                isModalCompany && (
                    <ModalCompany
                        isModal={isModalCompany}
                        handleClick={SearchCompany}
                        titleModal={titleModalCompany}
                        setIsModal={setIsModalCompany}

                        isLoading={isLoadingCompany}
                        data={dataCompany}
                        dataMap={dataMapCompany}
                        dataTable={dataTableCompany}
                        columnData={columnDataCompany}
                        SelectedData={SelectedDataCompany}
                        totalRows={totalRowsCompany}
                        setSelectedData={setSelectedDataCompany}
                        getData={getDataCompany}

                        setCompanyId={props.setCompanyId}
                        setCompanyName={props.setCompanyName}
                    />
                )
            }
        </>

    )
}

export default CreateEditBranch