import { Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableX from '../../../../../components/Tables/TableX';
import { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';

const SelectDataShipment = (props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let check = 0;
            props.ShipmentOrderIdList.forEach(element => {
                if (props.SelectedData.id === element) {
                    check = 1;
                }
            });

            if (check === 0) {
                let Sequence = 1;
                let tpId = 0;
                let customerId = '';
                let customerName = '';

                if (props.detailSequence > 0) {
                    Sequence = props.detailSequence + 1;
                }

                if (!props.temporaryReceiptId) {
                    tpId = props.temporaryReceiptId;

                    if (tpId === undefined) {
                        tpId = 0;
                    }
                }
                else {
                    tpId = 0;
                }

                if (props.jobType.serviceType === 'Export') {
                    if (props.SelectedData.billToShipperId !== 0) {
                        customerId = props.SelectedData.billToShipperId;
                        customerName = props.SelectedData.billToShipperName;
                    }
                    else {
                        customerId = props.SelectedData.shipperId;
                        customerName = props.SelectedData.shipperName;
                    }
                }
                else {
                    if (props.SelectedData.billToConsigneeId !== 0) {
                        customerId = props.SelectedData.billToConsigneeId;
                        customerName = props.SelectedData.billToConsigneeName;
                    }
                    else {
                        customerId = props.SelectedData.consigneeId;
                        customerName = props.SelectedData.consigneeName;
                    }
                }

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "rowStatus": "ACT",
                    "id": 0,
                    "sequence": Sequence,
                    "tpId": tpId,
                    "exRateId": props.RateId,
                    "rate": props.Rate,
                    "cashUSD": 0,
                    "cashIDR": 0,
                    "bankUSD": 0,
                    "bankIDR": 0,
                    "paidUSD": false,
                    "paidIDR": false,
                    "saveRV": false,
                    "shipmentOrderId": props.SelectedData.id,
                    "shipmentNo": props.SelectedData.shipmentNo,
                    "customerId": customerId,
                    "customerName": customerName,
                    "isStored": false,

                    "idLama": 0,
                    "temporaryPaymentJobBanks": [],
                };

                props.setShipmentOrderList([...props.ShipmentOrderList, data]);

                props.setShipmentOrderIdList([...props.ShipmentOrderIdList, data.shipmentOrderId]);

                emptyState();
                props.handleClick();

                props.setIsModal(false);
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Selected!' });
                emptyState();
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#FFF'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'warehouse'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataShipment;