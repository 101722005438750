import React from "react";
import { Grid } from "@mui/material";

const ButtonInfo = ({ ButtonComponents = null, isForm = true }) => {
    return (
        <div
            style={{
                bottom: 0,
                zIndex: 1000,
                width: '100%',
                position: 'sticky',
                backgroundColor: '#FAFAFA',
                padding: isForm ? '0px 32px 0px' : '0px',
            }}
        >
            <Grid
                container
                alignContent={'right'}
                justifyContent={"right"}
                sx={{ backgroundColor: '#FAFAFA', width: '100%', p: '0px 0px 16px' }}
            >
                <ButtonComponents />
            </Grid>
        </div>
    )
};

export default ButtonInfo;