import React, { memo } from 'react';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { newWindowConfig } from '../../../../utils/masks';

const ButtonCoreTaxSystem = memo(({
    ReloadData, UploadData, ImportData, handleClickFilter, filterName, DataRegUserAccess
}) => {
    const history = useNavigate();

    const renderButton = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.DownloadButton(ImportData, 'BULK IMPORT');
            case 'PRI':
                return ButtonComponent.UploadButton(UploadData, 'BULK REQUEST');
            case 'UPP':
                return ButtonComponent.CustomButton(() => window.open('/Majura/TaxNumberGeneration/CancelRequest', newWindowConfig), 'return', 'CANCEL REQUEST');
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid
                    container
                    item
                    spacing={1}
                    direction="row"
                    style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}
                >
                    {ButtonComponent.ReloadButton(ReloadData)}

                    {DataRegUserAccess.map((data) => (
                        <React.Fragment key={data.accessId}>
                            {renderButton(data.accessCode)}
                        </React.Fragment>
                    ))}

                    {ButtonComponent.CustomButton(handleClickFilter, 'filter', filterName)}
                    {ButtonComponent.CustomButton(() => history('/Majura/Invoice'), 'pop-up', 'ALL INVOICE')}
                </Grid>
            </div>
        </div>
    );
})

export default memo(ButtonCoreTaxSystem);