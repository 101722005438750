import { memo, useEffect, useState } from "react";
import FormInfo from "../../../../components/Components/FormInfo";
import { Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import { Icon, Input, showToast, TextInfo } from "../../../../components/ui";
import ApiService from "../../../../services/ApiService";
import { handleDataTable } from "../../../../utils/reusableFunction";
import SelectDataNonContact from "../Selects/SelectDataNonContact";

const TruckingRate = memo(({
    sectorId,
    sectorName,
    setSectorId,
    setSectorName,
    coverage,
    setCoverage,
    rateCollie,
    setRateCollie,
    rateSize20,
    setRateSize20,
    rateSize40,
    setRateSize40,
    rateKgs,
    setRateKgs,
    rateCbm,
    setRateCbm,
}) => {
    const [titleModalN, setTitleModalN] = useState('');
    const [isModalN, setIsModalN] = useState(false);
    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [dataTableN, setDataTableN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);

    useEffect(() => {
        getDataNonContact(1, 50);
        // eslint-disable-next-line
    }, [titleModalN]);

    const handleClickN = () => {
        if (isModalN === false) {
            setIsModalN(true);
            setIsLoadingN(true);
        }
        else {
            setIsModalN(false);
        }
    };

    const SearchSector = (type) => {
        if (type === 'search') {
            setTitleModalN('Sector');
            handleClickN();
        } else {
            setSectorId(0);
            setSectorName('');
            setCoverage('-');
            setRateCollie(0);
            setRateSize20(0);
            setRateSize40(0);
            setRateKgs(0);
            setRateCbm(0);
        }
    }

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        if (titleModalN === 'Sector') {
            param = 'zone/zone';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (titleModalN) {
                            case 'Sector':
                                setDataN(response.data.data.zone);
                                temp = response.data.data.zone;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableN, 'id');
                        setColumnDataN(response.data.data.columns.headerColumns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data, ' + error });
                    setIsModalN(false);
                })
        }
    }

    return (
        <>
            <div style={{ marginTop: '20px', width: '100%' }}>
                <form>
                    <FormInfo icon={'approve-doc'} isTop={true}>
                        <Stack direction={'column'} spacing={'20px'} sx={{ width: '100%' }}>
                            <Grid container sx={{ display: 'flex', overflow: 'hidden' }}>
                                <Grid item xs={4}>
                                    <Input
                                        label="Sector"
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={sectorName}
                                        type='text'
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {
                                                        sectorId !== 0 && (
                                                            <IconButton onClick={() => SearchSector('delete')}>
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#BE2957',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        )
                                                    }

                                                    <IconButton onClick={() => SearchSector('search')}>
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#00286D',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container sx={{ width: '100%', flexWrap: 'nowrap', overflow: 'hidden' }}>
                                <Grid item xs={4}>
                                    <TextInfo title={'Coverage'} value={coverage ? coverage : '-'} />
                                </Grid>

                                <Grid item xs={4} sx={{ paddingLeft: '64px' }}>
                                    <TextInfo title={`Rate Size 20'`} value={rateSize20} />
                                </Grid>

                                <Grid item xs={4} sx={{ paddingLeft: '64px' }}>
                                    <TextInfo title={`Rate Size 40'`} value={rateSize40} />
                                </Grid>
                            </Grid>

                            <Grid container sx={{ width: '100%', flexWrap: 'nowrap', overflow: 'hidden' }}>
                                <Grid item xs={4}>
                                    <TextInfo title={'Rate per Collie'} value={rateCollie} />
                                </Grid>

                                <Grid item xs={4} sx={{ paddingLeft: '64px' }}>
                                    <TextInfo title={'Rate per Kgs'} value={rateKgs} />
                                </Grid>

                                <Grid item xs={4} sx={{ paddingLeft: '64px' }}>
                                    <div style={{ marginBottom: '24px' }}>
                                        <TextInfo title={'Rate per Cbm'} value={rateCbm} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Stack>
                    </FormInfo>
                </form>
            </div>

            {
                isModalN && (
                    <SelectDataNonContact
                        isModal={isModalN}
                        handleClick={handleClickN}
                        titleModal={titleModalN}
                        setIsModal={setIsModalN}
                        setTitleModal={setTitleModalN}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        dataTable={dataTableN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setSectorId={setSectorId}
                        setSectorName={setSectorName}
                        setCoverage={setCoverage}
                        setRateCollie={setRateCollie}
                        setRateSize20={setRateSize20}
                        setRateSize40={setRateSize40}
                        setRateKgs={setRateKgs}
                        setRateCbm={setRateCbm}
                    />
                )
            }
        </>
    )
});

export default memo(TruckingRate);