import { forwardRef, memo, useRef, useState } from "react";
import { FullPopUp, Icon, Paginate, showToast } from "../../../../components/ui";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import LoadingSpinner from "../../../../components/Components/LoadingSpinner";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import TableX from "../../../../components/Tables/TableX";
import ModalCancelRequest from "./ModalCancelRequest";
import ApiService from "../../../../services/ApiService";

const TitleDetailComp = forwardRef(({ subTitle = '' }, ref) => {
    return (
        <div ref={ref}>
            <Typography
                sx={{
                    color: 'var(--Mjr-black-1, #171717)',
                    fontFamily: 'Switzer',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                }}
            >
                {subTitle}
            </Typography>
        </div>
    )
})

const ModalPreviewDetail = memo(({
    isModal,
    handleClick,
    title,
    subTitle,
    setTotalRows,
    getData,
    columnData,
    isLoading,
    setIsLoading,
    dataMap,
    SelectedData,
    setSelectedData,
    dataTable,
    totalRows,
    tableAccess
}) => {
    const modalTableRef = useRef(null);
    const param = 'invoice/invoice';
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [isModalCancel, setIsModalCancel] = useState(false);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    const Cancel = () => {
        emptyState();
        handleClick();
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50);
    };

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter, sortArr);
    }

    const CellDoubleClick = () => {

    }

    const getContainerHeight = () => {
        const hTitle = 21
        const hPadding = 64
        const hBtnPage = 56
        const hTabPage = 64
        const actionPage = 110

        const totalHeight = hPadding + hTitle + hBtnPage + hTabPage + actionPage

        return `${totalHeight}px`
    }

    const handleClickCancel = () => {
        if (isModalCancel) {
            setIsModalCancel(false);
        }
        else {
            setIsModalCancel(true);
        }
    }

    const handleCancelRequest = () => {
        handleClickCancel();
        setIsLoading(true);

        let body = {
            "invoice": {
                "id": '' + SelectedData.invoiceId,
                "isNonJob": SelectedData.isNonJob,
            }
        };
        ApiService.OperationCustomPost(param + '/UnApproveEFaktur', body)
            .then((response) => {
                if (response.data.code === 200) {
                    showToast({ type: 'success', message: 'Cancel Request Success!' });

                    setTimeout(() => {
                        getData(1, 50, []);
                    }, 1000);
                }
                else {
                    setIsLoading(false)
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const handleDropDownAction = (selectData, actionType, pageCode) => {
        setSelectedData(selectData);
        if (pageCode === 'TaxNumberGeneration') {
            switch (actionType) {
                case 'cancel':
                    handleClickCancel();
                    break;
                default:
                    break;
            }
        }
    }

    const scrollToBottom = () => {
        if (modalTableRef.current) {
            modalTableRef.current.scrollBy({ top: 10000, behavior: "smooth" });
        }
    };

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    style={{
                        display: 'flex',
                        padding: '8px 16px',
                        flexDirection: 'column',
                        alignSelf: 'stretch',
                        borderRadius: '12px',
                        border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                        background: 'var(--Mjr-white-4, #FFF)',
                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        marginTop: filterBy.length > 0 ? '16px' : '0px',
                    }}
                >
                    <div
                        ref={modalTableRef}
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                        }}
                    >
                        {
                            isLoading ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <TableX
                                            uniqueKey={'invoiceId'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                            uniqueCol={'TaxNumberGeneration'}
                                            customAction={handleDropDownAction}
                                            tableAccess={tableAccess}
                                        />
                                    </>
                                )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <FullPopUp
                id='modal-id'
                title={title}
                isOpen={isModal}
                handleClose={Cancel}
                titleDetail={
                    <TitleDetailComp
                        subTitle={subTitle}
                    />
                }
            >
                <Box sx={{ marginTop: '16px', width: '100%' }}>
                    {mainContainer}

                    <div style={{ marginTop: '32px' }}>
                        <Grid container item direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <IconButton
                                    size="small"
                                    sx={{
                                        display: 'flex',
                                        padding: '12px 20px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '16px',
                                        border: '1px solid var(--Mjr-Random-Light, #96B9FF)',
                                        maxHeight: '42px',
                                        maxWidth: '58px'
                                    }}
                                    onClick={scrollToBottom}
                                >
                                    <Icon
                                        iconName="arrow-down"
                                        svgIconProps={{
                                            fontSize: "small",
                                            htmlColor: "#00286D",
                                            sx: { height: "18px", width: "18px" },
                                        }}
                                    />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </FullPopUp>

            {
                isModalCancel && (
                    <ModalCancelRequest
                        isModal={isModalCancel}
                        handleClick={handleClickCancel}
                        handleCancelRequest={handleCancelRequest}
                    />
                )
            }
        </>
    );
})

export default memo(ModalPreviewDetail);