import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Grid, Box, MenuItem, Card, CardContent } from '@mui/material';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonTemporaryReceiptListView from './Buttons/ButtonTemporaryReceiptListView';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Selection, showToast } from '../../../../components/ui';
import { setDateFormat } from '../../../../utils/date';
import { encryptFunction, PrintPDF } from '../../../../utils/reusableFunction';

const TemporaryReceiptListView = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [isCompany, setIsCompany] = useState('C');
    const CustomCompanyData = [
        { id: 1, code: 'C', name: 'Per Company' },
        { id: 2, code: 'R', name: 'Per Regional' },
    ];

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Temporary Receipt List', link: '/Majura/TemporaryReceiptListReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Temporary Receipt List - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataAccess = () => {
        setIsLoadingBackDrop(true);
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'RTRL')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getDateNow();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCOSC() {
        if (titleConfirmation === 'Print') {
            PrintPDFData();
        }
        setIsModalConfirmation(false);
    }

    const PrintData = () => {
        setTitleConfirmation('Print')
        setTitleModalConfirmation('Temporary Receipt List');
        handleClickConfirmation();
    };

    function PrintPDFData() {
        setIsLoadingBackDrop(true);

        let params = [
            {
                "attr": "StartDate",
                "value": dateFrom
            },
            {
                "attr": "EndDate",
                "value": dateTo
            }
        ]

        let reportLink = 'Reports/Finance/Print/ControlReport/TemporaryReceiptListReport/TemporaryReceiptListReport/PrintTemporaryReceiptList?payloads=' + encryptFunction(params);
        PrintPDF(setIsLoadingBackDrop, 'Temporary Receipt List', reportLink);
    }

    function getDateNow() {
        setIsLoadingBackDrop(false);
        setDateFormat(new Date(), setDateFrom);
        setDateFormat(new Date(), setDateTo);
    }

    function handleDate(source, value) {
        if (source === 'From') {
            if (value > dateTo) {
                showToast({ type: 'error', message: 'Period From Can\'t Be Greater Than Period To' });
            }
            else {
                setDateFrom(value);
            }
        }
        else {
            if (value < dateFrom) {
                showToast({ type: 'error', message: 'Period To Can\'t Be Smaller Than Period From' });
            }
            else {
                setDateTo(value);
            }
        }
    }

    const ButtonComponents = () => {
        return (
            <ButtonTemporaryReceiptListView
                PrintData={PrintData}
                DataRegUserAccess={DataRegUserAccess}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Temporary Receipt List'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#FFF', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            fullWidth={true}
                                            value={isCompany}
                                            label={'Custom Company'}
                                            placeholder={'Select Custom Company'}
                                            onChange={(e) => setIsCompany(e.target.value)}
                                        >
                                            {
                                                CustomCompanyData.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Grid
                                            container
                                            spacing={'32px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    type={'date'}
                                                    value={dateFrom}
                                                    label="Period From Date"
                                                    onChange={(e) => handleDate("From", e)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <CustomDatePicker
                                                    type={'date'}
                                                    value={dateTo}
                                                    label="Period To Date"
                                                    onChange={(e) => handleDate("To", e)}
                                                    formControlProps={{
                                                        sx: {
                                                            width: '100%',
                                                            marginTop: '16px'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationCOSC}
                    />
                )
            }
        </Box>
    )
})

export default TemporaryReceiptListView