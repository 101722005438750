import { Grid, Stack, IconButton, MenuItem, InputAdornment, } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import SelectDataContact from '../Selects/SelectDataContact';
import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../../components/ui';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const ReceiptVoucherHeader = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isShipper, setIsShipper] = useState(false);
    const [isConsignee, setIsConsignee] = useState(false);
    const [isAgent, setIsAgent] = useState(false);
    const [isSSLine, setIsSSLine] = useState(false);
    const [isEMKL, setIsEMKL] = useState(false);
    const [isBranch, setIsBranch] = useState(false);
    const [isIATA, setIsIATA] = useState(false);
    const [isDepo, setIsDepo] = useState(false);
    const [isPersonal, setIsPersonal] = useState(false);
    const [isTrucking, setIsTrucking] = useState(false);
    const [isNone, setIsNone] = useState(false);

    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([])
    const [dataTableC, setDataTableC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([])

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchContact = (data) => {
        if (data === 'search') {
            setTitleModal('Contact');
            handleClick();
        }
        else if (data === 'delete') {
            props.setContactId(0);
            props.setContactCode('');
            props.setContactName('');
            props.setDataReceiptVoucher([]);
            props.setDataReceiptVoucherId([]);
            props.setBankData([]);
            props.setCashPaymentUSD(0);
            props.setCashPaymentIDR(0);
            props.calculateTotalDebitCredit([], props.Rate);
        }
    }

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([])
    const [dataTableN, setDataTableN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([])
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);

    const handleClick2 = (title) => {
        if (isModal2 === false) {
            setIsModal2(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal2(false);
            setTitleModal2('');
            localStorage.removeItem("title");
        }
    };

    const dataPaymentTo = [
        { id: 1, code: 2, name: 'Shipper', disabled: isShipper },
        { id: 2, code: 3, name: 'Consigee', disabled: isConsignee },
        { id: 3, code: 1, name: 'Agent', disabled: isAgent },
        { id: 4, code: 4, name: 'SSLine', disabled: isSSLine },
        { id: 5, code: 6, name: 'EMKL', disabled: isEMKL },
        { id: 6, code: 11, name: 'Branch', disabled: isBranch },
        { id: 7, code: 5, name: 'IATA', disabled: isIATA },
        { id: 8, code: 7, name: 'Depo', disabled: isDepo },
        { id: 9, code: 10, name: 'Trucking', disabled: isTrucking },
        { id: 10, code: 12, name: 'Personal', disabled: isPersonal },
        { id: 11, code: 0, name: 'None', disabled: isNone },
    ];

    function handleReceiptBy(val) {
        props.setReceiptBy(val);

        props.setContactId(0);
        props.setContactCode('');
        props.setContactName('');
        props.setCityId(0);
        props.setCityInt('');
        props.setCityName('');
        props.setAddress('');
        props.setDataReceiptVoucher([]);
        props.setDataReceiptVoucherId([]);
        props.setBankData([]);
        props.setCashPaymentUSD(0);
        props.setCashPaymentIDR(0);
        props.calculateTotalDebitCredit([], props.Rate);

        if (val === 'T') {
            props.setCustomerTo(4);
            props.setIsNot(false);
            props.setIsYes(true);

            setIsShipper(false);
            setIsConsignee(false);
            setIsAgent(false);
            setIsSSLine(false);
            setIsEMKL(false);
            setIsBranch(true);
            setIsIATA(false);
            setIsDepo(false);
            setIsPersonal(false);
            setIsTrucking(false);
            setIsNone(true);
        }
        else if (val === 'D') {
            props.setCustomerTo(2);
            props.setIsNot(false);
            props.setIsYes(true);

            setIsShipper(false);
            setIsConsignee(false);
            setIsAgent(true);
            setIsSSLine(true);
            setIsEMKL(true);
            setIsBranch(true);
            setIsIATA(true);
            setIsDepo(true);
            setIsPersonal(true);
            setIsTrucking(false);
            setIsNone(true);
        }
        else {
            props.setCustomerTo(0);
            props.setIsNot(true);
            props.setIsYes(false);

            setIsShipper(false);
            setIsConsignee(false);
            setIsAgent(false);
            setIsSSLine(false);
            setIsEMKL(false);
            setIsBranch(false);
            setIsIATA(false);
            setIsDepo(false);
            setIsPersonal(false);
            setIsTrucking(false);
            setIsNone(false);
        }
    }

    function handlePaymentTo(val) {
        props.setCustomerTo(val);
        props.setContactId(0);
        props.setContactCode('');
        props.setContactName('');
        props.setCityId(0);
        props.setCityInt('');
        props.setCityName('');
        props.setAddress('');
        props.setDataReceiptVoucher([]);
        props.setDataReceiptVoucherId([]);
        props.setSelectedReceiptVoucher({});
        props.setBankData([]);
        props.setCashPaymentUSD(0);
        props.setCashPaymentIDR(0);
        props.calculateTotalDebitCredit([], props.Rate);

        if (parseFloat(val) === 0) {
            props.setIsNot(true);
            props.setIsYes(false);
        }
        else {
            props.setIsNot(false);
            props.setIsYes(true);
        }
    };

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let param = '';
        var type = props.CustomerTo;
        if (type != null && type !== '') {
            if (props.ReceiptBy === 'G') {
                if (parseFloat(type) <= 10) {
                    param = 'regContact/regContact';
                    ApiService.ContactPostByPage(param, type, pageNumber, pageSize, filters)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedDataC({})
                                setDataC(response.data.data.contact);

                                let temp = response.data.data.contact
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMapC(indexed)
                                handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                                setColumnDataC(response.data.data.columns)
                                setTotalRowsC(response.data.totalRowCount)

                                setIsLoadingC(false)
                            }
                            setIsLoadingC(false)
                        })
                        .catch(function (error) {
                            setIsLoadingC(false)
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() })
                        })
                }
                else if (parseFloat(type) === 12) {
                    param = 'employee/employee';
                    ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedDataC({})
                                setDataC(response.data.data.employees);

                                let temp = response.data.data.employees
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMapC(indexed)
                                handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                                setColumnDataC(response.data.data.columns)
                                setTotalRowsC(response.data.totalRowCount)

                                setIsLoadingC(false)
                            }
                            setIsLoadingC(false)
                        })
                        .catch(function (error) {
                            setIsLoadingC(false)
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() })
                        })
                }
                else if (parseFloat(type) === 11) {
                    param = 'branch/branch';
                    ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setSelectedDataC({})
                                setDataC(response.data.data.branch);

                                let temp = response.data.data.branch
                                let indexed = temp.map((el, index) => {
                                    let indexedTemp = {
                                        ...el,
                                        index
                                    }
                                    return indexedTemp
                                })

                                setDataMapC(indexed)
                                handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                                setColumnDataC(response.data.data.columns)
                                setTotalRowsC(response.data.totalRowCount)

                                setIsLoadingC(false)
                            }
                            setIsLoadingC(false)
                        })
                        .catch(function (error) {
                            setIsLoadingC(false)
                            console.error('error saat fetch', error)
                            showToast({ type: 'error', message: error.toString() })
                        })
                }
            }
            else if (props.ReceiptBy === 'T') {
                let params = [];
                params = [
                    ...params,
                    { 'attr': 'paymentTo', 'value': type === 2 ? '8' : type === 3 ? '9' : '' + type, 'isDistinct': true },
                    { 'attr': 'customerId', 'value': '', 'isDistinct': true },
                    { 'attr': 'isAllRV', 'value': 'false' },
                    { 'attr': 'isApprovedAcc', 'value': 'true' }
                ];
                param = 'temporaryPayment/temporaryPayment';

                ApiService.FinAccPostByPage(param, 'SEACON', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.temporaryPayments);

                            let temp = response.data.data.temporaryPayments
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns.headerColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        showToast({ type: 'error', message: error.toString() })
                    })
            }
            else if (props.ReceiptBy === 'D') {
                let params = [];
                params = [
                    ...params,
                    { 'attr': 'contactTypeId', 'value': '' + type, 'isDistinct': true },
                    { 'attr': 'contactId', 'value': '', 'isDistinct': true },
                    { 'attr': 'isDP', 'value': 'true', 'isDistinct': true },
                    { 'attr': 'isDPJob', 'value': 'true', 'isDistinct': true },
                    { 'attr': 'isAllRV', 'value': 'false' },
                    { 'attr': 'isApprovedAcc', 'value': 'true' }
                ];
                param = 'temporaryReceipt/temporaryReceipt';

                ApiService.FinAccPostByPage(param, 'SEACON', pageNumber, pageSize, params, filters)
                    .then((response) => {
                        if (response.data.code === 200) {
                            setSelectedDataC({})
                            setDataC(response.data.data.temporaryReceipts);

                            let temp = response.data.data.temporaryReceipts
                            let indexed = temp.map((el, index) => {
                                let indexedTemp = {
                                    ...el,
                                    index
                                }
                                return indexedTemp
                            })

                            setDataMapC(indexed)
                            handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableC, 'id');
                            setColumnDataC(response.data.data.columns.headerColumns)
                            setTotalRowsC(response.data.totalRowCount)

                            setIsLoadingC(false)
                        }
                        setIsLoadingC(false)
                    })
                    .catch(function (error) {
                        setIsLoadingC(false);
                        showToast({ type: 'error', message: error.toString() })
                    })
            }
        }
        else {
            showToast({ type: 'error', message: 'Error!' })
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let title = JSON.parse(localStorage.getItem("title"));
        if (title === 'City') {
            let param = 'city/city';
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        setDataN(response.data.data.city);

                        let temp = response.data.data.city
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableN, 'id');
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() })
                })
        }
    };

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <div ref={props.infossNoRef}>
                                    <Input
                                        label={'Infoss Number'}
                                        value={props.infossNo}
                                        onChange={(e) => props.setInfossNo(e.target.value)}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginBottom: '16px !important'
                                            }
                                        }}
                                        disabled={props.isApprovedAcc}
                                        hidden={!isInfossNo}
                                        message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={!props.isEdit ? 6 : 8}
                                lg={!props.isEdit ? 6 : 8}
                                sm={!props.isEdit ? 6 : 8}
                                xs={12}
                            >
                                <Input
                                    label="Receipt Voucher No"
                                    textAlign='right'
                                    value={props.RVNo}
                                    disabled
                                    hidden={!props.isEdit}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <Grid
                                    container
                                    spacing={'32px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                        hidden={!props.isEdit}
                                    >
                                        <Input
                                            label="Reference"
                                            value={props.Reference}
                                            disabled
                                            hidden={!props.isEdit}
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={!props.isEdit ? 12 : 6}
                                        lg={!props.isEdit ? 12 : 6}
                                        sm={!props.isEdit ? 12 : 6}
                                        xs={12}
                                    >
                                        <div style={{ marginTop: !props.isEdit ? '0px' : '16px' }}>
                                            {props.selectPrincipleBy}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xl={!props.isEdit ? 6 : 4}
                                lg={!props.isEdit ? 6 : 4}
                                sm={!props.isEdit ? 6 : 4}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Rate'
                                            value={props.Rate}
                                            textAlign='right'
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Verify Acc'
                                            value={props.VerifyAccOn}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <CustomDatePicker
                                            label='Date Rate'
                                            value={props.ExRateDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Print Date'
                                            value={props.PrintedOn}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <div style={{ marginTop: '16px' }}>
                                    {props.selectReceiptBy(props.ReceiptBy, handleReceiptBy, props.isDisable)}
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <div style={{ marginTop: '16px' }}>
                                    <Selection
                                        id='0'
                                        label={'Receipt From'}
                                        fullWidth={true}
                                        placeholder={'Select Receipt From'}
                                        value={props.CustomerTo}
                                        onChange={(e) => handlePaymentTo(e.target.value)}
                                        disabled={props.isDisable}
                                    >
                                        {
                                            dataPaymentTo.map((data, index) => (
                                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code} hidden={data.disabled}>{data.name}</MenuItem>
                                            ))
                                        }
                                    </Selection>
                                </div>
                            </Grid>
                        </Grid>


                        <Grid
                            container
                            spacing={'32px'}
                            hidden={props.CustomerTo !== 0 ? false : true}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Customer'
                                        value={props.ContactCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%',
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.ContactName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchContact('delete')}
                                                        disabled={props.isNot}
                                                        hidden={props.isEdit ? true : props.ContactName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        hidden={props.isEdit}
                                                        disabled={!props.isJobOwner === true && props.isNot === false ? false : true}
                                                        onClick={() => searchContact('search')}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Input
                            label={'Address'}
                            multiline
                            fullWidth
                            value={props.Address}
                            disabled={props.isYes}
                            onChange={(e) => props.setAddress(e.target.value)}
                            hidden={props.CustomerTo === 0 ? false : true}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                            inputBaseProps={{
                                rows: 4,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                        />
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        type={props.CustomerTo}
                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setContactId={props.setContactId}
                        setContactCode={props.setContactCode}
                        setContactName={props.setContactName}
                        ReceiptBy={props.ReceiptBy}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataNonContact
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        data={dataN}
                        columnData={columnDataN}
                        dataMap={dataMapN}
                        dataTable={dataTableN}
                        setCityId={props.setCityId}
                        setCityInt={props.setCityInt}
                        setCityName={props.setCityName}
                    />
                )
            }
        </div>
    )
}

export default ReceiptVoucherHeader;