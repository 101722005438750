import { Stack, Box, Menu, Badge, Avatar, AppBar, Toolbar, Backdrop, MenuItem, IconButton, Typography } from '@mui/material';
import { HubConnectionState } from '@microsoft/signalr';
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '../../ui';
import { LightTooltip } from '../Sidebar/SideNavbar';
import majuraIconBlue from '../../../Assets/Icon/majuraIconBlue.svg';
import LoadingSpinner from '../../Components/LoadingSpinner';
import ApiService, { username, signalRConnection, roleName } from '../../../services/ApiService';

export default function AppHeader(props) {
    const photoProfile = localStorage.getItem('profilePicture') !== undefined ? localStorage.getItem('profilePicture') : null;
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const profileRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const [jmlTodo, setJmlTodo] = useState(0)
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(false);

    const history = useNavigate();

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            countRefresh++;
        }

        if (signalRConnection.state === HubConnectionState.Disconnected) {
            getConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countRefresh]);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        name = name.toUpperCase();
        let nama = name;
        let pjg = name.split(' ').length
        if (pjg > 1) {
            nama = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        } else {
            nama = `${name.split(' ')[0][0]}`
        }
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: '32px',
                height: '32px'
                // bgcolor: '#45454d',
            },
            children: nama
        };
    }

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(profileRef.current);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const logOut = () => {
        if (isLoggingOut) return;  // Prevent multiple clicks

        setIsLoggingOut(true);
        handleMenuClose();
        setIsLoadingBackDrop(true);

        if (props?.childRef?.current?.localSave) {
            props.childRef.current.localSave();
        }

        localStorage.setItem(username + "-lastUrl", JSON.stringify(window.location.pathname));

        ApiService.logOut().then((res) => {
            setTimeout(() => {
                localStorage.setItem(username + "-isLogOut", JSON.stringify(true));
                setIsLoadingBackDrop(false);
                history('/', { replace: true });
                setIsLoggingOut(false); // Reset state after logout
            }, 500);
        });
    };

    async function getConnection() {
        try {
            signalRConnection.on("ReceiveMessage", (res) => {
                if (res === 'ToDoNotif') {
                    ApiService.refreshToken();
                    ApiService.OperationPostByPage('todo/todo', 1, 100)
                        .then((response) => {
                            if (response.data.code === 200) {
                                setJmlTodo(response.data.totalRowCount)
                            }
                        })
                        .catch(function (error) {
                            console.error('error saat fetch', error)
                        })
                }
            });

            signalRConnection.onclose(e => { });

            if (signalRConnection.state === HubConnectionState.Disconnected) {
                await signalRConnection.start();
                await signalRConnection.invoke("Show", JSON.parse(localStorage.getItem("userCode")));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleProfile = () => {
        history('/Majura/profile')
        handleMenuClose()
    }

    const handleTodo = () => {
        history("/Majura")
        window.location.reload()
    }

    const handleMenu = () => {
        history("/Majura/mainMenu")
    }

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            open={isMenuOpen}
            keepMounted
            onClose={handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: '16px',
                        padding: '0px !important',
                        boxShadow: '2px 2px 16px 0px rgba(0, 0, 0, 0.08)',
                        border: '1px solid var(--Mjr-blue-4, #E5E9F0)',
                        background: '#FFF',
                    }
                }
            }}
            sx={{
                flexDirection: 'column',
                borderRadius: '4px',
                marginTop: '2px',
                padding: '0px !important',
            }}
        >
            <MenuItem
                onClick={handleProfile}
                sx={{
                    padding: '8px 16px',
                    cursor: 'pointer',
                    width: profileRef.current?.clientWidth,
                    borderBottom: '1px solid #E9E9EA',
                    transition: 'color 0.2s ease-in-out',
                }}
            >
                <Stack direction={'row'} spacing={'6px'} alignItems={'center'}>
                    <Icon
                        iconName={'account-outline'}
                        svgIconProps={{
                            htmlColor: '#171717',
                            sx: {
                                height: '10px',
                                width: '10px'
                            }
                        }}
                    />

                    <Typography
                        sx={{
                            color: 'var(--Mjr-black-2, #171717)',
                            fontFamily: 'Switzer',
                            fontSize: '10px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '160%',
                            letterSpacing: '0.1px',
                            '&:hover': {
                                color: '#00286D',
                            },
                        }}
                    >
                        PROFILE
                    </Typography>
                </Stack>
            </MenuItem>

            <MenuItem
                onClick={logOut}
                sx={{
                    padding: '8px 16px',
                    cursor: 'pointer',
                    transition: 'color 0.2s ease-in-out',
                }}
            >
                <Stack direction={'row'} spacing={'6px'} alignItems={'center'} justifyContent={'center'}>
                    <Icon
                        iconName={'log-out'}
                        svgIconProps={{
                            htmlColor: '#BE2957',
                            sx: {
                                height: '10px',
                                width: '10px'
                            }
                        }}
                    />

                    <Typography
                        sx={{
                            color: 'var(--Mjr-black-2, #BE2957)',
                            fontFamily: 'Switzer',
                            fontSize: '10px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '160%',
                            letterSpacing: '0.1px',
                            '&:hover': {
                                color: '#00286D',
                            },
                        }}
                    >
                        LOGOUT
                    </Typography>
                </Stack>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}

            <MenuItem >
                <IconButton
                    size="large"
                    aria-label="show Menu"
                    color="inherit"
                    onClick={handleMenu}
                >
                    <Badge color="error">
                        <Icon iconName={'book'} />
                    </Badge>
                </IconButton>
                <p>Menu</p>
            </MenuItem>

            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="show new notifications"
                    color="inherit"
                    onClick={handleTodo}
                >
                    <Badge badgeContent={jmlTodo} color="error">
                        <Icon iconName={'bell'} />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>

            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {/* <AccountCircle /> */}
                    {/* <Avatar alt="Profile Pic" src={profile} /> */}
                    <Avatar {...stringAvatar(username)} />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    return (
        <>
            <AppBar
                position="static"
                elevation={0}
                sx={{
                    display: 'flex',
                    padding: '0px 32px 0px 48px',
                    justifyContent: 'space-between',
                    alignSelf: 'stretch',
                    borderBottom: '1px solid var(--Mjr-blue-4, #E5E9F0)',
                    background: '#fff',
                    maxHeight: '64px',
                    boxShadow: 'none !important',
                    '&.MuiPaper-root, &.MuiAppBar-root': {
                        boxShadow: 'none !important',
                    },
                    '&MuiPaper-root-MuiAppBar-root': {
                        boxShadow: 'none !important',
                    },
                }}
            >
                <Toolbar sx={{ padding: '0px !important', boxShadow: 'none !important' }}>
                    <div style={{ width: 'fit-content', justifyContent: 'left' }}>
                        <Avatar
                            src={majuraIconBlue}
                            sx={{ width: 'fit-content', height: '20px' }}
                            variant='square'
                            alt='.'
                        />
                    </div>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Stack direction={'row'} spacing={'16px'}>
                            <LightTooltip title="To Do">
                                <IconButton
                                    size="small"
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    aria-label="show new notifications"
                                    color="inherit"
                                    onClick={handleTodo}
                                    sx={{ padding: '0px !important' }}
                                >
                                    <Badge
                                        badgeContent={jmlTodo}
                                        color="error"
                                        sx={{
                                            "& .MuiBadge-badge": {
                                                fontSize: '8px',
                                                padding: '4px'
                                            }
                                        }}
                                    >
                                        <Icon
                                            iconName={'bell'}
                                            svgIconProps={{
                                                htmlColor: '#000',
                                                sx: {
                                                    height: '16px',
                                                    width: '16px'
                                                }
                                            }}
                                        />
                                    </Badge>
                                </IconButton>
                            </LightTooltip>

                            <div
                                ref={profileRef}
                                style={{
                                    display: 'flex',
                                    padding: '6px 16px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '16px',
                                    maxHeight: '40px',
                                    borderRadius: '16px',
                                    border: '1px solid var(--Mjr-white-1, #E9E9EA)',
                                }}
                            >
                                <Box onClick={handleProfileMenuOpen} sx={{ cursor: 'pointer' }}>
                                    <Stack direction={'row'} spacing={'16px'} alignItems={'center'}>
                                        {
                                            photoProfile !== null ?
                                                <Avatar sx={{ width: '24px', height: '24px', userSelect: 'none' }} src={photoProfile} />
                                                :
                                                <Avatar sx={{ width: '24px', height: '24px', userSelect: 'none' }} {...stringAvatar(username)} />
                                        }

                                        <Stack direction={'column'}>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Mjr-blue-2, #00286D)',
                                                    fontFamily: 'Switzer',
                                                    fontSize: '10px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '160%',
                                                    letterSpacing: '0.1px',
                                                    userSelect: 'none',
                                                }}
                                            >
                                                {username}
                                            </Typography>

                                            <Typography
                                                sx={{
                                                    color: 'var(--Mjr-blue-2, #00286D)',
                                                    fontFamily: 'Switzer',
                                                    fontSize: '10px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '160%',
                                                    letterSpacing: '0.1px',
                                                    userSelect: 'none',
                                                }}
                                            >
                                                {roleName}
                                            </Typography>
                                        </Stack>

                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            disableFocusRipple={true}
                                            disableRipple={true}
                                        >
                                            <Icon
                                                iconName={'arrow-down'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#6692DE',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </Stack>
                                </Box>
                            </div>

                            {/* <LightTooltip title="Profile">
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={menuId}
                                    aria-haspopup="true"
                                    onClick={handleProfileMenuOpen}
                                    color="inherit"
                                    sx={{ padding: '0px' }}
                                >
                                </IconButton>
                            </LightTooltip> */}
                        </Stack>
                    </Box>

                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="small"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <Icon iconName={'three-dot'} svgIconProps={{ htmlColor: '#00286D' }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}
            {renderMenu}

            <Backdrop
                sx={{ color: '#000', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoadingBackDrop}
            >
                <LoadingSpinner />
            </Backdrop>
        </>
    );
}